
import Timeago from 'react-timeago';
import styled from 'styled-components';

import IAlert from 'src/ServerEntities/IAlert';

import AlertIcon from './Components/AlertIcon';
import { SessionContext } from '../SessionContext';
import React from 'react';

const ItemHeader = styled.header`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    background-color: #246896;  
    &.unassigned {
        background-color: #818181;
    }
    &.closed {
        background-color: #6d2f35;
    }
    padding: 4px;
    flex-direction: column;
    @media (min-width: 1279px) {
        padding: 0 4px 0 8px;
        border-radius: 16px;
        flex-direction: row;
    }
    & img {
        margin: 0 4px;
    }
    & time {
        margin: 0 4px;
        flex: 1 0 auto;
    }
`;

const AlertContainer = styled.li`
    margin-bottom: 10px;
`;

const AlertCount = styled.i`
    font-style: normal;
    background-color: #f99600;
    &.closed {
        background-color: #000000;
        padding: 2px 10px;
        border: 1px solid #f99600;
    }
    border-radius: 16px;
    padding: 4px 12px;
    margin: 0 4px;
    @media (max-width: 1279px) {
        display: none;
    }
`;

const AlertText = styled.p`
    margin: 0 4px;
    flex: 1 1 auto;
    overflow-wrap: break-word;
    overflow: hidden;
    @media (max-width: 1279px) {
        margin: 0;
        width: 100%;
    }
`;

const MAX_ALERT_LENGTH = 200;

export type SIZE = "large" | "medium" | "small";

interface IProps {
    alert: IAlert,
    closed: boolean
};

const shortFormatter = (value: number, unit: string, suffix: string) => {
    return `${value} ${unit.substring(0, 1)}`;
};

const getAlertText = (alert: IAlert) => {
    let alertText = alert.alert.length > MAX_ALERT_LENGTH ? alert.alert.substring(0, MAX_ALERT_LENGTH) : alert.alert;
    if (alert.service) {
        return `${alert.service} \u2013 ${alertText}`;
    }
    return alertText;
};

const DashboardAlertItem = (props: IProps) => {
    const { alert, closed } = props;
    const { state } = React.useContext(SessionContext);

    const openServer = (alertId: number) => (event: React.MouseEvent<HTMLImageElement>) => {
        window.open(`/Ram/Alerts/${state.webToken}?alertId=${alertId}`);
        event.stopPropagation();
    };

    return <AlertContainer className={closed ? "closed" : (alert.username ? "assigned" : "unassigned")}>
        <ItemHeader tabIndex={0} onClick={openServer(alert.id)}>
            <Timeago date={alert.lastUpdate || alert.timestamp} formatter={shortFormatter} />
            <AlertIcon type={alert.type} />
            <AlertCount className={closed ? "closed" : "current"}>{alert.count}</AlertCount>
            <AlertText>{getAlertText(alert)}</AlertText>
        </ItemHeader>
    </AlertContainer>;
};

export default DashboardAlertItem;
