import * as React from "react";
import styled from "styled-components";

interface IProps {
    children?: React.ReactNode,
    direction?: "row" | "column",
    style?: object
};

const OuterContainer = styled.div`
    display: flex;
    flex: 1 1 auto;
    overflow-x: hidden;
    position: relative;
    height: 100%;
`;

const InnerContainer = styled.div<IProps>`
    display: flex;
    flex-direction: ${props => props.direction};
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    overflow: auto;
`;

const ScrollingContainer = (props: IProps) => {
    const { children, direction } = props;
    return <OuterContainer className="scrolling-outer-container">
        <InnerContainer className="scrolling-inner-container" direction={direction || "row"}>
            {children}
        </InnerContainer>
    </OuterContainer>;
};

export default ScrollingContainer;