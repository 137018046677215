import styled from 'styled-components';

const DialogContent = styled.div`
    flex: 1 1 auto;
    padding: 0 24px 0px 24px;
    overflow-y: auto;
    background-color: #ffffff;
`;

export default DialogContent;
