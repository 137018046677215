import styled from 'styled-components';

interface IStyleProps {
    $dark?: boolean
};

const Overlay = styled.div<IStyleProps>`
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    position: fixed;
    touch-action: none;
    background-color: ${props => props.$dark ? "rgba(0, 0, 0, 0.5)" : "rgba(255, 255, 255, 0.5)"};
    -webkit-tap-highlight-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default Overlay;
