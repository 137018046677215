import * as React from 'react';
import useEffectOnSome from 'src/CustomHooks/useEffectOnSome';
import { BACKGROUND_COLOURS } from 'src/ServerEntities/IAlert';
import IRole from 'src/ServerEntities/IRole';
import IUser from 'src/ServerEntities/IUser';
import Button from 'src/SharedComponents/Button';
import Card from 'src/SharedComponents/Card';
import ErrorBox from 'src/SharedComponents/ErrorBox';
import IconButton from 'src/SharedComponents/IconButton';
import Label from 'src/SharedComponents/Label';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import ScrollingContainer from 'src/SharedComponents/ScrollingContainer';
import TextInput from 'src/SharedComponents/TextInput';
import ViewContainer from 'src/SharedComponents/ViewContainer';
import createErrorMessage from 'src/UsefulFunctions/createErrorMessage';
import dateConverter from 'src/UsefulFunctions/dateConverter';
import Description from 'src/Views/Manager/Components/Description';
import ManagerTitle from 'src/Views/Manager/Components/Title';
import TitleBarContainer from 'src/Views/Manager/Components/TitleBarContainer';
import styled from 'styled-components';
import { debounce } from 'throttle-debounce';
import AddRoleToUserDialog from './AddRoleDialog';
import ChangeColourDialog from './ChangeColourDialog';
import * as userManagementService from "./userManagementService";

interface IProps {
    selectedUser: IUser
    webToken: string,
    refreshUsers: () => void,
    setErrorMessage: (errorMessage: string) => void,
    loggedInUser: string,
    outerRefresh: number,
    setOuterRefresh: (count: number) => void
};

const TitleBar = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px 24px;
`;

const Title = styled.span`
    font-size: 18px;
    letter-spacing: 0.25px;
    line-height: 24px;
    font-weight: 600;
`;

interface IColourIconProps {
    colour: BACKGROUND_COLOURS
};

const UsernameRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const ColourIcon = styled.i<IColourIconProps>`
    height: 20px;
    width: 20px;
    margin: 0 12px;
    border-radius: 10px;
    background-color: ${props => props.colour};
    cursor: pointer;
`;

const Grid = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const NoRolesText = styled.div`
    display: flex;
    flex-direction: row;
    color: #555;
    justify-content: center;
    align-items: center;
    padding: 24px;
`;

const UserDetailsBar = styled.div`
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #e5e5e5;
    padding: 6px 24px;
    & p {
        margin: 0 24px 0 0;
        font-size: 14px;
        color: #4c4c4c;
        font-weight: bold;
    }
    & p label {
        font-weight: normal;
    }
`;

const PhoneNumberContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding: 2px 0;
    align-items: center;
    & > p {
        margin: 0 0 0 5px;
    }
`;

const SavedText = styled.p`
    color: #16B012;
    &:before {
        content: '\u2713';
        margin-right: 4px;
    }
`;

const UserDetailsView = styled(ViewContainer)`
    @media (min-width: 350px) and (max-width: 768px) {
        position: relative;
        bottom: 15%;
    }
`

const renderName = (user: IUser) => {
    if (user.title) {
        return `${user.fullName} - ${user.title}`;
    }
    return user.fullName;
};

const UserDetails = (props: IProps) => {
    const [showAddRoleDialog, setShowAddRoleDialog] = React.useState(false);
    const [showChangeColourDialog, setShowChangeColourDialog] = React.useState(false);
    const [refresh, setRefresh] = React.useState(0);
    const { webToken, selectedUser, refreshUsers, setErrorMessage, loggedInUser, outerRefresh, setOuterRefresh } = props;
    const [roles, setRoles] = React.useState([] as unknown as IRole[]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [userColour, setUserColour] = React.useState(selectedUser.colour);
    const [phoneNumber, setPhoneNumber] = React.useState(selectedUser.phoneNumber);
    const [phoneNumberUpdated, setPhoneNumberUpdated] = React.useState(false);

    useEffectOnSome(() => {
        setLoading(true);
        userManagementService.getRolesForUser(selectedUser.id, webToken, (serverRoles: IRole[]) => {
            setRoles(serverRoles);
            setLoading(false);
            setError("");
        }, (errorMessage: string) => {
            setRoles([]);
            setLoading(false);
            setError(errorMessage);
        })
    }, [webToken], [selectedUser, refresh]);

    const openChangeColourDialog = () => {
        setShowChangeColourDialog(true);
    };

    const closeChangeColourDialog = () => {
        setShowChangeColourDialog(false);
    };

    const openAddRoleDialog = () => {
        setShowAddRoleDialog(true);
    };

    const closeAddRoleDialog = () => {
        setShowAddRoleDialog(false);
    };

    const closeAddRoleDialogAndRefresh = () => {
        setShowAddRoleDialog(false);
        refreshRoles();
    };

    const refreshRoles = () => {
        setRefresh(refresh + 1);
    };

    const toggleUserState = () => {
        selectedUser.status = !selectedUser.status;
        setRefresh(refresh + 1);
        refreshUsers();
    };

    const updatePhoneNumber = (e: React.SyntheticEvent<HTMLInputElement>) => {
        const number = e.currentTarget.value;
        setPhoneNumber(number);
        setPhoneNumberUpdated(false);
        deferredSaveNumber(number);
    };

    const disableUser = () => {
        if (selectedUser) {
            setErrorMessage("");
            userManagementService.disableUser(
                selectedUser.id,
                webToken,
                toggleUserState,
                (serverError: string) => {
                    setErrorMessage(createErrorMessage("disabling user", serverError));
                });
        }
    };

    const enableUser = () => {
        if (selectedUser) {
            setErrorMessage("");
            userManagementService.enableUser(
                selectedUser.id,
                webToken,
                toggleUserState,
                (serverError: string) => {
                    setErrorMessage(createErrorMessage("enabling user", serverError));
                });
        }
    };

    const saveNewPhoneNumber = (phoneNumber: string) => {
        setErrorMessage("");
        setPhoneNumberUpdated(false);
        userManagementService.changePhoneNumber(
            selectedUser.id,
            phoneNumber,
            webToken,
            () => {
                setPhoneNumberUpdated(true);
                setOuterRefresh(outerRefresh + 1);
            }, (error: string) => {
                setErrorMessage(error);
            }
        );
    };
    const deferredSaveNumber = React.useRef(debounce(500, saveNewPhoneNumber)).current;

    const removeRoleFromUser = (role: IRole) => () => {
        setErrorMessage("");
        userManagementService.removeRoleFromUser(
            role.id,
            selectedUser.id,
            webToken,
            refreshRoles,
            (serverError: string) => {
                setErrorMessage(createErrorMessage("removing a role from a user", serverError));
            });
    };

    return <UserDetailsView>
        <AddRoleToUserDialog
            show={showAddRoleDialog}
            onClose={closeAddRoleDialog}
            onConfirmRoleAdd={closeAddRoleDialogAndRefresh}
            user={selectedUser}
            usersRoles={roles} />
        <ChangeColourDialog
            currentColour={selectedUser.colour}
            username={selectedUser.id}
            onChangeColour={setUserColour}
            show={showChangeColourDialog}
            onClose={closeChangeColourDialog}
            />
        <ScrollingContainer direction="column">
            <TitleBarContainer>
                <div>
                    <UsernameRow>
                        <ManagerTitle>{renderName(selectedUser)}</ManagerTitle><ColourIcon colour={userColour} onClick={openChangeColourDialog} />
                    </UsernameRow>
                    <Description>{selectedUser.id} {selectedUser.status === false ? "- Disabled user" : ""}</Description>
                    <PhoneNumberContainer>
                        <TextInput value={phoneNumber || ""} onChange={updatePhoneNumber} placeholder="Phone number" width="100px" />
                        {phoneNumberUpdated && <SavedText>Saved</SavedText>}
                    </PhoneNumberContainer>
                </div>
                <div>
                    {!selectedUser.status && <Button color={"#4f7326"} onClick={enableUser} disabled={selectedUser && selectedUser.id === loggedInUser}>Enable user</Button>}
                    {selectedUser.status && <Button color={"#ad0000"} onClick={disableUser} disabled={selectedUser && selectedUser.id === loggedInUser}>Disable user</Button>}
                </div>
            </TitleBarContainer>
            <UserDetailsBar>
                <p><label>Department</label>: {selectedUser.department || "Not known"}</p>
                <p><label>Organisation</label>: {selectedUser.organisation || "Not known"}</p>
                <p><label>Last login</label>: {dateConverter(selectedUser.lastLogin) || "No logins recorded"}</p>
                <p><label>Account created</label>: {dateConverter(selectedUser.created) || "Not known"}</p>
            </UserDetailsBar>
            <TitleBar>
                <Title>Roles</Title>
                <Button onClick={openAddRoleDialog}>Add role(s)</Button>
            </TitleBar>
            <LoadingIndicator type="Linear" show={loading} />
            <Grid style={{ padding: "0px 12px" }}>
                {error && <ErrorBox>{error}</ErrorBox>}
                {roles.map((role) => <Card key={role.id} style={{ width: "320px", margin: "12px", padding: "2px 2px 0px 0px" }}>
                    <Label style={{ padding: "6px 8px 6px 16px", wordWrap: "normal" }}>
                        <h2>{role.id}</h2>
                        <h3>{role.description}</h3>
                    </Label>
                    <IconButton onClick={removeRoleFromUser(role)}>×</IconButton>
                </Card>)}
                {roles.length === 0 && <NoRolesText>There are no roles currently associated with this user.</NoRolesText>}
            </Grid>
        </ScrollingContainer>
    </UserDetailsView>;
};

export default UserDetails;
