import { DateTime } from "luxon";
import { HEARTBEAT_WARNING_LIMIT } from "src/config";
import IAlert from "src/ServerEntities/IAlert";
import IServer from "src/ServerEntities/IServer";
import IServerAlerts from "src/ServerEntities/IServerAlerts";
import ISite from "src/ServerEntities/ISite";
import styled from "styled-components";

const StatusHeaderContainer = styled.div`
        display:flex;
        flex-direction: row;
    `;

const GreenContainer = styled.div`
        background-color: #42a83f;
        padding: 2px 10px 2px 10px;
        border-radius: 6px 0px 0px 6px; 
    `;

const YellowContainer = styled.div`
        background-color: #f99600;
        padding: 2px 10px 2px 10px;
    `;

const YellowContainerAsFirst = styled.div`
        background-color: #f99600;
        padding: 2px 10px 2px 10px;
          border-radius: 6px 0px 0px 6px; 
    `;

const RedContainer = styled.div`
        background-color: #ff2121;
        padding: 2px 10px 2px 10px;
        border-radius: 0px 6px 6px 0px; 
    `;


const PurpleContainer = styled.div`
        background-color: #6902de;
        padding: 2px 10px 2px 10px;
    `;

const GreyContainer = styled.div`
        background-color: #9e9e9e;
        padding: 2px 10px 2px 10px;
    `;

export const StatusHeader = (data: IStatusHeader | undefined, twoValuesOnly: boolean, queue?: boolean, disabled?: boolean) => {
    if (data) {
        return <StatusHeaderContainer>
            {!twoValuesOnly && <GreenContainer>{data.green}</GreenContainer>}
            {twoValuesOnly && <YellowContainerAsFirst>{data.yellow}</YellowContainerAsFirst>}
            {!twoValuesOnly && <YellowContainer>{data.yellow}</YellowContainer>}
            {!twoValuesOnly && queue && <PurpleContainer>{data.purple}</PurpleContainer>}
            {!twoValuesOnly && disabled && <GreyContainer>{data.grey}</GreyContainer>}
            <RedContainer>{data.red}</RedContainer>
        </StatusHeaderContainer>
    }
    return null;
}

export interface IStatusHeader {
    green: number,
    yellow: number,
    red: number,
    purple?: number,
    grey?: number
}

export const getServerStatusHeaderData = (items: ISite[]) => {
    let green = 0;
    let red = 0;
    let yellow = 0;

    items.forEach((item: ISite) => { item.servers.forEach(parseState) })

    function parseState(item: IServer) {
        const heartbeatHealthy = item.lastHeartbeat > (DateTime.now().toMillis() - HEARTBEAT_WARNING_LIMIT);
        if (!heartbeatHealthy) {
            red++;
        } else if (item.alerts > 0) {
            yellow++;
        } else {
            green++;
        }
    }

    const result: IStatusHeader = { "red": red, "yellow": yellow, "green": green }

    return result;
}

export const getErrorStatusHeaderData = (items: IServerAlerts[]) => {
    let green = 0;
    let red = 0;
    let yellow = 0;

    items.forEach((item: IServerAlerts) => { item.alerts.forEach(parseState) })

    function parseState(item: IAlert) {

        if (item.status === "firing") {
            red++;
        } else
            yellow++;
    }

    const result: IStatusHeader = { "red": red, "yellow": yellow, "green": green }

    return result;
}