import * as React from 'react';
import styled from 'styled-components';

import useEffectOnSome from 'src/CustomHooks/useEffectOnSome';
import ErrorBox from 'src/SharedComponents/ErrorBox';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import { SessionContext } from 'src/Views/SessionContext';
import { getNotificationTimeouts } from '../UserManagement/smsNotificationService';

import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import INotificationTimeout from 'src/ServerEntities/INotificationTimeout';
import HeartbeatNotificationRow from './HeartbeatNotificationRow';
import NotificationRuleDetails from './NotificationRuleDetails';

const SectionHeader = styled.div`
    background: #ffffff;
    border-bottom: 1px solid #e5e5e5;
    padding: 12px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    & > div {
        display: flex;
        flex-direction: column;
        width: 600px;
    }
    & p {
        margin: 6px 0;
        font-size: 1.2rem;
    }
    & h1 {
        font-size: 1.6rem;
        margin: 6px 0;
        font-weight: 500;
    }
`;

const Content = styled.div`
    padding: 12px 24px;
    & tr {
        display: flex;
        flex-direction: row;
    }
    & td {
        display: flex;
        flex-direction: row;
        padding: 4px;
        align-items: center;
        &:first-child {
            padding-left: 0;
            width: 150px;
        }
    }
`;

const getNotificationMessage = (heartbeatTimeouts: INotificationTimeout[]) => {
    if (!heartbeatTimeouts.length) {
        return "No heartbeat notifications sent.";
    }
    const min = heartbeatTimeouts.map(n => n.time).reduce((currentMin, value) => value < currentMin ? value : currentMin, heartbeatTimeouts[0].time);
    const max = heartbeatTimeouts.map(n => n.time).reduce((currentMax, value) => value > currentMax ? value : currentMax, heartbeatTimeouts[0].time);
    if (min === max) {
        return `Notifications sent after ${min} minutes.`
    }
    return `Notifications sent after between ${min} and ${max} minutes.`;
};

const HeartbeatNotifications = () => {
    const [open, setOpen] = React.useState(false);
    const { webToken } = React.useContext(SessionContext).state;
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [timeouts, setTimeouts] = React.useState([] as unknown as INotificationTimeout[]);
    const [notificationOpen, setNotificationOpen] = React.useState(null as unknown as INotificationTimeout | null);

    useEffectOnSome(() => {
        setLoading(true);
        getNotificationTimeouts(webToken, (heartbeatTimeouts) => {
            setLoading(false);
            setError("");
            setTimeouts(heartbeatTimeouts);
        }, (errorMessage) => {
            setLoading(false);
            setError(errorMessage);
        })
    }, [webToken], []);

    const toggleOpen = () => {
        setOpen(!open);
    };

    const updateItem = (index: number, newTimeout: number) => {
        const timeoutsCopy = [...timeouts];
        timeoutsCopy[index].time = newTimeout;
        setTimeouts(timeoutsCopy);
    };

    return <div>
        {notificationOpen && <NotificationRuleDetails notificationOpen={notificationOpen} setNotificationOpen={setNotificationOpen}
            updateItem={updateItem} setError={setError} webToken={webToken} />}
        {error && <ErrorBox>{error}</ErrorBox>}
        <SectionHeader>
            <div>
                <h1>Heartbeat notifications</h1>
                <p>{getNotificationMessage(timeouts)}</p>
            </div>
            <FontAwesomeIcon style={{cursor: "pointer"}} size='2x' icon={open ? faChevronUp : faChevronDown} onClick={toggleOpen} />
        </SectionHeader>
        <LoadingIndicator type="Linear" show={loading} />
        {open && <Content>
            <table>
                <tbody>
                    {timeouts.map((timeout, index) => {
                        return <HeartbeatNotificationRow
                            key={`heartbeat-timeout-${index}`}
                            notificationTimeout={timeout}
                            webToken={webToken}
                            setError={setError}
                            updateItem={updateItem}
                            setNotificationOpen={setNotificationOpen}
                            index={index}
                        />
                    })}
                </tbody>
            </table>
        </Content>}
    </div>;
};

export default HeartbeatNotifications;
