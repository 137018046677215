import * as React from 'react';
import styled from 'styled-components';
import { debounce } from 'throttle-debounce';

import IRole from "src/ServerEntities/IRole";
import IRoleSite from 'src/ServerEntities/IRoleSite';

import { SessionContext } from 'src/Views/SessionContext';

import Button from 'src/SharedComponents/Button';
import Dialog from 'src/SharedComponents/Dialog/Dialog';
import DialogActions from 'src/SharedComponents/Dialog/DialogActions';
import ErrorBox from 'src/SharedComponents/ErrorBox';
import Label from 'src/SharedComponents/Label';
import ListItem from 'src/SharedComponents/List/ListItem';
import SectionList, { ISectionListData, ISectionListRenderItemInfo } from 'src/SharedComponents/List/SectionList';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import TextInput from 'src/SharedComponents/TextInput';
import ManagerTitleBar from 'src/Views/Manager/ManagerTitleBar';

import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { addSitesToRole, getTopHundredSitesToAdd, searchForSitesToAdd } from "./roleManagementService";
import useEffectOnSome from 'src/CustomHooks/useEffectOnSome';

const SiteSearchBoxContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding: 8px;
`;

const EmptyList = () => <div style={{ color: "#555", padding: "24px" }}>There are no more sites to add to this role.</div>

const getSections = (sites: IRoleSite[]): Array<ISectionListData<{ title: string, description: string }>> => [
    { data: sites.map((site: IRoleSite) => ({ title: site.id, description: site.description })) }
];

interface IProps {
    role: IRole,
    show: boolean,
    onClose: () => void,
    onConfirmSiteAdd: () => void
}

const siteMatchesSite = (siteToMatch: string) => (site: IRoleSite) => siteToMatch === site.id;

const AddSiteToRoleDialog = (props: IProps) => {
    const { webToken } = React.useContext(SessionContext).state;
    const role = props.role;
    const [siteFilterText, setSiteFilterText] = React.useState("");
    const [sitesToAdd, setSitesToAdd] = React.useState([] as IRoleSite[]);
    const [sites, setSites] = React.useState([] as IRoleSite[]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");

    const closeDialog = () => {
        setSitesToAdd([]);
        props.onClose();
    };

    const searchSites = (searchText: string) => {
        if (searchText) {
            searchForSitesToAdd(role.id, searchText, webToken, (sitesFromServer: IRoleSite[]) => {
                setLoading(false);
                setSites(sitesFromServer);
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
            });
        } else {
            getTopHundredSitesToAdd(role.id, webToken, (sitesFromServer: IRoleSite[]) => {
                setLoading(false);
                setSites(sitesFromServer);
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
            });
        }
    };
    const delayedSearch = React.useRef(debounce(250, searchSites)).current;

    const toggleSiteToAdd = (site: IRoleSite) => () => {
        const sitesToAddCopy = [...sitesToAdd];
        const siteIndexInArray = sitesToAddCopy.findIndex((siteToAdd: IRoleSite) => {
            return site.id === siteToAdd.id;
        });
        if (siteIndexInArray === -1) {
            sitesToAddCopy.push(site);
        } else {
            sitesToAddCopy.splice(siteIndexInArray, 1);
        }
        setSitesToAdd(sitesToAddCopy);
    };

    const addSitesConfirm = () => {
        addSitesToRole(
            sitesToAdd,
            role.id,
            webToken,
            () => {
                setSitesToAdd([]);
                props.onConfirmSiteAdd();
            },
            (errorMessage) => {
                return ""
            })
    };

    const changeSiteFilterText = (e: React.SyntheticEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;
        setLoading(true);
        delayedSearch(value);
        setSiteFilterText(value);
    };

    useEffectOnSome(() => {
        searchSites("");
    }, [], [searchSites]);

    const renderSite = (info: ISectionListRenderItemInfo<{ title: string, description: string }>) =>
        <ListItem onClick={toggleSiteToAdd(sites[info.index])} key={`item${info.index}`}>
            <Label>
                <h2>{info.item.title}</h2>
                <h3>{info.item.description}</h3>
            </Label>
            {sitesToAdd.findIndex(siteMatchesSite(info.item.title)) !== -1 && <FontAwesomeIcon icon={faCheck} style={{ color: "#187fba" }} />}
        </ListItem>;

    return <Dialog open={props.show} onClose={closeDialog} style={{ width: "650px", height: "550px" }}>
        <ManagerTitleBar viewName="Add site(s)" viewDescription="Select sites below to add to this role." />
        <LoadingIndicator show={loading} type="Linear" />
        <SiteSearchBoxContainer>
            <TextInput margin="0 24px 0 0" value={siteFilterText} onChange={changeSiteFilterText} placeholder="Search" />
        </SiteSearchBoxContainer>
        {error && <ErrorBox>{error}</ErrorBox>}
        {sites.length === 0 && <EmptyList />}
        <SectionList sections={getSections(sites)} renderItem={renderSite} />
        <DialogActions>
            <Button outlined={true} onClick={closeDialog}>Cancel</Button>
            <Button onClick={addSitesConfirm} margin="0px 16px 0px 16px" disabled={sitesToAdd.length === 0}>Confirm</Button>
        </DialogActions>
    </Dialog>;
};

export default AddSiteToRoleDialog;
