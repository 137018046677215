import axios, { AxiosError, AxiosResponse } from "axios";

import { getAPIUrl } from 'src/config';
import IErrorDetails from "src/ServerEntities/IErrorDetails";
import IRole from 'src/ServerEntities/IRole';
import IUser from 'src/ServerEntities/IUser';
import { NETWORK_ERROR } from 'src/UsefulFunctions/createErrorMessage';

const handleError = (error: AxiosError<IErrorDetails>, failureCallback: (errorMessage: string) => void) => {
    let errorMessage = "Unknown error";
    if (error.response) {
        errorMessage = error.response.data.message
        if (error.response.status === 401 && error.response.data.message === undefined) {
            errorMessage = "Unauthorised to perform this request."
        }
    }
    if (error.message === "Network Error") {
        errorMessage = NETWORK_ERROR;
    }
    failureCallback(errorMessage as string);
};

export const searchForUsers = (searchTerm: string, webToken: string, successCallback: (users: IUser[]) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(
        `${getAPIUrl()}users/lookup/${searchTerm}`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
    .then((response: AxiosResponse<IUser[]>) => {
        successCallback(response.data);
    })
    .catch((error: AxiosError<IErrorDetails>) => {
        handleError(error, failureCallback);
    });
};

export const changeColour = (username: string, colour: string, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.post(
        `${getAPIUrl()}users/${username}/colour`,
        colour,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
    .then((response: AxiosResponse) => {
        successCallback();
    })
    .catch((error: AxiosError<IErrorDetails>) => {
        handleError(error, failureCallback);
    });
};

export const changePhoneNumber = (username: string, phoneNumber: string, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.put(
        `${getAPIUrl()}users/${username}/phoneNumber`,
        phoneNumber,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
    .then((response: AxiosResponse) => {
        successCallback();
    })
    .catch((error: AxiosError<IErrorDetails>) => {
        handleError(error, failureCallback);
    });
};

export const getRolesForUser = (username: string, webToken: string, successCallback: (roles: IRole[]) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(
        `${getAPIUrl()}users/${encodeURIComponent(username)}/roles`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
    .then((response: AxiosResponse<IRole[]>) => {
        successCallback(response.data);
    })
    .catch((error: AxiosError<IErrorDetails>) => {
        handleError(error, failureCallback);
    });
};

export const disableUser = (userId: string, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.put(
        `${getAPIUrl()}users/${userId}/disable`,
        {},
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<any>) => {
            successCallback();
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
}

export const enableUser = (userId: string, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.put(
        `${getAPIUrl()}users/${userId}/enable`,
        {},
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<any>) => {
            successCallback();
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
}

export const addRolesToUser = (roles: IRole[], userId: string, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    roles.forEach((role: IRole) => {
        axios.put(
            `${getAPIUrl()}users/${userId}/${role.id}/enable`,
            {},
            { headers: { Authorization: `Bearer ${webToken}` } }
        )
            .then((response: AxiosResponse<any>) => {
                successCallback();
            })
            .catch((error: AxiosError<IErrorDetails>) => {
                handleError(error, failureCallback);
            });
    })
};

export const removeRoleFromUser = (roleId: string, userId: string, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.put(
        `${getAPIUrl()}users/${userId}/${roleId}/disable`,
        {},
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<any>) => {
            successCallback();
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
}

export const markDomainAsHidden = (domainId: string, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.put(
        `${getAPIUrl()}domains/${domainId}/hidden`,
        {},
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<any>) => {
            successCallback();
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
}

export const markDomainAsNotHidden = (domainId: string, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.delete(
        `${getAPIUrl()}domains/${domainId}/hidden`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<any>) => {
            successCallback();
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
}

export const markDomainAsDefault = (domainId: string, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.put(
        `${getAPIUrl()}domains/${domainId}/default`,
        {},
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<any>) => {
            successCallback();
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const markDomainAsNotDefault = (domainId: string, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.delete(
        `${getAPIUrl()}domains/${domainId}/default`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<any>) => {
            successCallback();
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const createUser = (userId: string, webToken: string, user: IUser, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.post(
        `${getAPIUrl()}users/${userId}/create`,
        user,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<any>) => {
            successCallback();
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};
