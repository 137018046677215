import React from 'react';
import 'react-toggle/style.css';

import ErrorBox from 'src/SharedComponents/ErrorBox';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import { SessionContext } from 'src/Views/SessionContext';
import IDashboardInfo from 'src/ServerEntities/IDashboardInfo';
import { getDashboardInfo } from './ramService';
import ViewContainer from 'src/SharedComponents/ViewContainer';
import ClientView from 'src/Views/Ram/Components/ClientView';
import { useNavigate } from 'react-router';
import { getFirstPathOn401RoleChange } from 'src/Roles/RolesService';
import { RolesContext } from 'src/Roles/RolesContext';

interface IProps {
    changeRole: boolean,
    setChangeRole: (changeRole: boolean) => void
};

const RoleDashboard = (props: IProps) => {
    const { changeRole, setChangeRole } = props;
    const { state } = React.useContext(SessionContext);
    const [dashboardInfo, setDashboardInfo] = React.useState(undefined as unknown as IDashboardInfo);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const roleContext = React.useContext(RolesContext);
    const navigate = useNavigate();

    const checkRoleChange = async (errorMessage: string) => {
        if(changeRole) {
            const path = await getFirstPathOn401RoleChange(errorMessage, state.webToken, roleContext.state.ramConfiguration?.components, roleContext.state.role)
            if(path) {
                navigate(path);
            }
            setChangeRole(false);
        } 
    }

    React.useEffect(() => {
        setLoading(true);
        getDashboardInfo(state.webToken, (dashboardInfo: IDashboardInfo) => {
            setLoading(false);
            setDashboardInfo(dashboardInfo);
            setError("");
            setChangeRole(false);
        }, (errorMessage: string) => {
            checkRoleChange(errorMessage);
            setLoading(false);
            setError(errorMessage);
        });
    }, [state.webToken]);


    return <ViewContainer>
        <LoadingIndicator type="Linear" show={loading} />
        {error && <ErrorBox>{error}</ErrorBox>}
        {dashboardInfo && <ClientView dashboardInfo={dashboardInfo} />}
    </ViewContainer>;
};

export default RoleDashboard;
