import Chart from 'react-apexcharts';
import styled from 'styled-components';
import IMessageThroughput from 'src/ServerEntities/IMessageThroughput';

const ServerInformationContainer = styled.div`
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    background: #1c3e5a;
    & h3 {
        background-color: #246896;
        margin: 0;
        flex: 0 0 auto;
        padding: 12px 12px 6px;
        @media (min-width: 1279px) {
            border-radius: 16px 16px 0 0;
        }
        font-weight: 500;
        font-size: 1.2rem;
    }
    @media (min-width: 1279px) {
        flex: 1 1 auto;
        border-radius: 16px;
    }
    @media (max-width: 1279px) {
        width: 100%;
        margin-bottom: 4px;
    }
`;

interface IProps {
    messageThroughput: IMessageThroughput[]
};

const ServerMessageThroughput = (props: IProps) => {
    const { messageThroughput } = props;

    const xAxis: ApexXAxis = {
        type: "datetime"
    };

    const options = {
        chart: {
            foreColor: "#ffffff"
        },
        xaxis: xAxis,
        tooltip: {
            theme: "dark"
        }
    };
    const series = [{
        data: messageThroughput.map(item => {
            return { x: item.date, y: item.messages };
        }),
        name: "Messages"
    }];
    return <ServerInformationContainer>
        <h3>Message throughput</h3>
        <div>
            <Chart
                type="area"
                height="400"
                options={options}
                series={series}
            />
        </div>
    </ServerInformationContainer>;
};

export default ServerMessageThroughput;
