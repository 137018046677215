import React from 'react';

const useEffectOnSome = (effect: () => void, deps: any[], whenDeps: any[]) => {
    const whenRef = React.useRef(whenDeps || []);
    const initial = whenRef.current === whenDeps;
    const whenDepsChanged = initial || !whenRef.current.every((w, i) => w === whenDeps[i]);
    whenRef.current = whenDeps;
    const nullDeps = deps.map(() => null);
  
    return React.useEffect( // eslint-disable-line react-hooks/exhaustive-deps
        whenDepsChanged ? effect : () => {},
        whenDepsChanged ? deps : nullDeps
    );
};

export default useEffectOnSome;
