import * as React from "react";
import styled from "styled-components";

interface IListRenderItemInfo<ItemT> {
    item: ItemT;
    index: number;
}

interface ISectionBase<ItemT> {
    data: ItemT[];
    key?: string;
    renderItem?: SectionListRenderItem<ItemT>;
    ItemSeparatorComponent?: React.ComponentType<any> | null;
    keyExtractor?: (item: ItemT, index: number) => string;
}

export interface ISectionListData<ItemT> extends ISectionBase<ItemT> {
    [key: string]: any;
}
export interface ISectionListRenderItemInfo<ItemT> extends IListRenderItemInfo<ItemT> {
    section: ISectionListData<ItemT>;
}

type SectionListRenderItem<ItemT> = (info: ISectionListRenderItemInfo<ItemT>) => React.ReactElement<any> | null;

interface ISectionListProps<ItemT> {
    ItemSeparatorComponent?: React.ComponentType<any> | null;
    ListEmptyComponent?: React.ComponentType<any> | React.ReactElement<any> | null;
    SectionSeparatorComponent?: React.ComponentType<any> | React.ReactElement<any> | null;
    keyExtractor?: (item: ItemT, index: number) => string;
    renderItem: SectionListRenderItem<ItemT>;
    renderSectionHeader?: (info: { section: ISectionListData<ItemT> }) => React.ReactElement<any> | null;
    renderSectionFooter?: (info: { section: ISectionListData<ItemT> }) => React.ReactElement<any> | null;
    sections: Array<ISectionListData<ItemT>>;
};

const MainListContainer = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    overflow: hidden;
`;

const ContentWrapper = styled.div`
    flex: 1 1 auto;
    overflow: auto;
    min-height: 0;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
`;

const SectionList = (props: ISectionListProps<any>) => {
    const { renderItem, sections, renderSectionHeader, ItemSeparatorComponent } = props;

    return <MainListContainer className="SectionList">
        <ContentWrapper className="ListContentWrapper">
            {sections.map((section: ISectionListData<any>, index: number) => {
                const sectionElements = [];
                if (renderSectionHeader) {
                    sectionElements.push(renderSectionHeader({ section }));
                }
                section.data.forEach(
                    (item: any, itemIndex: number) => {
                        if (section.renderItem) {
                            sectionElements.push(section.renderItem({ index: itemIndex, item, section }));
                        } else {
                            sectionElements.push(renderItem({ index: itemIndex, item, section }));
                        }
                        if (ItemSeparatorComponent) {
                            sectionElements.push(<ItemSeparatorComponent />);
                        }
                    }
                );

                return sectionElements;
            }
            )}
        </ContentWrapper>
    </MainListContainer>;
};

export default SectionList;
