import { useEffect } from 'react';

const useOnClickOutsideSelect = (inputRef: { current: HTMLDivElement }, optionsRef: { current: HTMLUListElement }, handler: (event: Event) => void) => {
    useEffect(
        () => {
            const listener = (event: Event) => {
                // Do nothing if clicking ref's element or descendent elements
                if (!inputRef.current && !optionsRef.current) {
                    return;
                }
                if (inputRef.current && inputRef.current.contains(event.target as Node)) {
                    return;
                }
                if (optionsRef.current && optionsRef.current.contains(event.target as Node)) {
                    return;
                }

                handler(event);
            };

            document.addEventListener('mousedown', listener);
            document.addEventListener('touchend', listener);

            return () => {
                document.removeEventListener('mousedown', listener);
                document.removeEventListener('touchend', listener);
            };
        },
        // Add ref and handler to effect dependencies
        // It's worth noting that because passed in handler is a new ...
        // ... function on every render that will cause this effect ...
        // ... callback/cleanup to run every render. It's not a big deal ...
        // ... but to optimize you can wrap handler in useCallback before ...
        // ... passing it into this hook.
        [inputRef, optionsRef, handler]
    );
}

export default useOnClickOutsideSelect;