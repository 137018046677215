import React from "react";
import IQveraInformation from "src/ServerEntities/IQveraInformation";
import styled from "styled-components";
import { SessionContext } from "../SessionContext";

const AlertContainer = styled.li`
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 8px;
    height: 25px;
    width: 25px;
    background-color: #42a83f;
`;

interface IProps {
    qveraInformation: IQveraInformation
};

export const getQveraStatusHeader = (qveraChannels: IQveraInformation[]) => {
  return qveraChannels.reduce((acc, qveraInformation) => {
    if (qveraInformation.stateDescription.toLocaleUpperCase() === "STOPPED") {
      acc.yellow += 1;
    } else if (qveraInformation.stateDescription.toLocaleUpperCase() === "ERROR") {
      acc.red += 1;
    } else if (qveraInformation.stateDescription.toLocaleUpperCase() === "DISABLED") {
      acc.grey += 1;
    } else if (qveraInformation.inboundQueue > 0) {
      qveraInformation.stateDescription = "QUEUE";
      acc.purple += 1;
    } else {
      acc.green += 1;
    }
    return acc;
  }, { green: 0, red: 0, yellow: 0, purple: 0, grey: 0 });
}

const DashboardQveraInformationItem = (props: IProps) => {
    const { state } = React.useContext(SessionContext);

    const openServer = (id: string) => (event: React.MouseEvent<HTMLElement>) => {
        window.open(`/Ram/Server/${props.qveraInformation.site}/${props.qveraInformation.server}/${props.qveraInformation.namespace}/${state.webToken}?qveraId=${id}`);
        event.stopPropagation();
    };

    return <AlertContainer className={props.qveraInformation.stateDescription.toLocaleUpperCase()} onClick={openServer(props.qveraInformation.channelId)}/>;
};

export default DashboardQveraInformationItem;