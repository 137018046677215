import * as React from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';



import { RolesContext } from 'src/Roles/RolesContext';

import { RAM_COMPONENT_NAME } from 'src/ServerEntities/IConfiguration';
import MenuLink from '../Components/MenuLink';

const TopBar = styled.header`
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    padding: 10px 16px;
    @media (max-width: 1279px) {
        flex-direction: column;
        align-items: stretch;
        padding: 0;
    }
`;



const ActionContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    @media (max-width: 1279px) {
        display: none;
    }
`;

const MobileNavContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    background: #396282;
    width: 100%;
    position: absolute;
    top: 64px;
    z-index: 2;
    & a {
        font-size: 1.4rem;
        text-align: center;
        padding: 8px 4px;
        flex: 1 1 auto;
        border: 0 none;
    }

    #1577AE
`;




const MobileLink = styled.a`
    cursor: pointer;

`;



interface IProps {
    pageName: string,
};

const RhapsodyMenus = (props: IProps) => {
    const { pageName } = props;
    const [displayMobileNav, setDisplayMobileNav] = React.useState(false);
    const roleContext = React.useContext(RolesContext);
    const configuration = roleContext.state.ramConfiguration;
    const components = configuration?.components || [];
    const navigate = useNavigate();


    const onLinkClick = (tabName: string) => () => {
        setDisplayMobileNav(false);
        navigate(`/Ram/${tabName}`);
    };


    const getComponentForMobile = (componentName: RAM_COMPONENT_NAME, index: number) => {
        switch (componentName) {
            case "RhapsodyDashboard":
                return <MobileLink onClick={onLinkClick("RhapsodyDashboard")} className={pageName === "Rhapsody Dashboard" ? "selected header-link" : "header-link"}>Dashboard</MobileLink>
            case "RhapsodyServers":
                return <MobileLink onClick={onLinkClick("RhapsodyServers")} className={pageName === "Rhapsody Servers" ? "selected header-link" : "header-link"}>Servers</MobileLink>
            default:
                return null;
        }
    };

    const getComponent = (componentName: RAM_COMPONENT_NAME, index: number) => {
        switch (componentName) {
            case "RhapsodyDashboard":
                return <MenuLink key="rhap-dashboard-link" onClick={onLinkClick("Rhapsody Dashboard")} className={pageName === "Rhapsody Dashboard" ? "selected menu-link" : "menu-link"}>Dashboard</MenuLink>
            case "RhapsodyServers":
                return <MenuLink key="rhap-servers-link" onClick={onLinkClick("Rhapsody Servers")} className={pageName === "Rhapsody Servers" ? "selected menu-link" : "menu-link"}>Servers</MenuLink>
            case "RhapsodyComponents":
                return <MenuLink key="rhap-components-link" onClick={onLinkClick("Rhapsody Components")} className={pageName === "Rhapsody Components" ? "selected menu-link" : "menu-link"}>Components</MenuLink>
            case "RhapsodyAlerts":
                return <MenuLink key="rhap-alerts-link" onClick={onLinkClick("Rhapsody Alerts")} className={pageName === "Rhapsody Alerts" ? "selected menu-link" : "menu-link"}>Alerts</MenuLink>
            case "RhapsodyQueues":
                return <MenuLink key="rhap-queues-link" onClick={onLinkClick("Rhapsody Queues")} className={pageName === "Rhapsody Queues" ? "selected menu-link" : "menu-link"}>Queues</MenuLink>
            case "RhapsodyCertificates":
                return <MenuLink key="rhap-certificates-link" onClick={onLinkClick("Rhapsody Certificates")} className={pageName === "Rhapsody Certificates" ? "selected menu-link" : "header-link"}>Certificates</MenuLink>
            case "RhapsodyUsers":
                return <MenuLink key="rhap-users-link" onClick={onLinkClick("Rhapsody Users")} className={pageName === "Rhapsody Users" ? "selected menu-link" : "menu-link"}>Users</MenuLink>
            case "RhapsodyMessages":
                return <MenuLink key="rhap-messages-link" onClick={onLinkClick("Rhapsody Messages")} className={pageName === "Rhapsody Messages" ? "selected menu-link" : "header-link"}>Messages</MenuLink>

            default:
                return null;
        }
    };

    return <TopBar>
        {displayMobileNav && <MobileNavContainer>
            {components.map(getComponentForMobile)}
        </MobileNavContainer>}
        <ActionContainer>
            <ButtonContainer>
                {components.map(getComponent)}
            </ButtonContainer>
        </ActionContainer>
    </TopBar >;
};

export default RhapsodyMenus;
