import * as React from 'react'
import IProcessThresholds from 'src/ServerEntities/IProcessThresholds';
import IUser from 'src/ServerEntities/IUser';

interface IProps {
    children?: React.ReactNode;
};

interface ISetWebTokenAction { type: "setWebToken", payload: string };
interface IClearWebTokenAction { type: "clearWebToken", payload: undefined };
interface ISetLoggedInUserAction { type: "setLoggedInUser", payload: string };
interface IClearLoggedInUserAction { type: "clearLoggedInUser", payload: undefined };
interface IUserInteractionOccurredAction { type: "userInterractionOccurred", payload: undefined };
interface IClearUserInteractionOccurredAction { type: "clearUserInterractionOccurred", payload: undefined };
interface ISetShowExpiryDialog { type: "setShowExpiryDialog", payload: boolean };
interface ISetProcessThresholds { type: "setProcessThresholds", payload: IProcessThresholds };
interface ISetCanRaiseSupport { type: "setCanRaiseSupport", payload: boolean };

interface ISessionState {
    canRaiseSupport: boolean;
    webToken: string;
    loggedInUser: string;
    reportUser?: IUser;
    showExpiryDialog: boolean;
    userInterractionOccurred: boolean;
    processThresholds?: IProcessThresholds;
}

type Actions = ISetWebTokenAction | IClearWebTokenAction |
    ISetLoggedInUserAction | IClearLoggedInUserAction |
    IUserInteractionOccurredAction | IClearUserInteractionOccurredAction | ISetShowExpiryDialog | ISetProcessThresholds |
    ISetCanRaiseSupport;

const initialState: ISessionState = {
    canRaiseSupport: false,
    loggedInUser: "",
    showExpiryDialog: false,
    userInterractionOccurred: false,
    webToken: "",
    processThresholds: undefined
};

const reducer = (state: typeof initialState, action: Actions) => {
    switch (action.type) {
        case "setWebToken":
            return { ...state, webToken: action.payload, userInteractionOccurred: true };
        case "clearWebToken":
            return { ...state, webToken: "" };
        case "setLoggedInUser":
            return { ...state, loggedInUser: action.payload };
        case "clearLoggedInUser":
            return { ...state, loggedInUser: "" };
        case "clearUserInterractionOccurred":
            return { ...state, userInterractionOccurred: false };
        case "userInterractionOccurred":
            return { ...state, userInterractionOccurred: true };
        case "setShowExpiryDialog":
            return { ...state, showExpiryDialog: action.payload };
        case "setProcessThresholds":
            return { ...state, processThresholds: action.payload };
        case "setCanRaiseSupport":
            return { ...state, canRaiseSupport: action.payload };
    }
}

const SessionContext = React.createContext(initialState as unknown as { state: ISessionState, dispatch: React.Dispatch<Actions> });

const SessionContextProvider = (props: IProps) => {
    const [state, dispatch] = React.useReducer(reducer, (initialState as never));
    const patientViewerState = (state as ISessionState);
    return <SessionContext.Provider value={{ state: patientViewerState, dispatch } as any}>
        {props.children}
    </SessionContext.Provider>;
};

export { SessionContext, SessionContextProvider };
