import heartbeatBadIcon from 'src/images/customIcons/heartbeat-bad.png';
import heartbeatGoodIcon from 'src/images/customIcons/heartbeat-good.png';
import IMirrorMetrics from 'src/ServerEntities/IMirrorMetrics';
import dateConverter from 'src/UsefulFunctions/dateConverter';
import styled from 'styled-components';

const ServerInformationContainer = styled.div`
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    background: #1c3e5a;
    min-width: 250px;
    & h3 {
        background-color: #246896;
        margin: 0;
        flex: 0 0 auto;
        padding: 12px 12px 6px;
        @media (min-width: 1279px) {
            border-radius: 16px 16px 0 0;
        }
        font-weight: 500;
        font-size: 1.2rem;
    }
    & div {
        padding: 6px 12px 12px;
        display: flex;
    }
    & table {
        flex: 1 1 auto;
        text-align: left;
    }
    & img {
        width: 24px;
        height: 24px;
    }
    @media (min-width: 1279px) {
        flex: 1 1 auto;
        border-radius: 16px;
    }
    @media (max-width: 1279px) {
        width: 100%;
        margin-bottom: 4px;
    }
`;

interface IProps {
    nodes: IMirrorMetrics[]
};

const isHealthy = (node: IMirrorMetrics) => {
    if (node.position === "Async") {
        return node.state === "Async";
    }
    return node.state === "Active";
};

const MirrorMetrics = (props: IProps) => {
    const { nodes } = props;

    return <ServerInformationContainer>
        <h3>Mirror metrics</h3>
        <div>
            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Name</th>
                        <th>Position</th>
                        <th>State</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {nodes.map((node, index) => {
                        const isHealthyNode = isHealthy(node);
                        return <tr key={`console-error-${index}`}>
                            <td>{dateConverter(node.logged, true)}</td>
                            <td>{node.nodeName}</td>
                            <td>{node.position}</td>
                            <td>{node.state}</td>
                            <td>
                                <img
                                    alt={isHealthyNode ? "Healthy node" : "Unhealthy node"}
                                    title={isHealthyNode ? "Healthy node" : "Unhealthy node"}
                                    src={isHealthyNode ? heartbeatGoodIcon : heartbeatBadIcon} />
                            </td>
                        </tr>;
                    })}
                </tbody>
            </table>
        </div>
    </ServerInformationContainer>;
};

export default MirrorMetrics;
