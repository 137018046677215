import * as React from 'react';
import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router';

import { jwtDecode, JwtPayload } from "jwt-decode";

import IRoleAndJwt from 'src/Roles/IRoleAndJwt';
import { IManagerConfiguration, IRamConfiguration } from 'src/ServerEntities/IConfiguration';

import loginBackground from 'src/images/loginBackground.png';

import Button from 'src/SharedComponents/Button';
import Card from 'src/SharedComponents/Card';
import Dialog from 'src/SharedComponents/Dialog/Dialog';
import DialogActions from 'src/SharedComponents/Dialog/DialogActions';
import DialogContent from 'src/SharedComponents/Dialog/DialogContent';
import DialogTitle from 'src/SharedComponents/Dialog/DialogTitle';
import ErrorBox from 'src/SharedComponents/ErrorBox';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import Overlay from 'src/SharedComponents/Overlay';
import TextInput from 'src/SharedComponents/TextInput';
import ViewContainer from 'src/SharedComponents/ViewContainer';

import LoginForm from './LoginForm';

import { getManagerConfigurationForRole, getPreferredRole, getRamConfigurationForRole } from 'src/Roles/RolesService';
import attemptLogin, { getUsernameForToken, sendForgotPassword } from "src/Views/Login/loginService";

import { RolesContext } from 'src/Roles/RolesContext';
import { SessionContext } from 'src/Views/SessionContext';

import { isIE } from 'react-device-detect';
import TOTPCard from './TOTPCard';

const backgroundStyle = {
    backgroundAttachment: "fixed",
    backgroundImage: `url("${loginBackground}")`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
};

const getCardStyle = (isSmall: boolean): React.CSSProperties => {
    if (isSmall) {
        return {
            display: "flex",
            flexDirection: "column",
            minWidth: "100%",
            height: "100%"
        };
    }
    return {
        display: "flex",
        flexDirection: "column",
        minWidth: "500px",
        marginBottom: "100px"
    };
};

interface IProps {
    applicationName: string
};

interface IMXJwtPayload extends JwtPayload {
    MFA?: string
}


const Login = (props: IProps) => {
    const [errorText, setErrorText] = useState("");
    const { jwt } = useParams<"jwt">();
    const isSmall = useMediaQuery({
        query: '(max-width: 1279px)'
    });
    const [loggingIn, setLoggingIn] = useState(false);
    const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);
    const [emailValue, setEmailValue] = useState("");
    const [emailSentStatus, setEmailSentStatus] = useState("");
    const [emailLoading, setEmailLoading] = useState(false);
    const [mfaDialog, setMfaDialog] = useState(false);
    const [mfaJwt, setMfaJwt] = useState("");
    const { state, dispatch } = React.useContext(SessionContext);
    const rolesContext = React.useContext(RolesContext);
    const [fullUsername, setFullUsername] = useState("");

    React.useEffect(() => {
        if (jwt) {
            setLoggingIn(true);
            getUsernameForToken(jwt, (username: string) => {
                dispatch({ type: "setLoggedInUser", payload: username });
                dispatch({ type: "setWebToken", payload: jwt });
            }, (errorMessage: string) => {
                setErrorText(errorMessage);
                setLoggingIn(false);
            });
        }
    }, [jwt, dispatch]);

    const onForgotPassword = () => {
        setEmailSentStatus("");
        setForgotPasswordOpen(true);
    };

    const onClose = () => {
        setEmailValue("");
        setForgotPasswordOpen(false);
    };

    const onSubmitForgotPassword = () => {
        setEmailLoading(true);
        sendForgotPassword(emailValue, () => {
            setEmailSentStatus("An email has been sent to reset the password.");
            setEmailLoading(false);
        }, (error: string) => {
            setEmailSentStatus(error);
            setEmailLoading(false);
        });
    };

    const onEmailChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
        setEmailValue(e.currentTarget.value);
    };

    const updateConfiguration = (configuration: IRamConfiguration) => {
        rolesContext.dispatch({ type: "setRamConfiguration", payload: configuration });
    };

    const updateManagerConfiguration = (configuration: IManagerConfiguration) => {
        rolesContext.dispatch({ type: "setManagerConfiguration", payload: configuration });
    };

    const handleConfigurationError = (serverError: string) => {
        setErrorText(serverError);
    };

    const afterAuth = (applicationName: string, jwt: string, fullUsername: string) => {

        getPreferredRole(
            applicationName,
            jwt, // Webtoken
            (preferredRole: IRoleAndJwt) => {
                setLoggingIn(false);
                rolesContext.dispatch({ type: "setRole", payload: preferredRole.role });
                dispatch({ type: "setWebToken", payload: preferredRole.jwt });
                dispatch({ type: "setLoggedInUser", payload: fullUsername });
                if (preferredRole.role) {
                    if (applicationName === "RAM") {
                        getRamConfigurationForRole(preferredRole.role, preferredRole.jwt, updateConfiguration, handleConfigurationError);
                    } else {
                        getManagerConfigurationForRole(preferredRole.role, preferredRole.jwt, updateManagerConfiguration, handleConfigurationError);
                    }
                }
            }, (errorMessage: string) => {
                setLoggingIn(false);
                dispatch({ type: "setWebToken", payload: jwt });
                dispatch({ type: "setLoggedInUser", payload: fullUsername });
            },
        );

    }

    function loginUser(username: string, password: string, applicationName: string) {
        setFullUsername(username.toLowerCase());
        setLoggingIn(true);
        attemptLogin(username.toLowerCase(), password, (jwt: string) => {
            const decoded: IMXJwtPayload = jwtDecode(jwt);
            if (decoded.MFA === "REQUIRED") {
                setMfaDialog(true);
                setMfaJwt(jwt);
            }
            else {
                setTimeout(() => {
                    afterAuth(applicationName, jwt, username)
                }, 1000)
            }
        }, (errorMessage: string) => {
            setTimeout(() => {
                setLoggingIn(false);
                setErrorText(errorMessage);
            }, 1000);
        })
    }

    const onCloseMfa = (jwt: string) => {
        setMfaDialog(false);
        afterAuth(props.applicationName, jwt, fullUsername);
    }

    if (mfaDialog) {
        return <ViewContainer className="Background" style={isSmall ? {} : backgroundStyle}>
            <Overlay $dark={true}>
                <Card style={getCardStyle(isSmall)}>
                    <TOTPCard mfaJwt={mfaJwt} onClose={onCloseMfa} />
                </Card>
            </Overlay>
        </ViewContainer>;
    }

    return <ViewContainer className="Background" style={isSmall ? {} : backgroundStyle}>
        <Overlay $dark={true}>

            <Card style={getCardStyle(isSmall)}>
                <LoginForm
                    loggingIn={loggingIn && state.webToken === ""}
                    setLoggingIn={setLoggingIn}
                    loginUser={loginUser}
                    errorText={errorText}
                    applicationName={props.applicationName}
                />
                {isIE && <ErrorBox>You are using an unsupported browser, please use Edge instead.</ErrorBox>}
                <Button plain={true} outlined={false} onClick={onForgotPassword}>Forgot password</Button>
                <Dialog darkBackground={true} style={{ padding: "10px" }} open={forgotPasswordOpen} onClose={onClose}>
                    <DialogTitle>Provide your email to reset your password.</DialogTitle>
                    <DialogContent style={{ lineHeight: "25px" }}>
                        <TextInput placeholder={"Email address"} value={emailValue} onChange={onEmailChange} />
                        <p>{emailSentStatus}</p>
                        <LoadingIndicator show={emailLoading} type={"Linear"} />
                    </DialogContent>
                    <DialogActions>
                        {emailSentStatus === "" && <Button margin="0 20px 0 0" onClick={onSubmitForgotPassword}>OK</Button>}
                        {emailSentStatus === "" && <Button margin="0 24px 0 0" onClick={onClose}>Cancel</Button>}
                    </DialogActions>
                </Dialog>
            </Card>
        </Overlay>
    </ViewContainer>;
};

export default Login;
