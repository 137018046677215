import { SyntheticEvent, useState } from "react";
import Button from "src/SharedComponents/Button";
import DialogActions from "src/SharedComponents/Dialog/DialogActions";
import DialogContent from "src/SharedComponents/Dialog/DialogContent";
import DialogContentText from "src/SharedComponents/Dialog/DialogContentText";
import DialogTitle from "src/SharedComponents/Dialog/DialogTitle";
import ErrorText from "src/SharedComponents/ErrorText";
import LoadingIndicator from "src/SharedComponents/LoadingIndicator";
import TextInputCode from "src/SharedComponents/TextInputCode";
import styled from "styled-components";
import { sendTOTP } from "./loginService";

const Logo = styled.img`
    width: 144px;
    margin: 36px 36px 24px 36px;
    user-select: none;
`;

const LoginFadeContainer = styled.div`
    position: relative;
    transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 1;
    &.invisible {
        user-interaction: none;
        opacity: 0;
    }
`;

const LoginBoxContainer = styled.div`
    position: relative;
    max-width: 500px;
`;

interface IProps {
    mfaJwt: string,
    onClose: (jwt: string) => void
};

const TOTPCard = (props: IProps) => {
    const { mfaJwt, onClose } = props;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [code, setCode] = useState("");

    const sendCode = () => {
        setLoading(true);
        sendTOTP(code, mfaJwt, (jwt: string) => {
            setLoading(false);
            onClose(jwt);
        }, (errorMessage: string) => {
            setTimeout(() => {
                setLoading(false);
                setError(errorMessage);
            }, 1000);
        })
    }

    const changeText = (e: SyntheticEvent<HTMLInputElement>) => {
        setCode(e.currentTarget.value);
    }

    return <LoginBoxContainer className="LoginBoxContainer">
        <div style={{ display: "flex", justifyContent: "center" }}>
            <Logo className="logo" src="/images/imx-monitor-logo.png" />
        </div>
        <LoginFadeContainer className={`LoginFadeContainer ${loading === true ? "invisible" : ""}`}>
            <DialogTitle className="DialogTitle">Multi-factor Authentication</DialogTitle>
            <DialogContent className="DialogContent" style={{ margin: "0px 0px 24px 0px" }}>
                <DialogContentText className="DialogContentText">Please send the code from your authenticator</DialogContentText>
                <TextInputCode value={code} onChange={changeText} />
                {error && <ErrorText>{error}</ErrorText>}
            </DialogContent>
            <DialogActions className="DialogActions">
                <Button margin="0px 4px" onClick={sendCode}>Send code</Button>
            </DialogActions>
        </LoginFadeContainer>
        <LoadingIndicator show={loading} type="Radial" style={{ position: "absolute", left: "50%", top: "50%", marginLeft: "-25px", marginTop: "-25px" }} />
    </LoginBoxContainer>
}

export default TOTPCard;
