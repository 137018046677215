import * as React from 'react';
import useEffectOnSome from 'src/CustomHooks/useEffectOnSome';
import IKubernetesConfiguration from 'src/ServerEntities/IKubernetesConfiguration';
import Button from 'src/SharedComponents/Button';
import ErrorBox from 'src/SharedComponents/ErrorBox';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import TextInput from 'src/SharedComponents/TextInput';
import { SessionContext } from 'src/Views/SessionContext';
import styled from 'styled-components';
import { getKubernetesConfiguration, setKubernetesConfiguration } from '../UserManagement/kubernetesService';
import Title from './Title';

const ProcessThresholdsContainer = styled.div`
    padding: 12px 24px;
`;

const Container = styled.div`
    padding: 12px 24px;
`;

const LineContainer = styled.div`
    margin-bottom: 20px;
`;

const KubernetesConfiguration = () => {
    const { webToken } = React.useContext(SessionContext).state;
    const [loading, setLoading] = React.useState(false);
    const [minimumVersion, setMinimumVersion] = React.useState("");
    const [error, setError] = React.useState("");

    useEffectOnSome(() => {
        setLoading(true);
        getKubernetesConfiguration(webToken, (configuration: IKubernetesConfiguration) => {
            if (configuration) {
                setMinimumVersion(configuration.minimumVersion);
            }
            setError("");
            setLoading(false);
        }, (errorMessage: string) => {
            setError(errorMessage);
            setLoading(false);
        });
    }, [webToken], []);


    const updateMinimumVersion = (e: React.SyntheticEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;
        setMinimumVersion(value);
    };


    const saveConfiguration = () => {
        setLoading(true);
        setKubernetesConfiguration(
            {
                "minimumVersion": minimumVersion
            }, webToken, () => {
                setLoading(false);
                setError("");
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
            });
    };

    return <ProcessThresholdsContainer>
        {error && <ErrorBox>{error}</ErrorBox>}
        {loading && <LoadingIndicator type={'Linear'} show={loading} />}
        <Title>Configuration</Title>
        <Container>
            <LineContainer>
                <div>Minimum version</div>
                <TextInput disabled={loading} value={minimumVersion} onChange={updateMinimumVersion} width="120px" />
            </LineContainer>
            <div><Button onClick={saveConfiguration}>Save</Button></div>
        </Container>
    </ProcessThresholdsContainer>
};

export default KubernetesConfiguration;
