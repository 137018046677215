import heartbeatBadIcon from 'src/images/customIcons/heartbeat-bad.png';
import heartbeatGoodIcon from 'src/images/customIcons/heartbeat-good.png';
import IServer from 'src/ServerEntities/IServer';
import dateConverter from 'src/UsefulFunctions/dateConverter';
import styled from 'styled-components';

const ServerIcon = styled.img`
    height: 24px;
    width: 24px;
    margin: 0 4px;
    @media (max-width: 1279px) {
        display: none;
    }
`;

interface IProps {
    heartbeatHealthy: boolean,
    server: IServer
};

const ServerHeartbeatIcon = (props: IProps) => {
    const { heartbeatHealthy, server } = props;

    return <ServerIcon
        alt={`Last heartbeat received from ${server.name} at ${dateConverter(server.lastHeartbeat)}`}
        src={heartbeatHealthy ? heartbeatGoodIcon : heartbeatBadIcon} />
};

export default ServerHeartbeatIcon;
