import React from 'react';
import IConsoleError from 'src/ServerEntities/IConsoleError';
import dateConverter from 'src/UsefulFunctions/dateConverter';
import styled from 'styled-components';
import { TopBarContainer, getDetailIcon, getSummaryIcon } from './TopBarContainer';

const ServerInformationContainer = styled.div`
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    background: #1c3e5a;
    min-width: 250px;
    & h3 {
        background-color: #246896;
        margin: 0;
        flex: 0 0 auto;
        padding: 12px 12px 6px;
        @media (min-width: 1279px) {
            border-radius: 16px 16px 0 0;
        }
        font-weight: 500;
        font-size: 1.2rem;
    }
    & div {
        padding: 6px 12px 12px;
        display: flex;
    }
    & table {
        flex: 1 1 auto;
        text-align: left;
    }
    @media (min-width: 1279px) {
        flex: 1 1 auto;
        border-radius: 16px;
    }
    @media (max-width: 1279px) {
        width: 100%;
        margin-bottom: 4px;
    }
`;

const SummaryInfo = styled.div`
    padding: 12px;
    display: flex;
    justify-content: center;
`;

interface IProps {
    consoleErrors: IConsoleError[]
    startExpanded?: boolean
};

const ServerConsoleErrors = (props: IProps) => {
    const { consoleErrors } = props;
    const [expanded, setExpanded] = React.useState(props.startExpanded);
    const daysToCheck = 10;

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    const isInRange = (date: number) => {
        var startDate = new Date();
        startDate.setDate(startDate.getDate() - daysToCheck)
        return startDate.getTime() < date;
    };

    const rangeErrors = consoleErrors.filter(error => isInRange(error.logged));

    return <ServerInformationContainer>
        <TopBarContainer style={{ padding: "12px 12px 6px 12px" }} onClick={toggleExpanded}>
            Console errors
            {expanded && <img src={getDetailIcon} alt="expanded info" />}
            {!expanded && <img src={getSummaryIcon} alt="summary info" />}
        </TopBarContainer>
        {expanded === false && <ServerInformationContainer>
            <SummaryInfo>
                <h4>{rangeErrors.length} console errors in the past {daysToCheck} days.</h4>
            </SummaryInfo>
        </ServerInformationContainer>}
        {expanded === true && <ServerInformationContainer>
            <div>
                <table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Error</th>
                        </tr>
                    </thead>
                    <tbody>
                        {consoleErrors.map((error, index) => <tr key={`console-error-${index}`}>
                            <td>{dateConverter(error.logged)}</td>
                            <td>{error.info}</td>
                        </tr>)}
                    </tbody>
                </table>
            </div>
        </ServerInformationContainer>}
    </ServerInformationContainer>;
};

export default ServerConsoleErrors;
