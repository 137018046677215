import IEnsembleInterface, { IInterfaceItem } from "src/ServerEntities/IEnsembleInterface";
import styled from "styled-components";
import EnsembleInterfaceRow from "./EnsembleInterfaceRow";
import { IStatusHeader, StatusHeader } from "./StatusHeaderWidget";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbtack } from '@fortawesome/free-solid-svg-icons';

interface ItemRowProps {
    status: string;
};

const EnsembleExpandedInterface = styled.div<ItemRowProps>`
    .ensemble-header {
        display: flex;
        padding: 16px 18px;
        justify-content: space-between;
        align-items: center;
        background-color: rgba(36, 104, 150, 1);

        h4 {
            display: flex;
            align-items: center;
            margin: 0;
        }

        .ensemble-status {
            padding: 10px 20px;
            border-radius: 16px;
            background-color: ${props => {
                switch (props.status) {
                    case 'Active':
                        return '#42a83f';
                    case 'Error':
                        return '#ff2121';
                    case 'Inactive':
                        return '#f99600';
                    case 'Disabled':
                        return '#9E9E9E';
                    case 'Queue':
                        return '#6902de';
                    default:
                        return '#246896';
                }
            }};
        }
    }

    .ensemble-body {
        background-color: rgba(13, 12, 12, 0.5);
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
        padding: 12px;
    }
`;

interface IPinProps {
    $pinned: boolean
};

const PinContainer = styled.div<IPinProps>`
    transform: rotate(${(props) => props.$pinned ? "-90deg" : "0"});
    transition: transform 0.5s;
    cursor: pointer;
`;

interface IInterfaceProps {
    selectedInterface: IEnsembleInterface,
    onTogglePin: () => void,
    pinned: boolean
};

const getInterfaceStatusHeader = (services: IInterfaceItem[], processes: IInterfaceItem[], operations: IInterfaceItem[]) => {
    return services.concat(processes).concat(operations).reduce((acc, commpoint) => {
        if (!commpoint.isEnabled && commpoint.lastError) {
          acc.red += 1;
        } else if (commpoint.suspended) {
          acc.yellow += 1;
        } else {
          acc.green += 1;
        }
        return acc;
      }, { green: 0, red: 0, yellow: 0 });
};

const InterfaceDetails = (props: IInterfaceProps) => {
    const { onTogglePin, pinned, selectedInterface } = props;
    const commpointsStatusHeader: IStatusHeader = getInterfaceStatusHeader(selectedInterface.services, selectedInterface.processes, selectedInterface.operations);

    return <EnsembleExpandedInterface status={selectedInterface.status}>
        <div className="ensemble-header">
            <PinContainer $pinned={pinned} onClick={onTogglePin} title={pinned ? "Unpin" : "Pin"}>
                <FontAwesomeIcon icon={faThumbtack} />
            </PinContainer>
            <h4>{selectedInterface.name}</h4>
            {StatusHeader(commpointsStatusHeader, false)}
            <h4 className="ensemble-status">{selectedInterface.status}</h4>
        </div>
        <ul className="ensemble-body">
            {selectedInterface.services.map((service, index) => {
                return <EnsembleInterfaceRow key={`${selectedInterface.id}-service-${index}`} interfaceItem={service} hasAdapter={true} type='Service' />
            })}
            {selectedInterface.processes.map((service, index) => {
                return <EnsembleInterfaceRow key={`${selectedInterface.id}-process-${index}`} interfaceItem={service} hasAdapter={false} type='Process' />
            })}
            {selectedInterface.operations.map((service, index) => {
                return <EnsembleInterfaceRow key={`${selectedInterface.id}-operation-${index}`} interfaceItem={service} hasAdapter={true} type='Operation' />
            })}
        </ul>
    </EnsembleExpandedInterface>;
};

export default InterfaceDetails;