import styled from "styled-components";

import IQveraInformation from "src/ServerEntities/IQveraInformation";

interface ItemRowProps {
    status: string;
};

interface QueueProps {
    queue: number;
};

const InformationContainer = styled.li`
    display: flex;
    flex-direction: row;
    padding: 10px;
    justify-content: space-around;
    align-items: center;
`;

const InformationColumn = styled.div`
    margin-right: 30px;
`;

const QueueContainer = styled.ul<QueueProps>`
    display: flex;
    padding: 10px 20px;
    border-radius: 16px;
    background-color:  ${props => { if (props.queue > 0) { return '#6902de' } else { return '#42a83f' } }};
    flex-direction: column;
    align-content: flex-start;
    justify-content: center;
    align-items: center;
`;

const QveraExpandedInterface = styled.div<ItemRowProps>`
    .qvera-header {
        display: flex;
        padding: 16px 18px;
        justify-content: space-between;
        align-items: center;
        background-color: rgba(36, 104, 150, 1);

        h4 {
            display: flex;
            align-items: center;
            margin: 0;
        }

        .qvera-status {
            padding: 10px 20px;
            border-radius: 16px;
            background-color: ${props => {
        switch (props.status) {
            case 'ACTIVE':
                return '#42a83f';
            case 'RUNNING':
                return '#42a83f';
            case 'ERROR':
                return '#ff2121';
            case 'INACTIVE':
                return '#f99600';
            case 'STOPPED':
                return '#f99600';
            case 'DISABLED':
                return '#9E9E9E';
            case 'QUEUE':
                return '#6902de';
            default:
                return '#246896';
        }
    }};
        }
    }

    .qvera-body {
        background-color: rgba(13, 12, 12, 0.5);
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
        padding: 12px;
    }
`;

interface IInterfaceProps {
    selectedQveraInformation: IQveraInformation,
};


const nodeToRow = (node: string) => {
    return <div>
        {node}
    </div>
}

const queueInformation = (label: string, queue: number) => {
    return <QueueContainer queue={queue}>
        <li> {label}</li>
        <li>{queue}</li>

    </QueueContainer>
}

const QveraInformationDetails = (props: IInterfaceProps) => {
    const { selectedQveraInformation } = props;

    return <QveraExpandedInterface status={selectedQveraInformation.stateDescription.toLocaleUpperCase()}>
        <div className="qvera-header">
            <h4>{selectedQveraInformation.channelName}</h4>
            <h4 className="qvera-status">{selectedQveraInformation.stateDescription}</h4>
        </div>
        <ul className="qvera-body">
            <InformationContainer>
                <InformationColumn>
                    <ul>
                        <li>Last Message Time: {selectedQveraInformation.lastReceived}</li>
                        <li>Last Error: {selectedQveraInformation.lastError}</li>
                        <li>Error Queue: {selectedQveraInformation.errorQueue}</li>
                    </ul>
                </InformationColumn>
                <InformationColumn>
                    Stopped Nodes
                    {selectedQveraInformation.stoppedNodes.map(nodeToRow)}
                </InformationColumn>
            </InformationContainer>
            <InformationContainer>
                {queueInformation("Inbound", selectedQveraInformation.inboundQueue)}
                {queueInformation("Outbound", selectedQveraInformation.outboundQueue)}
                {queueInformation("Processing", selectedQveraInformation.processingQueue)}
            </InformationContainer>
        </ul>
    </QveraExpandedInterface>;
};

export default QveraInformationDetails;
