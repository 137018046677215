import * as React from 'react';
import { useNavigate, useParams } from 'react-router';

import loginBackground from 'src/images/loginBackground.png';
import Button from "src/SharedComponents/Button";
import Card from "src/SharedComponents/Card";
import LoadingIndicator from "src/SharedComponents/LoadingIndicator";
import Overlay from "src/SharedComponents/Overlay";
import TextInput from "src/SharedComponents/TextInput";
import ViewContainer from "src/SharedComponents/ViewContainer";
import styled from 'styled-components';
import { sendResetPassword } from "./loginService";

const Container = styled.div`
    text-align: left;
`;

const StatusContainer = styled.div`
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
`;

const backgroundStyle = {
    backgroundAttachment: "fixed",
    backgroundImage: `url("${loginBackground}")`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
};

const ResetPassword = () => {
    const { token } = useParams();
    const [passwordValue, setPasswordValue] = React.useState("");
    const [confirmPasswordValue, setConfirmPasswordValue] = React.useState("");
    const [changePasswordStatus, setChangePasswordStatus] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const navigate = useNavigate();

    const onPasswordChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
        setPasswordValue(e.currentTarget.value);
    };

    const onconfirmPasswordChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
        setConfirmPasswordValue(e.currentTarget.value);
    };

    const onSubmitResetPassword = () => {
        if (confirmPasswordValue !== passwordValue) {
            return setChangePasswordStatus("Password and confirmation do not match.");
        }
        setLoading(true);
        sendResetPassword(passwordValue, token || "", () => {
            setChangePasswordStatus("Your password has been reset, please login with the new credentials.");
            setLoading(false);
            setSuccess(true);
        }, (error: string) => {
            setChangePasswordStatus(error);
            setLoading(false);
        })
    };

    const redirectToLogin = () => {
        navigate("/Login");
    };

    return <ViewContainer className="Background" style={backgroundStyle}>
        <Overlay $dark={true}>
            <Card style={{ display: "flex", flexDirection: "column", minWidth: "500px", marginBottom: "100px", padding: "20px" }}>
                <h1>Reset password</h1>
                {!success && <Container>
                    <p>Please type a new password.</p>
                    <TextInput margin="30px 0px 20px 0px" type={"password"} label={"password"} value={passwordValue} onChange={onPasswordChange} />
                    <TextInput margin="20px 0px 20px 0px" type={"password"} label={"confirm password"} value={confirmPasswordValue} onChange={onconfirmPasswordChange} />
                    <LoadingIndicator show={loading} type={"Linear"} />
                    <Button style={{ textAlign: "right" }} onClick={onSubmitResetPassword}>OK</Button>
                </Container>}
                {changePasswordStatus !== "" && <StatusContainer>{changePasswordStatus}</StatusContainer>}
                {success && <Container>
                    <Button onClick={redirectToLogin}>Go to login</Button>
                </Container>}
            </Card>
        </Overlay>
    </ViewContainer>;
};

export default ResetPassword;
