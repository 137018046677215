import styled from 'styled-components';

const AlertCount = styled.i`
    font-style: normal;
    background-color: #f99600;
    &.closed {
        background-color: #000000;
        padding: 2px 10px;
        border: 1px solid #f99600;
    }
    border-radius: 16px;
    padding: 4px 12px;
    margin: 0 12px;
`;

export default AlertCount;
