import * as React from "react";
import { FormEvent, useState } from 'react';
import styled from 'styled-components';

import Button from 'src/SharedComponents/Button';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import TextInput from 'src/SharedComponents/TextInput';

const Logo = styled.img`
    width: 144px;
    margin: 36px 36px 24px 36px;
    user-select: none;
`;

const TitleText = styled.h3`
    font-size: 20px;
    font-weight: 500;
    color: #404040;
    margin: 0px 36px 20px 36px;
`;

const ErrorText = styled.p`
    color: #D0021B;
    margin-left: 36px;
`;

const LoginFadeContainer = styled.div`
    position: relative;
    transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 1;
    &.invisible {
        user-interaction: none;
        opacity: 0;
    }
`;

const LoginBoxContainer = styled.div`
    position: relative;
    max-width: 500px;
`;

interface IProps {
    applicationName: string,
    errorText: string,
    loginUser: (username: string, password: string, applicationName: string) => void,
    loggingIn: boolean,
    setLoggingIn: (isLoggingIn: boolean) => void
};

const LoginForm = (props: IProps) => {
    const { applicationName, loginUser, loggingIn, errorText } = props;

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    function changeUsername(e: React.SyntheticEvent<HTMLInputElement>) {
        setUsername(e.currentTarget.value);
    }

    function changePassword(e: React.SyntheticEvent<HTMLInputElement>) {
        setPassword(e.currentTarget.value);
    }

    const doLogin = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        loginUser(username, password, applicationName);
    };

    return <LoginBoxContainer className="LoginBoxContainer">
        <div style={{ display: "flex", justifyContent: "center" }}>
            <Logo className="logo" src="/images/imx-monitor-logo.png" />
        </div>
        <LoginFadeContainer className={`LoginFadeContainer ${loggingIn === true ? "invisible" : ""}`}>
            <TitleText className="titleText">Please sign in to continue</TitleText>
            <ErrorText>{errorText}</ErrorText>
            <form onSubmit={doLogin}>
                <TextInput autoFocus={true} value={username} onChange={changeUsername} margin="0px 72px 20px 36px" noOutline={true} placeholder="Username" autoComplete="username" required={true} hideAssistiveText={true} />
                <TextInput value={password} onChange={changePassword} margin="0px 72px 20px 36px" noOutline={true} placeholder="Password" autoComplete="current-password" required={true} hideAssistiveText={true} type="password" />
                <div style={{ width: "100px", margin: "20px 0px 20px 36px" }}><Button submit={true} disabled={username === "" || password === ""}>Sign in</Button></div>
            </form>
        </LoginFadeContainer>
        <LoadingIndicator show={loggingIn === true} type="Radial" style={{ position: "absolute", left: "50%", top: "50%", marginLeft: "-25px", marginTop: "-25px" }} />
    </LoginBoxContainer>;
};

export default LoginForm;
