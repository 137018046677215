import React, { SyntheticEvent } from 'react';
import IChannelCode from 'src/ServerEntities/IChannelCode';
import Button from 'src/SharedComponents/Button';
import Dialog from 'src/SharedComponents/Dialog/Dialog';
import DialogActions from 'src/SharedComponents/Dialog/DialogActions';
import DialogContent from 'src/SharedComponents/Dialog/DialogContent';
import DialogTitle from 'src/SharedComponents/Dialog/DialogTitle';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import TextInput from 'src/SharedComponents/TextInput';
import ViewContainer from 'src/SharedComponents/ViewContainer';
import { deleteChannelCode, getChannelCodes, saveChannelCode } from 'src/Views/Ram/ramService';
import { SessionContext } from 'src/Views/SessionContext';
import styled from 'styled-components';
import ManagerTitleBar from '../ManagerTitleBar';

interface IProps {
    refresh: number,
    tablabel: string
};

const Container = styled.div`
    border-sytle: solid;
    height: 100%;
    padding: 10px;
`;

const StyledTable = styled.div`
    width: 100%;
    margin-top: 20px;
    table-layout: fixed; 
    border-collapse: collapse;
`;

const ColumnHeader = styled.th`
    font-size: 1.1rem;
    font-weight: 600;
    text-align: left;
    padding: 8px;
    border: 1px solid #e5e5e5;
`;

const ColumnCell = styled.td`
    font-size: 1.1rem;
    font-weight: 600;
    text-align: left;
    padding: 8px;
    border: 1px solid #e5e5e5;
`;

const ChannelCodesTab = (props: IProps) => {
    const { webToken } = React.useContext(SessionContext).state;
    const [channelCodes, setChannelCodes] = React.useState([] as unknown as IChannelCode[]);
    const [loading, setLoading] = React.useState(false);
    const [refresh, setRefresh] = React.useState(0);
    const [errorMessage, setErrorMessage] = React.useState("");

    React.useEffect(() => {
        setLoading(true);
        getChannelCodes(webToken, (codes: IChannelCode[]) => {
            setLoading(false);
            setChannelCodes(codes);
        }, (errorMessage: string) => {
            setLoading(false);
        });
    }, [refresh]);


    const closeErrorDialog = () => {
        setErrorMessage("");
    };

    const toggleDisplayed = (index: number) => () => {
        const updatedDisplayed = !channelCodes[index].displayed;
        setChannelCode({ ...channelCodes[index], displayed: updatedDisplayed }, index);
        setLoading(true);
        saveChannelCode(channelCodes[index], webToken, () => {
            setLoading(false);
        }, (error: string) => { setErrorMessage(error); setLoading(false) })
    }

    const changeCode = (index: number) => (event: SyntheticEvent<HTMLInputElement, Event>) => {
        setChannelCode({ ...channelCodes[index], shortCode: event.currentTarget.value }, index);
    };

    const changeChannel = (index: number) => (event: SyntheticEvent<HTMLInputElement, Event>) => {
        setChannelCode({ ...channelCodes[index], channel: event.currentTarget.value }, index);
    };

    const setChannelCode = (channelCode: IChannelCode, index: number) => {
        const updatedCodes = [...channelCodes];
        updatedCodes[index] = channelCode;
        setChannelCodes(updatedCodes);
    }

    const updateChannelCode = (index: number) => () => {
        if (channelCodes[index].channel && channelCodes[index].shortCode) {
            setLoading(true);
            saveChannelCode(channelCodes[index], webToken, () => {
                setLoading(false);
            }, (error: string) => { setErrorMessage(error); setLoading(false) })
        }
    }

    const deleteCode = (index: number) => () => {
        setLoading(true);
        if (channelCodes[index].channel !== "") {
            deleteChannelCode(channelCodes[index].channel, webToken, () => {
                setRefresh(refresh + 1);
            }, (error: string) => { setErrorMessage(error); setLoading(false) })
        }
        else {
            setRefresh(refresh + 1);
        }
    }

    const codeToItem = (channelCode: IChannelCode, index: number) => {
        return (
            <tr key={`channel-code-row-${index}`}>
                <ColumnCell> <TextInput value={channelCode.channel} onChange={changeChannel(index)} onBlur={updateChannelCode(index)} /></ColumnCell>
                <ColumnCell> <TextInput maxLength={4} value={channelCode.shortCode} onChange={changeCode(index)} onBlur={updateChannelCode(index)} /></ColumnCell>
                <ColumnCell style={{ textAlign: "center" }} onClick={toggleDisplayed(index)} onBlur={updateChannelCode(index)}><input type="checkbox" checked={channelCode.displayed} /></ColumnCell>
                <ColumnCell style={{ textAlign: "center" }}> <Button color='#cc0000' onClick={deleteCode(index)}>Delete</Button></ColumnCell>
            </tr>
        );
    };

    const addChannelCode = () => {
        const newChannelCode: IChannelCode = {
            channel: '',
            shortCode: '',
            displayed: false,
        };
        setChannelCodes([...channelCodes, newChannelCode]);
    };

    return <ViewContainer style={{ backgroundColor: "#fafafa" }}>
        <ErrorDialog message={errorMessage} onClose={closeErrorDialog} />
        <ManagerTitleBar viewName="Channel Short Codes" viewDescription="" />
        <LoadingIndicator type="Linear" show={loading} />
        <Container>
            <Button onClick={addChannelCode}>Add Code</Button>
            <StyledTable>
                <thead>
                    <tr>
                        <ColumnHeader style={{ width: "30%" }}>TIE CommPoint Name</ColumnHeader>
                        <ColumnHeader style={{ width: "30%" }}>DashBoard Display Mnemonic</ColumnHeader>
                        <ColumnHeader style={{ width: "20%" }}>Displayed </ColumnHeader>
                        <ColumnHeader style={{ width: "20%" }} />
                    </tr>
                </thead>
                <tbody>
                    {channelCodes.map(codeToItem)}
                </tbody>
            </StyledTable>

        </Container>
    </ViewContainer>;
};

const ErrorDialog = ({ message, onClose }: { message: string, onClose: () => void }) => <Dialog open={message !== ""} onClose={onClose}>
    <DialogTitle>Error</DialogTitle>
    <DialogContent style={{ lineHeight: "25px" }}>{message}</DialogContent>
    <DialogActions><Button onClick={onClose}>OK</Button></DialogActions>
</Dialog>;

export default ChannelCodesTab;
