import * as React from 'react';
import styled from 'styled-components';

import ICommonProblem from 'src/ServerEntities/ICommonProblem';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import ViewContainer from 'src/SharedComponents/ViewContainer';
import ProblemRow from 'src/Views/Manager/Components/ProblemRow';
import ManagerTitleBar from 'src/Views/Manager/ManagerTitleBar';
import { SessionContext } from 'src/Views/SessionContext';
import { getProblems } from './commonProblemService';
import CreateCommonProblemDialog from './CreateCommonProblemDialog';

interface IProps {
    tablabel: string
};

interface IStyleProps {
    width: string
};

const ColumnHeader = styled.th<IStyleProps>`
    font-size: 1.1rem;
    font-weight: 600;
    text-align: left;
    padding: 8px;
    border: 1px solid #e5e5e5;
    width: ${props => props.width};
`;

const Button = styled.a`
    background: #187fba;
    border-radius: 18px;
    padding: 4px 16px;
    cursor: pointer;
    color: #ffffff;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: auto;
    text-align: center;

    @media(max-width: 375px) {
        font-size: 14px;
    }

`;

const StyledTable = styled.table`
    width: 100%;
    table-layout: fixed; 
    border-collapse: collapse;
    min-width: 800px;

    th, td {
        @media(max-width: 375px) {
            font-size: 14px;
        }
    }
`;

const CommonProblemsTab = (props: IProps) => {
    const [refresh, setRefresh] = React.useState(0);
    const [displayCreateDialog, setDisplayCreateDialog] = React.useState(false);
    const { webToken } = React.useContext(SessionContext).state;
    const [problems, setProblems] = React.useState([] as unknown as ICommonProblem[]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");

    React.useEffect(() => {
        setLoading(true);
        getProblems(webToken, (commonProblems: ICommonProblem[]) => {
            setProblems(commonProblems);
            setLoading(false);
            setError("");
        }, (errorMessage: string) => {
            setProblems([]);
            setLoading(false);
            setError(errorMessage);
        });
    }, [refresh, webToken]);

    const openCreateDialog = () => {
        setDisplayCreateDialog(true);
    };

    const closeCreateDialog = () => {
        setDisplayCreateDialog(false);
    };

    return <ViewContainer style={{ backgroundColor: "#fafafa", height: "80%" }}>
        {displayCreateDialog && <CreateCommonProblemDialog onClose={closeCreateDialog} refresh={refresh} setRefresh={setRefresh} />}
        <ManagerTitleBar viewName="Common problems" viewDescription="Here you can group similar problems together.">
            <Button onClick={openCreateDialog}>Create new common problem</Button>
        </ManagerTitleBar>
        <ViewContainer style={{padding: "12px"}}>
            <LoadingIndicator type="Linear" show={loading} />
            {problems && (
                <StyledTable>
                    <thead>
                        <tr>
                            <ColumnHeader width="40%">Problem</ColumnHeader>
                            <ColumnHeader width="40%">Translation</ColumnHeader>
                            <ColumnHeader width="10%" />
                        </tr>
                    </thead>
                    <tbody>
                        {problems.map((problem) => (
                            <ProblemRow
                                problem={problem}
                                setRefresh={setRefresh}
                                setLoading={setLoading}
                                refresh={refresh}
                                setError={setError}
                                key={`problem-row-${problem.id}`}
                            />
                        ))}
                    </tbody>
                </StyledTable>
            )}
            {error && error}
        </ViewContainer>
    </ViewContainer>;
};

export default CommonProblemsTab;
