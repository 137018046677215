import React from "react";
import ICommpointItem from "src/ServerEntities/IEnsembleCommPoint";
import styled from "styled-components";
import { SessionContext } from "../SessionContext";

const AlertContainer = styled.li`
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 8px;
    height: 25px;
    width: 25px;
    background-color: #42a83f;
`;

interface IProps {
    commpoint: ICommpointItem
};

export const getCommpointStatusHeader = (commpoints: ICommpointItem[]) => {
    return commpoints.reduce((acc, commpoint) => {
        if (commpoint.state === "STOPPED") {
          acc.yellow += 1;
        } else if (commpoint.state === "ERROR") {
          acc.red += 1;
        } else if (commpoint.state === "DISABLED") {
          acc.grey += 1;
        } else if (commpoint.inQueueSize > 0 || commpoint.state === "QUEUE") {
          commpoint.state = "QUEUE";
          acc.purple += 1;
        } else {
          acc.green += 1;
        }
        return acc;
      }, { green: 0, red: 0, yellow: 0, purple: 0, grey: 0 });
}

const DashboardCommpointItem = (props: IProps) => {
    const { state } = React.useContext(SessionContext);

    const openServer = (commpoint: string) => (event: React.MouseEvent<HTMLElement>) => {
        window.open(`/Ram/Server/${props.commpoint.site}/${props.commpoint.serverName}/${props.commpoint.namespace}/${state.webToken}?commpoint=${commpoint}`);
        event.stopPropagation();
    };

   return <AlertContainer className={props.commpoint.state} onClick={openServer(props.commpoint.name)} />;
}

export default DashboardCommpointItem;