import * as React from "react";
import styled from "styled-components";

import Modal from 'src/SharedComponents/Modal';
import Overlay from 'src/SharedComponents/Overlay';

interface IProps {
    darkBackground?: boolean,
    open: boolean,
    onClose: () => void,
    children: React.ReactNode,
    preventClickOutsideClose?: boolean,
    style?: React.CSSProperties,
    borderRadius?: boolean
};

const DialogContainer = styled.div<IStyle>`
    max-width: 600px;
    display: flex;
    flex: 0 1 auto;
    position: relative;
    overflow-y: auto;
    flex-direction: column;
    box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12);
    border-radius: 5px;
    background-color: white;
`;

const ScrollingContainer = styled.div<IStyle>`
    margin: 48px;
    @media (max-width: 1279px) {
        margin: 48px 10px;
    }
    max-height: calc(100% - 96px);
    border-radius: ${props => props.borderRadius ? "16px" : "none"};
    overflow-y: auto;
`;

interface IStyle {
    borderRadius?: boolean
}

function Dialog(props: IProps) {
    const { darkBackground, open, children, onClose, preventClickOutsideClose } = props;

    const closeDialog = (e: React.SyntheticEvent<HTMLElement>) => {
        onClose();
    };

    const cancelEventPropagation = (e: React.SyntheticEvent<HTMLElement>) => {
        e.stopPropagation();
    };

    const handleKeyDown = (event: any) => {
        const key = event.key || event.keyCode;
        if (key === "Escape") {
            event.stopPropagation();
            onClose();
        }
    };

    return <Modal show={open}>
        <Overlay onClick={preventClickOutsideClose ? cancelEventPropagation : closeDialog} className="overlay" $dark={darkBackground}>
            <ScrollingContainer>
                <DialogContainer onKeyDown={handleKeyDown} className="DialogContainer" onClick={cancelEventPropagation} style={props.style} borderRadius={props.borderRadius}>
                    {children}
                </DialogContainer>
            </ScrollingContainer>
        </Overlay>
    </Modal>;
};

export default Dialog;
