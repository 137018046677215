import * as React from 'react';
import styled from 'styled-components';

interface IProps {
    children: React.ReactNode,
    onClick?: (e?: React.SyntheticEvent<HTMLDivElement>) => void,
    onKeyDown?: (e: React.SyntheticEvent<HTMLDivElement>) => void,
    style?: React.CSSProperties,
    ref?: {current: HTMLDivElement}
}

const MenuItemContainer = styled.div`
    min-height: 24px;
    line-height: 20px;
    padding: 8px 16px;
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    &:hover {
        background-color: rgba(24,127,186,0.05);
    }
    &:focus {
        background-color: rgba(24,127,186,0.05);
    }
    cursor: pointer;
`;

const MenuItem = (props: IProps) => {
    const { children, onClick, onKeyDown, style } = props;

    const handleClick = (e: React.SyntheticEvent<HTMLDivElement>) => {
        e.stopPropagation();
        if (onClick) {
            onClick(e);
        }
    };

    return <MenuItemContainer onKeyDown={onKeyDown} tabIndex={0} onClick={handleClick} style={style}>
        {children}
    </MenuItemContainer>;
};

export default MenuItem;
