import * as React from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

import { addUserOnCall, getUsers, removeUserOnCall } from '../UserManagement/smsNotificationService';
import useEffectOnSome from 'src/CustomHooks/useEffectOnSome';
import IUser from 'src/ServerEntities/IUser';
import Checkbox from 'src/SharedComponents/Checkbox';
import ErrorBox from 'src/SharedComponents/ErrorBox';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import { SessionContext } from 'src/Views/SessionContext';

const UsersContainer = styled.div`
    padding: 12px 24px;
    background: #fafafa;
    border-bottom: 1px solid #e5e5e5;
    & h1 {
        font-size: 1.6rem;
        margin: 6px 0;
        font-weight: 500;
    }
    & td {
        padding: 4px;
        &:first-child {
            padding-left: 0;
        }
    }
`;

const UserLink = styled.a`
    cursor: pointer;
    color: #187fba;
`;

interface IProps {
    refresh: number
};

const NotificationUsers = (props: IProps) => {
    const refresh = props.refresh;
    const { webToken } = React.useContext(SessionContext).state;
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [users, setUsers] = React.useState([] as unknown as IUser[]);

    useEffectOnSome(() => {
        setLoading(true);
        getUsers(webToken, (serverUsers) => {
            setLoading(false);
            setError("");
            setUsers(serverUsers);
        }, (errorMessage) => {
            setLoading(false);
            setError(errorMessage);
        })
    }, [webToken], [refresh]);

    return <UsersContainer>
        <LoadingIndicator type="Linear" show={loading} />
        {error && <ErrorBox>{error}</ErrorBox>}
        <h1>Users on call</h1>
        <table>
            <tbody>
                {users.map((user, index) => {
                    return <UserRow key={`sms-user-row-${index}`} user={user} setError={setError} webToken={webToken} />;
                })}
            </tbody>
        </table>
    </UsersContainer>;
};

interface IUserRowProps {
    setError: (errorMessage: string) => void,
    user: IUser,
    webToken: string
};

const UserRow = (props: IUserRowProps) => {
    const {setError, user, webToken} = props;
    const [loading, setLoading] = React.useState(false);
    const [checked, setChecked] = React.useState(user.onCall);
    const navigate = useNavigate();

    const openUserPage = (username: string) => () => {
        navigate(`/Manager/users?username=${username}`);
    };

    const onError = (errorMessage: string) => {
        setLoading(false);
        setError(errorMessage);
    };

    const changeUserOnCall = (username: string) => (onCall: boolean) => {
        setLoading(true);
        if (onCall) {
            addUserOnCall(username, webToken, () => {
                setChecked(true);
                setLoading(false);
            }, onError);
        } else {
            removeUserOnCall(username, webToken, () => {
                setChecked(false);
                setLoading(false);
            }, onError);
        }
    };

    return <tr>
        <td>{user.id}</td>
        <td>{user.phoneNumber || <UserLink onClick={openUserPage(user.id)}>Add phone number in user management</UserLink>}</td>
        <td>
            <LoadingIndicator show={loading} type="Linear" />
            <Checkbox checked={checked} onChange={changeUserOnCall(user.id)} />
        </td>
    </tr>;
};

export default NotificationUsers;