import ViewContainer from 'src/SharedComponents/ViewContainer';
import ManagerTitleBar from '../ManagerTitleBar';
import styled from 'styled-components';
import Button from 'src/SharedComponents/Button';
import React from 'react';
import { SessionContext } from 'src/Views/SessionContext';
import { closeAllAlertsBySite, getSites } from 'src/Views/Ram/ramService';
import IRoleSite from 'src/ServerEntities/IRoleSite';
import Dropdown from 'src/SharedComponents/Dropdown';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import Dialog from 'src/SharedComponents/Dialog/Dialog';
import DialogActions from 'src/SharedComponents/Dialog/DialogActions';
import DialogContent from 'src/SharedComponents/Dialog/DialogContent';
import DialogTitle from 'src/SharedComponents/Dialog/DialogTitle';

interface IProps {
    refresh: number,
    tablabel: string
};

const Container = styled.div`
    border-sytle: solid;
    height: 100%;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 24px;
        height: 6%;
        margin-top: 10px;

        .dropdown-container {
            width: 12%;
            height: 90%;
        }

        .actions {
            width: 10%;
        }
    }
`;

const siteSorter = (a: IRoleSite, b: IRoleSite) => {
    return a.id.localeCompare(b.id);
};

const AlertsTab = (props: IProps) => {
    const { webToken } = React.useContext(SessionContext).state;
    const [siteNames, setSiteNames] = React.useState([] as unknown as IRoleSite[]);
    const [selectedSite, setSelectedSite] = React.useState<any | null>(null);
    const [loading, setLoading] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");

    React.useEffect(() => {
        getSites(webToken, (serverSites: IRoleSite[]) => {
            setSiteNames(serverSites.sort(siteSorter));
        }, (errorMessage: string) => {
        });
    }, []);

    const closeAllAlerts = () => {
        if (selectedSite) {
            setLoading(true);
            closeAllAlertsBySite(selectedSite.id, webToken, () => {
                setLoading(false);
            }, (errorMessage: string) => {
                setLoading(false);
                setErrorMessage(errorMessage);
            });
        }
    };

    const closeErrorDialog = () => {
        setErrorMessage("");
    };

    return <ViewContainer style={{ backgroundColor: "#fafafa" }}>
        <ErrorDialog message={errorMessage} onClose={closeErrorDialog} />
        <ManagerTitleBar viewName="Alerts" viewDescription="" />
        <LoadingIndicator type="Linear" show={loading} />
        <Container>
            <div className="header">
                <div className="dropdown-container">
                    <Dropdown options={siteNames} placeholder='select a site' onValueChange={setSelectedSite}/>
                </div>
                <div className="actions">
                    <Button style={{ width: "80%" }} onClick={closeAllAlerts}>Close All Alerts</Button>
                </div>
            </div>
        </Container>
    </ViewContainer>;
};

const ErrorDialog = ({ message, onClose }: { message: string, onClose: () => void }) => <Dialog open={message !== ""} onClose={onClose}>
    <DialogTitle>Error</DialogTitle>
    <DialogContent style={{ lineHeight: "25px" }}>{message}</DialogContent>
    <DialogActions><Button onClick={onClose}>OK</Button></DialogActions>
</Dialog>;

export default AlertsTab;
