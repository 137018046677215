import * as React from "react";

import Button from 'src/SharedComponents/Button';
import ViewContainer from 'src/SharedComponents/ViewContainer';
import ManagerTitleBar from 'src/Views/Manager/ManagerTitleBar';
import ClientUserDetails from "./ClientSiteRoleDetails";

import createErrorMessage from 'src/UsefulFunctions/createErrorMessage';
import * as clientManagementService from "./clientManagementService";

import IClient from 'src/ServerEntities/IClient';

interface IClientDetailsProps {
    selectedClient: IClient
    webToken: string,
    refreshClients: () => void,
    setErrorMessage: (errorMessage: string) => void,
    currentUser: string
};

const ClientDetails = (props: IClientDetailsProps) => {
    const [refresh, setRefresh] = React.useState(0);
    const { webToken, selectedClient, refreshClients, setErrorMessage, currentUser } = props;

    const disableClient = () => {
        if (selectedClient) {
            setErrorMessage("");
            clientManagementService.disableClient(
                selectedClient.id,
                webToken,
                () => {
                    refreshClients();
                    refreshHistory();
                },
                (serverError: string) => {
                    setErrorMessage(createErrorMessage("disabling client", serverError));
                });
        }
    };

    const enableClient = () => {
        if (selectedClient) {
            setErrorMessage("");
            clientManagementService.enableClient(
                selectedClient.id,
                webToken,
                () => {
                    refreshClients();
                    refreshHistory();
                },
                (serverError: string) => {
                    setErrorMessage(createErrorMessage("enabling client", serverError));
                });
        }
    };

    const refreshHistory = () => {
        setRefresh(refresh + 1);
    };

    return <ViewContainer>
        <ManagerTitleBar viewName={`${selectedClient.name}`} viewDescription={``}>
            {!selectedClient.enabled && <Button color={"#4f7326"} onClick={enableClient}>Enable client</Button>}
            {selectedClient.enabled && <Button color={"#ad0000"} onClick={disableClient}>Disable client</Button>}
        </ManagerTitleBar>
        <ClientUserDetails
            selectedClient={selectedClient}
            webToken={webToken}
            currentUser={currentUser}
            setErrorMessage={setErrorMessage}
        />
    </ViewContainer>;
};

export default ClientDetails;
