import React from 'react';
import useDataSorter from 'src/CustomHooks/useDataSorter';
import IRunningProcess from 'src/ServerEntities/IRunningProcess';
import TableSortLabel from 'src/SharedComponents/Table/TableSortLabel';
import { SessionContext } from 'src/Views/SessionContext';
import styled from 'styled-components';
import { TopBarContainer, getDetailIcon, getSummaryIcon } from './TopBarContainer';

const ServerInformationContainer = styled.div`
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    background: #1c3e5a;
    min-width: 250px;
    & h3 {
        background-color: #246896;
        margin: 0;
        flex: 0 0 auto;
        padding: 12px 12px 6px;
        @media (min-width: 1279px) {
            border-radius: 16px 16px 0 0;
        }
        font-weight: 500;
        font-size: 1.2rem;
    }
    & div {
        padding: 6px 12px 12px;
        display: flex;
    }
    & table {
        flex: 1 1 auto;
        text-align: left;
    }
    @media (min-width: 1279px) {
        flex: 1 1 auto;
        border-radius: 16px;
    }
    @media (max-width: 1279px) {
        width: 100%;
        margin-bottom: 4px;
    }
`;

const OverThreshold = styled.td`
    color: #ff0000;
    font-weight: bold;
`;

const UnderThreshold = styled.td`
    color: #ffffff;
`;

const SummaryInfo = styled.div`
    padding: 12px;
    display: flex;
    justify-content: center;
`;

interface IProps {
    processes: IRunningProcess[],
    startExpanded?: boolean
};

const ServerProcesses = (props: IProps) => {
    const { processes } = props;
    const { state } = React.useContext(SessionContext);
    const processThresholds = state.processThresholds;
    const [sortByColumn, setSortByColumn] = React.useState(0);
    const [sortDescending, setSortDescending] = React.useState(false);
    const [expanded, setExpanded] = React.useState(props.startExpanded);

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    const changeSortColumn = (columnIndex: number) => (event: React.SyntheticEvent) => {
        event.stopPropagation();
        if (columnIndex === sortByColumn) {
            setSortDescending(!sortDescending);
        } else {
            setSortDescending(true);
        }
        setSortByColumn(columnIndex);
    };

    const renderCommandsExecuted = (value: number) => {
        if (processThresholds && processThresholds.commandsExecuted && value > processThresholds.commandsExecuted) {
            return <OverThreshold>{value.toLocaleString('en-GB')}</OverThreshold>;
        }
        return <UnderThreshold>{value.toLocaleString('en-GB')}</UnderThreshold>;
    };

    const renderGlobalReferences = (value: number) => {
        if (processThresholds && processThresholds.globalReferences && value > processThresholds.globalReferences) {
            return <OverThreshold>{value.toLocaleString('en-GB')}</OverThreshold>;
        }
        return <UnderThreshold>{value.toLocaleString('en-GB')}</UnderThreshold>;
    };

    const processToArray = (process: IRunningProcess, index: number): [string, string, string, number, number, number] => [
        process.pidExternal,
        process.username,
        process.routine,
        process.commandsExecuted,
        process.globalReferences,
        index
    ];

    const processArrayToTableRow = (array: [string, string, string, number, number, number]) => <tr key={array[5]}>
        <td>
            {array[0]}
        </td>
        <td>
            {array[1]}
        </td>
        <td>
            {array[2]}
        </td>
        {renderCommandsExecuted(array[3])}
        {renderGlobalReferences(array[4])}
    </tr>;

    const sortedProcesses = processes
        .map(processToArray)
        .sort(useDataSorter(sortByColumn, sortDescending))

    return <ServerInformationContainer>
        <TopBarContainer style={{ padding: "12px 12px 6px 12px" }} onClick={toggleExpanded}>
            Running processes
            {expanded && <img src={getDetailIcon} alt="expanded info" />}
            {!expanded && <img src={getSummaryIcon} alt="summary info" />}
        </TopBarContainer>
        {expanded === false && <ServerInformationContainer>
            <SummaryInfo>
                <h4>{processes.length} running processes.</h4>
            </SummaryInfo>
        </ServerInformationContainer>}
        {expanded === true && <ServerInformationContainer>
            <div>
                <table>
                    <thead>
                        <tr>
                            <th>
                                <TableSortLabel active={sortByColumn === 0} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(0)}>
                                    PID
                                </TableSortLabel>
                            </th>
                            <th>
                                <TableSortLabel active={sortByColumn === 1} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(1)}>
                                    Username
                                </TableSortLabel>
                            </th>
                            <th>
                                <TableSortLabel active={sortByColumn === 2} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(2)}>
                                    Routine
                                </TableSortLabel>
                            </th>
                            <th>
                                <TableSortLabel active={sortByColumn === 3} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(3)}>
                                    Commands executed
                                </TableSortLabel>
                            </th>
                            <th>
                                <TableSortLabel active={sortByColumn === 4} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(4)}>
                                    Global references
                                </TableSortLabel>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            sortedProcesses.map(processArrayToTableRow)
                        }
                    </tbody>
                </table>
            </div>
        </ServerInformationContainer>}
    </ServerInformationContainer>;
};

export default ServerProcesses;
