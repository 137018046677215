import React from 'react';
import Timeago from 'react-timeago';
import adapterBadIcon from 'src/images/customIcons/adapter-bad.png';
import adapterGoodIcon from 'src/images/customIcons/adapter-good.png';
import heartbeatBadIcon from 'src/images/customIcons/heartbeat-bad.png';
import heartbeatGoodIcon from 'src/images/customIcons/heartbeat-good.png';
import { IInterfaceItem } from "src/ServerEntities/IEnsembleInterface";
import styled from 'styled-components';

type INTERFACE_ITEM_TYPE = "Service" | "Process" | "Operation";

interface IProps {
    hasAdapter: boolean,
    interfaceItem: IInterfaceItem,
    type: INTERFACE_ITEM_TYPE
};

const ItemRow = styled.li`
    margin: 3px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    & span {
        margin-right: 12px;
        width: calc(100% - 76px);
        overflow: hidden;
        text-overflow: ellipsis;
    }
    & img {
        width: 24px;
        height: 24px;
        margin: 0 4px;
        &.unhealthy {
            cursor: pointer;
        }
    }
`;

const ItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

const LastRow = styled.li`
    margin-bottom: 24px;
`;

export const HEALTHY_STATE_CODE = 1;

const EnsembleInterfaceRow = (props: IProps) => {
    const [errorShown, setErrorShown] = React.useState(false);
    const { hasAdapter, interfaceItem, type } = props;
    const enabledTitle = `${type} is ${interfaceItem.isEnabled === HEALTHY_STATE_CODE ? "enabled" : "disabled"}`;
    const adapterTitle = `Adapter is ${interfaceItem.adapterState === HEALTHY_STATE_CODE ? "enabled" : "disabled"}`;

    const toggleErrorShown = () => {
        if (interfaceItem.isEnabled !== HEALTHY_STATE_CODE || (hasAdapter && interfaceItem.adapterState !== HEALTHY_STATE_CODE)) {
            setErrorShown(!errorShown);
        }
    };

    return <ItemRow>
        <ItemContainer>
            <span>{interfaceItem.name}</span>
            <img
                className={interfaceItem.isEnabled === HEALTHY_STATE_CODE ? "healthy" : "unhealthy"}
                alt={enabledTitle}
                title={enabledTitle}
                onClick={toggleErrorShown}
                src={interfaceItem.isEnabled === HEALTHY_STATE_CODE ? heartbeatGoodIcon : heartbeatBadIcon} />
            {hasAdapter && <img
                className={interfaceItem.adapterState === HEALTHY_STATE_CODE ? "healthy" : "unhealthy"}
                alt={adapterTitle}
                title={adapterTitle}
                onClick={toggleErrorShown}
                src={interfaceItem.adapterState === HEALTHY_STATE_CODE ? adapterGoodIcon : adapterBadIcon} />
            }
        </ItemContainer>
        {errorShown && <ul>
            <li><b>Last error:</b> {interfaceItem.lastError}</li>
            <li><b>Time since last active:</b> <Timeago date={interfaceItem.lastActive} /></li>
            <LastRow><b>Queue size:</b> {interfaceItem.queueSize}</LastRow>
        </ul>}
    </ItemRow>;
};

export default EnsembleInterfaceRow;