import * as React from 'react';

import { SessionContext } from 'src/Views/SessionContext';

import Button from 'src/SharedComponents/Button';
import Dialog from 'src/SharedComponents/Dialog/Dialog';
import DialogActions from 'src/SharedComponents/Dialog/DialogActions';
import ErrorBox from 'src/SharedComponents/ErrorBox';
import Label from 'src/SharedComponents/Label';
import ListItem from 'src/SharedComponents/List/ListItem';
import SectionList, { ISectionListData, ISectionListRenderItemInfo } from 'src/SharedComponents/List/SectionList';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import ManagerTitleBar from 'src/Views/Manager/ManagerTitleBar';

import { addRolesToClient, getRolesToAdd, searchForRolesToAdd } from "./clientManagementService";
import useEffectOnSome from 'src/CustomHooks/useEffectOnSome';
import IClient from 'src/ServerEntities/IClient';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IRole from 'src/ServerEntities/IRole';

const EmptyList = () => <div style={{ color: "#555", padding: "24px" }}>There are no more roles to add to this Client.</div>

const getSections = (roles: IRole[]): Array<ISectionListData<{ title: string, description: string }>> => [
    { data: roles.map((site: IRole) => ({ title: site.id, description: site.description })) }
];

interface IProps {
    client: IClient,
    show: boolean,
    onClose: () => void,
    onConfirmRoleAdd: () => void
}

const roleMatchesRole = (roleToMatch: string) => (role: IRole) => roleToMatch === role.id;

const AddRoleToClientDialog = (props: IProps) => {
    const { webToken } = React.useContext(SessionContext).state;
    const Client = props.client;
    const [rolesToAdd, setRolesToAdd] = React.useState([] as IRole[]);
    const [roles, setRoles] = React.useState([] as IRole[]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");

    const closeDialog = () => {
        setRolesToAdd([]);
        props.onClose();
    };

    const searchRoles = (searchText: string) => {
        if (searchText) {
            searchForRolesToAdd(Client.id, searchText, webToken, (rolesFromServer: IRole[]) => {
                setLoading(false);
                setRoles(rolesFromServer);
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
            });
        } else {
            getRolesToAdd(Client.id, webToken, (rolesFromServer: IRole[]) => {
                setLoading(false);
                setRoles(rolesFromServer);
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
            });
        }
    };

    const toggleRoleToAdd = (role: IRole) => () => {
        const rolesToAddCopy = [...rolesToAdd];
        const roleIndexInArray = rolesToAddCopy.findIndex((roleToAdd: IRole) => {
            return role === roleToAdd;
        });
        if (roleIndexInArray === -1) {
            rolesToAddCopy.push(role);
        } else {
            rolesToAddCopy.splice(roleIndexInArray, 1);
        }
        setRolesToAdd(rolesToAddCopy);
    };

    const addRolesConfirm = () => {
        addRolesToClient(
            rolesToAdd,
            Client.id,
            webToken,
            () => {
                setRolesToAdd([]);
                props.onConfirmRoleAdd();
            },
            (errorMessage) => {
                return ""
            })
    };

    // const changeRoleFilterText = (e: React.SyntheticEvent<HTMLInputElement>) => {
    //     const value = e.currentTarget.value;
    //     setLoading(true);
    //     delayedSearch(value);
    //     setRoleFilterText(value);
    // };

    useEffectOnSome(() => {
        searchRoles("");
    }, [], [searchRoles]);

    const renderRole = (info: ISectionListRenderItemInfo<{ title: string, description: string }>) =>
        <ListItem onClick={toggleRoleToAdd(roles[info.index])} key={`item${info.index}`}>
            <Label>
                <h2>{info.item.title}</h2>
                <h3>{info.item.description}</h3>
            </Label>
            {rolesToAdd.findIndex(roleMatchesRole(info.item.title)) !== -1 && <FontAwesomeIcon icon={faCheck} style={{ color: "#187fba" }} />}
        </ListItem>;


    return <Dialog open={props.show} onClose={closeDialog} style={{ width: "650px", height: "550px" }}>
        <ManagerTitleBar viewName="Add role(s)" viewDescription="Select roles below to add to this Client." />
        <LoadingIndicator show={loading} type="Linear" />
        {error && <ErrorBox>{error}</ErrorBox>}
        {roles.length === 0 && <EmptyList />}
        <SectionList sections={getSections(roles)} renderItem={renderRole} />
        <DialogActions>
            <Button outlined={true} onClick={closeDialog}>Cancel</Button>
            <Button onClick={addRolesConfirm} margin="0px 16px 0px 16px" disabled={rolesToAdd.length === 0}>Confirm</Button>
        </DialogActions>
    </Dialog>;
};

export default AddRoleToClientDialog;
