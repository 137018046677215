import * as React from 'react';
import styled from 'styled-components';

import IHybridView from 'src/ServerEntities/IHybridView';
import IServer from 'src/ServerEntities/IServer';
import IServerAlerts from 'src/ServerEntities/IServerAlerts';
import ISite from 'src/ServerEntities/ISite';
import ErrorBox from 'src/SharedComponents/ErrorBox';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import ServerAlert from 'src/Views/Ram/Components/ServerAlert';
import TypeHolder from 'src/Views/Ram/Components/TypeHolder';
import ServerItem from 'src/Views/Ram/ServerItem';

import { useNavigate } from 'react-router';
import { RolesContext } from 'src/Roles/RolesContext';
import { getFirstPathOn401RoleChange } from 'src/Roles/RolesService';
import ISitePermissions from 'src/ServerEntities/ISitePermissions';
import renderSiteName from 'src/UsefulFunctions/renderSiteName';
import { SessionContext } from 'src/Views/SessionContext';
import { getHybridView } from './ramService';
import { filterAndSortUserAlerts, sortBySelection } from './Utils/alertUtils';

const MainContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 48px 48px 104px;
    flex: 1 0 auto;
`;

const HybridContainer = styled.main`
    display: flex;
    flex-direction: row;
    flex: 1 0 auto;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
`;

const AlertContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 8 1 auto;
    padding: 0 12px;
`;

const SiteContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0 1 auto;
    min-width: 320px;
    padding: 0 12px;
`;

const ListTitle = styled.h2`
    font-size: 1.4rem;
    font-weight: 600;
`;

const EmptyText = styled.p`
    margin: 0;
`;

const SiteHeader = styled.div`
    display: flex;
    flex-direction: row;
    margin: 8px 0 4px 20px;
    align-items: center;
`;

const SiteLabel = styled.div`
    margin-left: 10px;
`;

const SiteRow = styled.li`
    & li {
        background-color: #42a83f;
        margin: 4px 0;
    }
    &:nth-child(odd) li {
        background-color: #60c45b;
    }
    & li.with-alerts {
        background-color: #f99600;
    }
    & li.without-heartbeat {
        background-color: #ff2121;
    }
    &:nth-child(odd) li.with-alerts {
        background-color: #f99600;
    }
    &:nth-child(odd) li.without-heartbeat {
        background-color: #ff2121;
    }
    & h3 {
        width: 170px;
    }
`;

const ServerList = styled.ul`
    display: flex;
    flex-direction: column;
`;

interface IProps {
    selectedTypes: string[],
    viewUserIssues: boolean,
    changeRole: boolean,
    setChangeRole: (changeRole: boolean) => void,
    sortByDate: boolean
};

const Hybrid = (props: IProps) => {
    const { selectedTypes, viewUserIssues, changeRole, setChangeRole, sortByDate } = props;
    const { state } = React.useContext(SessionContext);
    const [currentAlerts, setCurrentAlerts] = React.useState([] as unknown as IServerAlerts[]);
    const [sites, setSites] = React.useState([] as unknown as ISite[]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [refresh, setRefresh] = React.useState(0);
    const { loggedInUser } = React.useContext(SessionContext).state;
    const roleContext = React.useContext(RolesContext);
    const navigate = useNavigate();

    const serverToRow = (site: string, siteName: string, permissions: ISitePermissions) => (server: IServer, index: number) => {
        return <ServerItem sitePermissions={permissions} enlarge={false} displaySite={true} linkToServer={true} site={site} siteName={siteName} server={server} key={`site-${siteName}-server-${server.name}-${index}`} refresh={refresh} setRefresh={setRefresh} />;
    };

    const siteToRow = (site: ISite, index: number) => {
        const servers = site.servers;
        return <SiteRow key={`ram-site-${index}`}>
            <SiteHeader>
                <TypeHolder className={`site-type-${site.type.toLocaleLowerCase()}`}>{site.type}</TypeHolder>
                <SiteLabel>{renderSiteName(site.site, site.alias)}</SiteLabel>
            </SiteHeader>
            <ServerList>
                {servers.map(serverToRow(site.site, renderSiteName(site.site, site.alias), site.permissions))}
            </ServerList>
        </SiteRow>;
    };

    const serverToItem = (closed: boolean, refresh: number, setRefresh: (refresh: number) => void, viewUserIssues: boolean) => (server: IServerAlerts, index: number) => {
        return <ServerAlert
            closed={closed}
            key={`hybrid-view-server-${server.name}-${index}-alerts`}
            refresh={refresh}
            server={server}
            setRefresh={setRefresh}
            viewName="hybrid-view"
            viewUserIssues={viewUserIssues}
        />
    };

    const checkRoleChange = async (errorMessage: string) => {
        if (changeRole) {
            const path = await getFirstPathOn401RoleChange(errorMessage, state.webToken, roleContext.state.ramConfiguration?.components, roleContext.state.role)
            if (path) {
                navigate(path);
            }
            setChangeRole(false);
        }
    }

    React.useEffect(() => {
        setLoading(true);
        getHybridView(state.webToken, (serverView: IHybridView) => {
            setLoading(false);
            setCurrentAlerts(serverView.alerts);
            setSites(serverView.sites);
            setError("");
            setChangeRole(false);
        }, (errorMessage: string) => {
            checkRoleChange(errorMessage);
            setLoading(false);
            setError(errorMessage);
        });
    }, [state.webToken, refresh]);

    const filteredCurrentAlerts = currentAlerts.filter(alert => selectedTypes.length === 0 || selectedTypes.includes(alert.type));
    const userIssues = filterAndSortUserAlerts(currentAlerts, loggedInUser, sortByDate);

    filteredCurrentAlerts.forEach(sortBySelection(sortByDate));

    const siteNamesWithUserIssues = userIssues.map(server => server.site);
    const filteredSites = sites.filter(site => selectedTypes.length === 0 || selectedTypes.includes(site.type));
    const orderedSites = filteredSites.sort((siteA, siteB) => siteB.alerts - siteA.alerts);
    const userFilteredSites = sites.filter(site => siteNamesWithUserIssues.includes(site.site));

    return <MainContent>
        <LoadingIndicator type="Linear" show={loading} />
        {error && <ErrorBox>{error}</ErrorBox>}
        <HybridContainer>
            <AlertContainer>
                <ListTitle>Current issues</ListTitle>
                {(filteredCurrentAlerts.length === 0 || userIssues.length === 0) && <EmptyText>No current issues</EmptyText>}
                <ul>
                    {viewUserIssues ? userIssues.map(serverToItem(false, refresh, setRefresh, props.viewUserIssues)) : filteredCurrentAlerts.map(serverToItem(false, refresh, setRefresh, props.viewUserIssues))}
                </ul>
            </AlertContainer>
            <SiteContainer>
                <ul>
                    {viewUserIssues ? userFilteredSites.map(siteToRow) : orderedSites.map(siteToRow)}
                </ul>
            </SiteContainer>
        </HybridContainer>
    </MainContent>;
};

export default Hybrid;
