import React, { useEffect } from 'react';
import IRoleSite from 'src/ServerEntities/IRoleSite';
import Button from 'src/SharedComponents/Button';
import ErrorBox from 'src/SharedComponents/ErrorBox';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import ScrollingContainer from 'src/SharedComponents/ScrollingContainer';
import Select from 'src/SharedComponents/Select/Select';
import SelectItem from 'src/SharedComponents/Select/SelectItem';
import TextInput from 'src/SharedComponents/TextInput';
import Description from 'src/Views/Manager/Components/Description';
import ManagerTitle from 'src/Views/Manager/Components/Title';
import TitleBarContainer from 'src/Views/Manager/Components/TitleBarContainer';
import styled from 'styled-components';
import { updateAlias, updateSiteType } from './SiteManagementService';

interface IProps {
    selectedSite: IRoleSite
    webToken: string,
    refreshSites: () => void,
    setErrorMessage: (errorMessage: string) => void,
    siteTypes: string[]
};


const UsernameRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;


const UserDetailsBar = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #e5e5e5;
    padding: 6px 24px;
    & p {
        margin: 0 24px 0 0;
        font-size: 14px;
        color: #4c4c4c;
        font-weight: bold;
    }
    & p label {
        font-weight: normal;
    }
`;

const SiteDetailsView = styled.div`
    display: flex;
    flex: 1 1 auto;
    @media (min-width: 350px) and (max-width: 768px) {
        position: relative;
        bottom: 15%;
    }
`


const SiteDetails = (props: IProps) => {
    const { selectedSite, webToken } = props;
    const { siteTypes } = props;
    const [selectedType, setSelectedType] = React.useState("");
    const [siteAlias, setSiteAlias] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [updateAvailable, setUpdateAvailable] = React.useState(false);

    const changeType = (type: string) => {
        setLoading(true);
        updateSiteType(selectedSite.id, type, webToken, (response: string) => {
            setLoading(false);
            setError("");
            setSelectedType(type);
            setError("");
        }, handleError);
    }

    const changeSiteAlias = (e: React.SyntheticEvent<HTMLInputElement>) => {
        setSiteAlias(e.currentTarget.value);
        setUpdateAvailable(true);
    }

    const handleError = (error: string) => {
        setError(error);
        setLoading(false);
        setSiteAlias(selectedSite.alias);
        setUpdateAvailable(false);
    }

    const updateSiteAlias = () => {
        if (siteAlias) {
            setLoading(true);
            updateAlias(selectedSite.id, siteAlias, webToken, (response: string) => {
                setLoading(false);
                setError("");
                setUpdateAvailable(false);
            }, handleError);
        }
    }

    useEffect(() => {
        setSelectedType(selectedSite.type)
        setSiteAlias(selectedSite.alias)
    }, [selectedSite.type, selectedSite.alias]);

    return <SiteDetailsView>
        <ScrollingContainer direction="column">
            <TitleBarContainer>
                <div>
                    <UsernameRow>
                        <ManagerTitle>{selectedSite.id}</ManagerTitle>
                    </UsernameRow>
                    <Description>{selectedSite.description}</Description>
                </div>
            </TitleBarContainer>
            <LoadingIndicator type="Linear" show={loading} />
            {error && <ErrorBox>{error}</ErrorBox>}
            <UserDetailsBar>
                <label>Type</label>
                <Select
                    childValues={siteTypes}
                    placeholder="Choose type"
                    margin="4px 24px 0px 10px"
                    value={selectedType}
                    onChange={changeType}
                    width="200px"
                >
                    {siteTypes.map((type: string) => <SelectItem key={`select-item-${type}`} itemKey={`select-item-${type}`} value={type}>
                        {type}
                    </SelectItem>)}
                </Select>
            </UserDetailsBar>
            <UserDetailsBar>
                <label style={{ marginRight: "10px" }}>Alias</label>
                <TextInput value={siteAlias} onChange={changeSiteAlias} />
                <Button disabled={!updateAvailable} onClick={updateSiteAlias}>Update</Button>
            </UserDetailsBar>
        </ScrollingContainer>
    </SiteDetailsView >;
};

export default SiteDetails;
