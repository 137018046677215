import styled from "styled-components";

const DialogContentText = styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 1.75;
    color: rgba(0, 0, 0, 0.54);
    margin: 0;
`;

export default DialogContentText;
