import QRCode from "qrcode.react";
import * as React from "react";
import Button from 'src/SharedComponents/Button';
import Dialog from 'src/SharedComponents/Dialog/Dialog';
import DialogActions from 'src/SharedComponents/Dialog/DialogActions';
import DialogContent from "src/SharedComponents/Dialog/DialogContent";
import DialogTitle from "src/SharedComponents/Dialog/DialogTitle";
import ErrorBox from 'src/SharedComponents/ErrorBox';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import TextInputCode from "src/SharedComponents/TextInputCode";
import styled from 'styled-components';
import { SessionContext } from '../SessionContext';
import { activateMfa, deactivateMfa, getMfaSecretKey, getMfaStatus } from "./mfaService";

interface IProps {
    open: boolean,
    onClose: () => void,
};

const OptionList = styled.ol`
    padding: 0;
    margin: 0;
    list-style-type: none;
`;

const OptionItem = styled.li`
    margin: 24px 0;
`;

const InfoLine = styled.div`
    margin: 0;
`;

const QRContainer = styled.div`
    padding: 12px;
    display: flex;
    justify-content: space-around;
`;

const GenerateCodeDialog = (props: IProps) => {
    const { onClose, open } = props;
    const [secretKey, setSecretKey] = React.useState("");
    const [code, setCode] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const { webToken } = React.useContext(SessionContext).state;
    const [mfaEnabled, setMfaEnabled] = React.useState(false);

    const closeDialog = () => {
        setSecretKey("");
        setCode("");
        onClose();
    };

    React.useEffect(() => {
        setLoading(true);
        getMfaStatus(webToken, (enabled: boolean) => {
            setLoading(false);
            setMfaEnabled(enabled);
            setErrorMessage("");
        }, (error: string) => {
            setLoading(false);
            setErrorMessage(error);
        });
    }, [webToken]);

    const generateCode = () => {
        setLoading(true);
        getMfaSecretKey(webToken, (key: string) => {
            setLoading(false);
            setSecretKey(key);
            setErrorMessage("");
        }, (error: string) => {
            setLoading(false);
            setErrorMessage(error);
        });
    };

    const changeCode = (e: React.SyntheticEvent<HTMLInputElement>) => {
        setCode(e.currentTarget.value)
    }

    const onActivateMfa = () => {
        setLoading(true);
        activateMfa(code, webToken, () => {
            setLoading(false);
            setSecretKey("");
            setCode("");
            setMfaEnabled(true);
            setErrorMessage("");
        }, (error: string) => {
            setLoading(false);
            setErrorMessage(error);
        });
    }

    const onDeactivateMfa = () => {
        setLoading(true);
        deactivateMfa(code, webToken, () => {
            setLoading(false);
            setSecretKey("");
            setCode("");
            setMfaEnabled(false);
            setErrorMessage("");
        }, (error: string) => {
            setLoading(false);
            setErrorMessage(error);
        });
    }

    const renderQR = () => {
        return (
            <OptionList>
                <OptionItem>
                    <InfoLine>Open your authenticator app and scan the following QR code:</InfoLine>
                    <QRContainer>
                        {secretKey && <QRCode value={secretKey} size={256} />}
                    </QRContainer>
                </OptionItem>
                <OptionItem>
                    <InfoLine>Insert the code generated in your authenticator</InfoLine>
                    <TextInputCode maxLength={6} value={code} onChange={changeCode} />
                </OptionItem>
                <OptionItem>
                    <Button onClick={onActivateMfa}>Send code</Button>
                </OptionItem>
            </OptionList>
        );
    };

    const renderNoMfa = () => {
        return (
            <OptionList>
                <OptionItem>
                    <InfoLine>Press activate to generate a TOTP code.</InfoLine>
                </OptionItem>
                <OptionItem>
                    <Button onClick={generateCode}>Activate</Button>
                </OptionItem>
            </OptionList>
        );
    };

    const renderMfa = () => {
        return (
            <OptionList>
                <OptionItem>
                    <InfoLine>Your account is protected with TOTP MFA.</InfoLine>
                    <OptionItem>
                        <InfoLine>Insert the code generated in your authenticator</InfoLine>
                        <TextInputCode maxLength={6} value={code} onChange={changeCode} />
                    </OptionItem>
                    <OptionItem>
                        <Button onClick={onDeactivateMfa}>Disable Multi-factor Authentication</Button>
                    </OptionItem>
                </OptionItem>
            </OptionList>
        );
    };

    return (
        <Dialog open={open} onClose={closeDialog} style={{ width: "400px", padding: "30px" }}>
            <DialogContent style={{ textAlign: "center" }}>
                <DialogTitle>Multi-factor Authentication</DialogTitle>
                {loading && <LoadingIndicator type="Linear" show={true} />}
                {secretKey ? renderQR() : !mfaEnabled && renderNoMfa()}
                {mfaEnabled && renderMfa()}
                {errorMessage && <ErrorBox>{errorMessage}</ErrorBox>}
                <DialogActions>
                    <Button margin="auto" outlined={true} onClick={closeDialog}>Close</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

export default GenerateCodeDialog;
