import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import FloatingLabel from './FloatingLabel';

const defaultColor = "#187fda";
const errorColor = "red";

const InputBox = styled.input`
line-height:30px;
font-size:14px;
height:30px;
background-color:transparent;
color: #0c0c0c;
width:100%;
`;

interface IProps {
    autoFocus?: boolean,
    value: string,
    onBlur?: (e: React.SyntheticEvent<HTMLInputElement>) => void,
    onChange: (e: React.SyntheticEvent<HTMLInputElement>) => void,
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void,
    disabled?: boolean,
    label?: string,
    type?: string,
    margin?: string,
    required?: boolean,
    hideAssistiveText?: boolean,
    width?: string,
    placeholder?: string,
    noOutline?: boolean,
    autoComplete?: string,
    invalid?: boolean,
    maxLength?: number
};

const TextInputContainer = styled.div<IProps>`
opacity: ${props => props.disabled ? 0.2 : 1};
position:relative;
border: 1px solid #e5e5e5;
border-radius:3px;
padding: 0 10px;
margin: ${props => props.margin ? props.margin : ""};
transition: border-color .15s cubic-bezier(.4,0,.2,1);
background-color: white;

&.focused{
    border-color:${defaultColor};
}
&.error{
    border-color:${errorColor};
}
&.noOutline{
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-radius:0px;
    padding-left:0px;
    padding-right:0px;
}
width:${props => props.width};
flex:${props => props.width ? "0 0 auto" : ""};
`;

interface ITextStyleProps {
    error: boolean | undefined
};

const AssistiveText = styled.p<ITextStyleProps>`
font-size:10px;
color: ${props => props.error ? errorColor : "#62676B"};
`;

const AssistiveTextRow = styled.div`
position: absolute;
display:flex;
flex-direction:row;
justify-content: space-between;
width:100%;
left:0px;
`;

const TextInput = (props: IProps) => {
    const {
        autoFocus,
        value,
        onChange,
        onBlur,
        onKeyDown,
        label,
        type,
        required,
        placeholder,
        noOutline,
        hideAssistiveText,
        autoComplete,
        disabled,
        invalid,
        maxLength
    } = props;

    const [focus, setFocus] = useState(false);
    const [error, setError] = useState(false);
    const reference = React.useRef(null as unknown as any);

    const flagAsFocused = () => {
        if (reference.current != null) {
            reference.current.focus();
        }
        setFocus(true);
    };

    const clearFocus = () => {
        setFocus(false);
        setError(value === "" && !!required);
    };

    const changeText = (event: React.SyntheticEvent<HTMLInputElement>) => {
        setError(event.currentTarget.value === "" && !!required);
        onChange(event);
    };

    const cancelContainerChangeEvent = (e: React.SyntheticEvent<any>) => {
        e.stopPropagation();
    };

    return <TextInputContainer
        style={invalid ? { borderColor: "red" } : undefined}
        className={`textInput ${focus && "focused"} ${error && "error"} ${noOutline && "noOutline"}`}
        onFocus={flagAsFocused}
        {...props}
        onBlur={clearFocus}
        onChange={cancelContainerChangeEvent}
    >
        {label && <FloatingLabel className={`textInputLabel ${focus && "focused"} ${(focus || value !== "" || placeholder) && "above"} ${(error && "error")}`} onClick={flagAsFocused}>{label}</FloatingLabel>}
        <InputBox
            autoFocus={autoFocus}
            disabled={disabled}
            value={value}
            ref={reference}
            onChange={changeText}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            type={type}
            maxLength={maxLength}
            placeholder={placeholder}
            autoComplete={autoComplete} />
        <AssistiveTextRow className="AssistiveTextRow">
            {(required && !hideAssistiveText) ? <AssistiveText className="AssistiveText" error={error}>*Required</AssistiveText> : null}
        </AssistiveTextRow>

    </TextInputContainer>
}

export default TextInput;
