import React from "react";
import INotificationTimeout from "src/ServerEntities/INotificationTimeout";
import Button from "src/SharedComponents/Button";
import Checkbox from "src/SharedComponents/Checkbox";
import TextInput from "src/SharedComponents/TextInput";
import styled from "styled-components";
import { debounce } from "throttle-debounce";
import { saveNotificationTimeout } from "../UserManagement/smsNotificationService";

const SavedCell = styled.td`
    color: #16B012;
    &:before {
        content: '\u2713';
        margin-right: 4px;
    }
`;

interface INotificationRowProps {
    index: number,
    notificationTimeout: INotificationTimeout,
    setError: (errorMessage: string) => void,
    setNotificationOpen: (notification: INotificationTimeout) => void,
    updateItem: (index: number, newTimeout: number) => void,
    webToken: string
};

const NotificationRow = (props: INotificationRowProps) => {
    const { index, setError, notificationTimeout, setNotificationOpen, updateItem, webToken } = props;
    const [timeoutValue, setTimeoutValue] = React.useState(notificationTimeout.time);
    const [loading, setLoading] = React.useState(false);
    const [saved, setSaved] = React.useState(false);
    const [emailAlert, setEmailAlert] = React.useState(notificationTimeout.emailAlert);
    const [smsAlert, setSmsAlert] = React.useState(notificationTimeout.smsAlert);

    const onEmailAlertChange = (checked: boolean) => {
        notificationTimeout.emailAlert = checked;
        setEmailAlert(checked);
        save(notificationTimeout);
    };

    const onSmsAlertChange = (checked: boolean) => {
        notificationTimeout.smsAlert = checked;
        setSmsAlert(checked);
        save(notificationTimeout);
    };

    const updateTimeout = (e: React.SyntheticEvent<HTMLInputElement>) => {
        const value = parseInt(e.currentTarget.value, 10);
        setTimeoutValue(value);
        notificationTimeout.time = value;
        deferredSave(notificationTimeout);
    };

    const save = (notificationTimeout: INotificationTimeout) => {
        setLoading(true);
        setSaved(false);
        saveNotificationTimeout(notificationTimeout, webToken, () => {
            setLoading(false);
            setSaved(true);
            updateItem(index, notificationTimeout.time);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    };

    const deferredSave = React.useRef(debounce(1000, save)).current;

    const viewDetails = () => {
        setNotificationOpen(notificationTimeout);
    };

    return <tr>
        <td>{notificationTimeout.site}</td>
        <td>Notifications sent after </td>
        <td><TextInput disabled={loading} type="number" width="35px" margin="0 5px" value={timeoutValue + ""} onChange={updateTimeout} /></td>
        <td>minutes of inactivity.</td>
        <td>Email Alert</td>
        <td><Checkbox checked={emailAlert} onChange={onEmailAlertChange}></Checkbox></td>
        <td>SMS Alert</td>
        <td><Checkbox checked={smsAlert} onChange={onSmsAlertChange}></Checkbox></td>
        <td><Button onClick={viewDetails}>View details</Button></td>
        {saved && <SavedCell>Saved</SavedCell>}
    </tr>;
};

export default NotificationRow;
