import Chart from 'react-apexcharts';
import ILicenceInformation from 'src/ServerEntities/ILicenceInformation';
import styled from 'styled-components';

const ServerInformationContainer = styled.div`
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    background: #1c3e5a;
    & h3 {
        background-color: #246896;
        margin: 0;
        flex: 0 0 auto;
        padding: 12px 12px 6px;
        @media (min-width: 1279px) {
            border-radius: 16px 16px 0 0;
        }
        font-weight: 500;
        font-size: 1.2rem;
    }
    @media (min-width: 1279px) {
        flex: 1 1 auto;
        border-radius: 16px;
    }
    @media (max-width: 1279px) {
        width: 100%;
        margin-bottom: 4px;
    }
`;

interface IProps {
    licenceInformation: ILicenceInformation[]
};

const ServerLicenceInformation = (props: IProps) => {
    const { licenceInformation } = props;
    const sortedInformation = licenceInformation.sort((a, b) => a.informationSent - b.informationSent);

    const xAxis: ApexXAxis = {
        type: "datetime"
    };

    const options = {
        chart: {
            foreColor: "#ffffff"
        },
        dataLabels: {
            enabled: false
        },
        tooltip: {
            theme: "dark"
        },
        xaxis: xAxis
    };
    const series = [{
        data: sortedInformation.map(item => {
            const informationSentAsDate = new Date(item.informationSent)
            return { x: informationSentAsDate, y: item.currentlyInUse };
        }),
        name: "Licences used"
    }, {
        data: sortedInformation.map(item => {
            const informationSentAsDate = new Date(item.informationSent)
            return { x: informationSentAsDate, y: item.maxUsed };
        }),
        name: "Maximum licences used"
    }];

    return <ServerInformationContainer>
        <h3>Licences used</h3>
        <Chart
            type="area"
            height="400"
            options={options}
            series={series}
        />
    </ServerInformationContainer>;
};

export default ServerLicenceInformation;
