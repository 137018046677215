import Axios, { AxiosError, AxiosResponse } from 'axios';
import { getAPIUrl } from 'src/config';

export const tellServerUserInteractionHasOccurred = (webToken: string, successCallback: (newJWT: string) => void, failureCallback: () => void) => {
    Axios.get(
        `${getAPIUrl()}session/${webToken}/update`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "text/plain" } }
    ).then((response: AxiosResponse<string>) => {
        successCallback(response.data);
    }).catch((error: AxiosError) => {
        failureCallback();
    });
};

export const verifySessionWithServer = (webToken: string, successCallback: () => void, failureCallback: () => void) => {
    Axios.get(
        `${getAPIUrl()}session/${webToken}/verify`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "text/plain" } }
    ).then((response: AxiosResponse<string>) => {
        successCallback();
    }).catch((error: AxiosError) => {
        failureCallback();
    });
};