import * as React from "react";

import useRestAPI from 'src/CustomHooks/useRestAPI';

import Select from 'src/SharedComponents/Select/Select';
import SelectItem from 'src/SharedComponents/Select/SelectItem';

import Button from 'src/SharedComponents/Button';
import Dialog from 'src/SharedComponents/Dialog/Dialog';
import DialogActions from 'src/SharedComponents/Dialog/DialogActions';
import DialogContent from 'src/SharedComponents/Dialog/DialogContent';
import DialogContentText from 'src/SharedComponents/Dialog/DialogContentText';
import DialogTitle from 'src/SharedComponents/Dialog/DialogTitle';
import ErrorText from 'src/SharedComponents/ErrorText';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';

import { RolesContext } from 'src/Roles/RolesContext';

import { changePreferredRole, getManagerConfigurationForRole, getRamConfigurationForRole } from 'src/Roles/RolesService';
import { IManagerConfiguration, IRamConfiguration } from 'src/ServerEntities/IConfiguration'
import { SessionContext } from 'src/Views/SessionContext';

interface IRoleChangeDialogProps {
    darkBackground?: boolean,
    open: boolean;
    onClose: () => void;
    applicationName: string;
    refresh?: number;
    dialogRef: { current: HTMLDivElement };
    resetUrl?: string;
    setChangeRole?: (changeRole: boolean) => void;
}

const RoleChangeDialog = (props: IRoleChangeDialogProps) => {
    const { applicationName, darkBackground, open, onClose, dialogRef, resetUrl, setChangeRole } = props;
    const [refresh, setRefresh] = React.useState(0);
    const { dispatch } = React.useContext(RolesContext);
    const [newRole, setNewRole] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState("");
    const [onOpen, setOnOpen] = React.useState(false);
    const [roleChangeLoading, setRoleChangeLoading] = React.useState(false);
    const [roleChangeError, setRoleChangeError] = React.useState("");
    const sessionContext = React.useContext(SessionContext);
    const { webToken } = sessionContext.state;

    const { data, error, loading } = useRestAPI(
        `dashboard/${applicationName}/roles`,
        "GET",
        {},
        webToken,
        refresh
    );

    const refreshRoles = () => {
        setRefresh(refresh + 1);
    };

    if (open && !onOpen) {
        setOnOpen(true);
        refreshRoles();
    }

    const roles = data || [];
    const selectedRole = roles.find((item: string) => item === newRole);

    const updateConfiguration = (configuration: IRamConfiguration) => {
        dispatch({ type: "setRamConfiguration", payload: configuration });
    };

    const updateManagerConfiguration = (configuration: IManagerConfiguration) => {
        dispatch({ type: "setManagerConfiguration", payload: configuration });
    };

    const handleConfigurationError = (serverError: string) => {
        setErrorMessage(serverError);
    };

    const onRoleChangeSuccess = (jwt: string) => {
        if (newRole !== null) {
            sessionContext.dispatch({ type: "setWebToken", payload: jwt });
            dispatch({ type: "setRole", payload: selectedRole });
            if (applicationName === "RAM") {
                getRamConfigurationForRole(selectedRole, jwt, updateConfiguration, handleConfigurationError);
            } else {
                getManagerConfigurationForRole(selectedRole, jwt, updateManagerConfiguration, handleConfigurationError);
            }
            if (resetUrl) {
                window.location.href = resetUrl;
            }
        }
        setRoleChangeLoading(false);
        closeDialog();
    }

    const onRoleChangeFailure = (serverError: string) => {
        setRoleChangeError(serverError);
        setRoleChangeLoading(false);
    }

    const confirmChangeRole = () => {
        if (selectedRole) {
            if(setChangeRole) setChangeRole(true);
            setRoleChangeLoading(true);
            changePreferredRole(selectedRole, applicationName, webToken, onRoleChangeSuccess, onRoleChangeFailure);        }
    }

    const closeDialog = () => {
        setNewRole("");
        setRoleChangeLoading(false);
        onClose();
        setOnOpen(false);
    };

    React.useEffect(() => {
        setRefresh(1);
    }, []);

    return <Dialog open={open} onClose={onClose} darkBackground={darkBackground}>
        <LoadingIndicator show={loading} type="Linear" />
        <DialogTitle className="DialogTitle">Change current role</DialogTitle>
        <DialogContent className="DialogContent" style={{ margin: "0px 0px 24px 0px" }}>
            <DialogContentText className="DialogContentText">Please select the new role you wish to use for IMX-CR</DialogContentText>
            <Select
                selectRef={dialogRef}
                childValues={roles}
                placeholder="Choose role"
                margin="4px 24px 0px 0px"
                value={selectedRole || ""}
                onChange={setNewRole}
                width="200px"
            >
                {roles.map((role: string) => <SelectItem key={`select-item-${role}`} itemKey={`select-item-${role}`} value={role}>
                    {role}
                </SelectItem>)}
            </Select>
            {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
            {error && <ErrorText>{error}</ErrorText>}
            <ErrorText>{roleChangeError}</ErrorText>
        </DialogContent>
        <DialogActions className="DialogActions">
            <Button margin="0px 4px" onClick={closeDialog} outlined={true}>Cancel</Button>
            <Button margin="0px 4px" disabled={newRole === null} loading={roleChangeLoading} onClick={confirmChangeRole} >Confirm</Button>
        </DialogActions>
    </Dialog>
}

export default RoleChangeDialog;