import * as React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ResetPassword from "./Login/ResetPassword";
import ManagerIndex from "./Manager/ManagerIndex";
import RamIndex from "./Ram/RamIndex";

import { SessionContext, SessionContextProvider } from './SessionContext';

import Login from 'src/Views/Login/Login';
import SessionManager from 'src/Views/SessionManager';

import { RolesContextProvider } from 'src/Roles/RolesContext';

const PageIndex = () => <SessionContextProvider>
    <SessionManager />
    <RolesContextProvider>
        <IndexWithSession />
    </RolesContextProvider>
</SessionContextProvider>;

const IndexWithSession = () => {
    const { state } = React.useContext(SessionContext);

    const renderRamRoute = (pageName?: string) => {
        return state.webToken ? <RamIndex pageName={pageName} /> : <Login applicationName="RAM" />;
    };

    const renderManagerRoute = () => {
        return state.webToken ? <ManagerIndex /> : <Login applicationName="Manager" />;
    };

    return <BrowserRouter>
        <Routes>
            <Route key="Ram" path="/Ram/Server/:siteName/:serverName/:namespace/:jwt" element={renderRamRoute("Server")} />
            <Route key="Ram" path="/Ram/Alerts/:jwt" element={renderRamRoute("Alerts")} />
            <Route key="Ram" path="/Ram/Sites/:siteName" element={renderRamRoute("Sites")} />
            <Route key="Ram" path="/Ram/:pageName" element={renderRamRoute()} />
            <Route key="Ram" path="/Ram" element={renderRamRoute()} />
            <Route key="password" path="/passwordreset/:token" element={<ResetPassword />} />
            <Route key="login" path="/Login" element={renderRamRoute()} />
            <Route key="Base" path="/" element={renderRamRoute()} />
            <Route key="ManagerBase" path="/Manager/*" element={renderManagerRoute()} />
            <Route key="ManagerChild" path="/Manager/:pageName" element={renderManagerRoute()} />
            <Route key="Ram" path="/Ram/Alert/:alertId" element={renderRamRoute("Alert")} />
        </Routes>
    </BrowserRouter>;
};

export default PageIndex;
