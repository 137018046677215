import * as React from 'react';
import useEffectOnSome from 'src/CustomHooks/useEffectOnSome';
import styled from 'styled-components';

import Card from 'src/SharedComponents/Card';
import ContentContainer from 'src/SharedComponents/ContentContainer';
import ErrorBox from "src/SharedComponents/ErrorBox";
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';

import useRestAPI from 'src/CustomHooks/useRestAPI';
import { RolesContext } from 'src/Roles/RolesContext';
import { SessionContext } from 'src/Views/SessionContext';

import { changePreferredRole, getManagerConfigurationForRole, getPreferredRole, getRamConfigurationForRole } from 'src/Roles/RolesService';

import { IManagerConfiguration, IRamConfiguration } from 'src/ServerEntities/IConfiguration';
import IRoleAndJwt from 'src/Roles/IRoleAndJwt';


const MainContainer = styled.div`
    display: flex;
`;

const InstructionText = styled.div`
    font-size: 18px;
    color: #575757;
    line-height: 19px;
    justify-content: center;
    text-align: center;
    padding: 36px;
`;

const RoleContainer = styled.div`
    font-size: 24px
    font-wieght: 300;
    letter-spacing: 1px;
    padding: 24px;
    color: #555;
    opacity: 1;
    &:hover {
        opacity: 0.6;
    }
`;

const Grid = styled.div`
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    justify-content:space-between;
`;

const ErrorText = styled.p`
display:flex;
flex-direction:row;
color: red;
line-height:21px;
font-size:16px;
justify-content:center;
align-items:center;
`;

interface IProps {
    applicationName: string
};

const PreferredRolePicker = (props: IProps) => {
    const applicationName = props.applicationName;
    const sessionContext = React.useContext(SessionContext);
    const { webToken } = sessionContext.state;
    const { dispatch } = React.useContext(RolesContext);
    const [roleChangeLoading, setRoleChangeLoading] = React.useState(false);
    const [initialToken] = React.useState(webToken);
    const [roleChangeError, setRoleChangeError] = React.useState("");
    const [error, setError] = React.useState("");
    const [role, setRole] = React.useState(undefined as unknown as string);
    const [triedPreferredRole, setTriedPreferredRole] = React.useState(false);

    const { data, loading } = useRestAPI(
        `dashboard/${applicationName}/roles`,
        "GET",
        {},
        initialToken
    );
    const roles = data || [];

    const onRoleChangeSuccess = (newRole: string) => (jwt: string) => {
        setRoleChangeLoading(false);
        sessionContext.dispatch({ type: "setWebToken", payload: jwt });
        dispatch({ type: "setRole", payload: newRole });
        if (applicationName === "RAM") {
            getRamConfigurationForRole(newRole, jwt, updateConfiguration, handleConfigurationError);
        } else {
            getManagerConfigurationForRole(newRole, jwt, updateManagerConfiguration, handleConfigurationError);
        }
    };

    const updateConfiguration = (configuration: IRamConfiguration) => {
        dispatch({ type: "setRamConfiguration", payload: configuration });
    };

    const updateManagerConfiguration = (configuration: IManagerConfiguration) => {
        dispatch({ type: "setManagerConfiguration", payload: configuration });
    };

    const handleConfigurationError = (errorMessage: string) => {
        setError(errorMessage);
    };

    const onRoleChangeFailure = (errorMessage: string) => {
        setRoleChangeError(errorMessage);
        setRoleChangeLoading(false);
    };

    const setPreferredRole = (role: string) => () => {
        setRoleChangeError("");
        if (role) {
            setRoleChangeLoading(true);
            changePreferredRole(role, applicationName, webToken, onRoleChangeSuccess(role), onRoleChangeFailure);
        }
    };

    useEffectOnSome(() => {
        if (!role) {
            getPreferredRole(applicationName, webToken, (roleAndJwt: IRoleAndJwt) => {
                setRoleChangeLoading(false);
                setTriedPreferredRole(true);
                if (roleAndJwt.role === "") {
                    return;
                }
                setRole(roleAndJwt.role);
                sessionContext.dispatch({ type: "setWebToken", payload: roleAndJwt.jwt });
                dispatch({ type: "setRole", payload: roleAndJwt.role });
                if (applicationName === "RAM") {
                    getRamConfigurationForRole(roleAndJwt.role, roleAndJwt.jwt, updateConfiguration, handleConfigurationError);
                } else {
                    getManagerConfigurationForRole(roleAndJwt.role, roleAndJwt.jwt, updateManagerConfiguration, handleConfigurationError);
                }
            }, (errorMessage: string, errorCode?: number) => {
                setError(errorMessage);
                setTriedPreferredRole(true);
            });
        }
    }, [], [applicationName, webToken]);

    useEffectOnSome(() => {
        if (roles.length > 0 && roles.length === 1 && roleChangeLoading === false) {
            const role = roles[0];
            if (role) {
                setRoleChangeLoading(true);
                changePreferredRole(role, applicationName, webToken, onRoleChangeSuccess(role), onRoleChangeFailure);
            }
        };
    }, [webToken, setRoleChangeLoading, onRoleChangeSuccess], [applicationName, roles]);

    return <MainContainer>
        {triedPreferredRole && (!role) && <ContentContainer>
            {roles.length === 0 && !roleChangeLoading && <InstructionText>Sorry, you have no roles available.</InstructionText>}
            {roles.length > 0 && !roleChangeLoading && <InstructionText>Please select your role for using IMX-CR</InstructionText>}
            {roles.length > 0 && !roleChangeLoading && <Grid style={{ justifyContent: "center" }}>
                {roles.map((role: string) => <Card key={role} margin="24px" onClick={setPreferredRole(role)} rippleColor="#187fba">
                    <RoleContainer>
                        {role}
                    </RoleContainer>
                </Card>)}
            </Grid>}
            {error && <ErrorBox>{error}</ErrorBox>}
            {loading && <LoadingIndicator show={loading || roleChangeLoading} type="Radial" />}
            <ErrorText>{roleChangeError}</ErrorText>
        </ContentContainer>}
    </MainContainer>;
};

export default PreferredRolePicker;
