import styled from "styled-components";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from 'react';

interface ContainerProps {
    $isOpen: boolean;
};

interface IProps {
    options: any[]
    placeholder: string,
    onValueChange: (value: any) => void; 
};

const Container = styled.div<ContainerProps>`
    width: 100%;
    height: 100%;
    border: 1px solid ${props => props.$isOpen ? '#187fba' : '#cfcfcf'};
    border-bottom: 1px ${props => props.$isOpen ? 'none' : 'solid'} #cfcfcf;
    border-radius: 3px;
    border-bottom-left-radius: ${props => props.$isOpen ? '0' : '3px'};
    border-bottom-right-radius: ${props => props.$isOpen ? '0' : '3px'};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 24px;
    color: #919191;
    position: relative;
    background-color: #fff; 

    .select {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .faAngleDown {
        font-size: 20px;
        color: ${props => props.$isOpen && '#187fba'};
    }

    &:hover {
        border-color: ${props => !props.$isOpen && '#000'};
        cursor: pointer;
        color: ${props => !props.$isOpen && '#000'};
    }

    .dropdown {
        display: none;
        border: 1px solid #187fba;
        position: absolute;
        border-top: none;
        top: 100%;
        left: 50%; 
        transform: translateX(-50%);
        width: 100%; 
        min-height: 200px;
        max-height: 200px;
        background-color: #fff; 
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        overflow: auto; 
        scroll-behavior: smooth;
    }
    
    .dropdown .option {
        padding: 12px 24px;
        border-bottom: 1px solid #cfcfcf;
    }
    
    .dropdown .option:hover {
        background-color: #e3e3e3;
    }

    .dropdown.open {
        display: block;
    }
`;

const Dropdown = (props: IProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [value, setValue] = useState<any | null>(null);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const selectValue = (value: any) => () => {
        setValue(value)
        setIsOpen(!isOpen);
        props.onValueChange(value);
    };

    return (
        <Container $isOpen={isOpen}>
            <div className="select" onClick={toggleDropdown}>
                <p>{ value?.id !== undefined ? value.id : props.placeholder}</p>
                <FontAwesomeIcon className="faAngleDown" icon={faAngleDown} />
            </div>
            <div className={`dropdown ${isOpen ? 'open' : ''}`}>
            {
                props.options.map((element, index) => (
                    <div key={`dropdown-item-${index}`} className="option" onClick={selectValue(element)}>{element.id}</div>
                ))
            }
            </div>
        </Container>
    );
};

export default Dropdown;