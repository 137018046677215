import * as React from "react";

import { VERIFY_ACTIVE_INTERVAL } from 'src/config';
import { SessionContext } from 'src/Views/SessionContext';
import { tellServerUserInteractionHasOccurred } from 'src/Views/sessionService';

interface IState {};

interface IProps {};

class SessionManager extends React.Component<IProps, IState>{
    public static contextType = SessionContext;
    public context!: React.ContextType<typeof SessionContext>
    private verifyInterval: NodeJS.Timeout | null;

    constructor(props: IProps) {
        super(props);
        this.verifyInterval = null;
    }

    public componentDidMount() {
        this.verifyInterval = setInterval(this.tellServerAboutSession.bind(this), VERIFY_ACTIVE_INTERVAL);
    }

    public componentWillUnmount() {
        if (this.verifyInterval) {
            clearTimeout(this.verifyInterval);
        }
    }

    public render() {
        return <span />;
    }

    private userInteractionServerCallSuccess(newJWT: string) {
        this.context.dispatch({ type: "setWebToken", payload: newJWT });
    }

    private closeSession() {
        this.context.dispatch({ type: "clearLoggedInUser", payload: undefined });
        this.context.dispatch({ type: "clearWebToken", payload: undefined });
        this.context.dispatch({ type: "setShowExpiryDialog", payload: false });
    }

    private tellServerAboutSession() {
        if (this.context.state.webToken) {
            tellServerUserInteractionHasOccurred(this.context.state.webToken, this.userInteractionServerCallSuccess.bind(this), this.closeSession.bind(this))
        }
    }
};

export default SessionManager;