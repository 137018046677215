import * as React from "react";
import styled from "styled-components";

import { SessionContext } from 'src/Views/SessionContext';

import Dialog from 'src/SharedComponents/Dialog/Dialog';
import ErrorBox from "src/SharedComponents/ErrorBox";
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import ManagerTitleBar from 'src/Views/Manager/ManagerTitleBar';

import { changeColour } from "./userManagementService";
import { BACKGROUND_COLOURS } from "src/ServerEntities/IAlert";

const ColourContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 12px;
`;

interface IColourIconProps {
    colour: string
};

const ColourIcon = styled.i<IColourIconProps>`
    width: 36px;
    height: 36px;
    background-color: ${props => props.colour};
    margin: 8px;
    border-radius: 18px;
    cursor: pointer;
`;

interface IProps {
    currentColour: string,
    onClose: () => void,
    onChangeColour: (newColour: BACKGROUND_COLOURS) => void,
    show: boolean,
    username: string
};

const COLOURS: BACKGROUND_COLOURS[] = ['#543b64', '#6b818d', '#64c2cb', '#aad4db', '#7b6e62', '#a9c436', '#e2968b', '#d9c70c'];

const ChangeColourDialog = (props: IProps) => {
    const { webToken } = React.useContext(SessionContext).state;
    const { onClose, onChangeColour, show, username } = props;
    const [errorMessage, setErrorMessage] = React.useState("");
    const [loading, setLoading] = React.useState(false);

    const changeUserColour = (colour: BACKGROUND_COLOURS) => () => {
        setLoading(true);
        changeColour(username, colour, webToken, () => {
            setErrorMessage("");
            setLoading(false);
            onChangeColour(colour);
            onClose();
        }, (error: string) => {
            setLoading(false);
            setErrorMessage(error);
        });
    };

    return <Dialog darkBackground={true} open={show} onClose={onClose} style={{ width: "320px" }}>
        <ManagerTitleBar viewName="Select colour" viewDescription="" />
        { errorMessage && <ErrorBox>{errorMessage}</ErrorBox>}
        <LoadingIndicator show={loading} type="Linear" />
        <ColourContainer>
            {COLOURS.map((colour, index) => <ColourIcon key={`colour-icon-${index}`} colour={colour} onClick={changeUserColour(colour)} />)}
        </ColourContainer>
    </Dialog>;
};

export default ChangeColourDialog;
