import Timeago from 'react-timeago';
import IServer from 'src/ServerEntities/IServer';
import styled from 'styled-components';
import dateConverter from 'src/UsefulFunctions/dateConverter';
import IVolume from 'src/ServerEntities/IVolume';
import IVolumeInformation from 'src/ServerEntities/IVolumeInformation';
import AlertIcon from './AlertIcon';
import QveraInformation from './QveraInformation';
import React from 'react';

import { TopBarContainer, getDetailIcon, getSummaryIcon, TitleContainer } from './TopBarContainer';

const ServerInformationContainer = styled.div`
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    background: #1c3e5a;
    min-width: 250px;
    & h3 {
        background-color: #246896;
        margin: 0;
        flex: 0 0 auto;
        padding: 12px 12px 6px;
        @media (min-width: 1279px) {
            border-radius: 16px 16px 0 0;
        }
        font-weight: 500;
        font-size: 1.2rem;
                display: flex;
        flex-direction: row;
        align-items: flex-end;
    }
    & dl {
        flex: 0 0 auto;
        font-size: 0.8rem;
    }
    & dt {
        flex: 0 0 30%;
    }
    & dd {
        flex-basis: calc(70% - 5px);
    }
    @media (min-width: 1279px) {
        flex: 1 1 auto;
        border-radius: 16px;
    }
    @media (max-width: 1279px) {
        width: 100%;
        margin-bottom: 4px;
    }
     &.warning {
        background: #f99600;
        color: #000000;
        & h3 {
            background: #feb648;
        }
        & li {
        border-top: 1px solid #000000;
    }
`;

const InfoItem = styled.div`
    padding: 12px;
`;

const VolumeItem = styled.li`
    border-top: 1px solid #ffffff;
    margin-top: 8px;
    margin-left: 24px;
`;

const SummaryInfo = styled.div`
    padding: 12px;
    display: flex;
    justify-content: center;
`;


interface IProps {
    server: IServer,
    startExpanded?: boolean
};

const getTotalVolumeSize = (volumes: IVolume[]) => {
    return volumes.reduce((previousValue, volume) => previousValue + volume.size, 0);
};

const convertHumanReadable = (value: number) => {
    let total = value;
    const unit = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB'];
    let i = 0;
    while (total > 1000) {
        total = total / 1000;
        i++;
    }
    return Math.round(total * 1000) / 1000 + " " + unit[i];
}

const getJournalTitle = (volumeInformation: IVolumeInformation) => {
    const hasOne = volumeInformation.volumes.length === 1;
    return `${volumeInformation.volumes.length} journal${hasOne ? "" : "s"} - Total size: ${convertHumanReadable(getTotalVolumeSize(volumeInformation.volumes))}`;
};

const isOverThreshold = (volumeInformation: IVolumeInformation) => {
    if (!volumeInformation || !volumeInformation.thresholds) {
        return false;
    }
    if (volumeInformation.volumes.length > volumeInformation.thresholds.maxCount) {
        return true;
    }
    // Volume sizes is in B, maxSize threshold is in MB so / 1000
    if ((getTotalVolumeSize(volumeInformation.volumes) / 1_000_000) > volumeInformation.thresholds.maxSize) {
        return true;
    }
    return false;
};

const ServerInformation = (props: IProps) => {
    const { server } = props;
    const overThreshold = isOverThreshold(server.volumes);
    const [expanded, setExpanded] = React.useState(props.startExpanded);

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    const isInRange = (date: number) => {
        var startDate = new Date();
        startDate.setHours(startDate.getHours() - 24)
        return startDate.getTime() < date;
    }

    const rangeVolumes = server.volumes.volumes.filter(volume => isInRange(volume.date));

    return <ServerInformationContainer className={overThreshold ? "warning" : ""}>
        <TopBarContainer style={{ padding: "12px 12px 6px" }} onClick={toggleExpanded} >
            <TitleContainer><AlertIcon type={"journal size"} />{server.type || "IE"} information</TitleContainer>
            {expanded && <img src={getDetailIcon} alt="expanded info" />}
            {!expanded && <img src={getSummaryIcon} alt="summary info" />}
        </TopBarContainer>
        {expanded === false && <ServerInformationContainer className={overThreshold ? "warning" : ""}>
            <SummaryInfo>
                {rangeVolumes.length ? <h4>{rangeVolumes.length} journals in the last 24h.</h4> : <h4>{server.version}</h4>}
            </SummaryInfo>
        </ServerInformationContainer>}
        {expanded === true && <ServerInformationContainer className={overThreshold ? "warning" : ""}>
            {server.version &&
                <InfoItem>
                    <dl>
                        <dt>Version</dt><dd>{server.version}</dd>
                    </dl>
                </InfoItem>
            }
            {server.uptime && server.uptime.lastTieBoot && <InfoItem>
                <dl>
                    <dt>Last TIE boot:</dt><dd><Timeago date={server.uptime.lastTieBoot} /></dd>
                </dl>
            </InfoItem>}
            {server.volumes && server.volumes.volumes.length > 0 && <InfoItem>
                <h4>{getJournalTitle(server.volumes)}</h4>
                <ul>
                    {server.volumes.volumes.map((volume, index) => {
                        return <VolumeItem key={`volume-item=${index}`}>
                            <dl>
                                <dt>Name</dt><dd>{volume.name}</dd>
                                <dt>Size</dt><dd>{convertHumanReadable(volume.size)}</dd>
                                <dt>Date</dt><dd>{dateConverter(volume.date, true)}</dd>
                            </dl>
                        </VolumeItem>;
                    })}
                </ul>
            </InfoItem>
            }
            {server.kubeInformation && server.kubeInformation.length > 0 && <InfoItem>
                    <h4>Kubernetes details</h4>
                    <dl>
                        <dt>Version</dt><dd>{server.kubeInformation[0].version}</dd>
                    </dl>
                </InfoItem>
            }
            {server.qveraInformation && server.qveraInformation.length > 0 &&
                <QveraInformation qveraInformation={server.qveraInformation} />
            }
        </ServerInformationContainer>
        }
    </ServerInformationContainer>;
};

export default ServerInformation;