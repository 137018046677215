import * as React from 'react';
import styled from 'styled-components';

import TextInput from 'src/SharedComponents/TextInput';
import ICommonProblem from 'src/ServerEntities/ICommonProblem';
import { deleteProblem, updateProblem } from 'src/Views/Manager/UserManagement/commonProblemService';
import { SessionContext } from 'src/Views/SessionContext';

interface IProps {
    problem: ICommonProblem,
    refresh: number,
    setError: (errorMessage: string) => void,
    setLoading: (isLoading: boolean) => void,
    setRefresh: (count: number) => void
};

const TableItem = styled.td`
    padding: 10px;
    border: 1px solid #e5e5e5;
    word-wrap: break-word;
`;

const TextField = styled.p`
    margin: 0;
`;

const Button = styled.a`
    background: #187fba;
    border-radius: 18px;
    height: 24px;
    padding: 4px 16px;
    cursor: pointer;
    color: #ffffff;

    @media (max-width: 600px) {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    @media (max-width: 500px) {
        padding: 12px 16px;
    }

`;

const DeleteButton = styled.a`
    background: #cc0000;
    border-radius: 18px;
    height: 24px;
    padding: 4px 16px;
    cursor: pointer;
    color: #ffffff;

    @media (max-width: 600px) {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    @media (max-width: 500px) {
        padding: 12px 16px;
    }
`;

const ProblemRow = (props: IProps) => {
    const { problem, refresh, setError, setLoading, setRefresh } = props;
    const [editingProblem, setEditingProblem] = React.useState(false);
    const [editingTranslation, setEditingTranslation] = React.useState(false);
    const [newProblem, setNewProblem] = React.useState(problem.problem);
    const [newTranslation, setNewTranslation] = React.useState(problem.translation);
    const { webToken } = React.useContext(SessionContext).state;

    const editProblem = () => {
        setEditingProblem(true);
    };

    const editTranslation = () => {
        setEditingTranslation(true);
    };

    const onSaveSuccess = () => {
        setEditingProblem(false);
        setEditingTranslation(false);
        setError("");
        setLoading(false);
        setRefresh(refresh + 1);
    };

    const onSaveError = (errorMessage: string) => {
        setEditingProblem(false);
        setEditingTranslation(false);
        setError(errorMessage);
        setLoading(false);
    };

    const saveProblemChanges = () => {
        if (newProblem !== problem.problem) {
            setLoading(true);
            updateProblem({
                id: problem.id,
                problem: newProblem,
                translation: problem.translation
            }, webToken, onSaveSuccess, onSaveError);
        } else {
            setEditingProblem(false);
        }
    };

    const deleteItem = () => {
        deleteProblem(problem, webToken, onSaveSuccess, onSaveError);
    };

    const handleKeyDown = (saveMethod: () => void) => (e: React.KeyboardEvent<HTMLInputElement>) => {
        const key = e.key;
        if (key === "Enter") {
            saveMethod();
        }
    };

    const saveTranslationChanges = () => {
        if (newTranslation !== problem.translation) {
            setLoading(true);
            updateProblem({
                id: problem.id,
                problem: problem.problem,
                translation: newTranslation
            }, webToken, onSaveSuccess, onSaveError);
        } else {
            setEditingTranslation(false);
        }
    };

    const updateNewProblem = (event: React.SyntheticEvent<HTMLInputElement, Event>) => {
        setNewProblem(event.currentTarget.value);
    };

    const updateNewTranslation = (event: React.SyntheticEvent<HTMLInputElement, Event>) => {
        setNewTranslation(event.currentTarget.value);
    };

    return <tr>
        <TableItem>
            {editingProblem ?
                <TextInput autoFocus={true} value={newProblem} onBlur={saveProblemChanges} onChange={updateNewProblem} onKeyDown={handleKeyDown(saveProblemChanges)} /> :
                <TextField onClick={editProblem}>{problem.problem}</TextField>}
        </TableItem>
        <TableItem>
            {editingTranslation ?
                <TextInput autoFocus={true} value={newTranslation} onBlur={saveTranslationChanges} onChange={updateNewTranslation} onKeyDown={handleKeyDown(saveTranslationChanges)} /> :
                problem.translation ?
                    <TextField onClick={editTranslation}>{problem.translation}</TextField> :
                    <Button onClick={editTranslation}>Add translation</Button>
            }
        </TableItem>
        <TableItem>
            <DeleteButton onClick={deleteItem}>Delete</DeleteButton>
        </TableItem>
    </tr>;
};

export default ProblemRow;
