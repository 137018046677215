import Button from 'src/SharedComponents/Button';
import ManagerTitle from 'src/Views/Manager/Components/Title';
import TitleBarContainer from 'src/Views/Manager/Components/TitleBarContainer';
import styled from 'styled-components';
import IServer from 'src/ServerEntities/IServer';
import React from 'react';
import { unmuteServer } from './SiteManagementService';
import ErrorBox from 'src/SharedComponents/ErrorBox';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import Description from '../Components/Description';
import ViewContainer from 'src/SharedComponents/ViewContainer';

interface IProps {
    selectedServer: IServer
    webToken: string,
    refreshServers: () => void,
    setErrorMessage: (errorMessage: string) => void,
};

const ServerDetailsContainer = styled.div`
      display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
`;

const ServerDetailsBar = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 30px 24px;
    & p {
        margin: 0 24px 0 0;
        font-size: 14px;
        color: #4c4c4c;
        font-weight: bold;
    }
    & p label {
        font-weight: normal;
    }
`;

const MutedServerDetails = (props: IProps) => {
    const { selectedServer, webToken } = props;
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");

    const unmute = () => {
        setLoading(true);
        unmuteServer(selectedServer.id, webToken, () => {
            setLoading(false);
            setError("");
            props.refreshServers();
        }, handleError);
    }

    const handleError = (error: string) => {
        setError(error);
        setLoading(false);
    }

    return <ViewContainer>
        <TitleBarContainer>
            <div>
                <ManagerTitle>{selectedServer.name}</ManagerTitle>
                <Description>{selectedServer.namespace}</Description>
                <Description>{selectedServer.production}</Description>
            </div>
        </TitleBarContainer>
        <ServerDetailsContainer>
            <LoadingIndicator type="Linear" show={loading} />
            {error && <ErrorBox>{error}</ErrorBox>}
            <ServerDetailsBar>
                <Button onClick={unmute}>Unmute</Button>
            </ServerDetailsBar>
        </ServerDetailsContainer>

    </ViewContainer >;
};

export default MutedServerDetails;
