import styled from 'styled-components';

export default styled.a`
    font-size: 18px;
    padding: 8px;
    width: 150px;
    border-left: 10px solid #fff;
    &.selected  {
        background-color: #A6CBE0;
        border-left: 10px solid #1577AE;
    }
`;
