import * as React from 'react';
import styled from 'styled-components';

import { SessionContext } from 'src/Views/SessionContext';

import Dialog from 'src/SharedComponents/Dialog/Dialog';
import ErrorBox from 'src/SharedComponents/ErrorBox';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import TextInput from 'src/SharedComponents/TextInput';
import { saveProblem } from "./commonProblemService";
import ManagerTitleBar from '../ManagerTitleBar';

const Button = styled.a`
    background: #187fba;
    border-radius: 18px;
    height: 24px;
    padding: 4px 16px;
    cursor: pointer;
    color: #ffffff;
`;

const CancelButton = styled.a`
    background: #fefefe;
    border-radius: 18px;
    border: 2px solid #333333;
    height: 24px;
    padding: 4px 16px;
    cursor: pointer;
    color: #333333;
`;

const InputContainer = styled.div`
    display: flex;
    padding: 12px;
    flex-direction: column;
`;

const DialogActions = styled.div`
    flex: 0 0 auto;
    padding: 8px 4px;
    display: flex;
    align-items: center;
    justify-content: space-around;
`;

interface IProps {
    onClose: () => void
    refresh: number,
    setRefresh: (refresh: number) => void
};

const CreateCommonProblemDialog = (props: IProps) => {
    const { onClose, refresh, setRefresh } = props;
    const { webToken } = React.useContext(SessionContext).state;
    const [problem, setProblem] = React.useState("");
    const [translation, setTranslation] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");

    const closeDialog = () => {
        onClose();
    };

    const updateProblem = (event: React.SyntheticEvent<HTMLInputElement, Event>) => {
        setProblem(event.currentTarget.value);
    };

    const updateTranslation = (event: React.SyntheticEvent<HTMLInputElement, Event>) => {
        setTranslation(event.currentTarget.value);
    };

    const save = () => {
        setLoading(true);
        saveProblem({ problem, translation }, webToken, () => {
            setLoading(false);
            setError("");
            setRefresh(refresh + 1);
            onClose();
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    };

    return <Dialog open={true} onClose={closeDialog} style={{ width: "650px", height: "400px" }} darkBackground={true} >
        <ManagerTitleBar viewName="Save new common problem" viewDescription="" />
        <LoadingIndicator type="Linear" show={loading} />
        {error && <ErrorBox>{error}</ErrorBox>}
        <InputContainer>
            <TextInput label="Problem" onChange={updateProblem} value={problem} required={true} margin="24px 0 0" />
            <TextInput label="Translation" onChange={updateTranslation} value={translation} margin="48px 0 0" />
        </InputContainer>
        <DialogActions>
            <Button onClick={save}>Save</Button>
            <CancelButton onClick={closeDialog}>Cancel</CancelButton>
        </DialogActions>
    </Dialog>;
};

export default CreateCommonProblemDialog;
