import React from "react";
import { ITimeException } from "src/ServerEntities/IAlertExceptions";
import Button from "src/SharedComponents/Button";
import TextInput from "src/SharedComponents/TextInput";
import styled from "styled-components";
import { debounce } from "throttle-debounce";

interface IProps {
    exception: ITimeException,
    onDelete: (id: number) => void,
    onSave: (exception: ITimeException) => void
};

const SavedCell = styled.td`
    color: #16B012;
    &:before {
        content: '\u2713';
        margin-right: 4px;
    }
`;

const AlertExceptionRow = (props: IProps) => {
    const { exception, onDelete, onSave } = props;
    const [startTime, setStartTime] = React.useState(exception.startTime || "");
    const [endTime, setEndTime] = React.useState(exception.endTime || "");
    const [saved, setSaved] = React.useState(false);

    const saveChanges = () => {
        setSaved(false);
        if (exception.id) {
            onSave(exception);
        }
    };
    const deferredSave = React.useRef(debounce(500, saveChanges)).current;

    const updateStartTime = (e: React.SyntheticEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;
        if (exception) {
            exception.startTime = value;
        }
        setStartTime(value);
        deferredSave();
    };

    const updateEndTime = (e: React.SyntheticEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;
        if (exception) {
            exception.endTime = value;
        }
        setEndTime(value);
        deferredSave();
    };

    const deleteRow = () => {
        if (exception.id) {
            onDelete(exception.id);
        }
    };

    return <tr>
        <td>Do not send notifications between</td>
        <td><TextInput type="time" value={startTime} onChange={updateStartTime}/></td>
        <td>and</td>
        <td><TextInput type="time" value={endTime} onChange={updateEndTime} /></td>
        <td><Button color="#cc0000" onClick={deleteRow}>Delete</Button></td>
        {saved && <SavedCell>Saved</SavedCell>}
    </tr>;
};

export default AlertExceptionRow;
