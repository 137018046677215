import React from 'react';
import useEffectOnSome from 'src/CustomHooks/useEffectOnSome';
import IRoleSite from 'src/ServerEntities/IRoleSite';
import ISupportIssueRequest from 'src/ServerEntities/ISupportIssueRequest';
import Dialog from 'src/SharedComponents/Dialog/Dialog';
import ErrorBox from 'src/SharedComponents/ErrorBox';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import Select from 'src/SharedComponents/Select/Select';
import SelectItem from 'src/SharedComponents/Select/SelectItem';
import TextInput from 'src/SharedComponents/TextInput';
import createErrorMessage from 'src/UsefulFunctions/createErrorMessage';
import { SessionContext } from 'src/Views/SessionContext';
import styled from 'styled-components';
import { getSites } from '../ramService';

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 24px 12px 0;
`;

const Title = styled.h2`
    background: #246896;
    margin: 0;
    border-radius: 16px 16px 0 0;
    padding: 6px 12px;
    font-weight: 600;
    font-size: 1.3rem;
    color: #ffffff;
`;

const ContentContainer = styled.div`
    padding: 18px 12px;
    background: #1c3e5a;
    border-radius: 0 0 16px 16px;
    @media (min-width: 1279px) {
        width: 400px;
    }
    display: flex;
    flex-direction: column;
`;

const Button = styled.button`
    background: #246896;
    border-radius: 16px;
    color: #ffffff;
    margin: 0 16px;
    padding: 6px 24px;
    cursor: pointer;
    font-weight: 500;
    font-size: 1.1rem;
`;

const TextArea = styled.textarea`
    flex: 1 1 auto;
    padding: 8px;
    margin: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    max-width: calc(100% - 18px);
    min-width: calc(100% - 18px);
    max-height: 600px;
`;

const Label = styled.label`
    color: #ffffff;
`;

interface IProps {
    error: string,
    issue?: string,
    loading: boolean,
    onClose: () => void,
    onSave: (issue: ISupportIssueRequest) => void
};

const CreateSupportIssueDialog = (props: IProps) => {
    const { error, loading, onClose, onSave } = props;
    const { webToken } = React.useContext(SessionContext).state;
    const [sitesLoading, setSitesLoading] = React.useState(false);
    const [siteError, setSiteError] = React.useState("");
    const [selectedSite, setSelectedSite] = React.useState("");
    const [issue, setIssue] = React.useState(props.issue || "");
    const [notes, setNotes] = React.useState("");
    const [siteNames, setSiteNames] = React.useState(null as unknown as string[]);

    useEffectOnSome(() => {
        setSitesLoading(true);
        getSites(webToken, (sites: IRoleSite[]) => {
            const siteNames = sites.map(s => s.id);
            siteNames.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
            setSiteNames(siteNames);
            if (selectedSite === "" && sites.length) {
                setSelectedSite(sites[0].id);
            }
            setSitesLoading(false);
            setSiteError("");
        }, (errorMessage: string) => {
            setSitesLoading(false);
            setSiteError(errorMessage);
        });
    }, [], [webToken, selectedSite]);

    const changeSelectedSite = (newSite: string) => {
        setSelectedSite(newSite);
    };

    const changeIssue = (e: React.SyntheticEvent<HTMLInputElement>) => {
        setIssue(e.currentTarget.value);
    };

    const changeNotes = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setNotes(e.target.value);
    };

    const clickSave = () => {
        onSave({
            issue,
            notes,
            site: selectedSite
        });
    };

    const closeDialog = () => {
        setSelectedSite("");
        setIssue("");
        setNotes("");
        onClose();
    };

    return <Dialog open={true} onClose={closeDialog} style={{ background: "none" }} preventClickOutsideClose={true}>
        <Title>Raise support ticket</Title>
        <ContentContainer>
            {error && <ErrorBox>{createErrorMessage("saving the support issue")}</ErrorBox>}
            {siteError && <ErrorBox>{createErrorMessage("getting the list of sites")}</ErrorBox>}
            {(loading || sitesLoading) && <LoadingIndicator type="Linear" show={true} />}
            <Label>Site</Label>
            {siteNames && siteNames.length > 0 && <Select
                childValues={siteNames}
                placeholder="Select site"
                margin="4px 0 20px"
                value={selectedSite}
                onChange={changeSelectedSite}
                width="200px"
            >
                {siteNames.map((name: string) => <SelectItem key={`select-item-${name}`} itemKey={`select-item-${name}`} value={name}>
                    {name}
                </SelectItem>)}
            </Select>}
            <Label>Issue</Label>
            <TextInput value={issue} onChange={changeIssue} margin="0 0 20px" />
            <Label>Additional information</Label>
            <TextArea rows={4} value={notes} onChange={changeNotes} />
            <ButtonContainer>
                <Button onClick={clickSave}>Save</Button>
                <Button onClick={closeDialog}>Cancel</Button>
            </ButtonContainer>
        </ContentContainer>
    </Dialog>;
};

export default CreateSupportIssueDialog;