import styled from 'styled-components';

export default styled.div`
    background-color: #1c3e5a;
    &.closed {
        background-color: #6d2f35;
    }
    flex: 1 0 auto;
    display: flex;
    justify-content: space-between;
    border-radius: 16px;
    padding: 2px 24px;
    & h2 {
        margin: 0;
        font-weight: 500;
        font-size: 1.2rem;
    }
`;