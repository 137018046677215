import styled from 'styled-components';
import { BACKGROUND_COLOURS } from 'src/ServerEntities/IAlert';

interface IUsernameTextStyleProps {
    backgroundColor: BACKGROUND_COLOURS
};

const getColourForBackgroundColour = (backgroundColor: BACKGROUND_COLOURS) => {
    if (backgroundColor === '#543b64' || backgroundColor === '#7b6e62' || backgroundColor === '#6d2f35') {
        return '#ffffff';
    }
    return '#000000';
};

const UsernameText = styled.p<IUsernameTextStyleProps>`
    margin: 0 0 0 12px;
    color: ${props => getColourForBackgroundColour(props.backgroundColor)};
    background-color: ${props => props.backgroundColor};
    padding: 4px 24px;
    border-radius: 16px;
    display: inline-block; 
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 

    @media (max-width: 1280px) {
        margin: 3px 0;
        padding: 4px 16px;
    }
`;


export default UsernameText;