import React from 'react';
import INote from 'src/ServerEntities/INote';
import dateConverter from 'src/UsefulFunctions/dateConverter';
import { saveServerNote } from 'src/Views/Ram/ramService';
import styled from 'styled-components';
import AddNoteDialog from './AddNoteDialog';
import HeaderLink from './HeaderLink';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    margin-top: 24px;
`;

const NotesPanel = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    & h3 {
        margin: 0 0 24px;
        font-weight: 500;
        font-size: 1.2rem;
    }
    & a {
        width: 80px;
        text-align: center;
    }
`;

const NoteList = styled.ul`
    flex: 1 1 auto;
    background: #246896;
    padding: 16px;
    border-radius: 16px;
`;

const NoteListItem = styled.li`
    display: flex;
    flex-direction: row;
    margin: 12px 0;
    & p {
        flex: 0 1 auto;
        margin: 0 16px 0 0;
    }
`;

interface IProps {
    namespace: string,
    notes: INote[],
    notesOpen: boolean,
    serverName: string,
    setNotesOpen: (open: boolean) => void,
    siteName: string,
    webToken: string
};

const createNote = (serverName: string) => (note: INote, index: number) => {
    return <NoteListItem key={`server-${serverName}-note-${index}`}>
        <p>{formatUsername(note.username)}</p>
        <p>{dateConverter(note.date)}</p>
        <p>{note.text}</p>
    </NoteListItem>;
};

const formatUsername = (username: string) => {
    return username.substring(0, username.indexOf('@'));
};

const NotesContainer = (props: IProps) => {
    const { namespace, notes, notesOpen, serverName, setNotesOpen, siteName, webToken } = props;
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");

    const displayCreateNoteDialog = () => {
        setNotesOpen(true);
    };

    const closeCreateNoteDialog = () => {
        setLoading(false);
        setNotesOpen(false);
    };

    const onSaveNote = (noteText: string) => {
        if (noteText) {
            setLoading(true);
            saveServerNote(siteName, serverName, namespace, noteText, webToken, closeCreateNoteDialog, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
            });
        }
    };

    return <Container>
        {notesOpen && <AddNoteDialog onSaveNote={onSaveNote} onClose={closeCreateNoteDialog} error={error} loading={loading} />}
        <NotesPanel>
            <h3>Notes</h3>
            <HeaderLink onClick={displayCreateNoteDialog}>Add note</HeaderLink>
        </NotesPanel>
        <NotesPanel>
            {notes.length > 0 &&
                <NoteList>
                    {notes.map(createNote(serverName))}
                </NoteList>
            }
        </NotesPanel>
    </Container>;
};

export default NotesContainer;
