import Timeago from 'react-timeago';
import IAlert from 'src/ServerEntities/IAlert';
import AlertCount from 'src/Views/Ram/Components/AlertCount';
import AlertIcon from 'src/Views/Ram/Components/AlertIcon';
import UsernameText from 'src/Views/Ram/Components/UsernameText';
import styled from 'styled-components';

const AlertText = styled.p`
    flex: 1 1 auto;
    overflow-wrap: break-word;
    @media (max-width: 1279px) {
        width: 100%;
    }
    @media (min-width: 1279px) {
        margin: 0 12px;
    }
`;

const AlertItem = styled.li`
    background-color: #1c3e5a;
    display: flex;
    padding: 0 6px;
    justify-content: space-between;
    margin-bottom: 4px;
    &.unassigned {
        background-color: #818181;
    }
    &.closed {
        background-color: #6d2f35;
    }
    @media (min-width: 1279px) {
        flex-direction: row;
        border-radius: 16px;
        padding: 0 12px;
        align-items: center;
    }
    @media (max-width: 1279px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

const shortFormatter = (value: number, unit: string) => {
    return `${value} ${unit.substring(0, 1)}`;
};

interface IProps {
    alert: IAlert,
    closed: boolean,
    minimised: boolean
};

const getUsername = (alert: IAlert, minimised: boolean, closed: boolean) => {
    if (minimised) {
        return alert.username ? alert.username.substring(0, 3) : "UN";
    }
    if (closed) {
        return `Closed - ${alert.username}`;
    }
    return alert.username || "Unassigned";
}

const Alert = (props: IProps) => {
    const { alert, closed, minimised } = props;
    return <AlertItem className={closed ? "closed" : (alert.username ? "assigned" : "unassigned")}>
        {minimised ? <Timeago date={alert.timestamp} formatter={shortFormatter} /> : <Timeago date={alert.timestamp} />}
        <AlertIcon type={alert.type} closed={closed} />
        <AlertCount className={closed ? "closed" : "current"}>{alert.count}</AlertCount>
        {!minimised && <AlertText>{alert.alert}</AlertText>}
        <UsernameText backgroundColor={closed ? "#6d2f35" : (alert.userColour || '#818181')}>
            {getUsername(alert, minimised, closed)}
        </UsernameText>
    </AlertItem>;
};

export default Alert;
