export const VIPER_TITLE = "RAM powered by IMX";
export const DEFAULT_PORT = 443;
export const DEV_BFF_PORT = 6010;

const envSettings = window as any;
const hostname = window.location.hostname;

const isDev = window.location.port === "3000";
const isDemo = window.location.port === "9999";

export const getAPIUrl = () => {
    if (isDev) {
        return `https://${hostname}:${DEV_BFF_PORT}/`;
    }
    if (isDemo) {
        return `https://${hostname}:9999/bff/`;
    }
    return envSettings.API_URL ? envSettings.API_URL : `https://${hostname}:${DEFAULT_PORT}/api/`;
};

export const VERIFY_ACTIVE_INTERVAL = 120000;
export const HEARTBEAT_WARNING_LIMIT = 600000; // Number of ms without a heartbeat which is cause for concern 600000 = 10 minutes
export const ALERT_LIMIT = 6000000; // Number of ms after it was received to continue to show a closed alert
export const ALERTS_PAGE_LIMIT = 1000; // Used for current, hybrid & closed alerts
export const MANAGEMENT_VIEW = "Restart Management";
export const SUPPORT_VIEW = "Restart Support";
