import * as React from 'react';
import styled from 'styled-components';

import Button from 'src/SharedComponents/Button';
import Dialog from 'src/SharedComponents/Dialog/Dialog';
import DialogActions from 'src/SharedComponents/Dialog/DialogActions';
import DialogContent from 'src/SharedComponents/Dialog/DialogContent';
import DialogTitle from 'src/SharedComponents/Dialog/DialogTitle';
import Label from 'src/SharedComponents/Label';
import ListItem from 'src/SharedComponents/List/ListItem';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import ViewContainer from 'src/SharedComponents/ViewContainer';
import ManagerTitleBar from 'src/Views/Manager/ManagerTitleBar';


import { SessionContext } from 'src/Views/SessionContext';

import { getMutedServers } from './SiteManagementService';
import IServer from 'src/ServerEntities/IServer';
import EmptyStateSplashContainer from 'src/SharedComponents/EmptyStateSplash';
import MutedServerDetails from './MutedServerDetails';

interface IProps {
    tablabel: string
};

const MutedServersListContainer = styled.div`
    width:320px;
    border-right: 1px solid #e5e5e5;
    background-color:white;
    max-width:320px;
    display:flex;
    flex: 1 1 auto;
    flex-direction:column;

    @media (min-width: 350px) and (max-width: 768px) {
        height: 45%;
        grid-row: 1;
        width: 100% !important;
        max-width: none !important;
        z-index: 1;
    }
`;

const MutedServersTabContainer = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    overflow: auto;
    backgroundColor: "#fafafa";

    @media (min-width: 350px) and (max-width: 768px) {
        display: grid;
        grid-template-rows: 20% auto;
    }
`

const MutedServersTab = (props: IProps) => {
    const [selectedServerId, setSelectedServerId] = React.useState("");
    const [selectedServer, setSelectedServer] = React.useState(undefined as unknown as IServer);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [refresh, setRefresh] = React.useState(0);
    const { webToken } = React.useContext(SessionContext).state;
    const [mutedServers, setMutedServers] = React.useState(undefined as unknown as IServer[])

    React.useEffect(() => {
        setLoading(true);
        getMutedServers(webToken, (servers: IServer[]) => {
            setLoading(false);
            setMutedServers(servers);
        }, setErrorMessage);
    }, [webToken, refresh]);

    const refreshServers = () => {
        setRefresh(refresh + 1);
        setSelectedServer(undefined as unknown as IServer)
    }


    const changeSelectedServer = (server: IServer) => () => {
        setSelectedServer(server);
        setSelectedServerId(server.id);
    }

    const closeErrorDialog = () => {
        setErrorMessage("");
    };

    const EmptyState = () => <EmptyStateSplashContainer>
        <h1>No server selected</h1>
        <h2>Select a server on the left.</h2>
    </EmptyStateSplashContainer>;

    return <ViewContainer style={{ backgroundColor: "#fafafa" }}>

        <ErrorDialog message={errorMessage} onClose={closeErrorDialog} />
        <ManagerTitleBar viewName="Muted server" viewDescription="Here you can list muted servers and unmute them." />
        <LoadingIndicator type="Linear" show={loading} />
        <MutedServersTabContainer style={{ flexDirection: "row" }}>

            <MutedServersListContainer>
                {mutedServers && mutedServers
                    .map((server: IServer, index) => <ListItem selected={selectedServerId !== undefined && selectedServerId === server.id} key={server.id + index} onClick={changeSelectedServer(server)}>
                        <Label>
                            <h2>{server.name}</h2>
                            <h3>{server.namespace}</h3>
                            <h3>{server.production}</h3>
                        </Label>
                    </ListItem>)}
            </MutedServersListContainer>
            {!selectedServer && <EmptyState />}
            {selectedServer && <MutedServerDetails
                key={selectedServerId}
                selectedServer={selectedServer}
                webToken={webToken}
                refreshServers={refreshServers}
                setErrorMessage={setErrorMessage} />}
        </MutedServersTabContainer>
    </ViewContainer>;
};



const ErrorDialog = ({ message, onClose }: { message: string, onClose: () => void }) => <Dialog open={message !== ""} onClose={onClose}>
    <DialogTitle>Error</DialogTitle>
    <DialogContent style={{ lineHeight: "25px" }}>{message}</DialogContent>
    <DialogActions><Button onClick={onClose}>OK</Button></DialogActions>
</Dialog>;

export default MutedServersTab;
