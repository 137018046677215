import axios, { AxiosError, AxiosResponse } from "axios";

import { getAPIUrl } from 'src/config';
import IAlertExceptions, { ITimeException } from "src/ServerEntities/IAlertExceptions";
import IAlertRule from "src/ServerEntities/IAlertRule";
import IErrorDetails from "src/ServerEntities/IErrorDetails";
import IHeartbeatExceptions from "src/ServerEntities/IHeartbeatExceptions";
import INotificationTimeout from "src/ServerEntities/INotificationTimeout";
import IUser from 'src/ServerEntities/IUser';
import IVolumeNotification from "src/ServerEntities/IVolumeNotification";
import { NETWORK_ERROR } from 'src/UsefulFunctions/createErrorMessage';

const handleError = (error: AxiosError<IErrorDetails>, failureCallback: (errorMessage: string) => void) => {
    let errorMessage = "Unknown error";
    if (error.response) {
        errorMessage = error.response.data.message
        if (error.response.status === 401 && error.response.data.message === undefined) {
            errorMessage = "Unauthorised to perform this request."
        }
    }
    if (error.message === "Network Error") {
        errorMessage = NETWORK_ERROR;
    }
    failureCallback(errorMessage as string);
};

export const getUsers = (webToken: string, successCallback: (users: IUser[]) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(
        `${getAPIUrl()}roles/users`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<IUser[]>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const saveNotificationTimeout = (notificationTimeout: INotificationTimeout, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.post(
        `${getAPIUrl()}rules/heartbeat`,
        notificationTimeout,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse) => {
            successCallback();
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const getNotificationTimeouts = (webToken: string, successCallback: (timeouts: INotificationTimeout[]) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(
        `${getAPIUrl()}rules/heartbeat`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<INotificationTimeout[]>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const getAlertRules = (webToken: string, successCallback: (alertRules: IAlertRule[]) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(
        `${getAPIUrl()}rules/alert`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<IAlertRule[]>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const getAlertRuleExceptions = (ruleId: number, webToken: string,
    successCallback: (alertExceptions: IAlertExceptions) => void, failureCallback: (errorMessage: string) => void) => {

    axios.get(
        `${getAPIUrl()}rules/alerts/${ruleId}/exceptions`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<IAlertExceptions>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const getHeartbeatNotificationExceptions = (site: string, webToken: string,
    successCallback: (alertExceptions: IHeartbeatExceptions) => void, failureCallback: (errorMessage: string) => void) => {

    axios.get(
        `${getAPIUrl()}rules/heartbeats/${site}/exceptions`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<IHeartbeatExceptions>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const updateAlertRuleException = (exceptionId: number, exception: ITimeException, webToken: string,
    successCallback: () => void, failureCallback: (errorMessage: string) => void) => {

    axios.put(
        `${getAPIUrl()}rules/alerts/exceptions/${exceptionId}`,
        exception,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then(successCallback)
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const updateHeartbeatException = (exceptionId: number, exception: ITimeException, webToken: string,
    successCallback: () => void, failureCallback: (errorMessage: string) => void) => {

    axios.put(
        `${getAPIUrl()}rules/heartbeats/exceptions/${exceptionId}`,
        exception,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then(successCallback)
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const updateAlertRuleSilencedOnHolidays = (ruleId: number, silenced: boolean, webToken: string,
    successCallback: () => void, failureCallback: (errorMessage: string) => void) => {

    axios.post(
        `${getAPIUrl()}rules/alerts/${ruleId}/exceptions/holidays`,
        silenced,
        { headers: { Authorization: `Bearer ${webToken}`, 'Content-Type': 'application/json' } }
    )
        .then(successCallback)
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const updateHeartbeatSilencedOnHolidays = (site: string, silenced: boolean, webToken: string,
    successCallback: () => void, failureCallback: (errorMessage: string) => void) => {

    axios.post(
        `${getAPIUrl()}rules/heartbeats/${site}/exceptions/holidays`,
        silenced,
        { headers: { Authorization: `Bearer ${webToken}`, 'Content-Type': 'application/json' } }
    )
        .then(successCallback)
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const deleteAlertRuleException = (exceptionId: number, webToken: string,
    successCallback: () => void, failureCallback: (errorMessage: string) => void) => {

    axios.delete(
        `${getAPIUrl()}rules/alerts/exceptions/${exceptionId}`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then(successCallback)
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const deleteHeartbeatException = (exceptionId: number, webToken: string,
    successCallback: () => void, failureCallback: (errorMessage: string) => void) => {

    axios.delete(
        `${getAPIUrl()}rules/heartbeats/exceptions/${exceptionId}`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then(successCallback)
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const saveNewAlertRuleException = (exception: ITimeException, webToken: string,
    successCallback: (id: number) => void, failureCallback: (errorMessage: string) => void) => {

    axios.post(
        `${getAPIUrl()}rules/alerts/exceptions`,
        exception,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<number>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const saveNewHeartbeatException = (exception: ITimeException, webToken: string,
    successCallback: (id: number) => void, failureCallback: (errorMessage: string) => void) => {

    axios.post(
        `${getAPIUrl()}rules/heartbeats/exceptions`,
        exception,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<number>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const updateAlertRule = (updatedRule: IAlertRule, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.put(
        `${getAPIUrl()}rules/alert`,
        updatedRule,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse) => {
            successCallback();
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const getVolumeNotificationLimits = (webToken: string, successCallback: (volumeNotification: IVolumeNotification[]) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(
        `${getAPIUrl()}rules/volume`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<IVolumeNotification[]>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const saveVolumeNotificationLimits = (volumeNotification: IVolumeNotification, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.post(
        `${getAPIUrl()}rules/volume`,
        volumeNotification,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse) => {
            successCallback();
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const addAlertRule = (newRule: IAlertRule, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.post(
        `${getAPIUrl()}rules/alert`,
        newRule,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse) => {
            successCallback();
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const deleteAlertRule = (id: number, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.delete(
        `${getAPIUrl()}rules/alert/${id}`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse) => {
            successCallback();
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const addUserOnCall = (username: string, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.post(
        `${getAPIUrl()}rules/users/${username}`,
        "",
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse) => {
            successCallback();
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const removeUserOnCall = (username: string, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.delete(
        `${getAPIUrl()}rules/users/${username}`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse) => {
            successCallback();
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};
