import * as React from 'react';
import { useLocation } from 'react-router-dom';
import useEffectOnSome from 'src/CustomHooks/useEffectOnSome';
import styled from 'styled-components';
import { debounce } from 'throttle-debounce';

import Button from 'src/SharedComponents/Button';
import Dialog from 'src/SharedComponents/Dialog/Dialog';
import DialogActions from 'src/SharedComponents/Dialog/DialogActions';
import DialogContent from 'src/SharedComponents/Dialog/DialogContent';
import DialogTitle from 'src/SharedComponents/Dialog/DialogTitle';
import EmptyStateSplashContainer from 'src/SharedComponents/EmptyStateSplash';
import Label from 'src/SharedComponents/Label';
import ListItem from 'src/SharedComponents/List/ListItem';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import ScrollingContainer from 'src/SharedComponents/ScrollingContainer';
import TextInput from 'src/SharedComponents/TextInput';
import ViewContainer from 'src/SharedComponents/ViewContainer';
import ManagerTitleBar from 'src/Views/Manager/ManagerTitleBar';

import transparentUserIcon from 'src/images/customIcons/transparentUser.png';

import { SessionContext } from 'src/Views/SessionContext';

import { searchForUsers } from "./userManagementService";

import IUser from 'src/ServerEntities/IUser';

import UserDetails from './UserDetails';
import CreateUserDialog from './CreateUserDialog';

interface IProps {
    outerRefresh: number,
    setOuterRefresh: (count: number) => void,
    tablabel: string
};

interface IUserListReference {
    display?: boolean
}

const UsersTabContainer = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    overflow: auto;
    backgroundColor: "#fafafa";

    @media (min-width: 350px) and (max-width: 768px) {
        display: grid;
        grid-template-rows: 20% auto;
    }
`;

const UserListContainer = styled.div`
    width: 320px;
    border-right: 1px solid #e5e5e5;
    background-color: white;
    max-width: 320px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    
    @media (min-width: 350px) and (max-width: 768px) {
        height: 45%;
        grid-row: 1;
        width: 100% !important;
        max-width: none !important;
        z-index: 1;
    }

`;

const SearchBoxContainer = styled.div`
    padding: 12px 16px;
    border-bottom: 1px solid #e5e5e5;
    background-color: #fafafa;
    position: relative; /* Add this line */

    @media (min-width: 350px) and (max-width: 768px) {
        display: flex;
        justify-content: space-between;
    }
`;

const EmptyText = styled.p`
    margin: 8px 4px;
`;

const UserListContainerReference = styled.div<IUserListReference>`
    @media (min-width: 350px) and (max-width: 768px) {
        position: relative;
        bottom: 14px;
        min-height: 250px;
        left: 0;
        z-index: 9999;
        margin: 0 16px 0 16px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 1;
        background-color: white;
    }
`

const UserListTextInput = styled(TextInput)`
    @media (min-width: 350px) and (max-width: 768px) {
        width: 100%;
    }
`

const userHasId = (id?: string) => (user: IUser) => id === user.id;

const getSearchSegment = (key: string, search: string) => {
    let value = search.substring(search.indexOf(key + "=") + key.length + 1);
    if (value.includes("&")) {
        value = value.substring(0, value.indexOf("&"));
    }
    return decodeURI(value);
};

const UsersTab = (props: IProps) => {
    const { outerRefresh, setOuterRefresh } = props;
    const [filterText, setFilterText] = React.useState("");
    const [selectedUserId, setSelectedUserId] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState("");
    const [refresh, setRefresh] = React.useState(0);
    const [users, setUsers] = React.useState([] as IUser[]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const location = useLocation();
    const [showAddUserDialog, setShowAddUserDialog] = React.useState(false);
    const [showUserList, setShowUserList] = React.useState(false)

    const { webToken, loggedInUser } = React.useContext(SessionContext).state;

    const userListContainerReference = React.useRef(null as unknown as HTMLDivElement);

    useEffectOnSome(() => {
        const initialUsername = location.search.includes("username=") ? getSearchSegment("username", location.search) : "";
        if (initialUsername && !users.length) {
            searchForUsers(initialUsername.toLowerCase(), webToken, (usersFromServer: IUser[]) => {
                setLoading(false);
                setSelectedUserId(initialUsername);
                setUsers(usersFromServer);
            }, setError);
        }
        setRefresh(1);
    }, [location], [webToken, users]);

    const search = (searchTerm: string, currentWebToken: string) => {
        searchForUsers(searchTerm && searchTerm.toLowerCase(), currentWebToken, (usersFromServer: IUser[]) => {
            setLoading(false);
            setUsers(usersFromServer);
        }, setError);
    };
    const delayedSearch = React.useRef(debounce(250, search)).current;

    const changeFilterText = (e: React.SyntheticEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;

        if (value) {
            setLoading(true);
            delayedSearch(value, webToken);
            setShowUserList(true)
        }
        setFilterText(value);

        if(!value) {
            setShowUserList(false)
        }
    };

    const changeSelectedUser = (user: IUser) => () => {
        setSelectedUserId(user.id);
        setShowUserList(false);
    }

    const refreshUsers = () => {
        setRefresh(refresh + 1);
    }

    const closeErrorDialog = () => {
        setErrorMessage("");
    };

    const selectedUser = users.find(userHasId(selectedUserId));

    const openAddUserDialog = () => {
        setShowAddUserDialog(true);
    }

    const closeAddUserDialog = () => {
        setShowAddUserDialog(false);
    }

    const closeAddUserDialogAndRefresh = () => {
        setShowAddUserDialog(false);
        refreshUsers();
    }

    return <ViewContainer style={{ backgroundColor: "#fafafa" }}>
        <CreateUserDialog
            show={showAddUserDialog}
            onClose={closeAddUserDialog}
            onConfirmUserAdd={closeAddUserDialogAndRefresh}
        />
        <ErrorDialog message={errorMessage} onClose={closeErrorDialog} />
        
        <ManagerTitleBar viewName="Users" viewDescription="Here you can enable/disable users and assign their roles. The role determines the IMX-CR functionality the user has access to.">
            <Button onClick={openAddUserDialog}>Add user</Button>
        </ManagerTitleBar>
        
        <UsersTabContainer style={{ flexDirection: "row" }}>
            <UserListContainer>
                <SearchBoxContainer>
                    <UserListTextInput value={filterText} onChange={changeFilterText} placeholder="Search for a user" />
                </SearchBoxContainer>
                <LoadingIndicator type="Linear" show={loading} />
                {showUserList && <UserListContainerReference ref={userListContainerReference} style={{ flex: "1 1 auto" }}>
                    {!users.length && !filterText && <EmptyText>Enter a username to search.</EmptyText>}
                    {!users.length && filterText && !loading && <EmptyText>No users found matching {filterText}.</EmptyText>}
                    {!!users.length && userListContainerReference.current ? <ScrollingContainer style={{ height: "100%" }} direction={"column"}>
                        {users
                            .map((user: IUser, index) => <ListItem selected={selectedUserId !== undefined && selectedUserId === user.id} key={user.id + index} onClick={changeSelectedUser(user)}>
                                <Label>
                                    <h2>{user.fullName}{user.status === false && <span style={{ color: "#bbbbbb", fontSize: "12px" }}> - Disabled</span>}</h2>
                                    <h3>{user.id}</h3>
                                </Label>
                            </ListItem>)}
                    </ScrollingContainer> : null}

                    {error && error}
                </UserListContainerReference>}
            </UserListContainer>
            {!selectedUser && <EmptyState />}
            {selectedUser && <UserDetails
                outerRefresh={outerRefresh}
                setOuterRefresh={setOuterRefresh}
                key={selectedUserId}
                selectedUser={selectedUser}
                webToken={webToken}
                refreshUsers={refreshUsers}
                setErrorMessage={setErrorMessage}
                loggedInUser={loggedInUser} />}
        </UsersTabContainer>
    </ViewContainer>;
};

const EmptyState = () => <EmptyStateSplashContainer>
    <img alt="No user selected" src={transparentUserIcon} style={{ marginBottom: "8px", opacity: 0.3 }} />
    <h1>No user selected</h1>
    <h2>Select a user on the left to view their IMX-CR functionality.</h2>
</EmptyStateSplashContainer>;

const ErrorDialog = ({ message, onClose }: { message: string, onClose: () => void }) => <Dialog open={message !== ""} onClose={onClose}>
    <DialogTitle>Error</DialogTitle>
    <DialogContent style={{ lineHeight: "25px" }}>{message}</DialogContent>
    <DialogActions><Button onClick={onClose}>OK</Button></DialogActions>
</Dialog>;

export default UsersTab;
