import { useState } from 'react';
import IEnsembleInterface from "src/ServerEntities/IEnsembleInterface";
import styled from 'styled-components';
import InterfaceDetails from './InterfaceDetails';
import IEnsembleCommPoint from 'src/ServerEntities/IEnsembleCommPoint';
import CommPointDetails from './CommPointDetails';
import { useLocation } from 'react-router';

const CardContainer = styled.article`
    display: flex;
    flex-direction: column;
    height: auto;
    
    @media (min-width: 1279px) {
        border-radius: 16px;
        width: 100%;
        height: auto;
    }
    @media (max-width: 1279px) {
        width: 100%;
    }
    & h3 {
        margin: 0;
        font-weight: 500;
        font-size: 1.2rem;
    }
    & dt {
        flex: 0 0 30%;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (max-width: 1279px) {
            flex: 0 0 100%;
        }
    }
    & dd {
        flex: 0 0 calc(70% - 5px);
        overflow: hidden;
        text-overflow: ellipsis;
        overflow-wrap: break-word;
        max-width: 520px;
        @media (max-width: 1279px) {
            flex: 0 0 calc(100% - 10px);
            margin: 0 0 6px 10px;
        }
    }

    .container {
        background-color: #1c3e5a;
        border-radius: 16px;
    }
`;

const CardHeader = styled.header`
    flex: 1 0 auto;
    display: flex;
    max-height:32px;
    min-height:32px;
    flex-direction: row;
    flex-wrap: nowrap;
    background: #246896;
    padding: 6px 12px;
    border-radius: 16px 16px 0 0;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 1279px) {
        align-items: flex-start;
        border-radius: 0;
        padding: 4px;
    }
`;

const StatusHeaderContainer = styled.div`
    display:flex;
    flex-direction: row;
    & div {
        padding: 2px 10px;
        &.active { background-color: #42a83f; }
        &.disabled { background-color: #9e9e9e; }
        &.queue { background-color: #6902de; }
        &.error { background-color: #ff2121; }
        &.inactive { background-color: #f99600; }
    }
    & div:first-child {
        border-radius: 6px 0 0 6px;
    }
    & div:last-child {
        border-radius: 0 6px 6px 0;
    }
`;

const Container = styled.ul`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px;
    width: auto;
`;

const AlertContainer = styled.li`
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 8px;
    height: 25px;
    width: 25px;
    cursor: pointer;
    
    // Interfaces
    &.Active { background-color: #42a83f; }
    &.Disabled { background-color: #9e9e9e; }
    &.Queue { background-color: #6902de; }
    &.Error { background-color: #ff2121; }
    &.Inactive { background-color: #f99600; }

    // Commpoints
    &.RUNNING { background-color: #42a83f; }
    &.DISABLED { background-color: #9e9e9e; }
    &.QUEUE { background-color: #6902de; }
    &.ERROR { background-color: #ff2121; }
    &.STOPPED { background-color: #f99600; }
`;

interface IProps {
    ensembleInterface?: IEnsembleInterface[],
    ensembleCommPoints?: IEnsembleCommPoint[],
    pinnedInterfaces?: IEnsembleInterface[],
    setPinnedInterfaces?: (value: IEnsembleInterface[]) => void;
    setPinnedCommPoints?: (value: IEnsembleCommPoint[]) => void;
    pinnedCommPoints?: IEnsembleCommPoint[];
    setInterfaceCookies?: (value: IEnsembleInterface[]) => void;
    setCommPointCookies?: (value: IEnsembleCommPoint[]) => void;
};

const interfacesToColours = (ensembleInterfaces?: IEnsembleInterface[], ensembleCommPoints?: IEnsembleCommPoint[]) => {

    // Interfaces
    if (ensembleInterfaces) {
        return ensembleInterfaces.reduce((accumulator, item) => {
            switch (item.status) {
                case 'Active':
                    accumulator.green++;
                    break;
                case 'Error':
                    accumulator.red++;
                    break;
                case 'Inactive':
                    accumulator.yellow++;
                    break;
                case 'Disabled':
                    accumulator.grey++;
                    break;
                case 'Queue':
                    accumulator.purple++;
                    break;
            }
            return accumulator;
        }, {
            green: 0,
            grey: 0,
            purple: 0,
            red: 0,
            yellow: 0
        });

        // Commpoints
    } else if (ensembleCommPoints) {
        return ensembleCommPoints.reduce((accumulator, item) => {
            switch (item.state) {
                case 'RUNNING':
                    accumulator.green++;
                    break;
                case 'ERROR':
                    accumulator.red++;
                    break;
                case 'STOPPED':
                    accumulator.yellow++;
                    break;
                case 'DISABLED':
                    accumulator.grey++;
                    break;
                case 'QUEUE':
                    accumulator.purple++;
                    break;
            }
            return accumulator;
        }, {
            green: 0,
            grey: 0,
            purple: 0,
            red: 0,
            yellow: 0
        });
    } else {
        return {
            green: 0,
            grey: 0,
            purple: 0,
            red: 0,
            yellow: 0
        }
    }
};

const EnsembleSummaryView = (props: IProps) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const interfaceId = queryParams.get('interfaceId');
    const commpointName = queryParams.get('commpoint');

    const [selectedInterface, setSelectedInterface] = useState(null as unknown as IEnsembleInterface);
    const [selectedCommPoint, setSelectedCommPoint] = useState(null as unknown as IEnsembleCommPoint);
    const { pinnedInterfaces, setPinnedInterfaces, setInterfaceCookies, pinnedCommPoints, setPinnedCommPoints, setCommPointCookies } = props

    if (interfaceId !== null && selectedInterface === null && props.ensembleInterface !== undefined) {
        const ensembleInterface = props.ensembleInterface.find((item) => item.id === parseInt(interfaceId));
        setSelectedInterface(ensembleInterface ? ensembleInterface : null as unknown as IEnsembleInterface);
    }
    if (commpointName && !selectedCommPoint && props.ensembleCommPoints) {
        const commpoint = props.ensembleCommPoints.find((item) => item.name === commpointName);
        setSelectedCommPoint(commpoint || null as unknown as IEnsembleCommPoint);
    }

    const selectInterface = (interfaceItem?: IEnsembleInterface, commPoint?: IEnsembleCommPoint) => () => {
        // Interfaces
        if (selectedInterface && interfaceItem && selectedInterface.id === interfaceItem.id) {
            setSelectedInterface(null as unknown as IEnsembleInterface);
        } else if (interfaceItem) {
            setSelectedInterface(interfaceItem);
            // Commpoints
        } else if (selectedCommPoint && commPoint && selectedCommPoint.name === commPoint.name) {
            setSelectedCommPoint(null as unknown as IEnsembleCommPoint);
        } else if (commPoint) {
            setSelectedCommPoint(commPoint);
        }
    };

    const pinInterface = (selectedInterface?: IEnsembleInterface, selectedCommPoint?: IEnsembleCommPoint) => () => {
        // Interfaces
        if (selectedInterface && setPinnedInterfaces && setInterfaceCookies) {
            const { pinnedInterfaces = [] } = props;
            const isInterfaceAlreadyPinned = pinnedInterfaces.some(
                (interfaceItem) => interfaceItem.id === selectedInterface.id
            );
            const updatedPinnedInterfaces = isInterfaceAlreadyPinned ? pinnedInterfaces.filter(item => item.id !== selectedInterface.id) : [...pinnedInterfaces, selectedInterface];
            setPinnedInterfaces(updatedPinnedInterfaces as IEnsembleInterface[]);
            setInterfaceCookies(updatedPinnedInterfaces as IEnsembleInterface[]);

            // Commpoints
        } else if (selectedCommPoint && setPinnedCommPoints && setCommPointCookies) {
            const { pinnedCommPoints = [] } = props;
            const isInterfaceAlreadyPinned = pinnedCommPoints.some(
                (commPoint) => commPoint.name === selectedCommPoint.name
            );
            const updatedPinnedInterfaces = isInterfaceAlreadyPinned ? pinnedCommPoints.filter(item => item.name !== selectedCommPoint.name) : [...pinnedCommPoints, selectedCommPoint];
            setPinnedCommPoints(updatedPinnedInterfaces as IEnsembleCommPoint[]);
            setCommPointCookies(updatedPinnedInterfaces as IEnsembleCommPoint[]);
        }

    };

    const headerColours = interfacesToColours(props.ensembleInterface, props.ensembleCommPoints);

    return <CardContainer>
        <div className="container">
            <CardHeader>
                {(() => {
                    if (props.ensembleInterface && props.ensembleInterface.length > 0) {
                        return <h3>Interfaces</h3>
                    } else if (props.ensembleCommPoints && props.ensembleCommPoints.length > 0) {
                        return <h3>Commpoints</h3>
                    } else {
                        return <h3></h3>
                    }
                })()}
                <StatusHeaderContainer>
                    <div className="active">{headerColours.green}</div>
                    <div className="disabled">{headerColours.grey}</div>
                    <div className="queue">{headerColours.purple}</div>
                    <div className="inactive">{headerColours.yellow}</div>
                    <div className="error">{headerColours.red}</div>
                </StatusHeaderContainer>
            </CardHeader>
            <Container>
                {props.ensembleInterface && props.ensembleInterface.map(item => {
                    return <AlertContainer key={`interface-item-${item.id}`} className={item.status} onClick={selectInterface(item)} />;
                })}
                {props.ensembleCommPoints && props.ensembleCommPoints.map((item, index) => {
                    return <AlertContainer key={`commpoint-item-${item.name}-${index}`} className={item.state} onClick={selectInterface(undefined, item)} />;
                })}
            </Container>
            <div className="selected-interface">
                {selectedInterface && <InterfaceDetails pinned={pinnedInterfaces ? pinnedInterfaces.includes(selectedInterface) : false} selectedInterface={selectedInterface} onTogglePin={pinInterface(selectedInterface)} />}
                {selectedCommPoint && <CommPointDetails pinned={pinnedCommPoints ? pinnedCommPoints.includes(selectedCommPoint) : false} selectedInterface={selectedCommPoint} onTogglePin={pinInterface(undefined, selectedCommPoint)} />}
            </div>
        </div>
    </CardContainer>;
};

export default EnsembleSummaryView;
