import axios, { AxiosError, AxiosResponse } from 'axios';

import { getAPIUrl } from 'src/config';
import IErrorDetails from 'src/ServerEntities/IErrorDetails';
import { NETWORK_ERROR } from 'src/UsefulFunctions/createErrorMessage';

export const getMfaStatus = (webToken: string, successCallback: (key: boolean) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(`${getAPIUrl()}login/totp/status`, { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "application/json" } })
        .then((response: AxiosResponse<boolean>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            let errorMessage = "Unknown error";
            if (error.response) {
                errorMessage = error.response.data.message;
            }
            if (error.message === "Network Error") {
                errorMessage = NETWORK_ERROR;
            }
            failureCallback(errorMessage as string);
        });
};


export const getMfaSecretKey = (webToken: string, successCallback: (key: string) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(`${getAPIUrl()}login/totp/generate`, { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "application/json" } })
        .then((response: AxiosResponse<string>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            let errorMessage = "Unknown error";
            if (error.response) {
                errorMessage = error.response.data.message;
            }
            if (error.message === "Network Error") {
                errorMessage = NETWORK_ERROR;
            }
            failureCallback(errorMessage as string);
        });
};

export const activateMfa = (code: string, webToken: string, successCallback: (key: string) => void, failureCallback: (errorMessage: string) => void) => {
    axios.post(`${getAPIUrl()}login/totp/activate`,
        code,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "application/json" } })
        .then((response: AxiosResponse<string>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            let errorMessage = "Unknown error";
            if (error.response) {
                errorMessage = error.response.data.message;
            }
            if (error.message === "Network Error") {
                errorMessage = NETWORK_ERROR;
            }
            failureCallback(errorMessage as string);
        });
};

export const deactivateMfa = (code: string, webToken: string, successCallback: (key: string) => void, failureCallback: (errorMessage: string) => void) => {
    axios.post(`${getAPIUrl()}login/totp/deactivate`,
        code,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "application/json" } })
        .then((response: AxiosResponse<string>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            let errorMessage = "Unknown error";
            if (error.response) {
                errorMessage = error.response.data.message;
            }
            if (error.message === "Network Error") {
                errorMessage = NETWORK_ERROR;
            }
            failureCallback(errorMessage as string);
        });
};