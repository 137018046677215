import silenceIcon from 'src/images/customIcons/notification.png';
import styled from 'styled-components';


const ServerIcon = styled.img`
    height: 24px;
    width: 24px;
    margin: 0 4px;
`;

interface IProps {
    onClick: (event: React.MouseEvent<HTMLImageElement>) => void
};

const SilenceServerIcon = (props: IProps) => {
    const { onClick } = props;


    return <ServerIcon onClick={onClick}
        alt="silence server"
        src={silenceIcon} />;
};

export default SilenceServerIcon;
