import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router';
import Timeago, { Unit } from 'react-timeago';
import styled from 'styled-components';

import IAlert from 'src/ServerEntities/IAlert';
import handleKeyboardSelect from 'src/UsefulFunctions/handleKeyboardSelect';
import AlertDetail from './AlertDetail';
import UsernameText from './Components/UsernameText';
import AlertIcon from './Components/AlertIcon';
import trendDownIcon from 'src/images/customIcons/arrow_trend_down_icon.svg';
import trendUpIcon from 'src/images/customIcons/arrow_trend_up_icon.svg';
import silenceIcon from 'src/images/customIcons/notification.png';

interface IProps {
    alert: IAlert,
    server: string,
    closed: boolean,
    size: SIZE,
    refresh: number,
    setRefresh: (count: number) => void,
    showCurrentIssues: boolean,
    startExpanded?: boolean
};

interface IAlertHeader {
    closed: boolean
};

const AlertHeader = styled.header<IAlertHeader>`
    width: 100%;
    background-color: ${props => props.closed ? "#6D2F35" : "#1c3e5a"};
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    flex-direction: row;

    &.unassigned {
        background-color: #818181;
    }
    &.closed {
        background-color: #6d2f35;
    }

    @media (min-width: 1279px) {
        border-radius: 16px;
        padding: 2px;
    }
`;

const AlertContainer = styled.li`
    width: 100%;
    margin-bottom: 10px;
`;

const AlertCount = styled.i`
    display: flex;
    justify-content: center;
    width: 2%;
    font-style: normal;
    background-color: "#f99600";
    border-radius: 16px;
    padding: 4px 12px;
    margin: 0 12px;

    &.closed {
        background-color: transparent;
        padding: 2px 10px;
        border: 1px solid #f99600;
    }

    @media (max-width: 1279px) {
        display: none;
    }
`;

const AlertText = styled.p`
    margin: 0 12px;
    flex: 0 1 40%;
    overflow-wrap: break-word;
    overflow: hidden;

    @media (max-width: 1279px) {
        width: 100%;
        flex: 1 1 auto;
    }
`;

const TrendIcon = styled.img`
    height: 24px;
    width: 24px;
    margin: 0 4px;
`;

const TrendUpIcon = styled(TrendIcon)`
    color: green;
`;

const TrendDownIcon = styled(TrendIcon)`
    color: red;
`;

const MutedIcon = styled.img`
    height: 24px;
    width: 24px;
    margin: 0 4px;
`;

const ItemHeader = styled.div`
    display: flex;    
`

const UsernameTextContainer = styled.div`
    display: flex;
    width: 20%;
    white-space: nowrap;
    overflow: hidden;
    justify-content: flex-end;
`;

const IconContainer = styled.div`
    width: 10%;
    @media (max-width: 1279px) {
        display: none;
    }
`;

const MAX_ALERT_LENGTH = 200;

export type SIZE = "large" | "medium" | "small";

const SMALL_UNITS: { [key: string]: string } = {
    "second": "sec",
    "minute": "min",
    "hour": "hr",
    "week": "wk",
    "month": "mnth",
    "year": "yr"
};

const ageFormatter = (value: number, unit: Unit, suffix: string) => {
    const smallUnit = SMALL_UNITS[unit] || unit;
    return `${value} ${smallUnit}${value !== 1 ? 's' : ''}`;
};

const getUsername = (alert: IAlert, minimised: boolean, closed: boolean) => {
    if (minimised) {
        return alert.username ? alert.username.substring(0, 3) : "UN";
    }
    if (closed) {
        return `Closed - ${alert.username}`;
    }
    return alert.username || "Unassigned";
};

const getAlertText = (alert: IAlert) => {
    let alertText = alert.alert.length > MAX_ALERT_LENGTH ? alert.alert.substring(0, MAX_ALERT_LENGTH) : alert.alert;
    if (alert.service) {
        return `${alert.service} \u2013 ${alertText}`;
    }
    return alertText;
};


const AlertItem = (props: IProps) => {
    const { alert, server, closed, size, refresh, setRefresh, startExpanded, showCurrentIssues } = props;
    const [expanded, setExpanded] = React.useState(startExpanded);
    const navigate = useNavigate();

    const isSmall = useMediaQuery({
        query: '(max-width: 1279px)'
    });

    const toggleExpanded = () => {
        if (isSmall) {
            navigate(`/Ram/Alert/${alert.id}`);
        } else {
            setExpanded(!expanded);
        }
    };

    return <AlertContainer key={`server-${server}-alert`} className={closed ? "closed" : (alert.username ? "assigned" : "unassigned")}>
        <ItemHeader>
            <AlertHeader onClick={toggleExpanded} onKeyDown={handleKeyboardSelect(toggleExpanded)} tabIndex={0} closed={closed}>
                {size === "large" ?
                    <Timeago date={alert.lastUpdate || alert.timestamp} style={{ paddingLeft: "6px", width: "10%", display: "flex", justifyContent: "center" }} /> :
                    <Timeago date={alert.lastUpdate || alert.timestamp} style={{ paddingLeft: "6px", width: "65px", display: "flex", justifyContent: "center" }} formatter={ageFormatter} />
                }
                <AlertIcon type={alert.type} closed={closed} />
                <AlertCount className={closed ? "closed" : "current"}>{alert.count}</AlertCount>
                {size !== "small" && <AlertText>{getAlertText(alert)}</AlertText>}
                <IconContainer>
                    {alert.muted ? <MutedIcon alt="Muted" src={silenceIcon} /> : null}
                    {alert.trend && alert.trend === "up" && <TrendUpIcon alt={"Trending upwards"} src={trendUpIcon} />}
                    {alert.trend && alert.trend === "down" && <TrendDownIcon alt={"Trending downwards"} src={trendDownIcon} />}
                </IconContainer>
                <UsernameTextContainer>
                    <UsernameText backgroundColor={closed ? "#6d2f35" : (alert.userColour || '#818181')}>
                        {getUsername(alert, size !== "large", closed)}
                    </UsernameText>
                </UsernameTextContainer>
            </AlertHeader>
        </ItemHeader>
        {expanded && <AlertDetail
            refresh={refresh}
            setRefresh={setRefresh}
            onClose={toggleExpanded}
            alert={alert}
            server={server}
            size={size}
            showCurrentIssues={showCurrentIssues}
            closed={closed}
        />}
    </AlertContainer>;
};

export default AlertItem;
