import * as React from "react";
import styled from "styled-components";

import Ripple from 'src/SharedComponents/Ripple';
import handleKeyboardSelect from 'src/UsefulFunctions/handleKeyboardSelect';

const IconButtonContainer = styled.div`
    z-index: 2;
    color: inherit;
    cursor: pointer;
    display: inline-flex;
    outline: none;
    position: relative;
    align-items: flex-start;
    line-height: 10px;
    user-select: none;
    border-radius: 0;
    vertical-align: middle;
    justify-content: center;
    text-decoration: none;
    background-color: transparent;
    flex: 0 0 auto;
    overflow: visible;
    font-size: 1.5rem;
    text-align: center;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50%;
    &:hover {
        background-color: rgb(255,255,255, 0.2);
    }
    &:focus {
        background-color: rgb(255,255,255, 0.6);
        outline: 1px dotted #333333;
    }
    &.disabled{
        opacity: 0.3;
        pointer-events:none;
    }
`;

interface IProps {
    children?: React.ReactNode,
    onClick: (e?: React.SyntheticEvent<HTMLDivElement>) => void
};

const IconButton = (props: IProps) => {

    const handleClick = (e: React.SyntheticEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
        props.onClick(e);
    };

    return <IconButtonContainer
        tabIndex={0}
        className={`IconButtonContainer`}
        onClick={handleClick}
        onKeyDown={handleKeyboardSelect(props.onClick)}>
        <Ripple style={{ borderRadius: "100%", padding: "4px 3px 8px 3px", display: "flex" }} color="187fba">
            {props.children}
        </Ripple>
    </IconButtonContainer>
};

export { IconButtonContainer };
export default IconButton;
