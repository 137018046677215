import { useState } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';

import IQveraInformation from 'src/ServerEntities/IQveraInformation';
import QveraInformationDetails from './QveraInformationDetails';

const CardContainer = styled.article`
    display: flex;
    flex-direction: column;
    height: auto;
    
    @media (min-width: 1279px) {
        border-radius: 16px;
        width: 100%;
        height: auto;
    }
    @media (max-width: 1279px) {
        width: 100%;
    }
    & h3 {
        margin: 0;
        font-weight: 500;
        font-size: 1.2rem;
    }
    & dt {
        flex: 0 0 30%;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (max-width: 1279px) {
            flex: 0 0 100%;
        }
    }
    & dd {
        flex: 0 0 calc(70% - 5px);
        overflow: hidden;
        text-overflow: ellipsis;
        overflow-wrap: break-word;
        max-width: 520px;
        @media (max-width: 1279px) {
            flex: 0 0 calc(100% - 10px);
            margin: 0 0 6px 10px;
        }
    }

    .container {
        background-color: #1c3e5a;
        border-radius: 16px;
    }
`;

const CardHeader = styled.header`
    flex: 1 0 auto;
    display: flex;
    max-height:32px;
    min-height:32px;
    flex-direction: row;
    flex-wrap: nowrap;
    background: #246896;
    padding: 6px 12px;
    border-radius: 16px 16px 0 0;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 1279px) {
        align-items: flex-start;
        border-radius: 0;
        padding: 4px;
    }
`;

const StatusHeaderContainer = styled.div`
    display:flex;
    flex-direction: row;
    margin-left: 50px;
    & div {
        padding: 2px 10px;
        &.active { background-color: #42a83f; }
        &.disabled { background-color: #9e9e9e; }
        &.queue { background-color: #6902de; }
        &.error { background-color: #ff2121; }
        &.inactive { background-color: #f99600; }
    }
    & div:first-child {
        border-radius: 6px 0 0 6px;
    }
    & div:last-child {
        border-radius: 0 6px 6px 0;
    }
`;

const Container = styled.ul`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px;
    width: auto;
`;

const AlertContainer = styled.li`
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 8px;
    padding-left: 12px;
    padding-right: 12px;
    height: 25px;
    cursor: pointer;
    
    // Interfaces
    &.Active { background-color: #42a83f; }
    &.Disabled { background-color: #9e9e9e; }
    &.Queue { background-color: #6902de; }
    &.Error { background-color: #ff2121; }
    &.Inactive { background-color: #f99600; }

    // Commpoints
    &.RUNNING { background-color: #42a83f; }
    &.DISABLED { background-color: #9e9e9e; }
    &.QUEUE { background-color: #6902de; }
    &.ERROR { background-color: #ff2121; }
    &.STOPPED { background-color: #f99600; }
`;

interface IProps {
    qveraInformation?: IQveraInformation[],
};

const interfacesToColours = (qveraInformation?: IQveraInformation[]) => {

    if (qveraInformation) {
        return qveraInformation.reduce((accumulator, item) => {
            switch (item.stateDescription.toLocaleUpperCase()) {
                case 'RUNNING':
                    accumulator.green++;
                    break;
                case 'ERROR':
                    accumulator.red++;
                    break;
                case 'STOPPED':
                    accumulator.yellow++;
                    break;
                case 'DISABLED':
                    accumulator.grey++;
                    break;
                case 'QUEUE':
                    accumulator.purple++;
                    break;
            }
            return accumulator;
        }, {
            green: 0,
            grey: 0,
            purple: 0,
            red: 0,
            yellow: 0
        });
    } else {
        return {
            green: 0,
            grey: 0,
            purple: 0,
            red: 0,
            yellow: 0
        }
    }
};

const QveraSummaryView = (props: IProps) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const channelId = queryParams.get('qveraId');
    const [selectedQveraInformation, setSelectedQveraInformation] = useState(null as unknown as IQveraInformation);

    if (channelId && !selectedQveraInformation && props.qveraInformation) {
        const channel = props.qveraInformation.find((item) => item.channelId === channelId);
        setSelectedQveraInformation(channel || null as unknown as IQveraInformation);
    }

    const selectInterface = (qveraItem?: IQveraInformation) => () => {
        if (selectedQveraInformation && qveraItem && selectedQveraInformation.channelId === qveraItem.channelId) {
            setSelectedQveraInformation(null as unknown as IQveraInformation);
        } else if (qveraItem) {
            setSelectedQveraInformation(qveraItem);
        }
    };

    if (props.qveraInformation) {
        props.qveraInformation.forEach((item) => {
            if (item.inboundQueue) {
                item.stateDescription = "QUEUE";
            }
        });
    }

    const headerColours = interfacesToColours(props.qveraInformation);

    return <CardContainer>
        <div className="container">
            <CardHeader>
                <h3>QVera Channels</h3>
                <StatusHeaderContainer>
                    <div className="active">{headerColours.green}</div>
                    <div className="disabled">{headerColours.grey}</div>
                    <div className="queue">{headerColours.purple}</div>
                    <div className="inactive">{headerColours.yellow}</div>
                    <div className="error">{headerColours.red}</div>
                </StatusHeaderContainer>
            </CardHeader>
            <Container>
                {props.qveraInformation && props.qveraInformation.map(item => {
                    return <AlertContainer key={`interface-item-${item.channelId}`} className={item.stateDescription.toUpperCase()} onClick={selectInterface(item)}>{item.channelShortCode}</AlertContainer>;
                })}
            </Container>
            <div className="selected-interface">
                {selectedQveraInformation && <QveraInformationDetails selectedQveraInformation={selectedQveraInformation} />}
            </div>
        </div>
    </CardContainer>;
};

export default QveraSummaryView;
