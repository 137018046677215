import React from 'react';
import Dialog from 'src/SharedComponents/Dialog/Dialog';
import ErrorBox from 'src/SharedComponents/ErrorBox';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import createErrorMessage from 'src/UsefulFunctions/createErrorMessage';
import styled from 'styled-components';

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 24px 12px 0;
`;

const Title = styled.h2`
    background: #246896;
    margin: 0;
    border-radius: 16px 16px 0 0;
    padding: 6px 12px;
    font-weight: 600;
    font-size: 1.3rem;
    color: #ffffff;
`;

const ContentContainer = styled.div`
    padding: 18px 12px;
    background: #1c3e5a;
    border-radius: 0 0 16px 16px;
    @media (min-width: 1279px) {
        width: 400px;
    }
    display: flex;
    flex-direction: column;
`;

interface ITextAreaStyleProps {
    height: string
};

const TextAreaContainer = styled.div<ITextAreaStyleProps>`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    min-height: ${props => props.height};
`;

const TextArea = styled.textarea`
    background: #246896;
    border-radius: 16px;
    flex: 1 1 auto;
    padding: 8px;
    margin: 0;
    color: #ffffff;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
`;

const Button = styled.button`
    background: #246896;
    border-radius: 16px;
    color: #ffffff;
    margin: 0 16px;
    padding: 6px 24px;
    cursor: pointer;
    font-weight: 500;
    font-size: 1.1rem;
`;

interface INoteState {
    error: string,
    loading: boolean,
    open: boolean,
    setError: (error: string) => void,
    setLoading: (loading: boolean) => void,
    setOpen: (isOpen: boolean) => void
};

export type { INoteState };

interface IProps {
    error: string,
    loading: boolean,
    onClose: () => void,
    onSaveNote: (noteText: string) => void
};

const AddNoteDialog = (props: IProps) => {
    const textAreaRef = React.useRef<HTMLTextAreaElement>(null);
    const { error, loading, onClose, onSaveNote } = props;
    const [text, setText] = React.useState("");
    const [parentHeight, setParentHeight] = React.useState("auto");

    React.useEffect(() => {
        setParentHeight(`${textAreaRef.current!.scrollHeight}px`);
    }, [text]);

    const updateText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setParentHeight(`${textAreaRef.current!.scrollHeight}px`);
        setText(e.target.value);
    };

    const clickSave = () => {
        onSaveNote(text);
    };

    const closeDialog = () => {
        setText("");
        onClose();
    };

    return <Dialog open={true} onClose={closeDialog} style={{ background: "none" }}>
        <Title>Add note</Title>
        <ContentContainer>
            {error && <ErrorBox>{createErrorMessage("saving the note")}</ErrorBox>}
            {loading && <LoadingIndicator type="Linear" show={true} />}
            <TextAreaContainer height={parentHeight}>
                <TextArea ref={textAreaRef} rows={1} placeholder="Type here" value={text} onChange={updateText} />
            </TextAreaContainer>
            <ButtonContainer>
                <Button onClick={clickSave}>Save</Button>
                <Button onClick={closeDialog}>Cancel</Button>
            </ButtonContainer>
        </ContentContainer>
    </Dialog>
};

export default AddNoteDialog;