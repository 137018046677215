import * as React from 'react';
import styled from 'styled-components';

import { SessionContext } from 'src/Views/SessionContext';

import Button from 'src/SharedComponents/Button';
import Dialog from 'src/SharedComponents/Dialog/Dialog';
import DialogActions from 'src/SharedComponents/Dialog/DialogActions';
import ErrorBox from 'src/SharedComponents/ErrorBox';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import TextInput from 'src/SharedComponents/TextInput';
import ManagerTitleBar from 'src/Views/Manager/ManagerTitleBar';

import { createClient } from './clientManagementService';

const UserFieldsContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px;
`;

const UserFieldLine = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
`;

const StatusContainer = styled.div`
    height: 70px;
    text-align: center;
`;

const MainContainer = styled.div`
    display:flex;
    height: 100%;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: space-around;
    justify-content: center;
    align-items: center;
`;

interface IProps {
    show: boolean,
    onClose: () => void,
    onConfirmClientAdd: () => void
}

const CreateClientDialog = (props: IProps) => {
    const { webToken } = React.useContext(SessionContext).state;
    const [ClientValue, setClientValue] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [success, setSuccess] = React.useState(false);

    const closeDialog = () => {
        setClientValue("");
        setError("");
        if (success) {
            props.onConfirmClientAdd();
            setSuccess(false);
        }
        props.onClose();
    };

    const addClientConfirm = () => {
        setLoading(true);
        createClient(webToken, ClientValue,
            () => {
                setLoading(false)
                setSuccess(true);
                setError("");
            },
            (errorMessage) => {
                setLoading(false);
                setError(errorMessage);
                setSuccess(false)
                return ""
            })
    };

    const changeClientValue = (e: React.SyntheticEvent<HTMLInputElement>) => {
        setClientValue(e.currentTarget.value);
    };

    return <Dialog open={props.show} onClose={closeDialog} style={{ width: "650px", height: "620px" }}>
        <ManagerTitleBar viewName="Create Client" viewDescription="Create a new Client." />
        <LoadingIndicator show={loading} type="Linear" />
        {!success && <UserFieldsContainer>
            <UserFieldLine>
                <TextInput margin="10px 10px 20px 10px" value={ClientValue} onChange={changeClientValue} label="Client" />
            </UserFieldLine>
            <StatusContainer>
                {error && <ErrorBox>{error}</ErrorBox>}
            </StatusContainer>
            <DialogActions>
                <Button outlined={true} onClick={closeDialog}>Cancel</Button>
                <Button onClick={addClientConfirm} margin="0px 16px 0px 16px" disabled={false}>Confirm</Button>
            </DialogActions>
        </UserFieldsContainer>}
        {success && <MainContainer>
            <StatusContainer>Client created successfully </StatusContainer>
            <Button onClick={closeDialog} margin="0px 16px 0px 16px" disabled={false}>Close</Button>
        </MainContainer>}
    </Dialog>;
};

export default CreateClientDialog;
