import * as React from 'react';

import INote from 'src/ServerEntities/INote';
import ErrorBox from 'src/SharedComponents/ErrorBox';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import { SessionContext } from 'src/Views/SessionContext';
import styled from 'styled-components';
import dateConverter from 'src/UsefulFunctions/dateConverter';
import AddNoteDialog from './AddNoteDialog';
import HeaderLink from './HeaderLink';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 4px;
    @media (min-width: 1279px) {
        padding: 20px 20px 10px 0px;
    }
`;

const MainContainer = styled.div`
    display: flex;
    flex-direction: row;
    @media (max-width: 1279px) {
        flex-direction: column;
    }
`;

const LeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    & h3 {
        margin: 0 0 8px;
        font-weight: 500;
        font-size: 1.2rem;
    }
    & a {
        width: 80px;
        text-align: center;
    }
    margin-right: 12px;
`;

const NoteContainer = styled.ul`
    display: flex;
    background-color: #187fba;
    border-radius: 16px;
    flex-direction: column;
    margin-top: 8px;
    padding: 8px;
`;

const NoteItem = styled.li`
    padding: 5px;
    display: flex;
    flex-direction: row;
    @media (max-width: 1279px) {
        flex-direction: column;
    }
`;

const NoteCell = styled.p`
    text-align: left;
    margin: 0 6px;
    flex: 0 0 auto;
`;

const NoteText = styled.p`
    text-align: left;
    margin: 0 6px;
    flex: 0 1 auto;
`;

interface IProps {
    addNote: (itemId: number, note: string, webToken: string, success: () => void, failure: (errorMessage: string) => void) => void,
    getNotes: (itemId: number, webToken: string, success: (notes: INote[]) => void, failure: (errorMessage: string) => void) => void,
    itemId: number
};

const AlertNotes = (props: IProps) => {
    const { webToken } = React.useContext(SessionContext).state;
    const { addNote, getNotes, itemId } = props;
    const [refresh, setRefresh] = React.useState(0);
    const [notes, setNotes] = React.useState([] as INote[]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [showNoteDialog, setShowNoteDialog] = React.useState(false);

    React.useEffect(() => {
        setLoading(true);
        getNotes(itemId, webToken, (notes: INote[]) => {
            setLoading(false);
            setNotes(notes);
            setError("");
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    }, [itemId, getNotes, refresh, webToken]);

    const noteToRow = (note: INote, index: number) => {
        return <NoteItem key={`alert-${itemId}-note-${index}`}>
            <NoteCell>
                {note.username ? note.username.substring(0, 3) : ""}
            </NoteCell>
            <NoteCell>
                {dateConverter(note.date)}
            </NoteCell>
            <NoteText>
                {note.text}
            </NoteText>
        </NoteItem>;
    };

    const openNoteDialog = () => {
        setShowNoteDialog(true);
    };

    const closeNoteDialog = () => {
        setShowNoteDialog(false);
    };

    const onSaveNote = (note: string) => {
        setLoading(true);
        addNote(itemId, note, webToken, () => {
            setLoading(false);
            setError("");
            closeNoteDialog();
            setRefresh(refresh + 1);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    };

    return <Container>
        {showNoteDialog && <AddNoteDialog onSaveNote={onSaveNote} onClose={closeNoteDialog} error={error} loading={loading} />}
        <LoadingIndicator show={loading} type="Linear" />
        {error && <ErrorBox>{error}</ErrorBox>}
        <MainContainer>
            <LeftContainer>
                <h3>Notes</h3>
                <HeaderLink onClick={openNoteDialog}>Add note</HeaderLink>
            </LeftContainer>
            {notes && notes.length > 0 && <NoteContainer>
                {notes.map(noteToRow)}
            </NoteContainer>}
        </MainContainer>
    </Container>;
};

export default AlertNotes;
