import * as React from 'react';
import styled from 'styled-components';

import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useEffectOnSome from 'src/CustomHooks/useEffectOnSome';
import ErrorBox from 'src/SharedComponents/ErrorBox';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import { SessionContext } from 'src/Views/SessionContext';
import { getVolumeNotificationLimits } from '../UserManagement/smsNotificationService';
import IVolumeNotification from 'src/ServerEntities/IVolumeNotification';
import VolumeNotificationRow from './VolumeNotificationRow';

const SectionHeader = styled.div`
    background: #ffffff;
    border-bottom: 1px solid #e5e5e5;
    padding: 12px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    & > div {
        display: flex;
        flex-direction: column;
        width: 600px;
    }
    & p {
        margin: 6px 0;
        font-size: 1.2rem;
    }
    & h1 {
        font-size: 1.6rem;
        margin: 6px 0;
        font-weight: 500;
    }
`;

const Container = styled.div`
    padding: 12px 24px;
`;

const VolumeNotifications = () => {
    const [open, setOpen] = React.useState(false);
    const { webToken } = React.useContext(SessionContext).state;
    const [loading, setLoading] = React.useState(false);
    const [limits, setLimits] = React.useState(null as unknown as IVolumeNotification[]);
    const [error, setError] = React.useState("");

    useEffectOnSome(() => {
        setLoading(true);
        getVolumeNotificationLimits(webToken, (serverLimits) => {
            setLimits(serverLimits);
            setError("");
            setLoading(false);
        }, (errorMessage: string) => {
            setError(errorMessage);
            setLoading(false);
        });
    }, [webToken], []);

    const toggleOpen = () => {
        setOpen(!open);
    };

    return <div>
        {error && <ErrorBox>{error}</ErrorBox>}
        <SectionHeader>
            <div>
                <h1>Volume notifications</h1>
            </div>
            <FontAwesomeIcon style={{cursor: "pointer"}} size='2x' icon={open ? faChevronUp : faChevronDown} onClick={toggleOpen} />
        </SectionHeader>
        <LoadingIndicator type="Linear" show={loading} />
        {open && <Container>
                <table>
                <tbody>
                    {limits.map(item => {
                        return <VolumeNotificationRow key={`volume-notification-site-${item.site}`} limits={item} setLoading={setLoading}
                            loading={loading} setError={setError} />
                    })}
                </tbody>
            </table>
        </Container>}
    </div>;
};

export default VolumeNotifications;
