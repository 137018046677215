import * as React from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

import RamUserMenu from 'src/Views/Ram/RamUserMenu';
import HeaderLink from './HeaderLink';

import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { RolesContext } from 'src/Roles/RolesContext';
import { SessionContext } from 'src/Views/SessionContext';

import { RAM_COMPONENT_NAME } from 'src/ServerEntities/IConfiguration';
import IHeaderInformation from 'src/ServerEntities/IHeaderInformation';

const TopBar = styled.header`
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    padding: 10px 16px;
    @media (max-width: 1279px) {
        flex-direction: column;
        align-items: stretch;
        padding: 0;
    }
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    & h1 {
        margin: 0;
        font-size: 2rem;
        font-weight: 600;
    }
    @media (max-width: 1279px) {
        justify-content: space-between;
        flex: 1 1 auto;
        padding: 10px 16px;
    }
`;

const ActionContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
    @media (max-width: 1279px) {
        display: none;
    }
`;

const MobileNavContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    background: #396282;
    width: 100%;
    position: absolute;
    top: 64px;
    z-index: 2;
    & a {
        font-size: 1.4rem;
        text-align: center;
        padding: 8px 4px;
        flex: 1 1 auto;
        border: 0 none;
    }
`;

const InfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    @media (max-width: 1279px) {
        display: none;
    }
`;

const Logo = styled.img`
    width: 100px;
    margin-right: 24px;
`;

const ServerInfo = styled.p`
    margin: 0;
    padding: 4px 12px;
    &.unassigned-some {
        color: #f99600;
    }
    &.unresponsive-some {
        color: #ff2121;
    }
`;

const HamburgerMenu = styled.div`
    cursor: pointer;
    @media (min-width: 1280px) {
        display: none;
    }
`;

const Title = styled.h1`
    @media (max-width: 1279px) {
        display: none;
    }
`;

const MobileLink = styled.a`
    cursor: pointer;
`;

interface IProps {
    headerInformation: IHeaderInformation
    pageName: string,
    openRoleChangeDialog: () => void,
    openMfaDialog: () => void
};

const Header = (props: IProps) => {
    const { headerInformation, openRoleChangeDialog, pageName, openMfaDialog } = props;
    const [displayMobileNav, setDisplayMobileNav] = React.useState(false);
    const roleContext = React.useContext(RolesContext);
    const rhapsodyView = roleContext.state.ramConfiguration ? roleContext.state.ramConfiguration.rhapsodyView : false;
    const configuration = roleContext.state.ramConfiguration;
    const components = configuration?.components || [];
    const multiSite = configuration && configuration.multiSite ? configuration.multiSite : false;
    const { dispatch, state } = React.useContext(SessionContext);
    const navigate = useNavigate();

    const signOut = () => {
        dispatch({ type: "clearWebToken", payload: undefined });
        roleContext.dispatch({ type: "clearRole" });
    };

    const onGotoManager = () => {
        navigate('/Manager');
    };

    const onLinkClick = (tabName: string) => () => {
        setDisplayMobileNav(false);
        navigate(`/Ram/${tabName}`);
    };

    const onHamburgerClick = () => {
        setDisplayMobileNav(!displayMobileNav);
    };

    const getComponentForMobile = (componentName: RAM_COMPONENT_NAME, index: number) => {
        switch (componentName) {
            case "Dashboard":
                return <MobileLink onClick={onLinkClick("Dashboard")} className={pageName === "Dashboard" ? "selected header-link" : "header-link"}>Dashboard</MobileLink>;
            case "RoleDashboard":
                return <MobileLink onClick={onLinkClick("RoleDashboard")} className={pageName === "RoleDashboard" ? "selected header-link" : "header-link"}>Dashboard</MobileLink>;
            case "Overview":
                return <MobileLink onClick={onLinkClick("Overview")} className={pageName === "Overview" ? "selected header-link" : "header-link"}>Overview</MobileLink>
            case "Support":
                return <MobileLink onClick={onLinkClick("Support")} className={pageName === "Support" ? "selected header-link" : "header-link"}>Support</MobileLink>
            case "Alerts":
                return <MobileLink onClick={onLinkClick("Alerts")} className={pageName === "Alerts" ? "selected header-link" : "header-link"}>Alerts</MobileLink>
            case "Sites":
                return <MobileLink onClick={onLinkClick("Sites")} className={pageName === "Sites" ? "selected header-link" : "header-link"}>{multiSite ? "Sites" : "Site"}</MobileLink>
            default:
                return null;
        }
    };

    const getComponent = (componentName: RAM_COMPONENT_NAME, index: number) => {
        switch (componentName) {
            case "Dashboard":
                return <HeaderLink key="dashboard-link" onClick={onLinkClick("Dashboard")} className={pageName === "Dashboard" ? "selected header-link" : "header-link"}>Dashboard</HeaderLink>
            case "RoleDashboard":
                return <HeaderLink key="role-dashboard-link" onClick={onLinkClick("RoleDashboard")} className={pageName === "RoleDashboard" ? "selected header-link" : "header-link"}>Dashboard</HeaderLink>
            case "Hybrid":
                return <HeaderLink key="hybrid-link" onClick={onLinkClick("Hybrid")} className={pageName === "Hybrid" ? "selected header-link" : "header-link"}>Hybrid</HeaderLink>;
            case "Overview":
                return <HeaderLink key="overview-link" onClick={onLinkClick("Overview")} className={pageName === "Overview" ? "selected header-link" : "header-link"}>Overview</HeaderLink>
            case "Support":
                return <HeaderLink key="support-link" onClick={onLinkClick("Support")} className={pageName === "Support" ? "selected header-link" : "header-link"}>Support</HeaderLink>
            case "Alerts":
                return <HeaderLink key="alerts-link" onClick={onLinkClick("Alerts")} className={pageName === "Alerts" ? "selected header-link" : "header-link"}>Alerts</HeaderLink>
            case "Sites":
                return <HeaderLink key="sites-link" onClick={onLinkClick("Sites")} className={pageName === "Sites" ? "selected header-link" : "header-link"}>{multiSite ? "Sites" : "Site"}</HeaderLink>
            case "Clients":
                return <HeaderLink key="clients-link" onClick={onLinkClick("Clients")} className={pageName === "Clients" ? "selected header-link" : "header-link"}>Clients</HeaderLink>
            default:
                return null;
        }
    };

    return <TopBar style={{
        backgroundColor: rhapsodyView ? "#1577AE" : "",
        color: rhapsodyView ? "#FFF" : "000",
        alignItems: rhapsodyView ? "center" : "flex-start"
    }}>
        <TitleContainer>
            <Logo src="/images/imx-monitor-header-logo.png" alt="Ram Logo" />
            <HamburgerMenu onClick={onHamburgerClick}><FontAwesomeIcon icon={faBars} size="2x" /></HamburgerMenu>
            <Title>{pageName === "RoleDashboard" ? "Dashboard" : pageName}</Title>
        </TitleContainer>
        {displayMobileNav && <MobileNavContainer>
            {components.map(getComponentForMobile)}
            <RamUserMenu onSignOut={signOut} role={roleContext.state.role} username={state.loggedInUser} onChangeRole={openRoleChangeDialog} onGotoManager={onGotoManager} onMfa={openMfaDialog} />
        </MobileNavContainer>}
        <ActionContainer>
            <ButtonContainer>
                {components.map(getComponent)}
                <RamUserMenu onSignOut={signOut} role={roleContext.state.role} username={state.loggedInUser} onChangeRole={openRoleChangeDialog} onGotoManager={onGotoManager} onMfa={openMfaDialog} />
            </ButtonContainer>
            {!rhapsodyView && <InfoContainer>
                <ServerInfo>{headerInformation.sites} {headerInformation.sites === 1 ? "site" : "sites"}</ServerInfo>
                <ServerInfo>{headerInformation.servers} {headerInformation.servers === 1 ? "server" : "servers"}</ServerInfo>
                <ServerInfo className={headerInformation.unresponsiveServers > 0 ? "unresponsive-some" : "unresponsive-none"}>
                    {headerInformation.unresponsiveServers} {headerInformation.unresponsiveServers === 1 ? "unresponsive server" : "unresponsive servers"}
                </ServerInfo>
                <ServerInfo className={headerInformation.unresponsiveServers > 0 ? "unassigned-some" : "unassigned-none"}>
                    {headerInformation.unassignedAlerts} {headerInformation.unassignedAlerts === 1 ? "unassigned alert" : "unassigned alerts"}
                </ServerInfo>
                <ServerInfo>{headerInformation.alertsInProgress} {headerInformation.alertsInProgress === 1 ? "alert in progress" : "alerts in progress"}</ServerInfo>
            </InfoContainer>}
        </ActionContainer>
    </TopBar >;
};

export default Header;
