import ViewContainer from 'src/SharedComponents/ViewContainer';
import ManagerTitleBar from '../ManagerTitleBar';

import KubernetesConfiguration from '../Components/KubeConfiguration';

interface IProps {
    refresh: number,
    tablabel: string
};

const KubernetesTab = (props: IProps) => {
    return <ViewContainer style={{ backgroundColor: "#fafafa" }}>
        <ManagerTitleBar viewName="Kubernetes" viewDescription="Here you can configure kubernetes metrics." />
        <KubernetesConfiguration />
    </ViewContainer>;
};

export default KubernetesTab;
