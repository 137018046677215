import React from 'react';
import Timeago from 'react-timeago';
import styled from 'styled-components';

import ISupportIssue from 'src/ServerEntities/ISupportIssue';
import handleKeyboardSelect from 'src/UsefulFunctions/handleKeyboardSelect';

import AlertIcon from 'src/Views/Ram/Components/AlertIcon';

const ItemHeader = styled.header`
    background-color: #1c3e5a;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #818181;
    &.unassigned {
        background-color: #818181;
    }
    &.closed {
        background-color: #6d2f35;
    }
    padding: 4px;
    flex-direction: column;
    @media (min-width: 1279px) {
        padding: 0 0 0 12px;
        border-radius: 16px;
        flex-direction: row;
    }
    & time {
        flex: 0 0 auto;
        margin: 0 6px;
    }
    & img {
        margin: 0 6px;
    }
`;

const AlertContainer = styled.li`
    margin-bottom: 10px;
`;

const AlertText = styled.p`
    margin: 0 12px;
    flex: 1 1 auto;
    overflow-wrap: break-word;
    overflow: hidden;
    @media (max-width: 1279px) {
        margin: 0;
        width: 100%;
    }
`;

export type SIZE = "large" | "medium" | "small";

interface IProps {
    issue: ISupportIssue,
    server: string,
    closed: boolean
};

const shortFormatter = (value: number, unit: string, suffix: string) => {
    return `${value} ${unit.substring(0, 1)}`;
};


const DashboardSupportIssueItem = (props: IProps) => {
    const { issue, server, closed } = props;
    const [expanded, setExpanded] = React.useState(false);

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    return <AlertContainer key={`server-${server}-alert`} className={closed ? "closed" : (issue.username ? "assigned" : "unassigned")}>
        <ItemHeader onClick={toggleExpanded} onKeyDown={handleKeyboardSelect(toggleExpanded)} tabIndex={0}>
            <Timeago date={issue.date} formatter={shortFormatter} />
            <AlertIcon type="issue" />
            <AlertText>{issue.issue}</AlertText>
        </ItemHeader>
    </AlertContainer>;
};

export default DashboardSupportIssueItem;
