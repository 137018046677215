import * as React from "react";
import Ripple from 'src/SharedComponents/Ripple';
import styled from "styled-components";

interface IProps {
    children?: any,
    onClick?: () => void,
    margin?: string,
    width?: string,
    style?: React.CSSProperties,
    rippleColor?: string
};

const CardContainer = styled.div<IProps>`
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius:5px;
    cursor: ${props => props.onClick ? "pointer" : "auto"};
    margin: ${props => props.margin ? props.margin : ""};
    display:flex;
    width: ${props => props.width ? props.width : ""};
    background-color:white;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

const Card = (props: IProps) => {
    const { onClick, children } = props;
    if (onClick) {
        return <CardContainer {...props} className="Card">
            <Ripple color={props.rippleColor ? props.rippleColor : "#333333"}>
                {children}
            </Ripple>
        </CardContainer>
    }
    return <CardContainer {...props}>
        {children}
    </CardContainer>;
};

export default Card;
