import styled from 'styled-components';

export default styled.i`
    padding: 4px;
    font-style: normal;
    margin-right: 8px;
    background-color: #2893e5;
    &.site-type-imx-cr {
        background-color: #ab50ae;
    }
    @media (max-width: 1279px) {
        display: none;
    }
`;
