import React from 'react';
import Timeago from 'react-timeago';
import styled from 'styled-components';

import ISupportIssue from 'src/ServerEntities/ISupportIssue';
import handleKeyboardSelect from 'src/UsefulFunctions/handleKeyboardSelect';
import UsernameText from './UsernameText';
import AlertIcon from './AlertIcon';
import SupportIssueDetail from './SupportIssueDetail';

const ItemHeader = styled.header`
    background-color: #1c3e5a;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    &.unassigned {
        background-color: #818181;
    }
    &.closed {
        background-color: #6d2f35;
    }
    padding: 4px;
    flex-direction: column;
    @media (min-width: 1279px) {
        padding: 0 0 0 24px;
        border-radius: 16px;
        flex-direction: row;
    }
`;

const AlertContainer = styled.li`
    margin-bottom: 10px;
`;

const AlertText = styled.p`
    margin: 0 12px;
    flex: 0 1 40%;
    overflow-wrap: break-word;
    overflow: hidden;
    @media (max-width: 1279px) {
        margin: 0;
        width: 100%;
    }
`;

export type SIZE = "large" | "medium" | "small";

interface IProps {
    issue: ISupportIssue,
    server: string,
    closed: boolean,
    size: SIZE,
    refresh: number,
    setRefresh: (count: number) => void,
    showCurrentIssues: boolean
};

const shortFormatter = (value: number, unit: string, suffix: string) => {
    return `${value} ${unit.substring(0, 1)}`;
};

const getUsername = (issue: ISupportIssue, minimised: boolean, closed: boolean) => {
    if (minimised) {
        return issue.username ? issue.username.substring(0, 3) : "UN";
    }
    if (closed) {
        return `Closed - ${issue.username}`;
    }
    return issue.username || "Unassigned";
};

const SupportIssueItem = (props: IProps) => {
    const { issue, server, closed, size, refresh, setRefresh } = props;
    const [expanded, setExpanded] = React.useState(false);

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    return <AlertContainer key={`server-${server}-alert`} className={closed ? "closed" : (issue.username ? "assigned" : "unassigned")}>
        <ItemHeader onClick={toggleExpanded} onKeyDown={handleKeyboardSelect(toggleExpanded)} tabIndex={0}>
            {size === "large" ? <Timeago date={issue.date} /> : <Timeago date={issue.date} formatter={shortFormatter} />}
            <AlertIcon type="issue" />
            {size !== "small" && <AlertText>{issue.jiraTicket} - {issue.issue}</AlertText>}
            <UsernameText backgroundColor={closed ? "#6d2f35" : (issue.userColour || '#818181')}>
                {getUsername(issue, size !== "large", closed)}
            </UsernameText>
        </ItemHeader>
        {expanded && <SupportIssueDetail
            refresh={refresh}
            setRefresh={setRefresh}
            onClose={toggleExpanded}
            issue={issue}
            server={server}
            closed={closed}
            size={size}
            showCurrentIssues={props.showCurrentIssues}
        />}
    </AlertContainer>;
};

export default SupportIssueItem;
