import alertIcon from 'src/images/customIcons/alert-type.png';
import alertIconMuted from 'src/images/customIcons/alert-type-muted.png';
import errorIcon from 'src/images/customIcons/error-type.png';
import heartbeatBadIcon from 'src/images/customIcons/heartbeat-bad.png';
import styled from 'styled-components';

interface IStyleProps {
    size: "medium" | "large"
};

const getPxForSize = (size: "medium" | "large") => {
    if (size === "large") {
        return "36px";
    }
    return "24px";
};

const Icon = styled.img<IStyleProps>`
    height: ${props => getPxForSize(props.size)};
    width: ${props => getPxForSize(props.size)};
    margin: 0 12px;
    @media (max-width: 1279px) {
        display: none;
    }
`;

const getIconSrc = (type: string, closed: boolean | undefined) => {
    if (type === 'heartbeat') {
        return heartbeatBadIcon;
    }
    if (type === 'error') {
        return errorIcon;
    }
    if (closed) {
        return alertIconMuted;
    }
    return alertIcon;
};

interface IProps {
    size?: "medium" | "large",
    type: string,
    closed?: boolean
};

const AlertIcon = (props: IProps) => {
    const size = props.size || "medium";
    return <Icon alt={`Alert type: ${props.type}`} src={getIconSrc(props.type, props.closed)} size={size} />;
};

export default AlertIcon;
