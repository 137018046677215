import IEnsembleInterface from "src/ServerEntities/IEnsembleInterface"
import styled from "styled-components";
import InterfaceDetails from "./InterfaceDetails";
import IEnsembleCommPoint from "src/ServerEntities/IEnsembleCommPoint";
import CommPointDetails from "./CommPointDetails";

interface IProps {
    ensembleInterface?: IEnsembleInterface[];
    ensembleCommPoints?: IEnsembleCommPoint[];
    setPinnedInterfaces?:  (value: IEnsembleInterface[]) => void;
    setPinnedCommPoints?:  (value: IEnsembleCommPoint[]) => void;
    setInterfaceCookies?: (value: IEnsembleInterface[]) => void;
    setCommPointCookies?: (value: IEnsembleCommPoint[]) => void;
};

const CardContainer = styled.div`
    background-color: #1c3e5a;
    border-radius: 16px;
`

const CardHeader = styled.div`
    flex: 1 0 auto;
    display: flex;
    max-height:32px;
    min-height:32px;
    flex-direction: row;
    flex-wrap: nowrap;
    background: #246896;
    padding: 6px 12px;
    border-radius: 16px 16px 0 0;
    align-items: center;
    h3 {
        margin: 0 12px 0 0;
        font-weight: 500;
        font-size: 1.2rem;
    }
`

const Container = styled.div`
    ul.ensemble-body {
        border-radius: 0;
    }
`;


const EnsemblePinnedInterfaces = (props: IProps) => {
    const { ensembleInterface, setPinnedInterfaces, ensembleCommPoints, setInterfaceCookies } = props

    const removePin = (interfaceItem?: IEnsembleInterface, commPointItem?: IEnsembleCommPoint) => () => {
        if(interfaceItem) {
            const updatedPinnedInterfaces = ensembleInterface?.filter(
                (interfaceItem: IEnsembleInterface) => interfaceItem.id !== interfaceItem.id
              );
              
              if(updatedPinnedInterfaces) {
                  if(setPinnedInterfaces && setInterfaceCookies) {
                      setPinnedInterfaces(updatedPinnedInterfaces);
                      setInterfaceCookies(updatedPinnedInterfaces);
                  }
              }
        } else if(commPointItem) {

        }
    };

    return <CardContainer>
        <CardHeader>
            <h3>Pinned</h3>
        </CardHeader>
        <Container>
            {ensembleInterface && ensembleInterface?.length > 0 && ensembleInterface.map((item) => {
                return <InterfaceDetails selectedInterface={item} onTogglePin={removePin(item)} key={`pinned-interface-${item.id}`} pinned={true} />
            })}
            {ensembleCommPoints && ensembleCommPoints?.length > 0 && ensembleCommPoints.map((item, index) => {
                return <CommPointDetails selectedInterface={item} onTogglePin={removePin} key={`pinned-commpoint-${item.name}-${index}`} pinned={true}></CommPointDetails>
            })}
        </Container>
    </CardContainer>
}

export default EnsemblePinnedInterfaces;