import * as React from 'react';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import Ripple from 'src/SharedComponents/Ripple';
import styled from "styled-components";

const defaultButtonColor = "#187fba";
const disabledButtonColor = "rgba(0,0,0,0.1)";

interface IButtonProps {
    $outlined?: boolean,
    $plain?: boolean,
    disabled?: boolean,
    $color?: string,
    $loading?: boolean,
    margin?: string
};

const ButtonContainer = styled.button<IButtonProps>`
    overflow: hidden;
    padding: 0;
    background-color: ${(props : IButtonProps) => {
        if (props.$outlined || props.$plain) {
            return "transparent";
        }
        if (props.disabled) {
            return disabledButtonColor;
        }
        if (props.$color) {
            return props.$color;
        }
        return defaultButtonColor;
      }
    };

    &:hover, &:focus {
        opacity: 0.7;
        background-color: ${(props: IButtonProps) => {
            if (props.$outlined || props.$plain) {
                return "rgba(0,0,0,0.1)";
            }
            return "auto";
        }};
    }
    cursor: pointer;
    border-radius: 4px;
    border: ${(props: IButtonProps) => {
        if (props.$outlined && props.disabled) {
            return "1px solid " + disabledButtonColor;
        }
        if (props.$outlined && props.$color) {
            return "1px solid " + props.$color;
        }
        if (props.$outlined) {
            return "1px solid " + defaultButtonColor;
        }
        return "0";
    }};
    color: ${(props: IButtonProps) => {
        let calculatedTextColor = "white";
        if ((props.$outlined || props.$plain) && props.disabled) {
            return disabledButtonColor;
        }
        if (props.$outlined || props.$plain) {
            calculatedTextColor = defaultButtonColor;
        }
        if ((props.$outlined || props.$plain) && props.$color) {
            calculatedTextColor = props.$color;
        }
        return calculatedTextColor;
    }};
    line-height: 36px;
    height: 36px;
    flex-direction: row;
    pointer-events: ${(props: IButtonProps) => props.disabled || props.$loading ? "none" : "all"};
    flex: 0 0 auto;
    margin: ${(props: IButtonProps) => props.margin ? props.margin : ""};
    transition: opacity .2s cubic-bezier(.35,0,.25,1),background-color .2s cubic-bezier(.35,0,.25,1);
`;

const ButtonInner = styled.div<{ $loading: boolean | undefined }>`
    padding: ${ props => props.$loading ? "3px 16px" : "0px 16px"};
    user-select: none;
    text-align: center;
`;

interface IProps {
    onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    children?: React.ReactNode,
    color?: string,
    rippleColor?: string,
    plain?: boolean,
    outlined?: boolean,
    disabled?: boolean,
    margin?: string,
    submit?: boolean,
    loading?: boolean,
    refObject?: React.MutableRefObject<any>,
    style?: React.CSSProperties
};

const Button = (props: IProps) => {
    const { onClick, children, rippleColor, outlined, color, plain, loading, refObject } = props;

    function localOnClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        if (onClick) {
            onClick(e);
        }
    }

    let calculatedRippleColor;

    if (plain || outlined) {
        calculatedRippleColor = "#187fba";
    }
    if (plain && color) {
        calculatedRippleColor = color;
    }
    if (outlined && color) {
        calculatedRippleColor = color;
    }
    if (rippleColor) {
        calculatedRippleColor = rippleColor;
    }

    return <ButtonContainer
            onClick={localOnClick}
            $outlined={outlined}
            $plain={plain}
            $color={color}
            disabled={props.disabled}
            $loading={loading}
            margin={props.margin}
            style={props.style}
            className="Button"
            ref={refObject}>
        <Ripple color={calculatedRippleColor}>
            <ButtonInner $loading={loading}>
                {!loading && children}
                {loading && <LoadingIndicator type="Radial" color="white" show={true} style={{ height: "20px", marginTop: "8px" }} />}
            </ButtonInner>
        </Ripple>
    </ButtonContainer>;
};

export default Button;
