import Chart from 'react-apexcharts';
import IQveraInformation from 'src/ServerEntities/IQveraInformation';
import styled from 'styled-components';

const ServerInformationContainer = styled.div`
    margin-top: 12px;
    display: flex;
    padding: 6px 12px 12px;
    flex-direction: column;
    background: #1c3e5a;
    min-width: 250px;
    & h3 {
        background-color: #246896;
        margin: 0;
        flex: 0 0 auto;
        padding: 12px 12px 6px;
        @media (min-width: 1279px) {
            border-radius: 16px 16px 0 0;
        }
        font-weight: 500;
        font-size: 1.2rem;
    }
    & table {
        flex: 1 1 auto;
        text-align: left;
    }
    @media (min-width: 1279px) {
        flex: 1 1 auto;
        border-radius: 16px;
    }
    @media (max-width: 1279px) {
        width: 100%;
        margin-bottom: 4px;
    }
`;

const Value = styled.div`
    margin-right: 10px;
`;

const ChannelContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const StatsContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
`;

const StatContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
    padding: 0px 10px 0px 0px;
    flex: 1 1 auto;
`;

const Label = styled.div`
    padding: 2px 8px 2px 0;
    display:flex;
    flex-direction:column;
    flex: 1 1 auto;
    overflow: hidden;
`;


interface IProps {
    qveraInformation: IQveraInformation[]
};

const options = {
    chart: {
        height: 200,
        width: 500,
        toolbar: {
            show: false
        },
    },
    colors: ['#a9c436', '#e296eb'],
    xaxis: {
        categories: ['Inbound', 'Outbound', 'Processing', 'Error'],
        labels: {
            show: false
        },
        axisTicks: {
            show: false
        },
        axisBorder: {
            show: false,
        },
    },
    yaxis: {
        labels: {
            show: true,
            style: {
                colors: ["#FFFFFF"],
                fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
                fontSize: '16px'
            }
        }
    },
    plotOptions: {
        bar: {
            borderRadius: 4,
            horizontal: true
        },
    },
    dataLabels: {
        enabled: true,

    },
    grid: {
        show: false
    },
    markers: {
        show: true,
        colors: ['#a9c436']
    },
    tooltip: {
        enabled: false
    }
};

const QveraInformation = (props: IProps) => {
    const { qveraInformation } = props;

    return <ServerInformationContainer>
        {qveraInformation.map((qveraInformation, index) => <ChannelContainer key={`console-error-${index}`}>
            <StatsContainer>
                <StatContainer><Label>Name:</Label><Value>{qveraInformation.channelName}</Value></StatContainer>
                <StatContainer><Label>State:</Label><Value>{qveraInformation.stateDescription}</Value></StatContainer>
                <StatContainer><Label>Stopped nodes:</Label><Value>{qveraInformation.stoppedNodes.join(', ')}</Value></StatContainer>
                <StatContainer><Label>Received:</Label><Value>{qveraInformation.receivedCount}</Value></StatContainer>
                <StatContainer><Label>Completed:</Label><Value>{qveraInformation.completedCount}</Value></StatContainer>
                <StatContainer><Label>Zone:</Label><Value>{qveraInformation.zoneName}</Value></StatContainer>
            </StatsContainer>
            <div>
                <Chart options={options} type="bar" width={300} series={[{
                    data: [qveraInformation.inboundQueue, qveraInformation.outboundQueue, qveraInformation.processingQueue, qveraInformation.errorQueue]
                }]} />
            </div>
        </ChannelContainer>
        )}
    </ServerInformationContainer >;
};

export default QveraInformation;
