import axios, { AxiosError, AxiosResponse } from "axios";

import { getAPIUrl } from 'src/config';
import IErrorDetails from "src/ServerEntities/IErrorDetails";
import IRole from "src/ServerEntities/IRole";
import IRoleSite from "src/ServerEntities/IRoleSite";
import IUser from 'src/ServerEntities/IUser';
import { NETWORK_ERROR } from 'src/UsefulFunctions/createErrorMessage';

const handleError = (error: AxiosError<IErrorDetails>, failureCallback: (errorMessage: string) => void) => {
    let errorMessage = "Unknown error";
    if (error.response) {
        errorMessage = error.response.data.message
        if (error.response.status === 401 && error.response.data.message === undefined) {
            errorMessage = "Unauthorised to perform this request."
        }
    }
    if (error.message === "Network Error") {
        errorMessage = NETWORK_ERROR;
    }
    failureCallback(errorMessage as string);
};

export const disableRole = (roleId: string, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.put(
        `${getAPIUrl()}roles/${roleId}/disable`,
        {},
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<any>) => {
            successCallback();
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const enableRole = (roleId: string, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.put(
        `${getAPIUrl()}roles/${roleId}/enable`,
        {},
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<any>) => {
            successCallback();
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const getTopHundredUsers = (roleId: string, webToken: string, successCallback: (users: IUser[]) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(
        `${getAPIUrl()}roles/${roleId}/users`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<IUser[]>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const searchForUsers = (roleId: string, searchTerm: string, webToken: string, successCallback: (users: IUser[]) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(
        `${getAPIUrl()}roles/${roleId}/users/${searchTerm}`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<IUser[]>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const getTopHundredUsersToAdd = (roleId: string, webToken: string, successCallback: (users: IUser[]) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(
        `${getAPIUrl()}roles/${roleId}/users/available`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<IUser[]>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const searchForUsersToAdd = (roleId: string, searchTerm: string, webToken: string, successCallback: (users: IUser[]) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(
        `${getAPIUrl()}roles/${roleId}/users/available/${searchTerm}`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<IUser[]>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const addUsersToRole = (users: IUser[], roleId: string, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    users.forEach((user: IUser) => {
        axios.put(
            `${getAPIUrl()}users/${user.id}/${roleId}/enable`,
            {},
            { headers: { Authorization: `Bearer ${webToken}` } }
        )
            .then((response: AxiosResponse<any>) => {
                successCallback();
            })
            .catch((error: AxiosError<IErrorDetails>) => {
                handleError(error, failureCallback);
            });
    })
};

export const createRole = (id: string, webToken: string, role: IRole, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.post(
        `${getAPIUrl()}roles/${id}/create`,
        role,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<any>) => {
            successCallback();
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const getApplications = (webToken: string, successCallback: (applications: string[]) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(`${getAPIUrl()}roles/applications`, { headers: { Authorization: `Bearer ${webToken}` } })
        .then((response: AxiosResponse<string[]>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};


export const getTopHundredUsersToAssign = (webToken: string, successCallback: (users: IUser[]) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(
        `${getAPIUrl()}roles/users`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<IUser[]>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const searchForUsersToAssign = (searchTerm: string, webToken: string, successCallback: (users: IUser[]) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(
        `${getAPIUrl()}roles/users/${searchTerm}`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<IUser[]>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const assignUserToAlert = (alertId: number, username: string, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.post(
        `${getAPIUrl()}ram/alert/${alertId}/assign/${username}`,
        {},
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<any>) => {
            successCallback();
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const searchForSites = (roleId: string, searchTerm: string, webToken: string, successCallback: (users: IRoleSite[]) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(
        `${getAPIUrl()}roles/${roleId}/sites/${searchTerm}`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<IRoleSite[]>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const getTopHundredSites = (roleId: string, webToken: string, successCallback: (users: IRoleSite[]) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(
        `${getAPIUrl()}roles/${roleId}/sites`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<IRoleSite[]>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const getTopHundredSitesToAdd = (roleId: string, webToken: string, successCallback: (users: IRoleSite[]) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(
        `${getAPIUrl()}roles/${roleId}/sites/available`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<IRoleSite[]>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const removeRoleFromSite = (roleId: string, siteId: string, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.put(
        `${getAPIUrl()}roles/${roleId}/sites/remove/${siteId}`,
        {},
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<any>) => {
            successCallback();
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
}

export const addSitesToRole = (sites: IRoleSite[], roleId: string, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    sites.forEach((site: IRoleSite) => {
        axios.put(
            `${getAPIUrl()}roles/${roleId}/sites/add/${site.id}`,
            {},
            { headers: { Authorization: `Bearer ${webToken}` } }
        )
            .then((response: AxiosResponse<any>) => {
                successCallback();
            })
            .catch((error: AxiosError<IErrorDetails>) => {
                handleError(error, failureCallback);
            });
    })
};

export const searchForSitesToAdd = (roleId: string, searchTerm: string, webToken: string, successCallback: (users: IRoleSite[]) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(
        `${getAPIUrl()}roles/${roleId}/sites/available/${searchTerm}`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<IRoleSite[]>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const setRoleCanEdit = (roleId: string, canEdit: boolean, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.post(
        `${getAPIUrl()}roles/${roleId}/edit/${canEdit}`,
        {},
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<any>) => {
            successCallback();
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};