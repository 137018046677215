import IAlert from "src/ServerEntities/IAlert";
import IServerAlerts from "src/ServerEntities/IServerAlerts";

const DEFAULT_PRIORITY = 100;
const DEFAULT_TIMESTAMP = 0;

const sortAlertsByRecentness = (alertA: IAlert, alertB: IAlert) => {
  return Math.max(alertB.lastUpdate, alertB.timestamp) - Math.max(alertA.lastUpdate, alertA.timestamp)
};

const sortAlerts = (alertA: IAlert, alertB: IAlert) => {
  return Math.max(alertA.lastUpdate, alertA.timestamp) - Math.max(alertB.lastUpdate, alertB.timestamp)
};

const sortAlertsByPriority = (alertA: IAlert, alertB: IAlert) => {
  const alertBpriority = alertB.priority !== 0 ? alertB.priority : DEFAULT_PRIORITY;
  const alertApriority = alertA.priority !== 0 ? alertA.priority : DEFAULT_PRIORITY;
  return Math.max(alertApriority) - Math.max(alertBpriority)
};

export const sortByRecentness = (serverAlerts: IServerAlerts) => {
  serverAlerts.alerts.sort(sortAlertsByRecentness);
};

export const sortByOldness = (serverAlerts: IServerAlerts) => {
  serverAlerts.alerts.sort(sortAlerts);
};


export const sortBySelection = (sortByDate: boolean) => (serverAlerts: IServerAlerts) => {
  if (sortByDate) {
    serverAlerts.alerts.sort(sortAlertsByRecentness);
  } else {
    serverAlerts.alerts.sort(sortAlertsByPriority);
  }
};

export const sortServerBySelection = (sortByDate: boolean) => (serverA: IServerAlerts, serverB: IServerAlerts) => {
  if (sortByDate) {
    const serverBtimestamp = serverB.alerts.length > 0 ? serverB.alerts[0].timestamp : DEFAULT_TIMESTAMP;
    const serverAtimestamp = serverA.alerts.length > 0 ? serverA.alerts[0].timestamp : DEFAULT_TIMESTAMP;

    return Math.max(serverBtimestamp) - Math.max(serverAtimestamp)
  } else {
    let serverBpriority = serverB.alerts.length > 0 ? serverB.alerts[0].priority : DEFAULT_PRIORITY;
    let serverApriority = serverA.alerts.length > 0 ? serverA.alerts[0].priority : DEFAULT_PRIORITY;

    serverBpriority = serverBpriority !== 0 ? serverBpriority : DEFAULT_PRIORITY;
    serverApriority = serverApriority !== 0 ? serverApriority : DEFAULT_PRIORITY;

    return Math.max(serverBpriority) - Math.max(serverApriority);
  }

};

export const filterAndSortUserAlerts = (servers: IServerAlerts[], loggedInUser: string, sortByDate: boolean): IServerAlerts[] => {
  const usersIssues = servers.map(server => {
    const filteredAlerts = server.alerts.filter(alert => alert.username === loggedInUser && alert.username !== null);
    return { ...server, alerts: filteredAlerts };
  }).filter(server => server.alerts.length > 0);

  usersIssues.forEach(sortBySelection(sortByDate))

  return usersIssues;
};

export const filterAndSortUserAlertsBy = (servers: IServerAlerts[], loggedInUser: string, sortByRecent: boolean): IServerAlerts[] => {
  const usersIssues = servers.map(server => {
    const filteredAlerts = server.alerts.filter(alert => alert.username === loggedInUser && alert.username !== null);
    return { ...server, alerts: filteredAlerts };
  }).filter(server => server.alerts.length > 0);

  if (sortByRecent) {
    usersIssues.forEach(sortByRecentness);
  }
  else {
    usersIssues.forEach(sortByOldness);
  }

  return usersIssues;
};
