import React from 'react';
import styled from "styled-components";

import Chart from 'react-apexcharts';

const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 200px;
    min-width: 150px;
    justify-content: center;
    align-items: center;
    @media (max-width: 1279px) {
        min-width: 100px;
    }
`;

interface IGraphProps {
    data: number[];
    labels: string[];
};

const DashboardGraph = (props: IGraphProps) => {
    const windowWidth = React.useRef(window.innerWidth);

    const toPercent = (value: number) => {
        const sum = props.data.reduce(function (accumVariable, curValue) {
            return accumVariable + curValue
        }, 0);
        return (value / sum) * 100;
    };

    const options = {
        colors: ["#42a83f", "#f99600", "#ff2121", "#6902de", "#9e9e9e"],
        plotOptions: {
            pie: {
                customScale: props.data.length > 2 ? 1 : 0.9
            }
        },
        stroke: {
            show: false,
        },
        legend: {
            show: false
        },
        dataLabels: {
            enabled: false
        },
        tooltip: {
            y: {
                formatter: (value: number) => {
                    return `${value.toLocaleString("en-GB", { maximumFractionDigits: 2, minimumFractionDigits: 2 })} %`;
                }
            }
        },
        labels: props.labels
    };

    const series = props.data.map((value: number) => { return toPercent(value); })

    return <ColumnContainer>
        <Chart
            type="pie"
            options={options}
            series={series}
            width={`${windowWidth.current > 1280 ? "260" : "120"}`}
        />
    </ColumnContainer>
}

export default DashboardGraph;