import * as React from "react";
import IUser from 'src/ServerEntities/IUser';

import useManagerAPI from 'src/CustomHooks/useManagerAPI';

import { SessionContext } from 'src/Views/SessionContext';

import IRole from "src/ServerEntities/IRole";

import Button from 'src/SharedComponents/Button';
import Dialog from 'src/SharedComponents/Dialog/Dialog';
import DialogActions from 'src/SharedComponents/Dialog/DialogActions';
import Label from 'src/SharedComponents/Label';
import ListItem from 'src/SharedComponents/List/ListItem';
import SectionList, { ISectionListData, ISectionListRenderItemInfo } from 'src/SharedComponents/List/SectionList';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import ManagerTitleBar from 'src/Views/Manager/ManagerTitleBar';

import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { addRolesToUser } from "./userManagementService";

const EmptyList = () => <div style={{ color: "#555", padding: "24px" }}>There are no more roles to add to this user.</div>

const getSections = (roles: IRole[]): Array<ISectionListData<{ title: string, description: string }>> => [
    { data: roles.map((role: IRole) => ({ title: role.id, description: role.description })) }
]

const filterAlreadyExistingRoles = (userRoles?: IRole[]) => (role: IRole) => {
    let rolePassesFilter = true;
    if (userRoles) {
        userRoles.forEach((userRole: IRole) => {
            if (userRole.id === role.id) {
                rolePassesFilter = false;
            }
        });
    }
    return rolePassesFilter;
}

interface IProps {
    user: IUser,
    usersRoles: IRole[]
    show: boolean,
    onClose: () => void,
    onConfirmRoleAdd: () => void
}

const roleMatchesRole = (roleToMatch: string) => (role: IRole) => roleToMatch === role.id;

const AddRoleToUserDialog = (props: IProps) => {
    const { webToken } = React.useContext(SessionContext).state;
    const { loading, data } = useManagerAPI("roles/", "GET", {}, webToken);
    const [rolesToAdd, setRolesToAdd] = React.useState([] as IRole[]);
    const roles: IRole[] = data ? (data as IRole[]).filter(filterAlreadyExistingRoles(props.usersRoles)) : [];

    const closeDialog = () => {
        setRolesToAdd([]);
        props.onClose();
    }

    const toggleRoleToAdd = (role: IRole) => () => {
        const rolesToAddCopy = [...rolesToAdd];
        let roleIndexInArray = -1;
        rolesToAddCopy.forEach((roleToAdd: IRole, index: number) => {
            if (role.id === roleToAdd.id) {
                roleIndexInArray = index;
            }
        })
        if (roleIndexInArray === -1) {
            rolesToAddCopy.push(role);
        } else {
            rolesToAddCopy.splice(roleIndexInArray, 1);
        }
        setRolesToAdd(rolesToAddCopy);
    }

    const addRolesConfirm = () => {
        addRolesToUser(
            rolesToAdd,
            props.user.id,
            webToken,
            () => {
                setRolesToAdd([]);
                props.onConfirmRoleAdd();
            },
            (error) => {
                return ""
            })
    }

    const renderRole = (info: ISectionListRenderItemInfo<{ title: string, description: string }>) =>
        <ListItem onClick={toggleRoleToAdd(roles[info.index])} key={`item${info.index}`}>
            <Label>
                <h2>{info.item.title}</h2>
                <h3>{info.item.description}</h3>
            </Label>
            {rolesToAdd.findIndex(roleMatchesRole(info.item.title)) !== -1 && <FontAwesomeIcon icon={faCheck} style={{ color: "#187fba" }} />}
        </ListItem>;

    return <Dialog darkBackground={true} open={props.show} onClose={closeDialog} style={{ width: "650px", height: "550px" }}>
        <ManagerTitleBar viewName="Add role(s)" viewDescription="Select roles below to add to this user." />
        <LoadingIndicator show={loading} type="Linear" />
        {roles.length === 0 && <EmptyList />}
        <SectionList sections={getSections(roles)} renderItem={renderRole} />
        <DialogActions>
            <Button outlined={true} onClick={closeDialog}>Cancel</Button>
            <Button onClick={addRolesConfirm} margin="0px 16px 0px 16px" disabled={rolesToAdd.length === 0}>Confirm</Button>
        </DialogActions>
    </Dialog>;
}

export default AddRoleToUserDialog;
