import axios, { AxiosError, AxiosResponse } from "axios";

import { getAPIUrl } from 'src/config';
import IErrorDetails from "src/ServerEntities/IErrorDetails";
import IRoleSite from "src/ServerEntities/IRoleSite";
import IServer from "src/ServerEntities/IServer";
import ISiteType from "src/ServerEntities/ISiteType";
import { NETWORK_ERROR } from 'src/UsefulFunctions/createErrorMessage';

const handleError = (error: AxiosError<IErrorDetails>, failureCallback: (errorMessage: string) => void) => {
    let errorMessage = "Unknown error";
    if (error.response) {
        errorMessage = error.response.data.message
        if (error.response.status === 403) {
            errorMessage = error.response.statusText;
        }
        if (error.response.status === 401 && error.response.data.message === undefined) {
            errorMessage = "Unauthorised to perform this request."
        }
    }
    if (error.message === "Network Error") {
        errorMessage = NETWORK_ERROR;
    }
    failureCallback(errorMessage as string);
};

export const searchForSites = (searchTerm: string, webToken: string, successCallback: (sites: IRoleSite[]) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(
        `${getAPIUrl()}sites/lookup/${searchTerm}`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<IRoleSite[]>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const getSiteTypes = (webToken: string, successCallback: (types: ISiteType[]) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(
        `${getAPIUrl()}sites/types/`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<ISiteType[]>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const saveSiteType = (siteType: ISiteType, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.post(
        `${getAPIUrl()}sites/types/`,
        siteType,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then(() => {
            successCallback();
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const updateSiteTypeDescription = (type: string, description: string, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.put(
        `${getAPIUrl()}sites/types/${type}/description`,
        description,
        { headers: { Authorization: `Bearer ${webToken}`, 'Content-Type': 'text/plain' } }
    )
        .then((response: AxiosResponse) => {
            successCallback();
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const deleteSiteType = (type: string, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.delete(
        `${getAPIUrl()}sites/types/${type}`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse) => {
            successCallback();
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const updateSiteType = (site: string, type: string, webToken: string, successCallback: (type: string) => void, failureCallback: (errorMessage: string) => void) => {
    axios.put(
        `${getAPIUrl()}sites/${site}/type`,
        type,
        { headers: { Authorization: `Bearer ${webToken}`, 'Content-Type': 'text/plain' } }
    )
        .then((response: AxiosResponse<string>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const getMutedServers = (webToken: string, successCallback: (servers: IServer[]) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(
        `${getAPIUrl()}ram/servers/muted`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<IServer[]>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const unmuteServer = (serverId: string, webToken: string, successCallback: (servers: IServer[]) => void, failureCallback: (errorMessage: string) => void) => {
    axios.put(
        `${getAPIUrl()}ram/servers/${serverId}/unmute`,
        {},
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<IServer[]>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const updateAlias = (site: string, alias: string, webToken: string, successCallback: (type: string) => void, failureCallback: (errorMessage: string) => void) => {
    axios.post(
        `${getAPIUrl()}sites/${site}/alias`,
        alias,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<string>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};
