import axios, { AxiosError, AxiosResponse } from "axios";

import { getAPIUrl } from 'src/config';
import IErrorDetails from "src/ServerEntities/IErrorDetails";
import IKubernetesConfiguration from "src/ServerEntities/IKubernetesConfiguration";
import { NETWORK_ERROR } from 'src/UsefulFunctions/createErrorMessage';

const handleError = (error: AxiosError<IErrorDetails>, failureCallback: (errorMessage: string) => void) => {
    let errorMessage = "Unknown error";
    if (error.response) {
        errorMessage = error.response.data.message
        if (error.response.status === 401 && error.response.data.message === undefined) {
            errorMessage = "Unauthorised to perform this request."
        }
    }
    if (error.message === "Network Error") {
        errorMessage = NETWORK_ERROR;
    }
    failureCallback(errorMessage as string);
};

export const setKubernetesConfiguration = (configuration: IKubernetesConfiguration, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.post(
        `${getAPIUrl()}ram/kubernetes/configuration`,
        configuration,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then(() => {
            successCallback();
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const getKubernetesConfiguration = (webToken: string, successCallback: (configuration: IKubernetesConfiguration) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(
        `${getAPIUrl()}ram/kubernetes/configuration`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<IKubernetesConfiguration>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};
