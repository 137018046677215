import * as React from 'react';
import styled from 'styled-components';

import Button from 'src/SharedComponents/Button';
import Card from 'src/SharedComponents/Card';
import ErrorBox from 'src/SharedComponents/ErrorBox';
import IconButton from 'src/SharedComponents/IconButton';
import Label from 'src/SharedComponents/Label';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';

import createErrorMessage from 'src/UsefulFunctions/createErrorMessage';
import * as ClientManagementService from "./clientManagementService";

import IClient from 'src/ServerEntities/IClient';

import useEffectOnSome from 'src/CustomHooks/useEffectOnSome';
import AddSiteToClientDialog from './AddSiteToClientDialog';
import AddRoleToClientDialog from './AddRoleToClientDialog';


const TitleBar = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px 24px;
`;

const Title = styled.span`
    font-size: 18px;
    letter-spacing: 0.25px;
    line-height: 24px;
    font-weight: 600;
`;


const UserHeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding: 12px 24px;
    justify-content: space-between;
`;

const Grid = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const NoUsersText = styled.div`
    display: flex;
    flex-direction: row;
    color: #555;
    justify-content: center;
    align-items: center;
    padding: 24px;
`;

interface IProps {
    selectedClient: IClient,
    webToken: string,
    currentUser: string,
    setErrorMessage: (errorMessage: string) => void
};

const ClientSiteRoleDetails = (props: IProps) => {
    const { selectedClient, webToken, setErrorMessage } = props
    const [sites, setSites] = React.useState([] as string[]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [showAddSiteDialog, setShowAddSiteDialog] = React.useState(false);
    const [roles, setRoles] = React.useState([] as string[]);
    const [showAddRoleDialog, setShowAddRoleDialog] = React.useState(false);

    const openAddSiteDialog = () => {
        setShowAddSiteDialog(true);
    };

    const closeAddSiteDialog = () => {
        setShowAddSiteDialog(false);
    };

    const closeAddSiteDialogAndRefresh = () => {
        setShowAddSiteDialog(false);
        refreshSites();
    };

    const refreshSites = () => {
        ClientManagementService.getSites(selectedClient.id, webToken, (sitesFromServer: string[]) => {
            setLoading(false);
            setSites(sitesFromServer);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    };

    const openAddRoleDialog = () => {
        setShowAddRoleDialog(true);
    };

    const closeAddRoleDialog = () => {
        setShowAddRoleDialog(false);
    };

    const closeAddRoleDialogAndRefresh = () => {
        setShowAddRoleDialog(false);
        refreshRoles();
    };
    const removeSiteFromClient = (site: string) => () => {
        setErrorMessage("");
        ClientManagementService.removeClientFromSite(
            selectedClient.id,
            site,
            webToken,
            refreshSites,
            (serverError: string) => {
                setErrorMessage(createErrorMessage("removing a Client from a xwuser", serverError));
            });
    };

    const refreshRoles = () => {
        ClientManagementService.getRoles(selectedClient.id, webToken, (rolesFromServer: string[]) => {
            setLoading(false);
            setRoles(rolesFromServer);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    };

    useEffectOnSome(() => {
        refreshSites();
        refreshRoles();
    }, [], []);

    const removeRoleFromClient = (role: string) => () => {
        setErrorMessage("");
        ClientManagementService.removeRoleFromClient(
            selectedClient.id,
            role,
            webToken,
            refreshRoles,
            (serverError: string) => {
                setErrorMessage(createErrorMessage("removing a Client from a xwuser", serverError));
            });
    };

    return <div style={{ overflowY: "auto" }}>
        <AddSiteToClientDialog
            show={showAddSiteDialog}
            onClose={closeAddSiteDialog}
            onConfirmSiteAdd={closeAddSiteDialogAndRefresh}
            client={selectedClient} />
        <AddRoleToClientDialog
            show={showAddRoleDialog}
            onClose={closeAddRoleDialog}
            onConfirmRoleAdd={closeAddRoleDialogAndRefresh}
            client={selectedClient} />
        <LoadingIndicator type="Linear" show={loading} />
        {error && <ErrorBox>{error}</ErrorBox>}
        <TitleBar>
            <Title>Sites</Title>
        </TitleBar>
        <UserHeaderContainer>
            <Button onClick={openAddSiteDialog}>Add Site(s)</Button>
        </UserHeaderContainer>
        <Grid style={{ padding: "0px 12px" }}>
            {sites.map((site) => <Card key={site} style={{ width: "320px", margin: "12px", padding: "2px 2px 0px 0px" }}>
                <Label style={{ padding: "6px 8px 6px 16px", wordWrap: "normal" }}>
                    <h2>{site}</h2>
                </Label>
                <IconButton onClick={removeSiteFromClient(site)}>×</IconButton>
            </Card>)}
            {sites.length === 0 && <NoUsersText>There are no sites currently associated with this Client.</NoUsersText>}
        </Grid>

        <TitleBar>
            <Title>Roles</Title>
        </TitleBar>
        <UserHeaderContainer>
            <Button onClick={openAddRoleDialog}>Add Role(s)</Button>
        </UserHeaderContainer>
        <Grid style={{ padding: "0px 12px" }}>
            {roles.map((role) => <Card key={role} style={{ width: "320px", margin: "12px", padding: "2px 2px 0px 0px" }}>
                <Label style={{ padding: "6px 8px 6px 16px", wordWrap: "normal" }}>
                    <h2>{role}</h2>
                </Label>
                <IconButton onClick={removeRoleFromClient(role)}>×</IconButton>
            </Card>)}
            {roles.length === 0 && <NoUsersText>There are no roles currently associated with this Client.</NoUsersText>}
        </Grid>
    </div>;
};

export default ClientSiteRoleDetails;
