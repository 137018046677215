import React from 'react';
import Timeago from 'react-timeago';
import styled from 'styled-components';

import ISupportIssue from 'src/ServerEntities/ISupportIssue';
import IUser from 'src/ServerEntities/IUser';

import ErrorBox from 'src/SharedComponents/ErrorBox';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import { SessionContext } from 'src/Views/SessionContext';
import AlertNotes from './AlertNotes';
import AssignUserDialog from './AssignUserDialog';
import UsernameText from './UsernameText';

import { addIssueNote, assignIssue, getIssueNotes, setIssueClosed } from '../ramService';

import createErrorMessage from 'src/UsefulFunctions/createErrorMessage';
import { SIZE } from '../AlertItem';

const OuterContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    margin-top: 8px;
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction row;
    flex: 1 0 auto;
    justify-content: space-between;
`;

const AlertContainer = styled.article`
    background-color: #1c3e5a;
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    flex: 1 1 auto;
    @media (min-width: 1279px) {
        border-radius: 16px;
        margin-bottom: 20px;
    }
    & > div {   
        padding: 4px;
        @media (min-width: 1279px) {
            padding: 12px;
        }
    }
    & h3 {
        margin: 0;
        font-weight: 500;
        font-size: 1.2rem;
    }
    & dt {
        flex: 0 0 30%;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (max-width: 1279px) {
            flex: 0 0 100%;
        }
    }
    & dd {
        flex: 0 0 calc(70% - 5px);
        overflow: hidden;
        text-overflow: ellipsis;
        overflow-wrap: break-word;
        max-width: 520px;
        @media (max-width: 1279px) {
            flex: 0 0 calc(100% - 10px);
            margin: 0 0 6px 10px;
        }
    }
`;

const AlertHeader = styled.header`
    flex: 1 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    background: #246896;
    padding: 6px 12px;
    border-radius: 16px 16px 0 0;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 1279px) {
        flex-direction: column;
        align-items: flex-start;
        border-radius: 0;
        padding: 4px;
    }
`;

const AlertButtons = styled.div`
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    align-items: center;
    @media (max-width: 1279px) {
        flex-direction: column-reverse;
        align-items: flex-start;
    }
`;

const Button = styled.button`
    border-radius: 16px;
    background: #1c3e5a;
    color: #ffffff;
    padding: 6px 16px;
    margin: 0 8px;
    font-size: 1rem;
    cursor: pointer;
    @media (max-width: 1279px) {
        margin: 3px 0;
    }
`;

const AlertBody = styled.div`
    flex: 1 1 auto;
    & h3 {
        margin: 0 0 12px;
        font-size: 1.2rem;
        font-weight: 500;
    }
    @media (max-width: 1279px) {
        width: 100%;
    }
    @media (min-width: 1279px) {
        margin: 0 12px;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
`;

interface IProps {
    issue: ISupportIssue,
    server: string,
    closed: boolean,
    onClose: () => void,
    refresh: number,
    setRefresh: (count: number) => void,
    size: SIZE,
    showCurrentIssues: boolean
};

const getUsername = (issue: ISupportIssue, minimised: boolean, closed: boolean) => {
    if (minimised) {
        return issue.username ? issue.username.substring(0, 3) : "UN";
    }
    if (closed) {
        return `Closed - ${issue.username}`;
    }
    return issue.username || "Unassigned";
};

const SupportIssueDetail = (props: IProps) => {
    const { closed, issue, onClose, refresh, setRefresh, size } = props;
    const { state } = React.useContext(SessionContext);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [userAssignment, setUserAssignment] = React.useState(false);

    const markAsClosed = () => {
        setIssueClosed(issue.id, state.webToken, () => {
            setLoading(false)
            setError("");
            onClose();
            setRefresh(refresh + 1);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    };

    const showUserAssignment = () => {
        setUserAssignment(true);
    };

    const closeUserAssignment = () => {
        setUserAssignment(false);
    };

    const assignUser = (user: IUser) => {
        issue.username = user.id;
        setRefresh(refresh + 1);
    };

    return <OuterContainer>
        <AssignUserDialog assignUser={assignIssue} show={userAssignment} onClose={closeUserAssignment} itemId={issue.id} onConfirmUserAdd={assignUser} />
        <LoadingIndicator type="Linear" show={loading} />
        {error && <ErrorBox>{createErrorMessage("loading RAM alerts", error)}</ErrorBox>}
        <ContentContainer>
            <AlertContainer>
                <AlertHeader>
                    <h3>Issue Details</h3>
                    <AlertButtons>
                        {!closed && issue.editable && <Button onClick={showUserAssignment}>{issue.username ? "Reassign" : "Assign"}</Button>}
                        {!closed && issue.editable && <Button onClick={markAsClosed}>Mark as closed</Button>}
                        <UsernameText backgroundColor={closed ? "#6d2f35" : (issue.userColour || '#818181')}>
                            {getUsername(issue, size !== "large", closed)}
                        </UsernameText>
                    </AlertButtons>
                </AlertHeader>
                <Container>
                    <AlertBody>
                        <h3>{issue.issue}</h3>
                        <dl>
                            <dt>Jira ticket</dt><dd>{issue.jiraTicket}</dd>
                            <dt>Date</dt><dd><Timeago date={issue.date} /></dd>
                        </dl>
                        <AlertNotes
                            addNote={addIssueNote}
                            getNotes={getIssueNotes}
                            itemId={issue.id}
                        />
                    </AlertBody>
                </Container>
            </AlertContainer>
        </ContentContainer>
    </OuterContainer>;
};

export default SupportIssueDetail;
