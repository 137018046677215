import React from "react";
import useEffectOnSome from "src/CustomHooks/useEffectOnSome";
import { ITimeException } from "src/ServerEntities/IAlertExceptions";
import IHeartbeatExceptions from "src/ServerEntities/IHeartbeatExceptions";
import INotificationTimeout from "src/ServerEntities/INotificationTimeout";
import Button from "src/SharedComponents/Button";
import Checkbox from "src/SharedComponents/Checkbox";
import Dialog from "src/SharedComponents/Dialog/Dialog";
import ErrorText from "src/SharedComponents/ErrorText";
import LoadingIndicator from "src/SharedComponents/LoadingIndicator";
import styled from "styled-components";
import { deleteHeartbeatException, getHeartbeatNotificationExceptions, saveNewHeartbeatException, updateHeartbeatException, updateHeartbeatSilencedOnHolidays } from "../UserManagement/smsNotificationService";
import AlertExceptionRow from "./AlertExceptionRow";
import HeartbeatNotificationRow from "./HeartbeatNotificationRow";

interface IProps {
    notificationOpen: INotificationTimeout,
    setError: (errorMessage: string) => void,
    setNotificationOpen: (notification: INotificationTimeout | null) => void,
    updateItem: (index: number, newTimeout: number) => void,
    webToken: string
};

const Content = styled.div`
    padding: 6px 12px 12px;
`;

const Title = styled.h1`
    background: #187fba;
    color: #ffffff;
    border-radius: 16px 16px 0 0;
    margin: 0;
    padding: 3px 12px;
    font-size: 1.7rem;
    font-weight: 600;
`;

const SubTitle = styled.h2`
    margin: 12px 0 6px;
    font-size: 1.5rem;
    font-weight: 600;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

const DIALOG_STYLE = {
    maxWidth: "1200px",
    width: "1200px"
};

const SavedText = styled.p`
    color: #16B012;
    margin: 0 0 12px 0;
    &:before {
        content: '\u2713';
        margin-right: 4px;
    }
`;

const NotificationRuleDetails = (props: IProps) => {
    const {notificationOpen, setError, setNotificationOpen, updateItem, webToken} = props;
    const [loading, setLoading] = React.useState(false);
    const [saved, setSaved] = React.useState(false);
    const [refresh, setRefresh] = React.useState(0);
    const [exceptionError, setExceptionError] = React.useState("");
    const [notificationExceptions, setNotificationExceptions] = React.useState(null as unknown as IHeartbeatExceptions);
    const [onHolidays, setOnHolidays] = React.useState(true);

    const closeDialog = () => {
        setNotificationOpen(null);
    };

    useEffectOnSome(() => {
        setLoading(true);
        getHeartbeatNotificationExceptions(notificationOpen.site, webToken, (heartbeatExceptions: IHeartbeatExceptions) => {
            setNotificationExceptions(heartbeatExceptions);
            setOnHolidays(!heartbeatExceptions.silencedOnHolidays);
            setError("");
            setLoading(false);
        }, (errorMessage: string) => {
            setError(errorMessage);
            setLoading(false);
        });
    }, [setError], [webToken, refresh, notificationOpen.site]);

    const createNew = () => {
        setLoading(true);
        saveNewHeartbeatException({site: notificationOpen.site}, webToken, (id: number) => {
            setLoading(false);
            setExceptionError("");
            setRefresh(refresh + 1);
        }, (errorMessage: string) => {
            setLoading(false);
            setExceptionError(errorMessage);
        });
    };

    const deleteRow = (id: number) => {
        setLoading(true);
        deleteHeartbeatException(id, webToken, () => {
            setLoading(false);
            setExceptionError("");
            setRefresh(refresh + 1);
        }, (errorMessage: string) => {
            setLoading(false);
            setExceptionError(errorMessage);
        });
    };

    const toggleSilencedOnHolidays = () => {
        setLoading(true);
        updateHeartbeatSilencedOnHolidays(notificationOpen.site, !notificationExceptions.silencedOnHolidays, webToken, () => {
            setLoading(false);
            setExceptionError("");
            setOnHolidays(!onHolidays);
        }, (errorMessage: string) => {
            setLoading(false);
            setExceptionError(errorMessage);
        });
    };

    const saveChanges = (exception: ITimeException) => {
        if (exception.id) {
            setLoading(true);
            setSaved(false);
            updateHeartbeatException(exception.id, exception, webToken, () => {
                setLoading(false);
                setSaved(true);
                setError("");
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
            });
        }
    }

    return <Dialog open={notificationOpen !== null} onClose={closeDialog} darkBackground={true} style={DIALOG_STYLE}>
        <Title>Notification details</Title>
        <Content>
            <table>
                <tbody>
                    {notificationOpen !== null && <HeartbeatNotificationRow key={`rule-row-${notificationOpen.site}`} notificationTimeout={notificationOpen}
                        setNotificationOpen={setNotificationOpen} index={0} updateItem={updateItem} setError={setError} webToken={webToken} /> }
                </tbody>
            </table>
            <div>
                <LoadingIndicator show={loading} type="Linear" />
                <SubTitle>Exceptions</SubTitle>
                <table>
                    <tbody>
                    {notificationExceptions && notificationExceptions.timeExceptions.map((exception, index) => {
                        return <AlertExceptionRow exception={exception} key={`alert-exception-${notificationOpen.site}-${index}`} onSave={saveChanges} onDelete={deleteRow} />;
                    })}
                    </tbody>
                </table>
                {saved && <SavedText>Saved</SavedText>}
                {exceptionError && <ErrorText>{exceptionError}</ErrorText>}
                <Button onClick={createNew}>Add exception</Button>
                <Row>
                    Send notifications during public holidays? <Checkbox checked={onHolidays} onChange={toggleSilencedOnHolidays} />
                </Row>
            </div>
        </Content>
    </Dialog>
};

export default NotificationRuleDetails;
