import * as React from 'react';
import styled from 'styled-components';

import useEffectOnSome from 'src/CustomHooks/useEffectOnSome';
import ISiteType from 'src/ServerEntities/ISiteType';
import ErrorBox from 'src/SharedComponents/ErrorBox';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import TextInput from 'src/SharedComponents/TextInput';
import ViewContainer from 'src/SharedComponents/ViewContainer';
import { SessionContext } from 'src/Views/SessionContext';
import { getSiteTypes, saveSiteType } from './SiteManagementService';
import SiteTypeRow from '../Components/SiteTypeRow';
import ManagerTitleBar from '../ManagerTitleBar';

interface IProps {
    refreshSiteTypes: number,
    setRefreshSiteTypes: (refresh: number) => void,
    tablabel: string
};

const StyledTable = styled.div`
    width: 100%;
    table-layout: fixed; 
    border-collapse: collapse;

    th, td {
        @media(max-width: 375px) {
            font-size: 14px;
        }
    }
`;

const ColumnHeader = styled.th`
    font-size: 1.1rem;
    font-weight: 600;
    text-align: left;
    padding: 8px;
    border: 1px solid #e5e5e5;
`;

const TableItem = styled.td`
    padding: 6px;
`;

const SiteTypesTab = (props: IProps) => {
    const { refreshSiteTypes, setRefreshSiteTypes } = props;
    const { webToken } = React.useContext(SessionContext).state;
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [siteTypes, setSiteTypes] = React.useState([] as unknown as ISiteType[]);
    const [newSite, setNewSite] = React.useState("");

    useEffectOnSome(() => {
        setLoading(true);
        getSiteTypes(webToken, (types) => {
            setLoading(false);
            setError("");
            setSiteTypes(types);
        }, (errorMessage) => {
            setLoading(false);
            setError(errorMessage);
        })
    }, [webToken], [refreshSiteTypes]);

    const saveNewSite = () => {
        if (newSite) {
            setLoading(true);
            saveSiteType({type: newSite, description: ""}, webToken, () => {
                setLoading(false);
                setError("");
                setNewSite("");
                setRefreshSiteTypes(refreshSiteTypes + 1);
            }, (errorMessage) => {
                setLoading(false);
                setError(errorMessage);
            });
        }
    };

    const handleKeyDown = (saveMethod: () => void) => (e: React.KeyboardEvent<HTMLInputElement>) => {
        const key = e.key;
        if (key === "Enter") {
            saveMethod();
        }
    };

    const updateNewSite = (event: React.SyntheticEvent<HTMLInputElement, Event>) => {
        setNewSite(event.currentTarget.value);
    };

    return <ViewContainer style={{ backgroundColor: "#fafafa"}}>
        <ManagerTitleBar viewName="Site types" viewDescription="Here you can set the types of site, for example Ensemble, Rhapsody, IMX-CR." />
        {error && <ErrorBox>{error}</ErrorBox>}
        <ViewContainer style={{padding: "12px"}}>
            <LoadingIndicator type="Linear" show={loading} />
            <StyledTable>
                    <thead>
                        <tr>
                            <ColumnHeader>Type</ColumnHeader>
                            <ColumnHeader>Description</ColumnHeader>
                            <ColumnHeader />
                        </tr>
                    </thead>
                    <tbody>
                        {siteTypes.map((siteType, index) => {
                            return <SiteTypeRow
                                key={`site-type-row-${index}`}
                                siteType={siteType}
                                refresh={refreshSiteTypes}
                                setRefresh={setRefreshSiteTypes}
                                setError={setError}
                                setLoading={setLoading}
                                />;
                        })}
                        <tr>
                            <TableItem>
                                <TextInput placeholder="Enter new site type" autoFocus={true} value={newSite} onBlur={saveNewSite} onChange={updateNewSite} onKeyDown={handleKeyDown(saveNewSite)} />
                            </TableItem>
                        </tr>
                    </tbody>              
            </StyledTable>
        </ViewContainer>
    </ViewContainer>
};

export default SiteTypesTab;
