import { Helmet } from 'react-helmet'

import "./App.css";

import 'ts-polyfill/lib/es2015-core';

import 'ts-polyfill/lib/es2015-collection';
import 'ts-polyfill/lib/es2015-promise';
import 'ts-polyfill/lib/es2016-array-include';
import 'ts-polyfill/lib/es2017-object';
import 'ts-polyfill/lib/es2017-string';
import 'ts-polyfill/lib/es2018-promise';

import styled from "styled-components";
import PageIndex from "./Views/ViewsIndex";

import { library } from '@fortawesome/fontawesome-svg-core'
import { faBell, faTimesCircle, faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { faPlus, faTag, faTimes, faUser } from '@fortawesome/free-solid-svg-icons'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { faHistory } from '@fortawesome/free-solid-svg-icons'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { VIPER_TITLE } from './config';

library.add(faArrowLeft as any);
library.add(faArrowUp as any);
library.add(faCaretDown as any);
library.add(faEllipsisV as any);
library.add(faHistory as any);
library.add(faPlus as any);
library.add(faUser as any);
library.add(faTrash as any);
library.add(faTimesCircle as any);
library.add(faStar as any);
library.add(faTag as any);
library.add(faTrashAlt);
library.add(faTimes);
library.add(faBell);

const AppContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
`;

const TitledApp = () => {
    return <AppContainer className="App">
        <Helmet>
            <title>{VIPER_TITLE}</title>
        </Helmet>
        <PageIndex />
    </AppContainer>
};

export default TitledApp;
