import * as React from 'react';
import styled from 'styled-components';

import TextInput from 'src/SharedComponents/TextInput';
import ISiteType from 'src/ServerEntities/ISiteType';
import { deleteSiteType, updateSiteTypeDescription } from 'src/Views/Manager/UserManagement/SiteManagementService';
import { SessionContext } from 'src/Views/SessionContext';

interface IProps {
    siteType: ISiteType,
    refresh: number,
    setError: (errorMessage: string) => void,
    setLoading: (isLoading: boolean) => void,
    setRefresh: (count: number) => void
};

const TableItem = styled.td`
    padding: 6px;
    border: 1px solid #e5e5e5;

`;

const TextField = styled.p`
    margin: 0;
`;

const Button = styled.a`
    background: #187fba;
    border-radius: 18px;
    height: 24px;
    padding: 4px 16px;
    cursor: pointer;
    color: #ffffff;

    @media (max-width: 600px) {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

`;

const DeleteButton = styled.a`
    background: #cc0000;
    border-radius: 18px;
    height: 24px;
    padding: 4px 16px;
    cursor: pointer;
    color: #ffffff;

    @media (max-width: 600px) {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
`;

const SiteTypeRow = (props: IProps) => {
    const { siteType, refresh, setError, setLoading, setRefresh } = props;
    const [editingDescription, setEditingDescription] = React.useState(false);
    const [newDescription, setNewDescription] = React.useState(siteType.description);
    const { webToken } = React.useContext(SessionContext).state;

    const editDescription = () => {
        setEditingDescription(true);
    };

    const onSaveSuccess = () => {
        setEditingDescription(false);
        setError("");
        setLoading(false);
        setRefresh(refresh + 1);
    };

    const onSaveError = (errorMessage: string) => {
        setEditingDescription(false);
        setError(errorMessage);
        setLoading(false);
    };

    const saveDescriptionChanges = () => {
        if (newDescription !== siteType.description) {
            setLoading(true);
            updateSiteTypeDescription(siteType.type, newDescription, webToken, onSaveSuccess, onSaveError);
        } else {
            setEditingDescription(false);
        }
    };

    const deleteItem = () => {
        deleteSiteType(siteType.type, webToken, onSaveSuccess, onSaveError);
    };

    const handleKeyDown = (saveMethod: () => void) => (e: React.KeyboardEvent<HTMLInputElement>) => {
        const key = e.key;
        if (key === "Enter") {
            saveMethod();
        }
    };

    const updateNewDescription = (event: React.SyntheticEvent<HTMLInputElement, Event>) => {
        setNewDescription(event.currentTarget.value);
    };

    return <tr>
        <TableItem width="25%">
            <TextField>{siteType.type}</TextField>
        </TableItem>
        <TableItem width="60%">
            {editingDescription ?
                <TextInput autoFocus={true} value={newDescription || ""} onBlur={saveDescriptionChanges} onChange={updateNewDescription} onKeyDown={handleKeyDown(saveDescriptionChanges)} /> :
                    siteType.description ?
                        <TextField onClick={editDescription}>{siteType.description}</TextField> :
                        <Button onClick={editDescription}>Add description</Button>
                }
        </TableItem>
        <TableItem style={{ textAlign: "center", width: "15%" }}>
            <DeleteButton onClick={deleteItem}>Delete</DeleteButton>
        </TableItem>
    </tr>;
};

export default SiteTypeRow;
