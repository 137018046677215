import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { CookieSetOptions } from 'universal-cookie';

/**
 * useCookie - React Hook for Cookies based on react-cookie
 * @param {string} key Cookie name
 * @param {Object|string} [initialValue]  Value will be assign if cookie doesn't exist.
 * @returns {Array} Returns cookie value, and the function to update it.
 */
export default function useCookie<T>(key: string, initialValue: T): [T, (value: T, options?: CookieSetOptions) => void] {
    const [cookies, setCookie] = useCookies([key]);

    const [item, setInnerValue] = useState(() => {
        if (cookies[key] !== undefined) {
            return cookies[key];
        }
        return initialValue;
    });

    /**
     * Set value of cookie
     * @param {Object|string} value 
     * @param {CookieSetOptions} [options]
     */
    const setValue = (value: T, options?: CookieSetOptions): void => {
        setInnerValue(value);
        if (!options) {
            options = {};
        }
        if (!options.sameSite) {
            options.sameSite = "strict";
        }
        setCookie(key, value, options);
    };

    return [item, setValue];
}

