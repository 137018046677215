import styled from 'styled-components';

export default styled.a`
    border: 2px solid #ffffff;
    border-radius: 18px;
    height: 24px;
    padding: 4px 16px;
    cursor: pointer;
    &.selected {
        border: 2px solid #396282;
        background-color: #396282;
    }
`;
