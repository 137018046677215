import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import useFocus from 'src/CustomHooks/useFocus';

import ManagerUserMenu from 'src/Views/Manager/ManagerUserMenu';

import { RolesContext } from 'src/Roles/RolesContext';
import { SessionContext } from 'src/Views/SessionContext';

const TopBar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 24px 0px 24px;
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    font-size: 21px;
    line-height: 28px;
    letter-spacing: 0.75px;
    color: #575757;
    align-items: center;
`;

const ViperLogo = styled.img`
    width: 90px;
    margin-right: 8px;
    cursor: pointer;
`;

interface IProps {
    setRoleChangeDialogOpen: (isOpen: boolean) => void
};

const ManagerBanner = (props: IProps) => {
    const roleContext = React.useContext(RolesContext);
    const { dispatch, state } = React.useContext(SessionContext);
    const roleState = roleContext.state;
    const { setRoleChangeDialogOpen } = props;
    const roleDialogRef = React.useRef(null as unknown as HTMLDivElement);
    const focus = useFocus(roleDialogRef);
    const navigate = useNavigate();

    const signOut = () => {
        dispatch({ type: "clearWebToken", payload: undefined });
        roleContext.dispatch({ type: "clearRole" });
    };

    const openRoleChangeDialog = () => {
        setRoleChangeDialogOpen(true);
        focus();
    };

    const onGotoRam = () => {
        navigate("/");
    };

    const goToHomeMenu = () => {
        navigate("Home");
    };

    const logo = roleState.managerConfiguration ? roleState.managerConfiguration.logo : "images/imx-monitor-logo.png";

    return <TopBar>
        <TitleContainer>
            <ViperLogo src={logo} onClick={goToHomeMenu} />
        </TitleContainer>
        <ManagerUserMenu role={roleContext.state.role} username={state.loggedInUser} onChangeRole={openRoleChangeDialog} onSignOut={signOut} onGotoRam={onGotoRam} />
    </TopBar>;
};

export default ManagerBanner;
