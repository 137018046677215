import ViewContainer from 'src/SharedComponents/ViewContainer';
import ManagerTitleBar from '../ManagerTitleBar';

import ProcessThresholds from '../Components/ProcessThresholds';

interface IProps {
    refresh: number,
    tablabel: string
};

const ThresholdsTab = (props: IProps) => {
    return <ViewContainer style={{ backgroundColor: "#fafafa" }}>
        <ManagerTitleBar viewName="Thresholds" viewDescription="Here you can configure thresholds for several metrics." />
        <ProcessThresholds />
    </ViewContainer>;
};

export default ThresholdsTab;
