import * as React from "react";
import Ripple from 'src/SharedComponents/Ripple';
import handleKeyboardSelect from 'src/UsefulFunctions/handleKeyboardSelect';
import styled from "styled-components";

interface IContainerProps {
    selected?: boolean,
    onClick?: () => void
};

const ListItemContainer = styled.div<IContainerProps>`
    background: ${props => props.selected ? "#F3F8FB" : "transparent"};
    position: relative;
    cursor: ${props => props.onClick ? "pointer" : "default"};
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    &:hover {
        background-color:rgba(24,127,186,0.05);
    }
    &:focus {
        background-color:rgba(24,127,186,0.05);
        outline: 1px dotted #333333;
    }
    flex:0 0 auto;
    &:after{
        content: '';
        position: absolute;
        left: 16px;
        right: 0px;
        border-bottom: 1px solid #e5e5e5;
        display: block;
    }
`;

const ListContentWrapper = styled.div`
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 1 1 auto;
    height: inherit;
`;

interface IProps {
    children?: React.ReactNode,
    onClick?: () => void
    key: string,
    style?: React.CSSProperties,
    selected?: boolean
};

const ListItem = (props: IProps) => {
    const { children, onClick } = props;

    if (onClick) {
        return <ListItemContainer onKeyDown={handleKeyboardSelect(onClick)} tabIndex={0} className="ListItemContainer" {...props}>
            <Ripple color="rgba(24,127,186,0.5)" style={{ height: "inherit" }}>
                <ListContentWrapper className="ListContentWrapper">
                    {children}
                </ListContentWrapper>
            </Ripple>
        </ListItemContainer>
    } else {
        return <ListItemContainer className="ListItemContainer" {...props}>
            <ListContentWrapper className="ListContentWrapper">
                {children}
            </ListContentWrapper>
        </ListItemContainer>;
    }
};

export default ListItem;
