import axios, { AxiosError, AxiosResponse } from "axios";

import { getAPIUrl } from 'src/config';
import IClient from "src/ServerEntities/IClient";
import IErrorDetails from "src/ServerEntities/IErrorDetails";
import IRole from "src/ServerEntities/IRole";
import { NETWORK_ERROR } from 'src/UsefulFunctions/createErrorMessage';

const handleError = (error: AxiosError<IErrorDetails>, failureCallback: (errorMessage: string) => void) => {
    let errorMessage = "Unknown error";
    if (error.response) {
        errorMessage = error.response.data.message
        if (error.response.status === 401 && error.response.data.message === undefined) {
            errorMessage = "Unauthorised to perform this request."
        }
    }
    if (error.message === "Network Error") {
        errorMessage = NETWORK_ERROR;
    }
    failureCallback(errorMessage as string);
};

export const disableClient = (clientId: number, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.put(
        `${getAPIUrl()}client/${clientId}/disable`,
        {},
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<any>) => {
            successCallback();
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const enableClient = (clientId: number, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.put(
        `${getAPIUrl()}client/${clientId}/enable`,
        {},
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<any>) => {
            successCallback();
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const createClient = (webToken: string, client: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.post(
        `${getAPIUrl()}client/create`,
        { name: client },
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<any>) => {
            successCallback();
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};


export const searchForSites = (clientId: string, searchTerm: string, webToken: string, successCallback: (users: String[]) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(
        `${getAPIUrl()}client/${clientId}/sites/${searchTerm}`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<String[]>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const getSites = (clientId: number, webToken: string, successCallback: (users: string[]) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(
        `${getAPIUrl()}client/${clientId}/sites`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<string[]>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const getSitesToAdd = (clientId: number, webToken: string, successCallback: (users: string[]) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(
        `${getAPIUrl()}client/${clientId}/sites/available`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<string[]>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const removeClientFromSite = (clientId: number, siteId: string, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.put(
        `${getAPIUrl()}client/${clientId}/sites/remove/${siteId}`,
        {},
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<any>) => {
            successCallback();
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
}

export const addSitesToClient = (sites: string[], clientId: number, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    sites.forEach((site: string) => {
        axios.put(
            `${getAPIUrl()}client/${clientId}/sites/add/${site}`,
            {},
            { headers: { Authorization: `Bearer ${webToken}` } }
        )
            .then((response: AxiosResponse<any>) => {
                successCallback();
            })
            .catch((error: AxiosError<IErrorDetails>) => {
                handleError(error, failureCallback);
            });
    })
};

export const searchForSitesToAdd = (clientId: number, searchTerm: string, webToken: string, successCallback: (roles: string[]) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(
        `${getAPIUrl()}client/${clientId}/sites/available/${searchTerm}`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<string[]>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};


export const searchForRoles = (clientId: number, searchTerm: string, webToken: string, successCallback: (users: String[]) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(
        `${getAPIUrl()}client/${clientId}/roles/${searchTerm}`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<string[]>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const getRoles = (clientId: number, webToken: string, successCallback: (roles: string[]) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(
        `${getAPIUrl()}client/${clientId}/roles`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<string[]>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const getRolesToAdd = (clientId: number, webToken: string, successCallback: (roles: IRole[]) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(
        `${getAPIUrl()}client/${clientId}/roles/available`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<IRole[]>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const removeRoleFromClient = (clientId: number, role: string, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.put(
        `${getAPIUrl()}client/${clientId}/roles/remove/${role}`,
        {},
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<any>) => {
            successCallback();
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
}

export const addRolesToClient = (roles: IRole[], clientId: number, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    roles.forEach((role: IRole) => {
        axios.put(
            `${getAPIUrl()}client/${clientId}/roles/add/${role.id}`,
            {},
            { headers: { Authorization: `Bearer ${webToken}` } }
        )
            .then((response: AxiosResponse<any>) => {
                successCallback();
            })
            .catch((error: AxiosError<IErrorDetails>) => {
                handleError(error, failureCallback);
            });
    })
};

export const searchForRolesToAdd = (clientId: number, searchTerm: string, webToken: string, successCallback: (roles: IRole[]) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(
        `${getAPIUrl()}client/${clientId}/roles/available/${searchTerm}`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<IRole[]>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const getClients = (webToken: string, successCallback: (roles: IClient[]) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(
        `${getAPIUrl()}client`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<IClient[]>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};
