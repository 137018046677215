import * as React from 'react'
import { IManagerConfiguration, IRamConfiguration } from 'src/ServerEntities/IConfiguration';

interface IProps {
    children?: React.ReactNode;
}

interface ISetRoleAction {
    type: "setRole",
    payload: string
};

interface ISetRamConfigurationAction {
    type: "setRamConfiguration",
    payload: IRamConfiguration
};

interface ISetManagerConfigurationAction {
    type: "setManagerConfiguration",
    payload: IManagerConfiguration
};

interface IClearRoleAction {
    type: "clearRole"
};

interface IRolesState {
    managerConfiguration?: IManagerConfiguration,
    ramConfiguration?: IRamConfiguration,
    role?: string
}

type Actions = ISetRoleAction | IClearRoleAction | ISetManagerConfigurationAction | ISetRamConfigurationAction;

const initialState: IRolesState = {
    managerConfiguration: undefined,
    role: undefined
}

const reducer = (state: typeof initialState, action: Actions) => {
    switch (action.type) {
        case "setRole":
            return { ...state, role: action.payload };
        case "setRamConfiguration":
            return { ...state, ramConfiguration: action.payload };
        case "setManagerConfiguration":
            return { ...state, managerConfiguration: action.payload };
        case "clearRole":
            return { ...state, role: undefined };
    }
    return state;
};

const RolesContext = React.createContext(initialState as unknown as { state: IRolesState, dispatch: React.Dispatch<Actions> });

const RolesContextProvider = (props: IProps) => {
    const [state, dispatch] = React.useReducer(reducer, (initialState as never));
    const rolesState = (state as IRolesState);
    return <RolesContext.Provider value={{ state: rolesState, dispatch } as any}>
        {props.children}
    </RolesContext.Provider>
};

export { RolesContext, RolesContextProvider };
