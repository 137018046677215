import * as React from 'react';

import styled from 'styled-components';
import IClient from 'src/ServerEntities/IClient';
import { getClients } from '../Manager/UserManagement/clientManagementService';
import { SessionContext } from '../SessionContext';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import ErrorBox from 'src/SharedComponents/ErrorBox';
import useEffectOnSome from 'src/CustomHooks/useEffectOnSome';
import { getDashboardInfoByClientId } from './ramService';
import IDashboardInfo from 'src/ServerEntities/IDashboardInfo';
import ClientView from './Components/ClientView';
import { useNavigate } from 'react-router';
import { getFirstPathOn401RoleChange } from 'src/Roles/RolesService';
import { RolesContext } from 'src/Roles/RolesContext';

const MainContent = styled.main`
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    @media (min-width: 1279px) {
        padding: 48px 48px 104px;
    }
`;

const ClientSelect = styled.select`
    width: 200px;
    padding: 6px 12px;
    border: 1px solid #ffffff;
    background: no-repeat 90% 90%/10% url('/images/drop-arrow.png') #000000;
    color: #ffffff;
    border-radius: 16px;
    font-size: 1.2rem;
    appearance: none;
    cursor: pointer;
    outline: none;
    margin-right: 24px;
`;

const ClientContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 0 100%;
`;

const getClientById = (id: number | undefined, clients: IClient[] | undefined) => {
    if (id && clients) {
        return clients.find(client => client.id === id);
    }
    return undefined as unknown as IClient;
};

interface IProps {
    changeRole: boolean,
    setChangeRole: (changeRole: boolean) => void
};


const Clients = (props: IProps) => {
    const { state } = React.useContext(SessionContext);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [clients, setClients] = React.useState([] as unknown as IClient[]);
    const [dashboardInfo, setDashboardInfo] = React.useState(undefined as unknown as IDashboardInfo);
    const [selectedClient, setSelectedClient] = React.useState(undefined as unknown as IClient);
    const roleContext = React.useContext(RolesContext);
    const navigate = useNavigate();

    useEffectOnSome(() => {
        setLoading(true);
        getClients(state.webToken, (clients: IClient[]) => {
            if (clients.length > 0) {
                setSelectedClient(clients[0]);
                setClients(clients);
            }
            setLoading(false);
            setError("");
        }, (errorMessage: string) => {
            setError(errorMessage);
            setLoading(false);
            setClients([]);
        });
    }, [], [state.webToken]);

    const checkRoleChange = async (errorMessage: string) => {
        if(props.changeRole) {
            const path = await getFirstPathOn401RoleChange(errorMessage, state.webToken, roleContext.state.ramConfiguration?.components, roleContext.state.role)
            if(path) {
                navigate(path);
            }
            props.setChangeRole(false);
        } 
    }

    React.useEffect(() => {
        if (selectedClient) {
            setLoading(true);
            getDashboardInfoByClientId(selectedClient.id, state.webToken, (info: IDashboardInfo) => {  
                setLoading(false);
                setError("");
                setDashboardInfo(info);
            }, async (errorMessage: string) => {
                checkRoleChange(errorMessage);
                setError(errorMessage);
                setLoading(false);
                setDashboardInfo(undefined as unknown as IDashboardInfo);
            });
        }
    }, [selectedClient, state.webToken]);

    const onSetClient = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const clientId = event.target.value;
        const client = getClientById(Number.parseInt(clientId, 10), clients);
        if (client) {
            setSelectedClient(client);
        }
    };

    return <MainContent>
        <ClientContainer>
            <LoadingIndicator show={loading} type="Linear" />
            {error && <ErrorBox>{error}</ErrorBox>}
            {clients.length === 0 ?
                <p>No clients configured.</p>
                :
                <ClientSelect onChange={onSetClient}>
                    {clients && clients.map((client: IClient) => {
                        return <option key={`select-item-${client.id}`} value={client.id} selected={selectedClient.name === client.name}>
                            {client.name}
                        </option>;
                    })}
                </ClientSelect>
            }
            {dashboardInfo && <ClientView dashboardInfo={dashboardInfo} />}
        </ClientContainer>
    </MainContent >
};

export default Clients; 