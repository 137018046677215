import * as React from 'react';
import styled from 'styled-components';

import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useEffectOnSome from 'src/CustomHooks/useEffectOnSome';
import IAlertRule from 'src/ServerEntities/IAlertRule';
import ErrorBox from 'src/SharedComponents/ErrorBox';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import { SessionContext } from 'src/Views/SessionContext';
import { addAlertRule, getAlertRules } from '../UserManagement/smsNotificationService';
import Button from 'src/SharedComponents/Button';
import AlertRuleDetails from './AlertRuleDetails';
import RuleRow from './RuleRow';

const SectionHeader = styled.div`
    background: #ffffff;
    border-bottom: 1px solid #e5e5e5;
    padding: 12px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    & > div {
        display: flex;
        flex-direction: column;
        width: 600px;
    }
    & p {
        margin: 6px 0;
        font-size: 1.2rem;
    }
    & h1 {
        font-size: 1.6rem;
        margin: 6px 0;
        font-weight: 500;
    }
`;

const Content = styled.div`
    padding: 12px 24px;
    & tr {
        display: flex;
        flex-direction: row;
    }
    & td {
        display: flex;
        flex-direction: row;
        padding: 4px;
        align-items: center;
        &:first-child {
            padding-left: 0;
        }
    }
`;

const AlertNotifications = () => {
    const [open, setOpen] = React.useState(false);
    const { webToken } = React.useContext(SessionContext).state;
    const [loading, setLoading] = React.useState(false);
    const [rules, setRules] = React.useState([] as unknown as IAlertRule[]);
    const [error, setError] = React.useState("");
    const [refresh, setRefresh] = React.useState(0);
    const [ruleOpen, setRuleOpen] = React.useState(null as unknown as IAlertRule | null);

    useEffectOnSome(() => {
        setLoading(true);
        getAlertRules(webToken, (serverRules) => {
            setRules(serverRules);
            setError("");
            setLoading(false);
        }, (errorMessage: string) => {
            setError(errorMessage);
            setLoading(false);
        });
    }, [webToken], [refresh]);

    const toggleOpen = () => {
        setOpen(!open);
    };

    const reloadRules = () => {
        setRefresh(refresh + 1);
    };

    const addRow = () => {
        setLoading(true);
        const newRule = {
            smsAlert: true,
            emailAlert: true
        } as IAlertRule;
        addAlertRule(newRule, webToken, () => {
            setLoading(false);
            setError("");
            reloadRules();
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    };


    return <div>
        {error && <ErrorBox>{error}</ErrorBox>}
        {ruleOpen && <AlertRuleDetails ruleOpen={ruleOpen} setRuleOpen={setRuleOpen} setError={setError} webToken={webToken} />}
        <SectionHeader>
            <div>
                <h1>Alert rules</h1>
                <p>{`Notifications sent for ${rules.length} rule${rules.length !== 1 ? "s" : ""}`}</p>
            </div>
            <FontAwesomeIcon style={{ cursor: "pointer" }} size='2x' icon={open ? faChevronUp : faChevronDown} onClick={toggleOpen} />
        </SectionHeader>
        <LoadingIndicator type="Linear" show={loading} />
        {open && <Content>
            <table>
                <tbody>
                    {rules.sort((a, b) => a.priority - b.priority).map((rule, index) => {
                        return <RuleRow key={`rule-row-${rule.id}-${index}`} rule={rule} setRuleOpen={setRuleOpen} setError={setError} webToken={webToken} onDelete={reloadRules} onReload={reloadRules} />
                    })}
                </tbody>
            </table>
            <Button onClick={addRow}>Add new rule</Button>
        </Content>}
    </div>;
};

export default AlertNotifications;
