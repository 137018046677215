import * as React from 'react';
import styled from 'styled-components';
import useEffectOnSome from 'src/CustomHooks/useEffectOnSome';
import ErrorBox from 'src/SharedComponents/ErrorBox';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import { SessionContext } from 'src/Views/SessionContext';
import TextInput from 'src/SharedComponents/TextInput';
import Button from 'src/SharedComponents/Button';
import { getProcessThresholds, setProcessThresholds } from '../UserManagement/thresholdService';
import IProcessThresholds from 'src/ServerEntities/IProcessThresholds';
import Title from './Title';

const ProcessThresholdsContainer = styled.div`
    padding: 12px 24px;
`;

const Container = styled.div`
    padding: 12px 24px;
`;

const LineContainer = styled.div`
    margin-bottom: 20px;
`;

const ProcessThresholds = () => {
    const { webToken } = React.useContext(SessionContext).state;
    const [loading, setLoading] = React.useState(false);
    const [commandsExecuted, setCommandsExecuted] = React.useState("");
    const [globalReferences, setGlobalReferences] = React.useState("");
    const [error, setError] = React.useState("");

    useEffectOnSome(() => {
        setLoading(true);
        getProcessThresholds(webToken, (processThresholds: IProcessThresholds) => {
            if (processThresholds.commandsExecuted) {
                setCommandsExecuted(processThresholds.commandsExecuted.toString());
            }
            if (processThresholds.globalReferences) {
                setGlobalReferences(processThresholds.globalReferences.toString());
            }
            setError("");
            setLoading(false);
        }, (errorMessage: string) => {
            setError(errorMessage);
            setLoading(false);
        });
    }, [webToken], []);


    const updateCommandsExecuted = (e: React.SyntheticEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;
        setCommandsExecuted(value);
    };

    const updateGlobalReferences = (e: React.SyntheticEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;
        setGlobalReferences(value);
    };

    const saveThresholds = () => {
        setLoading(true);
        setProcessThresholds(
            {
                "commandsExecuted": commandsExecuted !== "" ? Number(commandsExecuted) : undefined,
                "globalReferences": globalReferences !== "" ? Number(globalReferences) : undefined
            }, webToken, () => {
                setLoading(false);
                setError("");
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
            });
    };

    return <ProcessThresholdsContainer>
        {error && <ErrorBox>{error}</ErrorBox>}
        {loading && <LoadingIndicator type={'Linear'} show={loading} />}
        <Title>Processes</Title>
        <Container>
            <LineContainer>
                <div>Commands executed</div>
                <TextInput disabled={loading} value={commandsExecuted} onChange={updateCommandsExecuted} width="120px" />
            </LineContainer>
            <LineContainer> <div>Global references</div><TextInput disabled={loading} value={globalReferences} onChange={updateGlobalReferences} width="120px" /></LineContainer>
            <div><Button onClick={saveThresholds}>Save</Button></div>
        </Container>
    </ProcessThresholdsContainer>
};

export default ProcessThresholds;
