import * as React from 'react';
import { useLocation } from 'react-router-dom';
import useEffectOnSome from 'src/CustomHooks/useEffectOnSome';
import styled from 'styled-components';
import { debounce } from 'throttle-debounce';

import Button from 'src/SharedComponents/Button';
import Dialog from 'src/SharedComponents/Dialog/Dialog';
import DialogActions from 'src/SharedComponents/Dialog/DialogActions';
import DialogContent from 'src/SharedComponents/Dialog/DialogContent';
import DialogTitle from 'src/SharedComponents/Dialog/DialogTitle';
import EmptyStateSplashContainer from 'src/SharedComponents/EmptyStateSplash';
import Label from 'src/SharedComponents/Label';
import ListItem from 'src/SharedComponents/List/ListItem';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import ScrollingContainer from 'src/SharedComponents/ScrollingContainer';
import TextInput from 'src/SharedComponents/TextInput';
import ViewContainer from 'src/SharedComponents/ViewContainer';
import ManagerTitleBar from 'src/Views/Manager/ManagerTitleBar';

import transparentSiteIcon from 'src/images/customIcons/transparentUser.png';

import { SessionContext } from 'src/Views/SessionContext';

import SiteDetails from './SiteDetails';
import IRoleSite from 'src/ServerEntities/IRoleSite';
import ISiteType from 'src/ServerEntities/ISiteType';
import { getSiteTypes, searchForSites } from './SiteManagementService';

interface IProps {
    refreshSiteTypes: number,
    tablabel: string
};

const SiteListContainer = styled.div`
    width:320px;
    border-right: 1px solid #e5e5e5;
    background-color:white;
    max-width:320px;
    display:flex;
    flex: 1 1 auto;
    flex-direction:column;

    @media (min-width: 350px) and (max-width: 768px) {
        height: 45%;
        grid-row: 1;
        width: 100% !important;
        max-width: none !important;
        z-index: 1;
    }
`;

const SearchBoxContainer = styled.div`
    padding:12px 16px;
    border-bottom:1px solid #e5e5e5;
    background-color:#fafafa;
`;

const EmptyText = styled.p`
    margin: 8px 4px;
`;

const SitesTabContainer = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    overflow: auto;
    backgroundColor: "#fafafa";

    @media (min-width: 350px) and (max-width: 768px) {
        display: grid;
        grid-template-rows: 20% auto;
    }
`

const SitesListContainerReference = styled.div`
    @media (min-width: 350px) and (max-width: 768px) {
        position: relative;
        bottom: 14px;
        min-height: 250px;
        left: 0;
        z-index: 9999;
        margin: 0 16px 0 16px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 1;
        background-color: white;
    }
`

const siteHasId = (id?: string) => (site: IRoleSite) => id === site.id;

const getSearchSegment = (key: string, search: string) => {
    let value = search.substring(search.indexOf(key + "=") + key.length + 1);
    if (value.includes("&")) {
        value = value.substring(0, value.indexOf("&"));
    }
    return decodeURI(value);
};

const SitesTab = (props: IProps) => {
    const { refreshSiteTypes } = props;
    const [filterText, setFilterText] = React.useState("");
    const [selectedSiteId, setSelectedSiteId] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState("");
    const [refresh, setRefresh] = React.useState(0);
    const [sites, setSites] = React.useState([] as IRoleSite[]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const location = useLocation();
    const [siteTypes, setSiteTypes] = React.useState([] as string[]);
    const { webToken } = React.useContext(SessionContext).state;
    const [showSitesList, setShowSitesList] = React.useState(false)

    useEffectOnSome(() => {
        setLoading(true);
        getSiteTypes(webToken, (typesFromServer: ISiteType[]) => {
            setLoading(false);
            setSiteTypes(typesFromServer.map(s=>s.type));
        }, setError);
    }, [webToken], [refreshSiteTypes]);

    const siteListContainerReference = React.useRef(null as unknown as HTMLDivElement);

    useEffectOnSome(() => {
        const initialSitename = location.search.includes("sitename=") ? getSearchSegment("sitename", location.search) : "";
        if (initialSitename && !sites.length) {
            searchForSites(initialSitename.toLowerCase(), webToken, (sitesFromServer: IRoleSite[]) => {
                setLoading(false);
                setSelectedSiteId(initialSitename);
                setSites(sitesFromServer);
            }, setError);
        }
    }, [location], [webToken, sites]);

    const search = (searchTerm: string, currentWebToken: string) => {
        searchForSites(searchTerm && searchTerm.toLowerCase(), currentWebToken, (sitesFromServer: IRoleSite[]) => {
            setLoading(false);
            setSites(sitesFromServer);
        }, setError);
    };
    const delayedSearch = React.useRef(debounce(250, search)).current;

    const changeFilterText = (e: React.SyntheticEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;

        if (value) {
            setLoading(true);
            delayedSearch(value, webToken);
            setShowSitesList(true)
        }

        setFilterText(value);

        if(!value) {
            setShowSitesList(false)
        }
    };

    const changeSelectedSite = (site: IRoleSite) => () => {
        setSelectedSiteId(site.id);
        setShowSitesList(false)
    }

    const refreshSites = () => {
        setRefresh(refresh + 1);
    }

    const closeErrorDialog = () => {
        setErrorMessage("");
    };

    const selectedSite = sites.find(siteHasId(selectedSiteId));

    return <ViewContainer style={{ backgroundColor: "#fafafa" }}>

        <ErrorDialog message={errorMessage} onClose={closeErrorDialog} />
        <ManagerTitleBar viewName="Sites" viewDescription="Here you can set site details." />
        <SitesTabContainer style={{ flexDirection: "row" }}>
            <SiteListContainer>
                <SearchBoxContainer>
                    <TextInput value={filterText} onChange={changeFilterText} placeholder="Search for a site" />
                </SearchBoxContainer>
                <LoadingIndicator type="Linear" show={loading} />
                {showSitesList && <SitesListContainerReference ref={siteListContainerReference} style={{ flex: "1 1 auto" }}>
                    {!sites.length && !filterText && <EmptyText>Enter a site to search.</EmptyText>}
                    {!sites.length && filterText && !loading && <EmptyText>No sites found matching {filterText}.</EmptyText>}
                    {!!sites.length && siteListContainerReference.current ? <ScrollingContainer style={{ height: "100%" }} direction={"column"}>
                        {sites
                            .map((site: IRoleSite, index) => <ListItem selected={selectedSiteId !== undefined && selectedSiteId === site.id} key={site.id + index} onClick={changeSelectedSite(site)}>
                                <Label>
                                    <h2>{site.id}</h2>
                                    <h3>{site.description}</h3>
                                </Label>
                            </ListItem>)}
                    </ScrollingContainer> : null}
                    {error && error}
                </SitesListContainerReference>}
            </SiteListContainer>
            {!selectedSite && <EmptyState />}
            {selectedSite && <SiteDetails
                key={selectedSiteId}
                selectedSite={selectedSite}
                webToken={webToken}
                refreshSites={refreshSites}
                setErrorMessage={setErrorMessage}
                siteTypes={siteTypes}
            />}
        </SitesTabContainer>
    </ViewContainer>;
};

const EmptyState = () => <EmptyStateSplashContainer>
    <img alt="No site selected" src={transparentSiteIcon} style={{ marginBottom: "8px", opacity: 0.3 }} />
    <h1>No site selected</h1>
    <h2>Select a site on the left to view their IMX-CR functionality.</h2>
</EmptyStateSplashContainer>;

const ErrorDialog = ({ message, onClose }: { message: string, onClose: () => void }) => <Dialog open={message !== ""} onClose={onClose}>
    <DialogTitle>Error</DialogTitle>
    <DialogContent style={{ lineHeight: "25px" }}>{message}</DialogContent>
    <DialogActions><Button onClick={onClose}>OK</Button></DialogActions>
</Dialog>;

export default SitesTab;
