import * as React from 'react';
import styled from 'styled-components';
import { debounce } from 'throttle-debounce';

import TextInput from 'src/SharedComponents/TextInput';
import { SessionContext } from 'src/Views/SessionContext';
import { saveVolumeNotificationLimits } from '../UserManagement/smsNotificationService';
import IVolumeNotification from 'src/ServerEntities/IVolumeNotification';
import Select from 'src/SharedComponents/Select/Select';
import SelectItem from 'src/SharedComponents/Select/SelectItem';

const Content = styled.tr`
    padding: 12px 24px;
    & p {
        display: flex;
        flex-direction: row;
        margin: 0 8px 0;
        align-items: center;
    }
`;

const SavedText = styled.p`
    color: #16B012;
    &:before {
        content: '\u2713';
        margin-right: 4px;
    }
`;

interface IProps {
    limits: IVolumeNotification
    loading: boolean,
    setError: (error: string) => void,
    setLoading: (isLoading: boolean) => void
};

type BYTE_SIZE = "MB" | "GB" | "TB";

const getMultiplierForByteSize = (byteSize: BYTE_SIZE) => {
    switch (byteSize) {
        case "MB":
            return 1;
        case "GB":
            return 1_000;
        case "TB":
            return 1_000_000;
    }
};

const getByteSize = (maxSize: number): BYTE_SIZE => {
    if (maxSize > 1_000_000) {
        return "TB";
    } else if (maxSize > 1_000) {
        return "GB";
    }
    return "MB";
}

const VolumeNotificationRow = (props: IProps) => {
    const { webToken } = React.useContext(SessionContext).state;
    const { loading, setError, setLoading } = props;
    const [limits, setLimits] = React.useState(props.limits);
    const [saved, setSaved] = React.useState(false);
    const [byteSize, setByteSize] = React.useState(getByteSize(props.limits.maxSize));
    const site = props.limits.site;

    const updateCount = (e: React.SyntheticEvent<HTMLInputElement>) => {
        const newValue = parseInt(e.currentTarget.value, 10);
        const newLimits = {
            maxCount: newValue,
            maxSize: limits.maxSize,
            site
        };
        setLimits(newLimits);
        debouncedSave(newLimits);
    };

    const updateSize = (e: React.SyntheticEvent<HTMLInputElement>) => {
        const newValue = parseInt(e.currentTarget.value, 10);
        const newLimits = {
            maxCount: limits.maxCount,
            maxSize: newValue * getMultiplierForByteSize(byteSize),
            site
        };
        setLimits(newLimits);
        debouncedSave(newLimits);
    };

    const updateByteSize = (newByteSize: BYTE_SIZE) => {
        const newLimits = {
            maxCount: limits.maxCount,
            maxSize: limits.maxSize / getMultiplierForByteSize(byteSize) * getMultiplierForByteSize(newByteSize),
            site
        };
        setByteSize(newByteSize);
        debouncedSave(newLimits);
    };

    const saveNewLimits = (newLimits: IVolumeNotification) => {
        setLoading(true);
        setSaved(false);
        saveVolumeNotificationLimits(newLimits, webToken, () => {
            setLoading(false);
            setError("");
            setSaved(true);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    };
    const debouncedSave = React.useRef(debounce(500, saveNewLimits)).current;

    return <Content>
        <td>{site}</td>
        <td>
            <p>
                Send a notification if there are more than
                <TextInput disabled={loading} type="number" value={(limits.maxCount || "") + ""} onChange={updateCount} width="40px" margin="0 4px" />
                volumes, or if the total size of the volumes is more than
                <TextInput disabled={loading} type="number" value={(limits.maxSize / getMultiplierForByteSize(byteSize) || "") + ""} onChange={updateSize} width="50px" margin="0 4px" />
                <Select value={byteSize} onChange={updateByteSize} childValues={["MB", "GB", "TB"]}>
                    <SelectItem value="MB" itemKey="volume-byte-size-mb">MB</SelectItem>
                    <SelectItem value="GB" itemKey="volume-byte-size-gb">GB</SelectItem>
                    <SelectItem value="TB" itemKey="volume-byte-size-tb">TB</SelectItem>
                </Select>
                large.
                { saved && <SavedText>Saved</SavedText>}
            </p>
        </td>
    </Content>;
};

export default VolumeNotificationRow;