import * as React from 'react';
import styled from 'styled-components';

import useFocus from 'src/CustomHooks/useFocus';

import Menu from 'src/SharedComponents/Menu/Menu';
import MenuItem from 'src/SharedComponents/Menu/MenuItem';

import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IUserMenuProps {
    role?: string,
    username: string,
    onSignOut: () => void,
    onChangeRole: () => void,
    onGotoRam: () => void
};

const HeaderLink = styled.a`
    border: 2px solid #000000;
    border-radius: 18px;
    height: 24px;
    padding: 4px 16px;
    cursor: pointer;

    .username {
        z-index: -1;
    }

    .faCog { 
        display: none;
    }

    @media (max-width: 500px) {
        border: none;
        .username {
            display: none;
        }

        .faCog {
            font-size: 24px;
            display: block;
            z-index: -1;
        }

    }
`;

const UserContainer = styled.div`
    padding: 8px 16px 12px 16px;
    border-bottom:1px solid #e5e5e5;
    user-interaction: none;
    cursor: default;
`;

const TopLabel = styled.p`
    margin: 0;
    font-size: 14px;
    line-height: 19px;
    color: #555555;
`;

const UsernameLabel = styled.p`
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 25px
    color: #000000;
`;

const RoleLabel = styled.p`
    margin: 0;
    font-size: 14px;
    line-height: 22px;
`;

const ManagerUserMenu = (props: IUserMenuProps) => {
    const { role, username, onSignOut, onChangeRole, onGotoRam } = props;
    const [navMenuOpen, setNavMenuOpen] = React.useState(false);
    const menuButtonRef = React.useRef(null as unknown as HTMLAnchorElement);
    const menuRef = React.useRef(null as unknown as HTMLDivElement);
    const focus = useFocus(menuRef);

    const toggleNavMenu = () => {
        if (!navMenuOpen) {
            setNavMenuOpen(true);
            focus();
        } else {
            setNavMenuOpen(false);
        }
    };

    const closeNavMenu = () => {
        setNavMenuOpen(false);
    };

    const handleKeyDown = (isFirst: boolean, isLast: boolean, enterFunction: () => void) => (event: any) => {
        const key = event.key || event.keyCode;
        if (key === "Enter" || key === " ") {
            enterFunction();
        }
        if (key === "Escape") {
            menuButtonRef.current.focus();
            setNavMenuOpen(false);
        }
        if (key === "Tab" && event.shiftKey && isFirst) {
            menuButtonRef.current.focus();
            setNavMenuOpen(false);
        }
        if (key === "Tab" && !event.shiftKey && isLast) {
            menuButtonRef.current.focus();
            setNavMenuOpen(false);
        }
    };

    return <span>
        <HeaderLink onClick={toggleNavMenu} ref={menuButtonRef} style={{display: "flex"}}>
            <div className="username">{ username }</div>
            <FontAwesomeIcon className='faCog' icon={faCog} onClick={toggleNavMenu} />
        </HeaderLink>
        <Menu menuContainerRef={menuRef} open={navMenuOpen} onClose={closeNavMenu} anchorElement={menuButtonRef} width={300}>
            <UserContainer>
                <TopLabel>Signed in as</TopLabel>
                <UsernameLabel>{username}</UsernameLabel>
                <RoleLabel>{role}</RoleLabel>
            </UserContainer>
            <MenuItem key="change-role-item" onKeyDown={handleKeyDown(true, false, onChangeRole)} onClick={onChangeRole}>
                Change role
            </MenuItem>
            <MenuItem key="ram-item" onKeyDown={handleKeyDown(true, false, onGotoRam)} onClick={onGotoRam}>
                RAM
            </MenuItem>
            <MenuItem key="sign-out-item" onKeyDown={handleKeyDown(false, true, onSignOut)} onClick={onSignOut}>
                Sign out
            </MenuItem>
        </Menu>
    </span>
}

export default ManagerUserMenu;
