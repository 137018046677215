import alertBadIcon from 'src/images/customIcons/alert-bad.png';
import alertGoodIcon from 'src/images/customIcons/alert-good.png';
import IServer from 'src/ServerEntities/IServer';
import styled from 'styled-components';


const ServerIcon = styled.img`
    height: 24px;
    width: 24px;
    margin: 0 4px;
    @media (max-width: 1279px) {
        display: none;
    }
`;

interface IProps {
    server: IServer
};

const ServerAlertIcon = (props: IProps) => {
    const server = props.server;

    return <ServerIcon
        alt={`${server.alerts} database status ${server.name}`}
        src={server.backup && server.backup.status === "ERROR" ? alertBadIcon : alertGoodIcon} />;
};

export default ServerAlertIcon;
