import axios, { AxiosError, AxiosResponse } from "axios";

import { getAPIUrl } from 'src/config';
import ICommonProblem from "src/ServerEntities/ICommonProblem";
import IErrorDetails from "src/ServerEntities/IErrorDetails";
import { NETWORK_ERROR } from 'src/UsefulFunctions/createErrorMessage';

const handleError = (error: AxiosError<IErrorDetails>, failureCallback: (errorMessage: string) => void) => {
    let errorMessage = "Unknown error";
    if (error.response) {
        errorMessage = error.response.data.message
        if (error.response.status === 401 && error.response.data.message === undefined) {
            errorMessage = "Unauthorised to perform this request."
        }
    }
    if (error.message === "Network Error") {
        errorMessage = NETWORK_ERROR;
    }
    failureCallback(errorMessage as string);
};

export const getProblems = (webToken: string, successCallback: (problems: ICommonProblem[]) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(
        `${getAPIUrl()}commonProblems`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
    .then((response: AxiosResponse<ICommonProblem[]>) => {
        successCallback(response.data);
    })
    .catch((error: AxiosError<IErrorDetails>) => {
        handleError(error, failureCallback);
    });
};

export const updateProblem = (problem: ICommonProblem, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.put(
        `${getAPIUrl()}commonProblems/${problem.id}`,
        problem,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
    .then((response: AxiosResponse<any>) => {
        successCallback();
    })
    .catch((error: AxiosError<IErrorDetails>) => {
        handleError(error, failureCallback);
    });
};

export const saveProblem = (problem: ICommonProblem, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.post(
        `${getAPIUrl()}commonProblems`,
        problem,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
    .then((response: AxiosResponse<any>) => {
        successCallback();
    })
    .catch((error: AxiosError<IErrorDetails>) => {
        handleError(error, failureCallback);
    });
};

export const deleteProblem = (problem: ICommonProblem, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.delete(
        `${getAPIUrl()}commonProblems/${problem.id}`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
    .then((response: AxiosResponse<any>) => {
        successCallback();
    })
    .catch((error: AxiosError<IErrorDetails>) => {
        handleError(error, failureCallback);
    });
};
