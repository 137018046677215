import React from 'react';
import 'react-toggle/style.css';
import styled from 'styled-components';

import IRhapsodyServer from 'src/ServerEntities/IRhapsodyServer';
import ErrorBox from 'src/SharedComponents/ErrorBox';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import { SessionContext } from 'src/Views/SessionContext';
import { getRhapsodyHeader } from '../ramService';


const SiteContainer = styled.ul`
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
`;

const ContentContainer = styled.main`
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    @media (min-width: 1279px) {
        padding: 48px 48px 104px;
    }
    @media (max-width: 1279px) {
        margin-bottom: 120px;
    }
`;

interface IProps {
    selectedTypes: string[],
    changeRole: boolean,
    setChangeRole: (changeRole: boolean) => void
};

const TieInfo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 30px;
`;

const TieTitle = styled.div`
    font-size: 25px;
    font-weight: bold;
`;

const VersionTitle = styled.div`
    font-size: 20px;
    font-weight: bold;
    color: #1577AE;
`;

const LicenceTitle = styled.div`
    display: flex;
    flex-direction: column;
     font-size: 18px;
    font-weight: bold;
`;

const LicenceItem = styled.div`
`;

const ServerContainer = styled.div`
 display: flex;
    flex-direction: row;
`;

const ServerItem = styled.div`
    border-radius: 30px;
    margin-right: 20px;
    padding: 8px;
    background-color: #ccc;
    color: #FFF;
    width: 300px;
    text-align: center;
    font-size: 24px;
`;

const ServerItemSelected = styled.div`
    border-radius: 30px;
    margin-right: 20px;
    padding: 8px;
    background-color: #1577AE;
    color: #FFF;
    width: 300px;
    text-align: center;
    font-size: 24px;
`;


const RhapsodyDashboard = (props: IProps) => {
    const { state } = React.useContext(SessionContext);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [headerInfo, setHeaderInfo] = React.useState(undefined as unknown as IRhapsodyServer[]);
    const [selectedServer, setSelectedServer] = React.useState(0);

    React.useEffect(() => {
        setLoading(true);
        getRhapsodyHeader(state.webToken, (data: IRhapsodyServer[]) => {
            setLoading(false);
            setHeaderInfo(data);
            setError("");
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    }, [state.webToken]);

    const serverToTab = (server: IRhapsodyServer, index: number) => {
        const selectServer = (serverIndex: number) => () => {
            setSelectedServer(serverIndex);
        }
        if (selectedServer === index) {
            return <ServerItemSelected onClick={selectServer(index)}>{server.server}</ServerItemSelected>
        }
        return <ServerItem onClick={selectServer(index)}>{server.server}</ServerItem>
    }

    return <ContentContainer>
        <LoadingIndicator type="Linear" show={loading} />
        {error && <ErrorBox>{error}</ErrorBox>}
        {headerInfo && headerInfo[selectedServer] && <TieInfo>
            <TieTitle>Rhapsody Integration Engine</TieTitle>
            <VersionTitle>version {headerInfo[selectedServer].licenceVersion}</VersionTitle>
            <LicenceTitle>
                <LicenceItem>Licence: {headerInfo[selectedServer].licenceEndUser}</LicenceItem>
                <LicenceItem>Expiry: {headerInfo[selectedServer].licenceExpiryDate}</LicenceItem>
            </LicenceTitle>
        </TieInfo>}
        <ServerContainer>
            {headerInfo && headerInfo.map(serverToTab)}
        </ServerContainer>
        <SiteContainer>
            server {selectedServer} components
        </SiteContainer>
    </ContentContainer>;
};

export default RhapsodyDashboard;
