import React from "react";
import IAlertRule from "src/ServerEntities/IAlertRule";
import IAlertRuleValue from "src/ServerEntities/IAlertRuleValue";
import Button from "src/SharedComponents/Button";
import Checkbox from "src/SharedComponents/Checkbox";
import TextInput from "src/SharedComponents/TextInput";
import styled from "styled-components";
import { debounce } from "throttle-debounce";
import { updateAlertRule, deleteAlertRule } from "../UserManagement/smsNotificationService";

const SavedCell = styled.td`
    color: #16B012;
    &:before {
        content: '\u2713';
        margin-right: 4px;
    }
`;
const ValueBox = styled.div`
     display: flex;
    flex-direction: column;
`;

interface IRuleRowProps {
    onDelete?: () => void,
    onReload: () => void,
    rule: IAlertRule,
    setError: (error: string) => void,
    setRuleOpen: (rule: IAlertRule | null) => void,
    webToken: string
};

const RuleRow = (props: IRuleRowProps) => {
    const { onDelete, rule, setError, setRuleOpen, webToken } = props;
    const [previousPriority, setPreviousPriority] = React.useState(rule.priority);
    const [priority, setPriority] = React.useState(rule.priority);
    const [service, setService] = React.useState(rule.target);
    const [alertType, setAlertType] = React.useState(rule.alertType);
    if (!rule.values || !rule.values.length) {
        rule.values = [{ condition: "AND", value: "" }];
    }
    const [values, setValues] = React.useState(rule.values as IAlertRuleValue[]);
    const [loading, setLoading] = React.useState(false);
    const [saved, setSaved] = React.useState(false);
    const [emailAlert, setEmailAlert] = React.useState(rule.emailAlert);
    const [smsAlert, setSmsAlert] = React.useState(rule.smsAlert);


    const updateService = (e: React.SyntheticEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;
        setService(value);
        rule.target = value;
        deferredSave(rule);
    };

    const updatePriority = (e: React.SyntheticEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;
        const priorityNumber = parseInt(value);
        if (!isNaN(priorityNumber)) {
            setPriority(priorityNumber);
            setPreviousPriority(rule.priority);
            rule.priority = priorityNumber;
            deferredSave(rule);
        }
    };

    const updateAlertType = (e: React.SyntheticEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;
        setAlertType(value);
        rule.alertType = value;
        deferredSave(rule);
    };

    const updateValue = (index: number) => (e: React.SyntheticEvent<HTMLInputElement>) => {
        rule.values = [...values];
        rule.values[index].value = e.currentTarget.value;
        setValues(rule.values);
        deferredSave(rule);
    };

    const save = (rule: IAlertRule) => {
        setLoading(true);
        setSaved(false);
        rule.values = [...rule.values.filter(v => v.value !== "")];
        updateAlertRule(rule, webToken, () => {
            setLoading(false);
            setSaved(true);
            setError("");
            if (previousPriority !== rule.priority) {
                props.onReload();
            }

        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    };
    const deferredSave = React.useRef(debounce(1000, save)).current;

    const deleteRule = () => {
        if (!onDelete) {
            return;
        }
        setLoading(true);
        deleteAlertRule(rule.id, webToken, () => {
            setLoading(false);
            setError("");
            onDelete();
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    };

    const onEmailAlertChange = (checked: boolean) => {
        rule.emailAlert = checked;
        setEmailAlert(checked);
        save(rule);
    };

    const onSmsAlertChange = (checked: boolean) => {
        rule.smsAlert = checked;
        setSmsAlert(checked);
        save(rule);
    };

    const renderValue = (value: IAlertRuleValue, index: number) => {
        return <TextInput disabled={loading} value={value.value || ""} onChange={updateValue(index)} width="320px" />;
    };

    const addValue = () => {
        rule.values = [...values];
        rule.values.push({ condition: "AND", value: "" });
        setValues(rule.values);
    };

    const viewDetails = () => {
        setRuleOpen(rule);
    };

    return <tr>
        <td>Service contains</td>
        <td><TextInput disabled={loading} value={service} onChange={updateService} width="100px" /></td>
        <td>and type contains</td>
        <td><TextInput disabled={loading} value={alertType} onChange={updateAlertType} width="100px" /></td>
        <td>and alert contains</td>
        <td>
            <ValueBox>
                {values.map(renderValue)}
            </ValueBox>
        </td>
        <td><Button onClick={addValue}>+</Button></td>
        <td>Email Alert</td>
        <td><Checkbox checked={emailAlert} onChange={onEmailAlertChange}></Checkbox></td>
        <td>SMS Alert</td>
        <td><Checkbox checked={smsAlert} onChange={onSmsAlertChange}></Checkbox></td>
        <td>Priority</td>
        <td><TextInput disabled={loading} value={priority.toString()} onChange={updatePriority} width="30px" /></td>
        {onDelete && <td><Button onClick={viewDetails}>View details</Button></td>}
        {onDelete && <td><Button color="#cc0000" onClick={deleteRule}>Delete</Button></td>}
        {saved && <SavedCell>Saved</SavedCell>}
    </tr>;
};

export default RuleRow;
