import * as React from "react";
import * as ReactDOM from 'react-dom';
import styled from "styled-components";

const modalRoot = document.getElementById('modal-root');

const Presentation = styled.div`
top: 0;
left: 0;
right: 0;
bottom: 0;
z-index: 1300;
position: fixed;
`;

const MenuOverlay = styled.div`
top: 0;
left: 0;
right: 0;
bottom: 0;
z-index: -1;
position: fixed;
background-color: transparent;
-webkit-tap-highlight-color: transparent;
`;

interface IProps {
    children: React.ReactNode;
    style?: React.CSSProperties;
    show: boolean;
}

class Modal extends React.Component<IProps> {
    public componentDidMount() {
        // The portal element is inserted in the DOM tree after
        // the Modal's children are mounted, meaning that children
        // will be mounted on a detached DOM node. If a child
        // component requires to be attached to the DOM tree
        // immediately when mounted, for example to measure a
        // DOM node, or uses 'autoFocus' in a descendant, add
        // state to Modal and only render the children when Modal
        // is inserted in the DOM tree.
        // if (modalRoot) {
        //     // modalRoot.appendChild(this.el);
        // }
    }

    public componentWillUnmount() {
        // if (modalRoot) {
        //     modalRoot.removeChild(this.el);
        // }
    }

    public render() {
        if (modalRoot && this.props.show) {
            return ReactDOM.createPortal(
                <Presentation
                    onContextMenu={this.stop}
                    onDoubleClick={this.stop}
                    onDrag={this.stop}
                    onDragEnd={this.stop}
                    onDragEnter={this.stop}
                    onDragExit={this.stop}
                    onDragLeave={this.stop}
                    onDragOver={this.stop}
                    onDragStart={this.stop}
                    onDrop={this.stop}
                    onMouseDown={this.stop}
                    onMouseEnter={this.stop}
                    onMouseLeave={this.stop}
                    onMouseMove={this.stop}
                    onMouseOver={this.stop}
                    onMouseOut={this.stop}
                    onMouseUp={this.stop}

                    onKeyDown={this.stop}
                    onKeyPress={this.stop}
                    onKeyUp={this.stop}

                    onFocus={this.stop}
                    onBlur={this.stop}

                    onChange={this.stop}
                    onInput={this.stop}
                    onInvalid={this.stop}
                    onSubmit={this.stop}
                >
                    {this.props.children}
                    <MenuOverlay style={this.props.style} />

                </Presentation>,
                modalRoot,
            );
        }
        return null;
    }

    private stop(e: React.SyntheticEvent<HTMLDivElement>) {
        e.stopPropagation();
    }

}

export default Modal;
