import IEnsembleInterface, { IInterfaceItemAndServer } from "src/ServerEntities/IEnsembleInterface";
import styled from "styled-components";
import { SessionContext } from "../SessionContext";
import React from "react";

const AlertContainer = styled.li`
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 8px;
    height: 25px;
    width: 25px;
    background-color: #42a83f
`;

export type SIZE = "large" | "medium" | "small";

interface IProps {
  site: string,
  serverName: string,
  namespace: string,
  ensembleInterface: IEnsembleInterface
};

export const getInterfaceStatusHeaderData = (interfaceItemAndServer: IInterfaceItemAndServer[]) => {
  const initialAcc = { green: 0, red: 0, yellow: 0, purple: 0, grey: 0 };
  return interfaceItemAndServer.reduce((totalAcc, item) => {
    item.ensembleInterfaces.forEach(ensembleInterface => {
      if (ensembleInterface.status === "Stopped") {
        totalAcc.yellow += 1;
      } else if (ensembleInterface.status === "Error") {
        totalAcc.red += 1;
      } else if (ensembleInterface.status === "Disabled") {
        totalAcc.grey += 1;
      } else if (ensembleInterface.status === "Queue") {
        totalAcc.purple += 1;
      } else {
        totalAcc.green += 1;
      }
    });
    return totalAcc;
  }, initialAcc);
};



const DashboardInterfaceItem = (props: IProps) => {
  const { state } = React.useContext(SessionContext);

  const openServer = (id: number) => (event: React.MouseEvent<HTMLElement>) => {
    window.open(`/Ram/Server/${props.site}/${props.serverName}/${props.namespace}/${state.webToken}?interfaceId=${id}`);
    event.stopPropagation();
  };

  return <AlertContainer className={props.ensembleInterface.status} onClick={openServer(props.ensembleInterface.id)}>
  </AlertContainer>;
}

export default DashboardInterfaceItem;