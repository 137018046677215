
import IIntervalStats from "src/ServerEntities/IIntervalStats";
import styled from "styled-components";

interface IStyleProps {
    value: number
};


const getNumberColor = (value: number) => {
    if (value > 5) {
        return "#ff2021";
    } else if (value > 0) {
        return "#e38801";
    }
    return "#42a73f";
}

const SmallNumberContainer = styled.td<IStyleProps>`
    text-align: center;
    font-size: 1.5em;
    color: ${props => props.value > 0 ? "#FF0000" : "#00FF00"};
`;

const BigNumberContainer = styled.td<IStyleProps>`
    width: 25%;
    text-align: center;
    font-size: 5em;
    color: ${props => getNumberColor(props.value)};
    @media (max-width: 1279px) {
        font-size: 3em;
    }
`;

const LabelContainer = styled.td`
    text-align: center;
    font-size: 1em;
`;

const StatsTable = styled.table`
    width: 500px;
    @media (max-width: 1279px) {
        width: auto;
    }
`;

interface IIntervalProps {
    heartbeatStats: IIntervalStats,
    backupStats: IIntervalStats,
    errorStats: IIntervalStats
};

const IntervalStats = (props: IIntervalProps) => {
    return <StatsTable>
        <tbody>
            <tr>
                <LabelContainer>Past 24 hours</LabelContainer>
                <BigNumberContainer value={props.heartbeatStats.day}>{props.heartbeatStats.day}</BigNumberContainer>
                <BigNumberContainer value={props.backupStats.day}>{props.backupStats.day}</BigNumberContainer>
                <BigNumberContainer value={props.errorStats.day}>{props.errorStats.day}</BigNumberContainer>
            </tr>
            <tr>
                <td></td>
                <LabelContainer>Heartbeat lost</LabelContainer>
                <LabelContainer>Backup errors</LabelContainer>
                <LabelContainer>Errors</LabelContainer>
            </tr>
            <tr>
                <LabelContainer>Past week</LabelContainer>
                <SmallNumberContainer value={props.heartbeatStats.week}>{props.heartbeatStats.week}</SmallNumberContainer>
                <SmallNumberContainer value={props.backupStats.week}>{props.backupStats.week}</SmallNumberContainer>
                <SmallNumberContainer value={props.errorStats.week}>{props.errorStats.week}</SmallNumberContainer>
            </tr>
        </tbody>
    </StatsTable>;
};

export default IntervalStats;