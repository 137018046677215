import styled from "styled-components";

import Ripple from 'src/SharedComponents/Ripple';
import handleKeyboardSelect from 'src/UsefulFunctions/handleKeyboardSelect';

interface IProps {
    checked: boolean,
    disabled?: boolean,
    onChange: (checked: boolean) => void
};

interface IContainerStyleProps {
    color: string
};

const CheckboxContainer = styled.div<IContainerStyleProps>`
color: ${props => props.color};
border-radius: 50%;
cursor:pointer;
`;

const CheckboxInnerContainer = styled.div`
border-radius: 50%;
`;

const CheckboxSVG = styled.svg<IContainerStyleProps>`
box-sizing: border-box;
color:${props => props.color};
cursor:pointer;
display:block;
fill:${props => props.color};
flex-shrink:0;
font-size:24px;
height:24px;
overflow-x:hidden;
overflow-y:hidden;
text-align:center;
transition-delay:0s;
transition-duration:0.2s;
transition-property:fill;
transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
user-select:none;
width:24px;
-webkit-font-smoothing:antialiased;
-webkit-tap-highlight-color:rgba(0, 0, 0, 0);
`;

const ClickableContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
`;

const getColor = (disabled: boolean, checked: boolean) => {
    if (disabled) {
        return '#d8dadb';
    }
    if (checked) {
        return '#187fba';
    }
    return '#62676B';
};

const CheckboxElement = ({currentColor, checked}: {currentColor: string, checked: boolean}) => {
    return <CheckboxInnerContainer>
        <CheckboxSVG color={currentColor}>
            {checked && <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />}
            {!checked && <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" />}
        </CheckboxSVG>
    </CheckboxInnerContainer>;
};

const Checkbox = (props: IProps) => {
    const { checked, disabled } = props;
    const currentColor = getColor(!!disabled, checked);

    function toggleCheckbox() {
        props.onChange(!checked)
    };

    if (disabled) {
        return <Container>
            <CheckboxContainer className="checkbox" color={currentColor}>
                <CheckboxElement currentColor={currentColor} checked={checked} />
            </CheckboxContainer>
        </Container>;    
    }

    return <ClickableContainer tabIndex={0} onKeyDown={handleKeyboardSelect(toggleCheckbox)} onClick={toggleCheckbox}>
        <CheckboxContainer className="checkbox" color={currentColor}>
            <Ripple color={checked ? "rgba(24, 127, 186,0.5)" : "rgba(0,0,0,0.1)"} style={{ borderRadius: "50%" }} >
                <CheckboxElement currentColor={currentColor} checked={checked} />
            </Ripple>
        </CheckboxContainer>
    </ClickableContainer>;
};

export default Checkbox;
