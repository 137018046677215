import * as React from 'react';
import styled from 'styled-components';

import Button from 'src/SharedComponents/Button';
import Dialog from 'src/SharedComponents/Dialog/Dialog';
import DialogActions from 'src/SharedComponents/Dialog/DialogActions';
import DialogContent from 'src/SharedComponents/Dialog/DialogContent';
import DialogTitle from 'src/SharedComponents/Dialog/DialogTitle';
import EmptyStateSplashContainer from 'src/SharedComponents/EmptyStateSplash';
import Label from 'src/SharedComponents/Label';
import ListItem from 'src/SharedComponents/List/ListItem';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import ViewContainer from 'src/SharedComponents/ViewContainer';
import ManagerTitleBar from 'src/Views/Manager/ManagerTitleBar';

import ClientDetails from "./ClientDetails";

import transparentClientIcon from 'src/images/customIcons/transparentRole.png';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SessionContext } from 'src/Views/SessionContext';

import useManagerAPI from 'src/CustomHooks/useManagerAPI';

import IClient from 'src/ServerEntities/IClient';
import CreateClientDialog from './CreateClientDialog';

interface IProps {
    tablabel: string
}

interface IDisplayMenu {
    displayMenu: boolean
}

const ClientListContainer = styled.div<IDisplayMenu>`
    border-right: 1px solid #e5e5e5;
    background-color: white;
    max-width: 320px;
    min-width: 320px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    z-index: 2;

    @keyframes slideIn {
        from {
          transform: translateX(-100%);
        }
        to {
          transform: translateX(0);
        }
    }
      
    @keyframes slideOut {
        from {
          transform: translateX(0);
        }
        to {
          transform: translateX(-100%);
        }
    }

    @media (min-width: 350px) and (max-width: 768px) {
        position: absolute;
        height: 100%;
        display: ${props => (props.displayMenu ? "flex" : "none")};
        animation: ${props => (props.displayMenu ? "slideIn" : "slideOut")} 0.5s ease-in;
    }
`;

const ClientHeader = styled.h4`
    text-transform: uppercase;
    font-size: 12px;
    margin: 0;
    padding: 12px 0px 6px 12px;
`;

const HamburgerHeader = styled.div`
    display: flex;
    justify-content: flex-end;
    height: 8%;
    padding: 0;

    
    @media (min-width: 768px) {
        .faBars {
            display:none;
        }
    }
`

const ClientList = styled.div`
    overflow-y: auto;
`;

const HamburgerMenu = styled.div<IDisplayMenu>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    flex-direction: column;
    height: 100%;
    border-right: ${props => (props.displayMenu ? "none" : " 1px solid #e5e5e5")};

    .faBars {
        display: ${props => (props.displayMenu ? "none" : "block")};
        margin-top: 12px;
        margin-bottom: auto;
    }

    @media (min-width: 768px) {
        display: none;
    }
`;


const clientHasId = (id?: number) => (client: IClient) => id === client.id;

const ClientsTab = (props: IProps) => {
    const [selectedClientId, setSelectedClientId] = React.useState(undefined as unknown as number | undefined);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [refresh, setRefresh] = React.useState(0);
    const [showAddClientDialog, setShowAddClientDialog] = React.useState(false);
    const { webToken, loggedInUser } = React.useContext(SessionContext).state;

    const clientListContainerReference = React.useRef(null as unknown as HTMLDivElement);

    const { data, loading, error } = useManagerAPI("client", "GET", {}, webToken, refresh);
    const { data: disabledData, loading: disabledLoading, error: disabledError } = useManagerAPI("client/disabled", "GET", {}, webToken, refresh);

    const clients = data ? [...data] : [];
    const disabledClients = disabledData ? [...disabledData] : [];

    const [displayMenu, setDisplayMenu] = React.useState(false);

    React.useEffect(() => {
        setRefresh(1);
    }, []);

    const changeSelectedClient = (client: IClient) => () => {
        setSelectedClientId(client.id);
    }

    const refreshClients = () => {
        setRefresh(refresh + 1);
    }

    const closeErrorDialog = () => {
        setErrorMessage("");
    }

    const buildClientItem = (client: IClient, index: number) => {
        return <ListItem style={{ marginLeft: "8px" }} selected={selectedClientId !== undefined && selectedClientId === client.id} key={`${client.id}-${index}`} onClick={changeSelectedClient(client)}>
            <Label>
                <h2>{client.name}</h2>
            </Label>
        </ListItem>;
    }

    const selectedClient = clients.concat(disabledClients).find(clientHasId(selectedClientId));


    const openAddClientDialog = () => {
        setShowAddClientDialog(true);
    }

    const closeAddClientDialog = () => {
        setShowAddClientDialog(false);
    }

    const closeAddClientDialogAndRefresh = () => {
        setShowAddClientDialog(false);
        refreshClients();
    }

    const handleDisplayMenu = () => {
        if(displayMenu) {
            setDisplayMenu(false)
        } else {
            setDisplayMenu(true)
        }
    }

    return <ViewContainer style={{ backgroundColor: "#fafafa" }}>
        <CreateClientDialog
            show={showAddClientDialog}
            onClose={closeAddClientDialog}
            onConfirmClientAdd={closeAddClientDialogAndRefresh}
        />
        <ErrorDialog message={errorMessage} onClose={closeErrorDialog} />
        <ManagerTitleBar viewName="Clients" viewDescription="Here you can enable/disable clients and assign sites to those clients.">
            <Button onClick={openAddClientDialog}>Add client</Button>
        </ManagerTitleBar>
        <ViewContainer style={{ flexDirection: "row" }}>
            <ClientListContainer displayMenu={ displayMenu }>
                <LoadingIndicator type="Linear" show={loading || disabledLoading} />
                <ClientList ref={clientListContainerReference} style={{ flex: "1 1 auto" }}>
                    <HamburgerHeader>
                        <FontAwesomeIcon onClick={handleDisplayMenu} className='faPlus' icon={faPlus}  style={{ transform: "scale(1)", zIndex: "1", rotate: "45deg", margin: "8px"  }}/>
                    </HamburgerHeader>
                    {data && clientListContainerReference.current ? <div>
                        <ClientHeader>Enabled Clients</ClientHeader>
                        {clients.map(buildClientItem)}
                        <ClientHeader>Disabled Clients</ClientHeader>
                        {disabledClients.map(buildClientItem)}
                    </div> : null}
                    {error && error}
                    {disabledError && disabledError}
                </ClientList>

            </ClientListContainer>
            <HamburgerMenu onClick={ handleDisplayMenu } displayMenu={ displayMenu }>
                <FontAwesomeIcon className='faBars' icon={faBars}  style={{ transform: "scale(1)", zIndex: "1"  }}/>
            </HamburgerMenu>
            {!selectedClient && <EmptyState />}
            {selectedClient && <ClientDetails
                key={selectedClientId}
                selectedClient={selectedClient}
                webToken={webToken}
                currentUser={loggedInUser}
                refreshClients={refreshClients}
                setErrorMessage={setErrorMessage} />}

        </ViewContainer>
    </ViewContainer>
};

const EmptyState = () => <EmptyStateSplashContainer>
    <img alt="No client selected" src={transparentClientIcon} style={{ marginBottom: "8px", opacity: 0.3 }} />
    <h1>No client selected</h1>
    <h2>Select a client on the left to view the client&apos;s IMX-CR functionality.</h2>
</EmptyStateSplashContainer>;

const ErrorDialog = ({ message, onClose }: { message: string, onClose: () => void }) => <Dialog open={message !== ""} onClose={onClose}>
    <DialogTitle>Error</DialogTitle>
    <DialogContent style={{ lineHeight: "25px" }}>{message}</DialogContent>
    <DialogActions><Button onClick={onClose}>OK</Button></DialogActions>
</Dialog>;

export default ClientsTab;
