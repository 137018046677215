import * as React from 'react';
import styled from 'styled-components';
import { BACKGROUND_COLOURS } from 'src/ServerEntities/IAlert';
import IUser from 'src/ServerEntities/IUser';

import { SessionContext } from 'src/Views/SessionContext';

import Button from 'src/SharedComponents/Button';
import Dialog from 'src/SharedComponents/Dialog/Dialog';
import DialogActions from 'src/SharedComponents/Dialog/DialogActions';
import ErrorBox from 'src/SharedComponents/ErrorBox';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import TextInput from 'src/SharedComponents/TextInput';
import ManagerTitleBar from 'src/Views/Manager/ManagerTitleBar';

import { createUser } from "./userManagementService";

const UserFieldsContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px;
`;

const UserFieldLine = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
`;
const UserFieldCell = styled.div`
    width: 100%;
`;

const StatusContainer = styled.div`
    height: 70px;
    text-align: center;
`;

const MainContainer = styled.div`
    display:flex;
    height: 100%;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: space-around;
    justify-content: center;
    align-items: center;
`;

interface IColourIconProps {
    colour: BACKGROUND_COLOURS
};

const ColourIcon = styled.i<IColourIconProps>`
    width: 36px;
    height: 36px;
    border-radius: 18px;
    margin: 0 12px;
    position: relative;
    font-style: normal;
    cursor: pointer;
    background-color: ${props => props.colour};
    &.selected::before {
        font-weight: bold;
        content: '\\2227';
        position: absolute;
        left: 11px;
        top: -20px;
    }
    &.selected::after {
        font-weight: bold;
        content: '\\2228';
        position: absolute;
        left: 11px;
        top: 31px;
    }
`;

interface IProps {
    show: boolean,
    onClose: () => void,
    onConfirmUserAdd: () => void
};

const COLOURS: BACKGROUND_COLOURS[] = ['#543b64', '#6b818d', '#64c2cb', '#aad4db', '#7b6e62', '#a9c436', '#e2968b', '#d9c70c'];

const CreateUserDialog = (props: IProps) => {
    const { webToken } = React.useContext(SessionContext).state;
    const [usernameValue, setUsernameValue] = React.useState("");
    const [firstNameValue, setFirstNameValue] = React.useState("");
    const [lastNameValue, setLastNameValue] = React.useState("");
    const [organisationValue, setOrganisationValue] = React.useState("");
    const [departmentValue, setDepartmentValue] = React.useState("");
    const [phoneNumber, setPhoneNumber] = React.useState("");
    const [selectedColour, setSelectedColour] = React.useState(COLOURS[0]);
    const [userToAdd, setUserToAdd] = React.useState({} as IUser);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [success, setSuccess] = React.useState(false);

    const closeDialog = () => {
        setUsernameValue("");
        setFirstNameValue("");
        setLastNameValue("");
        setOrganisationValue("");
        setDepartmentValue("");
        setPhoneNumber("");
        setError("");
        if (success) {
            props.onConfirmUserAdd();
            setSuccess(false);
        }
        props.onClose();
    };

    const addUsersConfirm = () => {
        setLoading(true);
        userToAdd.id = usernameValue;
        userToAdd.firstName = firstNameValue;
        userToAdd.lastName = lastNameValue;
        userToAdd.department = departmentValue;
        userToAdd.organisation = organisationValue;
        userToAdd.fullName = userToAdd.firstName + " " + userToAdd.lastName;
        userToAdd.colour = selectedColour;
        userToAdd.phoneNumber = phoneNumber;
        setUserToAdd(userToAdd);

        createUser(userToAdd.id, webToken, userToAdd,
            () => {
                setLoading(false)
                setSuccess(true);
                setError("");
            },
            (errorMessage) => {
                setLoading(false);
                setError(errorMessage);
                return ""
            })
    };

    const changeTextField = (setter: (value: string) => void) => (e: React.SyntheticEvent<HTMLInputElement>) => {
        setter(e.currentTarget.value);
    };

    const changeSelectedColour = (colour: BACKGROUND_COLOURS) => () => {
        setSelectedColour(colour);
    };

    return <Dialog darkBackground={true} open={props.show} onClose={closeDialog} style={{ width: "650px" }}>
        <ManagerTitleBar viewName="Create user" viewDescription="Create a new user." />
        <LoadingIndicator show={loading} type="Linear" />
        {!success && <UserFieldsContainer>
            <UserFieldLine>
                <UserFieldCell>
                    <TextInput margin="10px 10px 20px" value={usernameValue} onChange={changeTextField(setUsernameValue)} label="Email address" />
                </UserFieldCell>
                <UserFieldCell>
                    <TextInput margin="10px 10px 20px" value={phoneNumber} onChange={changeTextField(setPhoneNumber)} label="Phone number" />
                </UserFieldCell>
            </UserFieldLine>
            <UserFieldLine>
                <UserFieldCell>
                    <TextInput margin="10px 10px 20px" value={firstNameValue} onChange={changeTextField(setFirstNameValue)} label="First name" />
                </UserFieldCell>
                <UserFieldCell>
                    <TextInput margin="10px 10px 20px" value={lastNameValue} onChange={changeTextField(setLastNameValue)} label="Last name" />
                </UserFieldCell>
            </UserFieldLine>
            <UserFieldLine>
                <UserFieldCell>
                    <TextInput margin="10px 10px 20px" value={organisationValue} onChange={changeTextField(setOrganisationValue)} label="Organisation" />
                </UserFieldCell>
                <UserFieldCell>
                    <TextInput margin="10px 10px 20px" value={departmentValue} onChange={changeTextField(setDepartmentValue)} label="Department" />
                </UserFieldCell>
            </UserFieldLine>
            <UserFieldLine>
                {COLOURS.map((colour, index) => {
                    return <ColourIcon
                        key={`create-user-colour-${index}`}
                        colour={colour}
                        className={selectedColour === colour ? "selected" : "nonselected"}
                        onClick={changeSelectedColour(colour)} />;
                })}
            </UserFieldLine>
            <StatusContainer>
                {error && <ErrorBox>{error}</ErrorBox>}
            </StatusContainer>
            <DialogActions>
                <Button outlined={true} onClick={closeDialog}>Cancel</Button>
                <Button onClick={addUsersConfirm} margin="0 16px" disabled={false}>Confirm</Button>
            </DialogActions>
        </UserFieldsContainer>}
        {success && <MainContainer>
            <StatusContainer>User created successfully </StatusContainer>
            <Button onClick={closeDialog} margin="0 16px" disabled={false}>Close</Button>
        </MainContainer>}
    </Dialog>;
};

export default CreateUserDialog;
