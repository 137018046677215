import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { debounce } from 'throttle-debounce';

import { BACKGROUND_COLOURS } from 'src/ServerEntities/IAlert';
import IUser from 'src/ServerEntities/IUser';

import { SessionContext } from 'src/Views/SessionContext';

import Button from 'src/SharedComponents/Button';
import Dialog from 'src/SharedComponents/Dialog/Dialog';
import DialogActions from 'src/SharedComponents/Dialog/DialogActions';
import ErrorBox from 'src/SharedComponents/ErrorBox';
import Label from 'src/SharedComponents/Label';
import ListItem from 'src/SharedComponents/List/ListItem';
import SectionList, { ISectionListData, ISectionListRenderItemInfo } from 'src/SharedComponents/List/SectionList';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import TextInput from 'src/SharedComponents/TextInput';
import ManagerTitleBar from 'src/Views/Manager/ManagerTitleBar';

import { getTopHundredUsersToAssign, searchForUsersToAssign } from "../../Manager/UserManagement/roleManagementService";
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const UserSearchBoxContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding: 8px;
`;

interface IColourIconProps {
    backgroundColour: BACKGROUND_COLOURS
};

const ColourIcon = styled.i<IColourIconProps>`
    width: 36px;
    height: 36px;
    border-radius: 18px;
    background-color: ${props => props.backgroundColour};
    margin-right: 12px;
`;

const EmptyList = () => <div style={{ color: "#555", padding: "24px" }}>There are no more users to assign to this alert.</div>

const getSections = (users: IUser[]): Array<ISectionListData<{ title: string, description: string }>> => [
    { data: users.map((user: IUser) => ({ title: user.id, description: user.fullName })) }
];

interface IProps {
    assignUser: (itemId: number, username: string, webToken: string, onSuccess: () => void, onFailure: (errorMessage: string) => void) => void,
    show: boolean,
    itemId: number,
    onClose: () => void,
    onConfirmUserAdd: (user: IUser) => void
};

const AssignUserDialog = (props: IProps) => {
    const isSmall = useMediaQuery({
        query: '(max-width: 1279px)'
    });
    const { webToken } = React.useContext(SessionContext).state;
    const [userFilterText, setUserFilterText] = React.useState("");
    const [userToAdd, setUserToAdd] = React.useState({} as IUser);
    const [users, setUsers] = React.useState([] as IUser[]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");

    const closeDialog = () => {
        props.onClose();
    };

    React.useEffect(() => {
        setLoading(true);
        getTopHundredUsersToAssign(webToken, (usersFromServer: IUser[]) => {
            setLoading(false);
            setUsers(usersFromServer);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    }, [webToken]);


    const searchUsers = (searchText: string) => {
        if (searchText) {
            searchForUsersToAssign(searchText, webToken, (usersFromServer: IUser[]) => {
                setLoading(false);
                setUsers(usersFromServer);
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
            });
        } else {
            getTopHundredUsersToAssign(webToken, (usersFromServer: IUser[]) => {
                setLoading(false);
                setUsers(usersFromServer);
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
            });
        }
    };
    const delayedSearch = React.useRef(debounce(250, searchUsers)).current;

    const toggleUserToAdd = (user: IUser) => () => {
        setUserToAdd(user);
    };

    const addUsersConfirm = () => {
        props.assignUser(
            props.itemId,
            userToAdd.id,
            webToken,
            () => {
                props.onConfirmUserAdd(userToAdd);
                closeDialog();
            },
            (errorMessage) => {
                return ""
            });
    };

    const changeUserFilterText = (e: React.SyntheticEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;
        setLoading(true);
        delayedSearch(value);
        setUserFilterText(value);
    };

    const userMatchesUser = (userToMatch: string, user: IUser) => userToMatch === user.id;

    const renderUser = (info: ISectionListRenderItemInfo<{ title: string, description: string }>) =>
        <ListItem onClick={toggleUserToAdd(users[info.index])} key={`item${info.index}`}>
            <ColourIcon backgroundColour={users[info.index].colour} />
            <Label>
                <h2>{info.item.title}</h2>
                <h3>{info.item.description}</h3>
            </Label>
            {userMatchesUser(info.item.title, userToAdd) && <FontAwesomeIcon icon={faCheck} style={{ color: "#187fba" }} />}
        </ListItem>;

    return <Dialog open={props.show} onClose={closeDialog} style={{ width: isSmall ? "300px" : "650px", height: "550px" }}>
        <ManagerTitleBar viewName="Assign user" viewDescription="Select user below to assign to this alert." />
        <LoadingIndicator show={loading} type="Linear" />
        <UserSearchBoxContainer>
            <TextInput margin="0 24px 0 0" value={userFilterText} onChange={changeUserFilterText} placeholder="Search" />
        </UserSearchBoxContainer>
        {error && <ErrorBox>{error}</ErrorBox>}
        {users.length === 0 && <EmptyList />}
        <SectionList sections={getSections(users)} renderItem={renderUser} />
        <DialogActions>
            <Button outlined={true} onClick={closeDialog}>Cancel</Button>
            <Button onClick={addUsersConfirm} margin="0px 16px 0px 16px">Confirm</Button>
        </DialogActions>
    </Dialog>;
};

export default AssignUserDialog;
