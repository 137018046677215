import IInterfaceChange from 'src/ServerEntities/IInterfaceChange';
import dateConverter from 'src/UsefulFunctions/dateConverter';
import styled from 'styled-components';

const ServerInformationContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: #1c3e5a;
    min-width: 250px;
    & h3 {
        background-color: #246896;
        margin: 0;
        flex: 0 0 auto;
        padding: 12px 12px 6px;
        @media (min-width: 1279px) {
            border-radius: 16px 16px 0 0;
        }
        font-weight: 500;
        font-size: 1.2rem;
    }
    & div {
        padding: 6px 12px 12px;
        display: flex;
    }
    & table {
        flex: 1 1 auto;
        text-align: left;
    }
    @media (min-width: 1279px) {
        flex: 1 1 auto;
        border-radius: 16px;
    }
    @media (max-width: 1279px) {
        width: 100%;
        margin-bottom: 4px;
    }
`;

const interfaceStateToValue = (state: number) => {
    switch (state) {
        case 1:
            return "Created";
        case 2:
            return "Started";
        case 3:
            return "Stopped";
        case 4:
            return "Deleted";
        default:
            return "Unknown state change";
    }
}

interface IProps {
    interfaceChanges: IInterfaceChange[]
};

const ServerInterfaces = (props: IProps) => {
    const { interfaceChanges } = props;

    return <ServerInformationContainer>
        <h3>Interfaces</h3>
        <div>
            <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Date</th>
                        <th>Change</th>
                    </tr>
                </thead>
                <tbody>
                    {interfaceChanges.map((interfaceChange, index) => <tr key={`interface-change-${interfaceChange.name}-${index}`}>
                        <td>{interfaceChange.name}</td>
                        <td>{dateConverter(interfaceChange.date, true)}</td>
                        <td>{interfaceStateToValue(interfaceChange.state)}</td>
                    </tr>)}
                </tbody>
            </table>
        </div>
    </ServerInformationContainer>;
};

export default ServerInterfaces;
