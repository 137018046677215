import * as React from 'react';
import styled from 'styled-components';

import IAlert from 'src/ServerEntities/IAlert';
import ErrorBox from 'src/SharedComponents/ErrorBox';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import { getAlertsBySite } from 'src/Views/Ram/ramService';
import { SessionContext } from 'src/Views/SessionContext';
import Alert from './Alert';

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

interface IProps {
    site: string,
    refresh: number
};

const SiteAlertsList = (props: IProps) => {
    const { refresh, site } = props;
    const { webToken } = React.useContext(SessionContext).state;
    const [alerts, setAlerts] = React.useState([] as IAlert[]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");

    React.useEffect(() => {
        setLoading(true);
        getAlertsBySite(site, webToken, (alerts: IAlert[]) => {
            setLoading(false);
            setAlerts(alerts);
            setError("");
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    }, [refresh, site, webToken]);

    const alertToRow = (alert: IAlert, index: number) => {
        return <Alert
            alert={alert}
            closed={false}
            key={`alerts-site-${site}-alert-${index}`}
            minimised={true}
        />;
    };

    return <Container>
        <LoadingIndicator show={loading} type="Linear" />
        {error && <ErrorBox>{error}</ErrorBox>}
        {alerts && <ul>
            {alerts.map(alertToRow)}
        </ul>}
    </Container>;
};

export default SiteAlertsList;
