import { faThumbtack } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IEnsembleCommPoint from "src/ServerEntities/IEnsembleCommPoint"
import styled from "styled-components";
// import { IStatusHeader, StatusHeader } from "./StatusHeaderWidget";

interface IProps {
    selectedInterface: IEnsembleCommPoint,
    onTogglePin: () => void,
    pinned: boolean
};

interface ItemRowProps {
    status: string;
};

const EnsembleExpandedCommPoint = styled.div<ItemRowProps>`
    .ensemble-header {
        display: flex;
        padding: 16px 18px;
        justify-content: space-between;
        align-items: center;
        background-color: rgba(36, 104, 150, 1);

        h4 {
            display: flex;
            align-items: center;
            margin: 0;
        }

        .ensemble-status {
            padding: 10px 20px;
            border-radius: 16px;
            background-color: ${props => {
                switch (props.status) {
                    case 'RUNNING':
                        return '#42a83f';
                    case 'ERROR':
                        return '#ff2121';
                    case 'STOPPED':
                        return '#f99600';
                    case 'DISABLED':
                        return '#9E9E9E';
                    case 'QUEUE':
                        return '#6902de';
                    default:
                        return '#246896';
                }
            }};
        }

        
    }

    .ensemble-body {
        background-color: rgba(13, 12, 12, 0.5);
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
        padding: 14px;
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        box-sizing: border-box;

        .primary-grid {
            grid-column: 1;
            grid-row: 2;
        }

        
        .secondary-grid {
            grid-column: 2;
            grid-row: 2
        }

        li {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin: 2px;
        }
    }

    
    .container {
        background-color: #1c3e5a;
        border-radius: 16px;
    }
`;

interface IPinProps {
    $pinned: boolean
};


const PinContainer = styled.div<IPinProps>`
    transform: rotate(${(props) => props.$pinned ? "-90deg" : "0"});
    transition: transform 0.5s;
    cursor: pointer;
`;

const formatTime = (time: number) => {
    if (time < 60) {
        return `${time} seconds`;
    } else if (time < 3600) {
        const minutesAgo = Math.floor(time / 60);
        return `${minutesAgo} minutes`;
    } else if (time < 86400) {
        const hoursAgo = Math.floor(time / 3600);
        return `${hoursAgo} hours`;
    } else {
        const daysAgo = Math.floor(time / 86400);
        return `${daysAgo} days`;
    }
};

const CommPointDetails = (props: IProps) => {
    const { onTogglePin, pinned, selectedInterface } = props;
    
    return <EnsembleExpandedCommPoint status={props.selectedInterface.state}>
        <div className="container">
            <div className="ensemble-header">
                <PinContainer $pinned={pinned} onClick={onTogglePin} title={pinned ? "Unpin" : "Pin"}>
                    <FontAwesomeIcon icon={faThumbtack} />
                </PinContainer>
                <h4>{selectedInterface.name}</h4>
                <h4 className="ensemble-status">{selectedInterface.state}</h4>
            </div>
            <ul className="ensemble-body">
                <div className="primary-grid">
                    <li><b>Type: </b>{selectedInterface.type}</li>
                    <li><b>Mode: </b>{selectedInterface.mode}</li>
                    <li><b>Uptime: </b>{selectedInterface.uptime}</li>
                    <li><b>Connection Count: </b>{selectedInterface.connectionCount}</li>
                    <li><b>Messages Received Count: </b>{selectedInterface.receivedCount}</li>
                </div>
                <div className="secondary-grid">
                    <li><b>Messages Sent: </b>{selectedInterface.sentCount}</li>
                    <li><b>Folder Path: </b>{selectedInterface.folderPath}</li>
                    <li><b>Input Idle Time: </b>{formatTime(selectedInterface.inputIdleTime)}</li>
                    <li><b>Output Idle TIme: </b>{formatTime(selectedInterface.connectionCount)}</li>
                    <li><b>Input Queue Size: </b>{selectedInterface.inQueueSize}</li>
                    <li><b>Output Queue Size: </b>{selectedInterface.outQueueSize}</li>
                </div>
            </ul>
        </div>
    </EnsembleExpandedCommPoint>
}

export default CommPointDetails;