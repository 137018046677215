import styled from 'styled-components';

interface IStyleProps {
    marginLeft?: string
};

export default styled.ul<IStyleProps>`
    margin: 12px 0 0;
    margin-left: ${props => props.marginLeft ? props.marginLeft : 0};
`;
