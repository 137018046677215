import axios, { AxiosError, AxiosResponse } from "axios";

import { getAPIUrl } from 'src/config';
import IErrorDetails from "src/ServerEntities/IErrorDetails";
import IProcessThresholds from "src/ServerEntities/IProcessThresholds";
import IUser from 'src/ServerEntities/IUser';
import { NETWORK_ERROR } from 'src/UsefulFunctions/createErrorMessage';

const handleError = (error: AxiosError<IErrorDetails>, failureCallback: (errorMessage: string) => void) => {
    let errorMessage = "Unknown error";
    if (error.response) {
        errorMessage = error.response.data.message
        if (error.response.status === 401 && error.response.data.message === undefined) {
            errorMessage = "Unauthorised to perform this request."
        }
    }
    if (error.message === "Network Error") {
        errorMessage = NETWORK_ERROR;
    }
    failureCallback(errorMessage as string);
};

export const setProcessThresholds = (processThresholds: IProcessThresholds, webToken: string, successCallback: (users: IUser[]) => void, failureCallback: (errorMessage: string) => void) => {
    axios.post(
        `${getAPIUrl()}ram/thresholds/processes`,
        processThresholds,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<IUser[]>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};

export const getProcessThresholds = (webToken: string, successCallback: (processThresholds: IProcessThresholds) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(
        `${getAPIUrl()}ram/thresholds/processes`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<IProcessThresholds>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError<IErrorDetails>) => {
            handleError(error, failureCallback);
        });
};
