import IAlert from "src/ServerEntities/IAlert";
import ISupportIssue from "src/ServerEntities/ISupportIssue";

const showForCurrentUser = (username: string, viewAll: boolean) => (alert: IAlert) => {
    if (viewAll) {
        return true;
    }
    if (alert.username && alert.username !== username) {
        return false;
    }
    return true;
};

export const showSupportIssueForCurrentUser = (username: string, viewAll: boolean) => (supportIssue: ISupportIssue) => {
    if (viewAll) {
        return true;
    }
    if (supportIssue.username && supportIssue.username !== username) {
        return false;
    }
    return true;
};

export default showForCurrentUser;
