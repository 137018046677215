import styled from 'styled-components';

import IAlert from 'src/ServerEntities/IAlert';
import IDashboardInfo from "src/ServerEntities/IDashboardInfo";
import IEnsembleInterface, { IInterfaceItemAndServer } from 'src/ServerEntities/IEnsembleInterface';
import IServer from 'src/ServerEntities/IServer';
import ISiteSupportIssues from 'src/ServerEntities/ISiteSupportIssues';
import ISupportIssue from 'src/ServerEntities/ISupportIssue';
import renderSiteName from 'src/UsefulFunctions/renderSiteName';

import AlertList from './AlertList';
import DashboardGraph from './DashboardGraph';
import IntervalStats from './IntervalStats';
import { IStatusHeader, getServerStatusHeaderData, StatusHeader, getErrorStatusHeaderData } from './StatusHeaderWidget';
import DashboardAlertItem from '../DashboardAlertItem';
import DashboardInterfaceItem, { getInterfaceStatusHeaderData } from '../DashboardInterfaceItem';
import DashboardServerItem from '../DashboardServerItem';
import DashboardSupportIssueItem from '../DashboardSupportIssueItem';
import DashboardCommpointItem, { getCommpointStatusHeader } from '../DashboardCommpointItem';
import ICommpointItem from 'src/ServerEntities/IEnsembleCommPoint';
import { DateTime } from 'luxon';
import { HEARTBEAT_WARNING_LIMIT } from 'src/config';
import IQveraInformation from 'src/ServerEntities/IQveraInformation';
import DashboardQveraInformationItem, { getQveraStatusHeader } from '../DashboardQveraInformationItem';

const SiteContainer = styled.div`
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: stretch;
`;

const TopContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    flex: 1 0 auto;
`;

const LowerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  width: 100%;
  justify-content: space-between;
  & > * {
    flex-basis: 24%;
    margin-bottom: 30px;
  }
  @media (max-width: 1279px) {
    & > * {
      flex-basis: 33.33%;
    }
  }
`;

const GraphContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1 0 auto;
`;

const GraphBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const IntervalStatsContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const CardContainer = styled.article`
    background-color: #1c3e5a;
    display: flex;
    width: 24%;
    flex-direction: column;
    margin-bottom: 8px;
    @media (min-width: 1279px) {
        border-radius: 16px;
        margin-bottom: 20px;
    }
    @media (max-width: 1279px) {
        width: 100%;
    }
    & > div {   
        padding: 4px;
        @media (min-width: 1279px) {
            padding: 12px;
        }
    }
    & h3 {
        margin: 0;
        font-weight: 500;
        font-size: 1.2rem;
    }
    & dt {
        flex: 0 0 30%;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (max-width: 1279px) {
            flex: 0 0 100%;
        }
    }
    & dd {
        flex: 0 0 calc(70% - 5px);
        overflow: hidden;
        text-overflow: ellipsis;
        overflow-wrap: break-word;
        max-width: 520px;
        @media (max-width: 1279px) {
            flex: 0 0 calc(100% - 10px);
            margin: 0 0 6px 10px;
        }
    }
`;

const CardHeader = styled.header`
    flex: 1 0 auto;
    display: flex;
    max-height:32px;
    min-height:32px;
    flex-direction: row;
    flex-wrap: nowrap;
    background: #246896;
    padding: 6px 12px;
    border-radius: 16px 16px 0 0;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 1279px) {
        align-items: flex-start;
        border-radius: 0;
        padding: 4px;
    }
`;

const ServerListContainer = styled.ul`
    display: flex;
    flex-direction: column;
    & li {
        background-color: #42a83f;
    }
    & li.with-alerts {
        background-color: #f99600;
    }
    & li.qvera-state-error {
        background-color: #f99600;
    }
    & li.without-heartbeat {
        background-color: #ff2121;
    }
    padding: 5px;
`;

const Container = styled.ul`
    display: flex;
    flex-direction: column;
    padding: 10px;
`;

const EnsembleListStatusContainer = styled.ul`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-content: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    & li.Active, li {
        background-color: #42a83f;
    }

    & li.Error {
        background-color: #ff2121;
    }

    & li.Stopped {
        background-color: #f99600;
    }

    & li.Queue {
        background-color: #6902de;
    }

    & li.Disabled {
        background-color: #9e9e9e;
    }
`;

const EnsembleCommPointStatusContainer = styled.ul`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-content: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;

    & li.RUNNING, li {
        background-color: #42a83f;
    }

    & li.ERROR {
        background-color: #ff2121;
    }

    & li.STOPPED {
        background-color: #f99600;
    }

    & li.QUEUE {
        background-color: #6902de;
    }

    & li.DISABLED {
        background-color: #9e9e9e;
    }
`;

const GraphTitle = styled.h3`
    text-align: center;
    font-weight: 500;
    font-size: 1.2rem;
    margin: 0;
`;

interface IProps {
    dashboardInfo: IDashboardInfo
};

const sortAlerts = (alertA: IAlert, alertB: IAlert) => {
    const timeA = alertA.lastUpdate || alertA.timestamp;
    const timeB = alertB.lastUpdate || alertB.timestamp;
    return timeB - timeA;
};

const getServerStatus = (server: IServer) => {

    const alerts = server.alerts > 0;
    const heartbeatHealthy = server.lastHeartbeat > (DateTime.now().toMillis() - HEARTBEAT_WARNING_LIMIT);

    if (!heartbeatHealthy) {
        return 0;

    }
    if (heartbeatHealthy && alerts) {
        return 1;
    }
    return 2;
}

const sortServers = (serverA: IServer, serverB: IServer) => {
    const statusA = getServerStatus(serverA);
    const statusB = getServerStatus(serverB);

    if (statusA < statusB) {
        return -1;
    } else if (statusA > statusB) {
        return 1;
    } else {
        return 0;
    }
};

const sortInterfaceServers = (serverA: IInterfaceItemAndServer, serverB: IInterfaceItemAndServer) => {
    const nameA = `${serverA.namespace || ''}${serverA.production || ''}`;
    const nameB = `${serverB.namespace || ''}${serverB.production || ''}`;
    return nameA.localeCompare(nameB);
};

const getInterfaceStatus = (ensembleInterface: IEnsembleInterface) => {

    if (ensembleInterface.status === "Stopped") {
        return 3;
    } else if (ensembleInterface.status === "Error") {
        return 4;
    } else if (ensembleInterface.status === "Disabled") {
        return 2;
    } else if (ensembleInterface.status === "Queue") {
        return 1;
    } else {
        return 0;
    }
}

const sortInterfaces = (interfaceA: IEnsembleInterface, interfaceB: IEnsembleInterface) => {
    const statusA = getInterfaceStatus(interfaceA);
    const statusB = getInterfaceStatus(interfaceB);

    if (statusA > statusB) {
        return -1;
    } else if (statusA < statusB) {
        return 1;
    } else {
        return 0;
    }
};

const alertToItem = (alert: IAlert, index: number) => {
    return <DashboardAlertItem
        key={`alerts-view-alert-${index}`}
        alert={alert}
        closed={false}
    />;
};

const supportIssueToItem = (server: string, closed: boolean) =>
    (issue: ISupportIssue, index: number) => {
        return <DashboardSupportIssueItem
            key={`support-issues-view-issue-${index}`}
            issue={issue}
            server={server}
            closed={closed}
        />;
    };

const serverToSupportItem = (closed: boolean, username: string, viewAll: boolean) =>
    (site: ISiteSupportIssues, index: number) => {
        return <AlertList>
            {site.supportIssues
                .sort((issueA: ISupportIssue, issueB: ISupportIssue) => issueB.date - issueA.date)
                .map(supportIssueToItem(site.site, closed))}
        </AlertList>
    };


const ClientView = (props: IProps) => {
    const dashboardInfo = props.dashboardInfo;
    const serverStatusHeader: IStatusHeader | undefined = dashboardInfo ? getServerStatusHeaderData(dashboardInfo.servers) : undefined;
    const interfacesStatusHeader: IStatusHeader | undefined = dashboardInfo ? getInterfaceStatusHeaderData(dashboardInfo.interfaces) : undefined;
    const commpointsStatusHeader: IStatusHeader | undefined = dashboardInfo ? getCommpointStatusHeader(dashboardInfo.commpoints) : undefined;
    const qveraStatusHeader: IStatusHeader | undefined = dashboardInfo ? getQveraStatusHeader(dashboardInfo.qveraInformation) : undefined;
    const errorStatusHeader: IStatusHeader | undefined = dashboardInfo ? getErrorStatusHeaderData(dashboardInfo.alerts) : undefined;
    const getLicenceData: number[] = (dashboardInfo && dashboardInfo.licences.length > 0) ?
        [dashboardInfo.licences.reduce((a, c) => a + c.available, 0), dashboardInfo.licences.reduce((a, c) => a + c.currentlyInUse, 0)] :
        [];

    const serverToRow = () => (server: IServer, index: number) => {
        const siteName = server.siteName ? server.siteName : "";
        return <DashboardServerItem displaySite={true} siteName={siteName} server={server} key={`site-${siteName}-server-${server.name}-${index}`} />;
    };

    const ensembleInterfaceToRow = (site: string, serverName: string, namespace: string) => (ensembleInterface: IEnsembleInterface, index: number) => {
        return <DashboardInterfaceItem
            serverName={serverName}
            site={site}
            namespace={namespace}
            key={`interface-view-${index}`}
            ensembleInterface={ensembleInterface}
        />;
    };

    const ensembleServerToRow = (server: IInterfaceItemAndServer, index: number) => {
        return <div key={`ensemble-server-row-${server.name}-${index}`}>
            <h3>{[server.server, server.namespace, server.production].filter(a=>a).join(" - ")}</h3>
            <EnsembleListStatusContainer>
                {server.ensembleInterfaces.sort(sortInterfaces).map(ensembleInterfaceToRow(server.site, server.server, server.namespace))}
            </EnsembleListStatusContainer>
        </div>
    };

    const ensembleCommpointToRow = (commpoint: ICommpointItem, index: number) => {
        return <DashboardCommpointItem commpoint={commpoint} key={`commpoint-${commpoint.name}-${index}`} />;
    };

    const qveraInformationToRow = (qveraInformation: IQveraInformation, index: number) => {
        return <DashboardQveraInformationItem qveraInformation={qveraInformation} key={`qvera-info-${qveraInformation.server}-${index}`} />;
    };

    const getServersWithParentSite = (): IServer[] => {
        return dashboardInfo.servers.flatMap(({ site, alias, servers }) =>
            servers.map(server => ({
                ...server,
                siteName: renderSiteName(site, alias)
            }))
        );
    };

    return <SiteContainer>
        <TopContainer>
            <GraphContainer>
                <GraphBox>
                    <GraphTitle>Servers</GraphTitle>
                    <DashboardGraph labels={["Healthy", "Backup error", "Lost heartbeat"]} data={[
                        serverStatusHeader ? serverStatusHeader.green : 0,
                        serverStatusHeader ? serverStatusHeader.yellow : 0,
                        serverStatusHeader ? serverStatusHeader.red : 0]} />
                </GraphBox>
                {
                    dashboardInfo.interfaces.length > 0 && (
                        <GraphBox>
                            <GraphTitle>Interfaces</GraphTitle>
                            <DashboardGraph labels={["Active", "Stopped", "Error", "Queue", "Disabled"]} data={[
                                interfacesStatusHeader ? interfacesStatusHeader.green : 0,
                                interfacesStatusHeader ? interfacesStatusHeader.yellow : 0,
                                interfacesStatusHeader ? interfacesStatusHeader.red : 0,
                                interfacesStatusHeader && interfacesStatusHeader.purple ? interfacesStatusHeader.purple : 0,
                                interfacesStatusHeader && interfacesStatusHeader.grey ? interfacesStatusHeader.grey : 0
                            ]} />
                        </GraphBox>
                    )
                }

                {dashboardInfo.commpoints.length > 0 && (
                    <GraphBox>
                        <GraphTitle>Commpoints</GraphTitle>
                        <DashboardGraph labels={["Active", "Stopped", "Error", "Queue", "Disabled"]} data={[
                            commpointsStatusHeader ? commpointsStatusHeader.green : 0,
                            commpointsStatusHeader ? commpointsStatusHeader.yellow : 0,
                            commpointsStatusHeader ? commpointsStatusHeader.red : 0,
                            commpointsStatusHeader && commpointsStatusHeader.purple ? commpointsStatusHeader.purple : 0,
                            commpointsStatusHeader && commpointsStatusHeader.grey ? commpointsStatusHeader.grey : 0
                        ]} />
                    </GraphBox>
                )
                }

                {dashboardInfo.qveraInformation.length > 0 && <GraphBox>
                    <GraphTitle>QVera Channels</GraphTitle>
                    <DashboardGraph labels={["Active", "Stopped", "Error", "Queue", "Disabled"]} data={[
                        qveraStatusHeader ? qveraStatusHeader.green : 0,
                        qveraStatusHeader ? qveraStatusHeader.yellow : 0,
                        qveraStatusHeader ? qveraStatusHeader.red : 0,
                        qveraStatusHeader && qveraStatusHeader.purple ? qveraStatusHeader.purple : 0,
                        qveraStatusHeader && qveraStatusHeader.grey ? qveraStatusHeader.grey : 0
                    ]} />
                </GraphBox>}

                {getLicenceData.length > 0 && <GraphBox>
                    <GraphTitle>Licences</GraphTitle>
                    <DashboardGraph labels={["free", "used"]} data={getLicenceData} />
                </GraphBox>}
            </GraphContainer>
            <IntervalStatsContainer>
                <IntervalStats
                    heartbeatStats={dashboardInfo.heartbeatStats}
                    backupStats={dashboardInfo.backupStats}
                    errorStats={dashboardInfo.alertStats}
                />
            </IntervalStatsContainer>
        </TopContainer>
        <LowerContainer>
            <CardContainer>
                <CardHeader>
                    <h3>Servers</h3>
                    {StatusHeader(serverStatusHeader, false)}
                </CardHeader>
                <ServerListContainer>
                    {getServersWithParentSite().sort(sortServers).map(serverToRow())}
                </ServerListContainer>
            </CardContainer>
            {
                dashboardInfo.interfaces.length > 0 && (
                    <CardContainer>
                        <CardHeader>
                            <h3>Interfaces</h3>
                            {StatusHeader(interfacesStatusHeader, false, true, true)}
                        </CardHeader>
                        <Container>
                            {dashboardInfo.interfaces.sort(sortInterfaceServers).map(ensembleServerToRow)}
                        </Container>
                    </CardContainer>
                )
            }
            <CardContainer>
                <CardHeader>
                    <h3>Errors</h3>
                    {StatusHeader(errorStatusHeader, true)}
                </CardHeader>
                <Container>
                    {dashboardInfo.alerts.flatMap(serverAlerts => serverAlerts.alerts).sort(sortAlerts).map(alertToItem)}
                </Container>
            </CardContainer>
            <CardContainer>
                <CardHeader>
                    <h3>Support Tickets</h3>
                </CardHeader>
                <Container>
                    {dashboardInfo.supportItems.map(serverToSupportItem(true, "", true))}
                </Container>
            </CardContainer>
            {dashboardInfo.commpoints.length > 0 && (
                <CardContainer>
                    <CardHeader>
                        <h3>Commpoints</h3>
                        {StatusHeader(commpointsStatusHeader, false, true, true)}
                    </CardHeader>
                    <Container>
                        <EnsembleCommPointStatusContainer>
                            {dashboardInfo.commpoints.map(ensembleCommpointToRow)}
                        </EnsembleCommPointStatusContainer>
                    </Container>
                </CardContainer>
            )}
            {dashboardInfo.qveraInformation.length > 0 && (
                <CardContainer>
                    <CardHeader>
                        <h3>QVera Channels</h3>
                        {StatusHeader(qveraStatusHeader, false, true, true)}
                    </CardHeader>
                    <Container>
                        <EnsembleCommPointStatusContainer>
                            {dashboardInfo.qveraInformation.map(qveraInformationToRow)}
                        </EnsembleCommPointStatusContainer>
                    </Container>
                </CardContainer>
            )}
        </LowerContainer>
    </SiteContainer>;
};

export default ClientView;
