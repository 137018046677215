import styled from "styled-components";
import summaryIcon from 'src/images/customIcons/dots_summary.png';
import detailIcon from 'src/images/customIcons/dots_detail.png';

export const TopBarContainer = styled.div`
    background-color: #246896;
    margin: 0;
    flex: 0 0 auto;
    @media (min-width: 1279px) {
        border-radius: 16px 16px 0 0;
    }
    font-weight: 500;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 28px;
    cursor: pointer;
`;

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
`;

export const getSummaryIcon = summaryIcon;
export const getDetailIcon = detailIcon;