import { useEffect } from 'react';

// Hook
function useOnClickOutside(ref: { current: HTMLElement }, linkRef: { current: HTMLElement }, handler: (event: Event) => void) {
    useEffect(
        () => {
            const listener = (event: Event) => {
                // Do nothing if clicking ref's element or descendent elements
                if (!ref.current || ref.current.contains(event.target as Node) || linkRef.current === event.target) {
                    return;
                }

                handler(event);
            };

            document.addEventListener('click', listener);

            return () => {
                document.removeEventListener('click', listener);
            };
        },
        // Add ref and handler to effect dependencies
        // It's worth noting that because passed in handler is a new ...
        // ... function on every render that will cause this effect ...
        // ... callback/cleanup to run every render. It's not a big deal ...
        // ... but to optimize you can wrap handler in useCallback before ...
        // ... passing it into this hook.
        [ref, linkRef, handler]
    );
}

export default useOnClickOutside;
