import React from "react";
import useEffectOnSome from "src/CustomHooks/useEffectOnSome";
import IAlertExceptions, { ITimeException } from "src/ServerEntities/IAlertExceptions";
import IAlertRule from "src/ServerEntities/IAlertRule";
import Button from "src/SharedComponents/Button";
import Checkbox from "src/SharedComponents/Checkbox";
import Dialog from "src/SharedComponents/Dialog/Dialog";
import ErrorText from "src/SharedComponents/ErrorText";
import LoadingIndicator from "src/SharedComponents/LoadingIndicator";
import styled from "styled-components";
import { deleteAlertRuleException, getAlertRuleExceptions, saveNewAlertRuleException, updateAlertRuleException, updateAlertRuleSilencedOnHolidays } from "../UserManagement/smsNotificationService";
import AlertExceptionRow from "./AlertExceptionRow";
import RuleRow from "./RuleRow";

interface IProps {
    ruleOpen: IAlertRule,
    setError: (errorMessage: string) => void,
    setRuleOpen: (rule: IAlertRule | null) => void,
    webToken: string
};

const Content = styled.div`
    padding: 6px 12px 12px;
`;

const Title = styled.h1`
    background: #187fba;
    color: #ffffff;
    border-radius: 16px 16px 0 0;
    margin: 0;
    padding: 3px 12px;
    font-size: 1.7rem;
    font-weight: 600;
`;

const SubTitle = styled.h2`
    margin: 12px 0;
    font-size: 1.6rem;
    font-weight: 600;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

const DIALOG_STYLE = {
    maxWidth: "1200px",
    width: "1200px"
};

const SavedText = styled.p`
    color: #16B012;
    margin: 0 0 12px 0;
    &:before {
        content: '\u2713';
        margin-right: 4px;
    }
`;

const AlertRuleDetails = (props: IProps) => {
    const { ruleOpen, setError, setRuleOpen, webToken } = props;
    const [loading, setLoading] = React.useState(false);
    const [saved, setSaved] = React.useState(false);
    const [refresh, setRefresh] = React.useState(0);
    const [exceptionError, setExceptionError] = React.useState("");
    const [alertExceptions, setAlertExceptions] = React.useState(null as unknown as IAlertExceptions);
    const [onHolidays, setOnHolidays] = React.useState(true);

    const closeDialog = () => {
        setRuleOpen(null);
    };

    useEffectOnSome(() => {
        setLoading(true);
        getAlertRuleExceptions(ruleOpen.id, webToken, (alertExceptions: IAlertExceptions) => {
            setAlertExceptions(alertExceptions);
            setOnHolidays(!alertExceptions.silencedOnHolidays);
            setError("");
            setLoading(false);
        }, (errorMessage: string) => {
            setError(errorMessage);
            setLoading(false);
        });
    }, [setError], [webToken, refresh, ruleOpen.id]);

    const createNew = () => {
        setLoading(true);
        saveNewAlertRuleException({ ruleId: ruleOpen.id }, webToken, (id: number) => {
            setLoading(false);
            setExceptionError("");
            setRefresh(refresh + 1);
        }, (errorMessage: string) => {
            setLoading(false);
            setExceptionError(errorMessage);
        });
    };

    const deleteRow = (id: number) => {
        setLoading(true);
        deleteAlertRuleException(id, webToken, () => {
            setLoading(false);
            setExceptionError("");
            setRefresh(refresh + 1);
        }, (errorMessage: string) => {
            setLoading(false);
            setExceptionError(errorMessage);
        });
    };

    const toggleSilencedOnHolidays = () => {
        setLoading(true);
        updateAlertRuleSilencedOnHolidays(ruleOpen.id, !alertExceptions.silencedOnHolidays, webToken, () => {
            setLoading(false);
            setExceptionError("");
            setOnHolidays(!onHolidays);
        }, (errorMessage: string) => {
            setLoading(false);
            setExceptionError(errorMessage);
        });
    };

    const saveChanges = (exception: ITimeException) => {
        if (exception.id) {
            setLoading(true);
            setSaved(false);
            updateAlertRuleException(exception.id, exception, webToken, () => {
                setLoading(false);
                setSaved(true);
                setError("");
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
            });
        }
    }

    return <Dialog open={ruleOpen !== null} onClose={closeDialog} darkBackground={true} style={DIALOG_STYLE}>
        <Title>Rule details</Title>
        <Content>
            <table>
                <tbody>
                    {ruleOpen !== null && <RuleRow key={`rule-row-${ruleOpen.id}`} rule={ruleOpen} setRuleOpen={setRuleOpen} setError={setError} webToken={webToken} onReload={() => { }} />}
                </tbody>
            </table>
            <div>
                <LoadingIndicator show={loading} type="Linear" />
                <SubTitle>Exceptions</SubTitle>
                <table>
                    <tbody>
                        {alertExceptions && alertExceptions.timeExceptions.map((exception, index) => {
                            return <AlertExceptionRow onSave={saveChanges} exception={exception} key={`alert-exception-${ruleOpen.id}-${index}`} onDelete={deleteRow} />;
                        })}
                    </tbody>
                </table>
                {saved && <SavedText>Saved</SavedText>}
                {exceptionError && <ErrorText>{exceptionError}</ErrorText>}
                <Button onClick={createNew}>Add exception</Button>
                <Row>
                    Send notifications during public holidays? <Checkbox checked={onHolidays} onChange={toggleSilencedOnHolidays} />
                </Row>
            </div>
        </Content>
    </Dialog>
};

export default AlertRuleDetails;
