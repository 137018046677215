import * as React from 'react';
import styled from 'styled-components';

import Button from 'src/SharedComponents/Button';
import Dialog from 'src/SharedComponents/Dialog/Dialog';
import DialogActions from 'src/SharedComponents/Dialog/DialogActions';
import DialogContent from 'src/SharedComponents/Dialog/DialogContent';
import DialogTitle from 'src/SharedComponents/Dialog/DialogTitle';
import EmptyStateSplashContainer from 'src/SharedComponents/EmptyStateSplash';
import Label from 'src/SharedComponents/Label';
import ListItem from 'src/SharedComponents/List/ListItem';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import ViewContainer from 'src/SharedComponents/ViewContainer';
import ManagerTitleBar from 'src/Views/Manager/ManagerTitleBar';

import RoleDetails from "./RoleDetails";

import transparentRoleIcon from 'src/images/customIcons/transparentRole.png';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { RolesContext } from 'src/Roles/RolesContext';
import { SessionContext } from 'src/Views/SessionContext';

import useManagerAPI from 'src/CustomHooks/useManagerAPI';

import IRole from 'src/ServerEntities/IRole';
import CreateRoleDialog from './CreateRoleDialog';

interface IProps {
    tablabel: string
}

interface IDisplayMenu {
    displayMenu: boolean
}

const RoleListContainer = styled.div<IDisplayMenu>`
    border-right: 1px solid #e5e5e5;
    background-color: white;
    max-width: 320px;
    min-width: 320px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    z-index: 2;

    @keyframes slideIn {
        from {
          transform: translateX(-100%);
        }
        to {
          transform: translateX(0);
        }
    }
      
    @keyframes slideOut {
        from {
          transform: translateX(0);
        }
        to {
          transform: translateX(-100%);
        }
    }

    @media (min-width: 350px) and (max-width: 768px) {
        position: absolute;
        height: 100%;
        display: ${props => (props.displayMenu ? "flex" : "none")};
        animation: ${props => (props.displayMenu ? "slideIn" : "slideOut")} 0.5s ease-in;
    }
`;

const RoleHeader = styled.h4`
    display: flex;
    alignt-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    font-size: 12px;
    margin: 0;
    padding: 12px 0px 6px 12px;
    z-index: 2;
    height: 50%;

    @media (min-width: 768px) {
        img {
            display:none;
        }
    }
`;

const RoleList = styled.div`
    display: grid;
    grid-template-rows: 10% auto;
    overflow-y: auto;
`;

const HamburgerMenu = styled.div<IDisplayMenu>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    flex-direction: column;
    height: 100%;
    border-right: ${props => (props.displayMenu ? "none" : " 1px solid #e5e5e5")};

    .faBars {
        display: ${props => (props.displayMenu ? "none" : "block")};
        margin-top: 12px;
        margin-bottom: auto;
    }

    @media (min-width: 768px) {
        display: none;
    }
`;


const roleHasId = (id?: string) => (role: IRole) => id === role.id;

const RolesTab = (props: IProps) => {
    const [selectedRoleId, setSelectedRoleId] = React.useState(undefined as unknown as string | undefined);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [refresh, setRefresh] = React.useState(0);
    const [showAddRoleDialog, setShowAddRoleDialog] = React.useState(false);

    const { webToken, loggedInUser } = React.useContext(SessionContext).state;
    const currentRole = React.useContext(RolesContext).state.role || "";

    const roleListContainerReference = React.useRef(null as unknown as HTMLDivElement);

    const { data, loading, error } = useManagerAPI("roles", "GET", {}, webToken, refresh);
    const { data: disabledData, loading: disabledLoading, error: disabledError } = useManagerAPI("roles/disabled", "GET", {}, webToken, refresh);

    const roles = data ? [...data] : [];
    const disabledRoles = disabledData ? [...disabledData] : [];

    const [displayMenu, setDisplayMenu] = React.useState(false);

    React.useEffect(() => {
        setRefresh(1);
    }, []);

    const changeSelectedRole = (role: IRole) => () => {
        setSelectedRoleId(role.id);
    }

    const refreshRoles = () => {
        setRefresh(refresh + 1);
    }

    const closeErrorDialog = () => {
        setErrorMessage("");
    }

    const buildRoleItem = (role: IRole, index: number) => {
        return <ListItem style={{ marginLeft: "8px" }} selected={selectedRoleId !== undefined && selectedRoleId === role.id} key={role.id + index} onClick={changeSelectedRole(role)}>
            <Label>
                <h2>{role.id}</h2>
                <h3 style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{role.description}</h3>
            </Label>
        </ListItem>;
    }

    const selectedRole = roles.concat(disabledRoles).find(roleHasId(selectedRoleId));


    const openAddRoleDialog = () => {
        setShowAddRoleDialog(true);
    }

    const closeAddRoleDialog = () => {
        setShowAddRoleDialog(false);
    }

    const closeAddRoleDialogAndRefresh = () => {
        setShowAddRoleDialog(false);
        refreshRoles();
    }

    const handleDisplayMenu = () => {
        if(displayMenu) {
            setDisplayMenu(false)
        } else {
            setDisplayMenu(true)
        }
    }

    return <ViewContainer style={{ backgroundColor: "#fafafa" }}>
        <CreateRoleDialog
            show={showAddRoleDialog}
            onClose={closeAddRoleDialog}
            onConfirmRoleAdd={closeAddRoleDialogAndRefresh}
        />
        <ErrorDialog message={errorMessage} onClose={closeErrorDialog} />
        <ManagerTitleBar viewName="Roles" viewDescription="Here you can enable/disable roles and assign users and applications to those roles. The role determines the IMX-CR functionality the user has access to.">
            <Button onClick={openAddRoleDialog}>Add role</Button>
        </ManagerTitleBar>
        <ViewContainer style={{ display: "flex", flexDirection: "row"  }}>
            <RoleListContainer displayMenu={ displayMenu }>
                <LoadingIndicator type="Linear" show={loading || disabledLoading} />

                <RoleList ref={roleListContainerReference} style={{ flex: "1 1 auto" }}>
                    {data && roleListContainerReference.current ? <div>
                        <RoleHeader style={{gridColumn: "1", width: "90%"}}>
                            <p>Enabled Roles</p>
                            <FontAwesomeIcon onClick={handleDisplayMenu} className='faPlus' icon={faPlus}  style={{ transform: "scale(1.5)", zIndex: "1", rotate: "45deg"  }}/>
                        </RoleHeader>
                        {roles.map(buildRoleItem)}
                        <RoleHeader>Disabled Roles</RoleHeader>
                        {disabledRoles.map(buildRoleItem)}
                    </div> : null}
                    {error && error}
                    {disabledError && disabledError}
                </RoleList>
            </RoleListContainer>
            <HamburgerMenu onClick={ handleDisplayMenu } displayMenu={ displayMenu }>
                <FontAwesomeIcon className='faBars' icon={faBars}  style={{ transform: "scale(1)", zIndex: "1"  }}/>
            </HamburgerMenu>
            {!selectedRole && <EmptyState />}
            {selectedRole && <RoleDetails
                key={selectedRoleId}
                selectedRole={selectedRole}
                webToken={webToken}
                currentUser={loggedInUser}
                refreshRoles={refreshRoles}
                currentRole={currentRole}
                setErrorMessage={setErrorMessage} />}
        </ViewContainer>
    </ViewContainer>
};

const EmptyState = () => <EmptyStateSplashContainer>
    <img alt="No role selected" src={transparentRoleIcon} style={{ marginBottom: "8px", opacity: 0.3 }} />
    <h1>No role selected</h1>
    <h2>Select a role on the left to view the role&apos;s IMX-CR functionality.</h2>
</EmptyStateSplashContainer>;

const ErrorDialog = ({ message, onClose }: { message: string, onClose: () => void }) => <Dialog open={message !== ""} onClose={onClose}>
    <DialogTitle>Error</DialogTitle>
    <DialogContent style={{ lineHeight: "25px" }}>{message}</DialogContent>
    <DialogActions><Button onClick={onClose}>OK</Button></DialogActions>
</Dialog>;

export default RolesTab;
